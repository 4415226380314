import { useEffect, useRef, useState } from 'react';
import './info.css';
import { AiBased, HqIcon } from '../../../assets/images';

const Info = () => {
  const [posX1, setPosX1] = useState(0);
  const [posY1, setPosY1] = useState(0);
  const [directionX1, setDirectionX1] = useState(1);
  const [directionY1, setDirectionY1] = useState(1);

  const [posX2, setPosX2] = useState(0);
  const [posY2, setPosY2] = useState(0);
  const [directionX2, setDirectionX2] = useState(1);
  const [directionY2, setDirectionY2] = useState(1);

  const container1Ref = useRef(null);
  const container2Ref = useRef(null);

  const animationId1 = useRef();
  const animationId2 = useRef();

  const box1Style = {
    position: 'absolute',
    top: `20px`,
    left: `${posX1}px`
    // top: `${posY1}px`,
    // left: `20px`
  };

  const box2Style = {
    position: 'absolute',
    bottom: `20px`,
    // bottom: `${posY2}px`,
    // bottom: `20px`,
    right: `${posX2}px`
  };

  useEffect(() => {
    const moveBox1 = () => {
      if (container1Ref.current) {
        const containerWidth = container1Ref.current.offsetWidth - 300;
        const containerHeight = container1Ref.current.offsetHeight - 350; //Not usable right now

        // Horizontal movement
        if (posX1 > containerWidth - 224) {
          setDirectionX1(-1);
        } else if (posX1 <= 0) {
          setDirectionX1(1);
        }
        setPosX1(posX1 + directionX1 * 0.25);

        // Vertical movement
        if (posY1 > containerHeight - 110) {
          setDirectionY1(-1);
        } else if (posY1 <= 0) {
          setDirectionY1(1);
        }
        setPosY1(posY1 + directionY1 * 0.25);
      }

      // Continue the animation
      animationId1.current = window.requestAnimationFrame(moveBox1);
    }

    const moveBox2 = () => {
      if (container2Ref.current) {
        const containerWidth = container2Ref.current.offsetWidth - 120;
        const containerHeight = container2Ref.current.offsetHeight; //Not usable right now

        // Horizontal movement
        if (posX2 > containerWidth - 343) {
          setDirectionX2(-1);
        } else if (posX2 <= 0) {
          setDirectionX2(1);
        }
        setPosX2(posX2 + directionX2 * 0.25);

        // Vertical movement
        if (posY2 > containerHeight - 110) {
          setDirectionY2(-1);
        } else if (posY2 <= 0) {
          setDirectionY2(1);
        }
        setPosY2(posY2 + directionY2 * 0.25);
      }

      // Continue the animation
      animationId2.current = window.requestAnimationFrame(moveBox2);
    }

    // Start the animations
    animationId1.current = window.requestAnimationFrame(moveBox1);
    animationId2.current = window.requestAnimationFrame(moveBox2);

    // Cleanup function to stop the animations when the component is unmounted
    return () => {
      window.cancelAnimationFrame(animationId1.current);
      window.cancelAnimationFrame(animationId2.current);
    }
  }, [posX1, posY1, directionX1, directionY1, posX2, posY2, directionX2, directionY2]);

  return (
    <>
      <br  id='info'/>
      <div id='info-container' className="wrapper">
        <div className='d-flex align-items-center flex-row info'>
          <div className='infoImg-container1' ref={container1Ref}>
            <div className={`infoHome-img1`} style={box1Style}>
              <img src={AiBased} alt="purple-net-icon" />
              <h3>AI-based</h3>
            </div>
          </div>
          <article className='d-flex flex-column justify-content-center align-items-start infoHome-article1'>
            <button className='infoArticle-button'>Service & Treatment</button>
            <h1>AI Based <br/> Brain & Heart Scan</h1>
            <p>Our AI-based QEEG scan technology is a non-invasive and painless way to assess your brain function and identify any potential issues. This technology uses advanced algorithms to analyze your brainwaves and generate a detailed report that helps us understand your brain's unique activity patterns.</p>
          </article>
        </div>
        <div className="d-flex align-items-center  flex-row info">
          <article className='d-flex flex-column justify-contentcenter align-items-end infoHome-article2'>
            <button className='infoArticle-button'>Service & Treatment</button>
            <h1>AI Based <br/> Personalized <br/> Treatment at Home</h1>
            <p>We offer personalized treatment options that are tailored to your specific needs. Our non-invasive treatments include TDCS, TMS, neurofeedback, brain training, and other advanced technological therapies that are designed to improve brain function, reduce stress and anxiety, and enhance overall well-being.</p>
          </article>
          <div className='infoImg-container2' ref={container2Ref} style={{position:'relative'}}>
            <div className="infoHome-img2" style={box2Style}>
              <img src={HqIcon} alt="purple-net-icon" />
              <h3>Highest Quality of Care</h3>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Info;
















// import React from 'react'
// import './info.css'
// import { AiBased, HqIcon } from '../../../assets/images'

// const Info = () => {
//   return (
//     <>
//       <br  id='info'/>
//       <div id='info-container' className="wrapper" /*style={{border:'1px dashed red'}}*/>
//         <div className='d-flex align-items-center flex-row info'>
//           <div className='infoImg-container1'>
//             <div className={`infoHome-img1 ${boxStyle}`}>
//               <img src={AiBased} alt="purple-net-icon" />
//               <h3>AI-based</h3>
//             </div>
//           </div>
//           <article className='d-flex flex-column justify-content-center align-items-start infoHome-article1'>
//             <button className='infoArticle-button'>Service & Treatment</button>
//             <h1>AI Based <br/> Brain & Heart Scan</h1>
//             <p>Our AI-based QEEG scan technology is a non-invasive and painless way to assess your brain function and identify any potential issues. This technology uses advanced algorithms to analyze your brainwaves and generate a detailed report that helps us understand your brain's unique activity patterns.</p>
//           </article>
//         </div>
//         <div className="d-flex align-items-center  flex-row info">
//           <article className='d-flex flex-column justify-content-center align-items-end infoHome-article2'>
//             <button className='infoArticle-button'>Service & Treatment</button>
//             <h1>AI Based <br/> Personalized <br/> Treatment at Home</h1>
//             <p>We offer personalized treatment options that are tailored to your specific needs. Our non-invasive treatments include TDCS, TMS, neurofeedback, brain training, and other advanced technological therapies that are designed to improve brain function, reduce stress and anxiety, and enhance overall well-being.</p>
//           </article>
//           <div className='infoImg-container2' style={{position:'relative'}}>
//             <div className="infoHome-img2">
//               <img src={HqIcon} alt="purple-net-icon" />
//               <h3>Highest Quality of Care</h3>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   )
// }

// export default Info