import { React, useState } from 'react'
import './cooperateHeader.css'
import {Link} from "react-router-dom";

const CoooperateHeaderMilitary = () => {

    return (
      <header id='headerDiagnostic' className='d-flex flex-column'>
        <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
          <h1>Cooperate</h1>
          <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and cognitive abilities, identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
          <a className='navbar-Respbtn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
        </article>
        <div className="breakline-diagnostic"></div>
  
        <div className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
          <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/L38T6fnrVjc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>

        <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                <h1>DeepSynaps Military Performance Enhancement Package:</h1>
                  <ol>
                      <li>
                          <p><b>Comprehensive Physical and Cognitive Assessment:</b></p>
                          <ul>
                              <li>
                                  <p><b>Body Scan:</b></p>
                                  <p>Including EMG, ECG/Pulse, Respiratory, Temperature, Skin condition, SCP, and EOG scans to assess soldiers' physical health, stress levels, and physiological responses in training and combat situations.</p>
                              </li>
                              <li>
                                  <p><b>Brain Scan (QEEG Brain Mapping):</b></p>
                                  <p>Both Dry and Wet EEG scans to assess cognitive readiness, identify areas for improvement, and detect potential cognitive challenges.</p>
                              </li>
                          </ul>
                      </li>

                      <li>
                          <p><b>Mental Resilience and Cognitive Enhancement:</b></p>
                          <ul>
                              <li>
                                  <p><b>Neurofeedback Sessions:</b></p>
                                  <p>Personalized neurofeedback training to enhance focus, concentration, and mental resilience, helping soldiers maintain peak cognitive states during high-pressure situations.</p>
                              </li>
                              <li>
                                  <p><b>Neuromodulation (TDCS-PBM):</b></p>
                                  <p>Transcranial Direct Current Stimulation (TDCS) and Photobiomodulation (PBM) sessions to optimize cognitive function, reduce mental fatigue, and improve decision-making under stress.</p>
                              </li>
                              <li>
                                  <p><b>Biofeedback Sessions:</b></p>
                                  <p>Customized biofeedback sessions to manage stress, anxiety, and optimize mental states before and during missions.</p>
                              </li>

                              <li>
                                  <p><b>Combat-Ready Mental Training:</b></p>
                                  <p>Specialized mental training to prepare soldiers for the psychological challenges of combat and stress management techniques for post-mission recovery.</p>
                              </li>
                          </ul>
                      </li>

                      <li>
                          <p><b>Tactical Training Integration:</b></p>
                          <p>- Integration of DeepSynaps technology into tactical training exercises to assess and enhance soldiers' cognitive and physiological readiness.</p>
                          <p>- Simulation-based training scenarios with real-time cognitive monitoring and feedback.</p>
                      </li>

                      <li>
                          <p><b>Reporting and Performance Monitoring:</b></p>
                          <p>- Detailed reports summarizing soldiers' physical and cognitive assessment results and progress over time.</p>
                          <p>- Regular monitoring of data to track improvements and tailor training programs accordingly.</p>
                      </li>

                      <li>
                          <p><b>Equipment and Software:</b></p>
                          <p>DeepSynaps will provide military-grade diagnostic equipment, software licenses, and technical support for seamless integration into training facilities and operational environments.</p>
                      </li>

                      <li>
                          <p><b>Leadership and Team Building:</b></p>
                          <p>Leadership and team-building workshops to improve communication, cooperation, and decision-making among units.</p>
                      </li>
                      <li>
                          <p><b>Confidentiality and Security:</b></p>
                          <p>Ensuring the highest level of data security and confidentiality for sensitive military personnel information.</p>
                      </li>
                  </ol>
                  <p>This package is designed to help military organizations optimize the performance and mental resilience of their soldiers. It combines advanced technology with targeted training and support to prepare military personnel for the physical and cognitive challenges of their roles. DeepSynaps will work closely with military units to customize the package to meet their specific needs and operational requirements while maintaining the highest standards of security and confidentiality.</p>
              </div>
            </div>
        </div>
      </header>
    )
}

export default CoooperateHeaderMilitary