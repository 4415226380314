import React, { useState } from 'react'
import './headerTreatments.css'

const HeaderBiofeedback = () => {
  const [showDiagnostic1, setShowDiagnostic1] = useState(true)
  const [showDiagnostic2, setShowDiagnostic2] = useState(false)
  const [showDiagnostic3, setShowDiagnostic3] = useState(false)
  const [showDiagnostic4, setShowDiagnostic4] = useState(false)
  const [showDiagnostic5, setShowDiagnostic5] = useState(false)
  const [showDiagnostic6, setShowDiagnostic6] = useState(false)

  const showSection1 = () => { // Showing 1st element
    setShowDiagnostic1(true)
    setShowDiagnostic2(false)
    setShowDiagnostic3(false)
    setShowDiagnostic4(false)
    setShowDiagnostic5(false)
    setShowDiagnostic6(false)
  }

  const showSection2 = () => { // Showing 2nd element
    setShowDiagnostic1(false)
    setShowDiagnostic2(true)
    setShowDiagnostic3(false)
    setShowDiagnostic4(false)
    setShowDiagnostic5(false)
    setShowDiagnostic6(false)
  }

  const showSection3 = () => { // Showing 3rd element
    setShowDiagnostic1(false)
    setShowDiagnostic2(false)
    setShowDiagnostic3(true)
    setShowDiagnostic4(false)
    setShowDiagnostic5(false)
    setShowDiagnostic6(false)
  }

  const showSection4 = () => { // Showing 4th element
    setShowDiagnostic1(false)
    setShowDiagnostic2(false)
    setShowDiagnostic3(false)
    setShowDiagnostic4(true)
    setShowDiagnostic5(false)
    setShowDiagnostic6(false)
  }

  const showSection5 = () => { // Showing 4th element
    setShowDiagnostic1(false)
    setShowDiagnostic2(false)
    setShowDiagnostic3(false)
    setShowDiagnostic4(false)
    setShowDiagnostic5(true)
    setShowDiagnostic6(false)
  }


  const showSection6 = () => { // Showing 4th element
    setShowDiagnostic1(false)
    setShowDiagnostic2(false)
    setShowDiagnostic3(false)
    setShowDiagnostic4(false)
    setShowDiagnostic5(false)
    setShowDiagnostic6(true)
  }

  return (
    <>
      <header id='headerDiagnostic' className='d-flex flex-column'>
        <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
          <h1>Interventions</h1>
          <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
          <a className='navbar-Respbtn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
        </article>
        <div className="breakline-diagnostic"></div>


        <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
          <div className="headerDiagnostic-options d-flex flex-column align-items-start">
            <button className={showDiagnostic1 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection1}>What is Biofeedback?</button>
            <button className={showDiagnostic2 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection2}>Our Advanced Biofeedback Services</button>
            <button className={showDiagnostic3 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection3}>Who Can Benefit From Biofeedback?</button>
            <button className={showDiagnostic4 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection4}>Your Journey With Us: What to Expect</button>
            <button className={showDiagnostic5 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection5}>Preparation Guide for Biofeedback Session</button>
            <button className={showDiagnostic6 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection6}>Why Choose Us?</button>
          </div>
          {showDiagnostic1 && 
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle3 d-flex flex-column align-items-start">
                <h1>What is Biofeedback?</h1>
                <p>Biofeedback is a powerful technique that harnesses the power of electronic monitoring to give real-time feedback on various physiological functions. This allows individuals to learn how to modify and control their body's responses, such as brain waves, muscle tone, skin temperature, and more. The goal? To promote relaxation, reduce stress, and alleviate specific medical symptoms, offering a pathway to enhanced well-being.</p>
              </div>
            </div>
          }{showDiagnostic2 && 
            <div className="headerDiagnostic-content">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>Our Advanced Biofeedback Services</h1>
                <div className="howItWorksOL">
                  <p>We offer a comprehensive suite of biofeedback services to help you delve deep into your physiological and neurological states:</p>
                  <p><b>1. EMG (Electromyography):</b> A tool for measuring muscle electrical activity, aiding in muscle tension control. </p>
                  <p><b>2. ECG/Pulse (Electrocardiography):</b> This service gauges your heart's electrical activities to manage stress or cardiac conditions.</p>
                  <p><b>3. Respiratory:</b> A deep insight into your breathing rate and patterns.</p>
                  <p><b>4. Temperature:</b> A diagnostic for understanding skin temperature and related blood flow.</p>
                  <p><b>5. Skin Conduction (GSR):</b> Monitor skin moisture levels, indicating physiological arousal.</p>
                  <p><b>6. SCP (Slow Cortical Potential):</b> Delve deep into the brain's electrical potentials for conditions like ADHD.</p>
                  <p><b>7. EOG (Electrooculography):</b> A pivotal tool for tracking eye movements, used in sleep studies and more.</p>
                </div>
              </div>
            </div>
          }{showDiagnostic3 &&
            <div className="headerDiagnostic-content">
              <div className="diagnosticArticle3 d-flex flex-column align-items-start">
                <h1>Who Can Benefit From Biofeedback?</h1>
                <div className="conditionsUL d-flex flex-row">
                  <div className="workingAreas">
                    <p><b>From general wellness enthusiasts to patients with specific conditions, our services cater to a wide audience:</b></p>
                    <ul style={{gap:'12px'}} className='d-flex flex-column'>
                      <li>Stress & Anxiety Relief</li>
                      <li>Pain Management for Chronic Conditions</li>
                      <li>Cardiovascular Condition Management</li>
                      <li>Sleep Disorder Treatment</li>
                      <li>Rehabilitation After Injuries</li>
                      <li>Athletic Performance Enhancement</li>
                      <li>... and much more!</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          }{showDiagnostic4 &&
            <div className="headerDiagnostic-content">
              <div className="diagnosticArticle4 d-flex flex-column align-items-start">
                <h1>Your Journey With Us: What to Expect</h1>
                <p><b>1. Initial Assessment:</b> Understand your medical history and symptoms.</p>
                <p><b>2. Mental Health Assessment:</b> Gauge cognitive and emotional well-being.</p>
                <p><b>3. Physiological Stress Test:</b> Using the Nexus 32 device, we’ll delve deep into your body's physiological responses, providing a comprehensive overview.</p>
                <p><b>4. During The Session</b> Comfort is key. You'll be seated as our technicians attach non-invasive sensors. Real-time data is displayed, helping us understand your body’s responses.</p>
                <p><b>5. Post-Session Analysis:</b> A comprehensive report and discussion on therapeutic interventions or strategies tailored for you.</p>
              </div>
            </div>
          }
          {showDiagnostic5 &&
              <div className="headerDiagnostic-content">
                <div className="diagnosticArticle4 d-flex flex-column align-items-start">
                  <h1>Preparation Guide for Biofeedback Session</h1>
                  <p><b>Cleanliness:</b> Clean skin, free from lotions or oils.</p>
                  <p><b>Clothing:</b> Opt for comfort, avoiding metallic elements.</p>
                  <p><b>Diet:</b> Avoid caffeine or nicotine 3 hours prior. Opt for a light, low-sugar meal/snack.</p>
                  <p><b>Medications:</b> Inform us about any medication you're on.</p>
                  <p><b>Rest:</b> Come well-rested.</p>
                  <p><b>Mental State:</b> An open mind works best. Share any concerns with us!</p>
                </div>
              </div>
          }
          {showDiagnostic6 &&
              <div className="headerDiagnostic-content">
                <div className="diagnosticArticle4 d-flex flex-column align-items-start">
                  <h1>Why Choose Us?</h1>
                  <p>Our state-of-the-art Nexus 32 qEEG, neuro, and biofeedback device positions us at the forefront of understanding the mind-body connection. Dive deep with us, and let’s pave your path to holistic health and wellness!</p>
                  <p>For inquiries, please contact us at <a href="mailto: info@deepsynaps.com">info@deepsynaps.com</a>.</p>
                  <p>Welcome to a transformative journey of self-discovery and optimized health. Dive deep into the intricacies of your body and mind with us!</p>
                </div>
              </div>
          }
        </div>
      </header>
    </>
  )
}

export default HeaderBiofeedback