import React from 'react'
import './monthlySubscription.css'


const MonthlySubscription = () => {

  // const handleRedirect = () => {
  //   // Redirect page to pabau sign-up page
  //   window.location.href = 'https://connect.pabau.com/connectreg.php?compid=13311';
  // };

  return (
    <>
      <div id='monthlySub' className="wrapper d-flex flex-column align-items-end">
        <div className="monthlySub d-flex flex-row justify-content-between align-items-center">
          <article>
            <p>Premium</p>
            <h1>Monthly Subscription</h1>
          </article>
          <div className="sub-detail d-flex flex-column justify-content-center">
            <div className="subDetail-header">
              <button className="sub-details-Infobtn">Subscription</button>
              {/* <p>Scans & Treatments</p> */} <br />
              <h2>£700/mth</h2>
            </div>
            <ul>
              <li>Mental health assessment and Report (10)</li>
              <li>Cognitive test and Report (6)</li>
              <li>QEEG scan and Report (2)</li>
              <li>Tdcs or PBM headset, (4 months) subscription and personalized protocol</li>
              <li>Biofeedback (20 sessions)</li>
              <li>Neurofeedback (20 Sessions)</li>
            </ul>
            <a className="sub-details-btn" href="https://connect.pabau.com/connectreg.php?compid=13311" target='_blank' rel="noreferrer">Subscribe Now</a> {/*  */}
          </div>
        </div>
      </div>
    </>
  )
}

export default MonthlySubscription