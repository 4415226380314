import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';


const GeneralCognitiveAssessment = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                        <h1>General Cognitive Assessment (CAB)</h1>
                        <p>The General Assessment Battery (CAB) helps healthcare professionals precisely evaluate a wide range of abilities and detect cognitive well-being (high-moderate-low) in patients. With this tool, the health professional can identify strengths and weaknesses in the areas of memory, concentration/attention, executive functions, planning, and coordination, as well as, discover the general cognitive profile of the patient. The General Assessment Battery (CAB) serves as an essential tool in the hands of healthcare professionals, aiming to deliver a comprehensive evaluation of a spectrum of cognitive capabilities, thereby determining the cognitive well-being spectrum from high to low. When used in tandem with various tools and therapies, it can provide a more comprehensive insight into cognitive functioning. This test is suitable for children 7+, teens, adults or seniors. The battery can be used cross-culturally, and for both males and females.</p>
                        <p>The General Assessment Battery (CAB) serves as a pivotal tool for healthcare professionals, offering a comprehensive analysis of a variety of cognitive capabilities, thereby determining a spectrum from high to low cognitive well-being. When applied in conjunction with other tools and therapies, it offers deeper insights into cognitive functioning.</p>
                        <p><strong>Relation to QEEG,Neurofeedback,biofeedback and Neuromodulation</strong></p>

                        <ul>
                            <li>
                                <strong>Memory:</strong>
                                <ul>
                                    <li><strong>Brain Regions and QEEG:</strong> Memory processes are chiefly linked to the hippocampus and temporal lobes. Discrepancies or strengths in memory might be evident as distinct patterns in these areas when evaluated using QEEG.</li>
                                    <li><strong>Biofeedback:</strong> Electrocardiography (ECG) can monitor heart rate patterns, such as Heart Rate Variability (HRV), to assess reactions related to memory encoding or retrieval.</li>
                                    <li><strong>Neurofeedback:</strong> Training of the Alpha brain waves may enhance memory-related activities.</li>
                                    <li><strong>tDCS:</strong> Focusing on the temporal regions, this method can potentially modulate memory processes.</li>
                                    <li><strong>PBM:</strong> Therapy targeting the hippocampus can stimulate neuronal activity, promoting better memory retention and recall.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Concentration/Attention:</strong>
                                <ul>
                                    <li><strong>Brain Regions and QEEG:</strong> The prefrontal cortex and parietal lobes play pivotal roles in attentional control. Shifts in attention might have corresponding patterns when evaluated using QEEG.</li>
                                    <li><strong>Biofeedback:</strong> Electromyography (EMG) gauges muscle tension during tasks demanding concentration. </li>
                                    <li><strong>Neurofeedback:</strong> Sensory Motor Rhythm (SMR) training can boost attentional control.</li>
                                    <li><strong>tDCS:</strong> Energizing the prefrontal cortex can fine-tune attention and concentration capabilities.</li>
                                    <li><strong>PBM:</strong> Implementing red to near-infrared light on the parietal and prefrontal areas can bolster the metabolic capacity of neurons, which may benefit attention mechanisms.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Executive Functions:</strong>
                                <ul>
                                    <li><strong>Brain Regions and QEEG:</strong> Executive tasks are predominantly connected to the frontal lobes, especially the dorsolateral prefrontal cortex.</li>
                                    <li><strong>Biofeedback:</strong> Skin Conduction (SC) readings provide insights into physiological arousal linked to decision-making processes.</li>
                                    <li><strong>Neurofeedback:</strong> Uptraining of Beta waves might refine executive processing.</li>
                                    <li><strong>tDCS:</strong> Targeting the dorsolateral prefrontal cortex can better executive decision-making capacities.</li>
                                    <li><strong>PBM:</strong> Using light therapy on frontal areas can foster synaptic plasticity, potentially benefiting executive functionalities.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Planning:</strong>
                                <ul>
                                    <li><strong>Brain Regions and QEEG:</strong> Planning skills are related to the frontal lobes.</li>
                                    <li><strong>Neurofeedback:</strong> Distinct training protocols might enhance planning abilities.</li>
                                    <li><strong>tDCS:</strong> Energizing frontal areas can modulate tasks linked to planning.</li>
                                    <li><strong>PBM:</strong> Directing light onto frontal sections can aid planning processes.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Coordination:</strong>
                                <ul>
                                    <li><strong>Brain Regions and QEEG:</strong> Coordination is primarily linked to the cerebellum and basal ganglia.</li>
                                    <li><strong>Biofeedback:</strong> EMG is fundamental for gauging muscle coordination.</li>
                                    <li><strong>Neurofeedback:</strong> Protocols enhancing coherence between the cerebellum and motor cortex can refine coordination.</li>
                                    <li><strong>tDCS:</strong> Focusing on the cerebellum can bolster motor coordination abilities.</li>
                                    <li><strong>PBM:</strong> Targeting the cerebellar section with light therapy can result in improved coordination results.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>Leveraging the General Assessment Battery in tandem with these state-of-the-art techniques offers a holistic view of cognitive health. This equips professionals to pinpoint cognitive strengths and weaknesses, paving the path for bespoke interventions for cognitive enhancement.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default GeneralCognitiveAssessment