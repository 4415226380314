import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';
import {Link} from "react-router-dom";


const UsefulFieldOfViewTest = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                        <div className={"row w-100"}>
                            <div className={"col-md-8 col-sm-12"}><h1>Useful Field of View Test</h1></div>
                            <div className={"col-md-4 col-sm-12 text-md-end text-sm-left pt-1"}><Link target={"_blank"} className={"btn btn-outline-dark"} to={'https://www.cognifit.com/battery-of-tests/wifivi-test/visual-capacity-test'}>Start test now</Link></div>
                        </div>
                        <p>This test is designed to assess an individual’s visual attention span, especially pertaining to the ability to process and respond to peripheral visual information without moving the eyes or head.</p>
                        <p><strong>Relation to QEEG, Neurofeedback, Biofeedback, and Neuromodulation:</strong></p>
                        <ul>
                            <li><strong>Brain Region:</strong> Occipital Lobes, Parietal Lobes</li>
                            <li><strong>Brodmann Area:</strong> BA 7 (Parietal), BA 18 & 19 (Occipital)</li>
                            <li><strong>EEG Electrode Places:</strong> O1, O2, P3, P4</li>
                            <li>
                                <strong>Neurofeedback:</strong>
                                <ul>
                                    <li><strong>Protocol:</strong> Reinforcing stability and responsiveness in alpha (8-12 Hz) and beta (13-30 Hz) frequency bands, particularly within regions responsible for visual processing and spatial attention.</li>
                                    <li><strong>Objective:</strong> To optimize the efficiency and reactivity of neural networks associated with visual attention and peripheral processing.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Biofeedback:</strong>
                                <ul>
                                    <li><strong>Assessment:</strong> HRV (Heart Rate Variability) to assess stress and attentional engagement, potentially employing EOG (Electrooculography) to evaluate eye movement and fixation patterns.</li>
                                    <li><strong>Protocol:</strong> Training individuals to manage physiological responses, such as stress or distractibility, especially during instances that require focused and peripheral visual attention.</li>
                                    <li><strong>Objective:</strong> To ensure that physiological factors do not detract from optimal visual attention and processing.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Neuromodulation:</strong>
                                <ul>
                                    <li>
                                        <strong>tDCS:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Occipital and Parietal Lobes</li>
                                            <li><strong>Protocol:</strong> Implementing anodal tDCS to enhance neuronal excitability within visual and attention-related networks.</li>
                                            <li><strong>Objective:</strong> To facilitate the neural mechanisms that underlie visual attention and peripheral processing, thus enhancing performance.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>PBM:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Occipital and Parietal Lobes</li>
                                            <li><strong>Protocol:</strong> Employing photobiomodulation to foster neural health and functioning in the targeted regions.</li>
                                            <li><strong>Objective:</strong> To support the integrity and functionality of neural circuits implicated in visual attention and peripheral processing.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Mental Health Assessments and Their Relation:</strong>
                                <ul>
                                    <li><strong>Holmes-Rahe Life Stress Inventory:</strong> Elevated life stress may potentially impact visual attention capabilities, potentially due to the cognitive and emotional load that stress exerts.</li>
                                    <li><strong>SLEEP SCALE:</strong> Adequate and quality sleep is crucial for optimal visual attention and processing. Disruptions or deficits in sleep may detrimentally impact performance in tasks requiring focused and peripheral visual attention.</li>
                                    <li><strong>Friendship Scale:</strong> Social support and relational satisfaction might influence stress levels and emotional well-being, which can indirectly impact cognitive faculties like visual attention.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>In light of the mental health assessments, a holistic approach that encompasses emotional, social, and physiological factors, alongside cognitive faculties, would yield a comprehensive and nuanced understanding of the individual. This would enable more tailored and effective application of neurofeedback, biofeedback, and neuromodulation interventions. Always ensure that these strategies are employed under the guidance of a qualified professional to ascertain safety and efficacy.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default UsefulFieldOfViewTest