import React, { useState } from 'react'
import './innovation.css'

const Innovation = () => {

  const [showArticle1, setShowArticle1] = useState(true)
  const [showArticle2, setShowArticle2] = useState(false)

  const showTraining = () => {
    setShowArticle1(true)
    setShowArticle2(false)
  }

  const showConsultation = () => {
    setShowArticle1(false)
    setShowArticle2(true)
  }

  return (
    <>
        <div id='innovation' className='wrapper d-flex flex-column justify-content-center align-items-center'>
          <article className='header-article'>
            <h1>Innovation</h1>
          </article>
          <div className="innovation-content d-flex flex-column align-items-start">
            <div className="innovation-content-upperCard-container d-flex flex-row align-items-start">
              <div className="innovation-content-upperCard d-flex flex-column align-items-start">
                <h3>DeepSynaps Analytics</h3>
                <p>DeepSynaps Analitica will focus on data anonymization and analysis to predict and prevent different neurological and mental health condations via machine learning ,AI and deep learning technics</p>
              </div>
              <div className="innovation-content-upperCard d-flex flex-column align-items-start">
                <h3>Software and Hardware Development</h3>
                <p>Mintech innovation is focusing on AI based software and hardware development project</p>
              </div>
            </div>
            <div className="innovation-content-lowerCard-container d-flex flex-row align-items-start">
              <div className="innovation-content-lowerCard1"></div>
              <div className="innovation-content-lowerCard2 d-flex flex-column align-items-start">
                <div className="links d-flex align-items-start">
                  <button className={showArticle1 ? 'innovation-active-btn' : 'innovation-passive-btn'} onClick={showTraining}>Trainings</button>
                  <button className={showArticle2 ? 'innovation-active-btn' : 'innovation-passive-btn'} onClick={showConsultation}>Consultations</button>
                </div>
                {showArticle1 &&
                  <article id='article1' className='d-flex flex-column justify-content-center align-items-start'>
                    <p>The DeepSynaps academy provide online and face to face trainings. It will create an online learning platform for the companies.</p>
                    <ul>
                      <li>rTMS Training and Practice</li>
                      <li>tDCS Training and Practice</li>
                      <li>QEEG Training and Practice</li>
                      <li>Photon bio modulation training and practice</li>
                    </ul>
                    <p>After the compulsory training individuals will have certificate of attendance and the clinic will have cosmos health franchising certification of memberships</p>
                  </article>
                }
                {showArticle2 &&
                  <iframe title='leadForm-innovation' className='innovation-leadForm' src='https://crm.pabau.com/modules/lead-form/form.php?compid=13311'></iframe>
                }
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default Innovation

