import { React, useState } from 'react'
import './cooperateHeader.css'
import {Link} from "react-router-dom";

const CoooperateHeaderSchools = () => {

    return (
      <header id='headerDiagnostic' className='d-flex flex-column'>
        <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
          <h1>Cooperate</h1>
          <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and cognitive abilities, identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
          <a className='navbar-Respbtn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
        </article>
        <div className="breakline-diagnostic"></div>
  
        <div className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
          <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/L38T6fnrVjc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>

        <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                <h1>DeepSynaps School Wellness and Educational Enhancement Package:</h1>
                  <ol>
                      <li>
                          <p><b>Mental Health and Educational Assessment:</b></p>
                          <ul>
                              <li>
                                  <p><b>Mental Health Assessment:</b></p>
                                  <p>Assessment tools and questionnaires to evaluate students' emotional well-being, stress levels, and overall mental health.</p>
                              </li>
                              <li>
                                  <p><b>Cognitive Assessment:</b></p>
                                  <p>Comprehensive cognitive assessment tests to measure students' cognitive abilities, memory, attention, and problem-solving skills.</p>
                              </li>
                          </ul>
                      </li>

                      <li>
                          <p><b>Biofeedback and Neurofeedback Sessions:</b></p>
                          <p>- Customized biofeedback sessions for students to learn stress management and relaxation techniques, helping them deal with academic and emotional stress effectively.</p>
                          <p>- Personalized neurofeedback training sessions to enhance students' focus, concentration, and cognitive performance, supporting their academic success.</p>
                      </li>

                      <li>
                          <p><b>Brain Mapping and Analysis:</b></p>
                          <ul>
                              <li>
                                  <p><b>QEEG Brain Mapping:</b></p>
                                  <p>Both Dry and Wet EEG scans to assess brain activity, identify learning patterns, and detect any potential learning difficulties or cognitive challenges.</p>
                              </li>
                          </ul>
                      </li>
                      <li>
                          <p><b>Teacher Training:</b></p>
                          <p>- Training for school teachers and staff on recognizing and supporting students with varying cognitive and emotional needs.</p>
                          <p>- Guidance on integrating biofeedback and neurofeedback techniques into the classroom environment to enhance learning and emotional well-being.</p>
                      </li>

                      <li>
                          <p><b>Reporting and Monitoring:</b></p>
                          <p>- Detailed reports summarizing students' assessment results and progress over time.</p>
                          <p>- Regular monitoring of student data to track improvements and tailor interventions accordingly.</p>
                      </li>
                      <li>
                          <p><b>Equipment and Software:</b></p>
                          <p>- DeepSynaps will provide all necessary diagnostic equipment, software licenses, and technical support for seamless integration into the school environment.</p>
                          <p>- Access to an easy-to-use platform for teachers and school counselors to track student progress and well-being.</p>
                      </li>
                      <li>
                          <p><b>Parental Involvement:</b></p>
                          <p>Workshops and resources for parents to better understand and support their children's cognitive and emotional development.</p>
                      </li>
                  </ol>
                  <p>This specialized package is designed to promote the mental health and cognitive development of students within the school setting. It aims to create a supportive and enriched learning environment where students can thrive academically and emotionally. DeepSynaps will collaborate closely with the school to adapt the package to meet the specific needs of students, teachers, and the educational institution as a whole.</p>
              </div>
            </div>
        </div>
      </header>
    )
}

export default CoooperateHeaderSchools