import React from 'react'
import './headerAbout.css'
import { HeaderAboutImg } from '../../../assets/images'

const HeaderAbout = () => {
  return (
    <>
      <header id='headerAbout' className='wrapper d-flex flex-column'>
        <div className="header-title">
          <article className='d-flex flex-row align-items-center'>
            <h1>Welcome to DeepSynaps: Nurturing Mindful Innovations</h1>
            <p>In the epoch of technological revolution, DeepSynaps emerges as a beacon of innovation, blending the meticulous realms of neurology and artificial intelligence to carve out a niche in personalized brain and mental health solutions. Engaging with the profound depths of cognitive function and mental well-being, we curate solutions that not only understand but speak the unique neurological language of every individual.</p>
          </article>
        </div>
        <div className="header-image">
          <img src={HeaderAboutImg} alt="" />
        </div>
      </header>
    </>
  )
}

export default HeaderAbout