import { React } from 'react'
import './cooperateHeader.css'

const CoooperateHeaderBusinessAndManagement = () => {

    return (
      <header id='headerDiagnostic' className='d-flex flex-column'>
        <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
          <h1>Cooperate</h1>
          <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and cognitive abilities, identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
          <a className='navbar-Respbtn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
        </article>
        <div className="breakline-diagnostic"></div>
  
        <div className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
          <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/L38T6fnrVjc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>

        <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                <h1>DeepSynaps Business and Management Performance Enhancement Package:</h1>
                  <ol>
                      <li>
                          <p><b>Executive and Employee Wellness Assessment:</b></p>
                          <ul>
                              <li>
                                  <p><b>Mental Health Assessment:</b></p>
                                  <p>Assessment tools and questionnaires to evaluate the mental well-being and stress levels of executives and employees.</p>
                              </li>
                              <li>
                                  <p><b>Cognitive Assessment:</b></p>
                                  <p>Comprehensive cognitive assessments to measure cognitive abilities, memory, attention, and problem-solving skills of key personnel.</p>
                              </li>
                          </ul>
                      </li>

                      <li>
                          <p><b>Leadership and Team Optimization:</b></p>
                          <p>- Personalized leadership assessments for executives to identify strengths and areas for improvement.</p>
                          <p>- Team assessments to enhance communication, cooperation, and decision-making among management teams and departments.</p>
                      </li>

                      <li>
                          <p><b>Personalized Training and Coaching:</b></p>
                          <ul>
                              <li>
                                  <p><b>Neurofeedback Sessions:</b></p>
                                  <p>Personalized neurofeedback training to improve focus, concentration, and cognitive performance, helping executives make informed decisions and manage stress effectively.</p>
                              </li>
                              <li>
                                  <p><b>Neuromodulation (TDCS-PBM):</b></p>
                                  <p>Transcranial Direct Current Stimulation (TDCS) and Photobiomodulation (PBM) sessions to enhance cognitive function, reduce mental fatigue, and promote creativity.</p>
                              </li>
                              <li>
                                  <p><b>Biofeedback Sessions:</b></p>
                                  <p>Customized biofeedback sessions to manage stress, anxiety, and optimize mental states for improved leadership and decision-making.</p>
                              </li>
                          </ul>
                      </li>

                      <li>
                          <p><b>Performance Optimization Workshops:</b></p>
                          <p>- Leadership development workshops to help executives and managers develop key leadership skills, such as strategic thinking and emotional intelligence.</p>
                          <p>- Stress management workshops for employees to enhance their resilience and well-being.</p>
                      </li>

                      <li>
                          <p><b>Reporting and Progress Tracking:</b></p>
                          <p>- Detailed reports summarizing assessment results and progress over time for both executives and employees.</p>
                          <p>- Regular monitoring of data to track improvements and adjust training and coaching programs as needed.</p>
                      </li>

                      <li>
                          <p><b>Equipment and Software:</b></p>
                          <p>DeepSynaps will provide diagnostic equipment, software licenses, and technical support to facilitate effective assessments and training.</p>
                      </li>

                      <li>
                          <p><b>Employee Engagement and Well-being:</b></p>
                          <p>Strategies and initiatives to enhance employee engagement, satisfaction, and overall well-being.</p>
                      </li>

                      <li>
                          <p><b>Confidentiality and Data Security:</b></p>
                          <p>Ensuring the highest level of data security and confidentiality for sensitive employee information.</p>
                      </li>

                  </ol>
                  <p>This package is designed to support businesses and management teams in optimizing leadership, decision-making, and employee well-being. By integrating cognitive and mental wellness assessments, training, and coaching, it aims to enhance leadership effectiveness, employee performance, and overall workplace productivity. DeepSynaps will collaborate closely with businesses to tailor the package to meet their specific goals and organizational needs, ultimately contributing to a more resilient and high-performing workforce.</p>
              </div>
            </div>
        </div>
      </header>
    )
}

export default CoooperateHeaderBusinessAndManagement