import React from 'react'
import { SEO, Navbar, HeaderDiagnostic, Footer, CombinationPricing, CombinationSubs } from '../Components'

const Diagnostic = () => {
  return (
    <>
      <SEO robots = 'noindex, nofollow' url='https://oxfordmindtech.com/diagnostic'/>
      <Navbar/>
      <HeaderDiagnostic />
      <CombinationPricing />
      <CombinationSubs />
      <Footer />
    </>
  )
}

export default Diagnostic