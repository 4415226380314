import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';
import { Link } from 'react-router-dom';


const TowerOfHanoiTest = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                        <div className={"row w-100"}>
                            <div className={"col-md-8 col-sm-12"}><h1>Tower of Hanoi Test (CAT-TOH)</h1></div>
                            <div className={"col-md-4 col-sm-12 text-md-end text-sm-left pt-1"}>
                                <Link target={"_blank"} className={"btn btn-outline-dark"} to={'https://www.cognifit.com/gb/battery-of-tests/tower-of-hanoi'}>Start test now</Link>
                            </div>
                        </div>

                        <p>Gauge your planning, organization, and problem-solving abilities through strategic movement of discs.</p>
                        <p><strong>Relation to QEEG, Neurofeedback, Biofeedback, and Neuromodulation:</strong></p>
                        <ul>
                            <li><strong>Brain Region:</strong> Prefrontal Cortex, Parietal Lobes</li>
                            <li><strong>Broadman Area:</strong> 9, 10, 46 (Prefrontal Cortex); 7, 40 (Parietal Lobes)</li>
                            <li><strong>EEG Electrode Places:</strong> Fz, Pz, F4, F3, P3, P4 (Standard 10-20 system places)</li>
                            <li>
                                <strong>Neurofeedback:</strong>
                                <ul>
                                    <li><strong>Protocol:</strong> Enhancing beta wave activity in the prefrontal cortex and modulating theta-beta ratios for improved planning and cognitive control.</li>
                                    <li><strong>Objective:</strong> To foster improved strategic planning, problem-solving, and task persistence.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Biofeedback:</strong>
                                <ul>
                                    <li><strong>Assessment:</strong> Utilizing GSR, HRV, and EMG to assess stress response, physiological arousal, and muscle tension during cognitive task performance.</li>
                                    <li><strong>Protocol:</strong> Training for adaptive physiological responses to cognitive stressors and improved muscle relaxation during task performance.</li>
                                    <li><strong>Objective:</strong> To manage physiological stress responses and ensure cognitive resources are optimally allocated during problem-solving tasks.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Neuromodulation:</strong>
                                <ul>
                                    <li>
                                        <strong>tDCS:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Dorsolateral Prefrontal Cortex (DLPFC)</li>
                                            <li><strong>Protocol:</strong> Applying anodal stimulation to DLPFC to potentially enhance executive functions related to planning and organization.</li>
                                            <li><strong>Objective:</strong> To enhance the neural circuits involved in problem-solving and strategic planning.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>PBM:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Prefrontal Cortex</li>
                                            <li><strong>Protocol:</strong> Using photobiomodulation to enhance cellular metabolism and connectivity in neural circuits involved in problem-solving.</li>
                                            <li><strong>Objective:</strong> To optimize neuronal functionality and support cognitive resources during task performance.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Mental Health Assessments and Their Relation:</strong>
                                <ul>
                                    <li><strong>DASS 21/42:</strong> Stress, anxiety, and depression (assessed through DASS) can negatively impact cognitive resources and executive function, providing context to performance on problem-solving tasks like the Tower of Hanoi Test.</li>
                                    <li><strong>Holmes Rahe Life Event Scale:</strong> Understanding an individual’s recent stress history (via the Holmes Rahe Scale) provides context for current cognitive performance and potential stress impacts on problem-solving capabilities.</li>
                                    <li><strong>Personality Scale:</strong> Delineating personality traits can offer insight into cognitive style, problem-solving approach, and potential stress responses during cognitive task performance.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>Employing the Tower of Hanoi Test within a comprehensive cognitive assessment and intervention framework provides an enriched understanding of an individual’s problem-solving capabilities, underpinned by their neural, physiological, and mental health context. Combining cognitive assessments with neurofeedback, biofeedback, and neuromodulation facilitates a multi-faceted approach to understanding and enhancing cognitive performance amidst varying mental health states.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default TowerOfHanoiTest