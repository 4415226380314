import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';
import { Link } from 'react-router-dom';


const MazeTest = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">

                        <div className={"row w-100"}>
                            <div className={"col-md-8 col-sm-12"}><h1>Maze Test</h1></div>
                            <div className={"col-md-4 col-sm-12 text-md-end text-sm-left pt-1"}>
                                <Link target={"_blank"} className={"btn btn-outline-dark"} to={'https://www.cognifit.com/battery-of-tests/viper-plan-test/programming-test'}>Start test now</Link>
                            </div>
                        </div>

                        <p>Navigate through complex paths to assess planning and problem-solving skills.</p>
                        <p><strong>Relation to QEEG, Neurofeedback, Biofeedback, and Neuromodulation:</strong></p>
                        <ul>
                            <li><strong>Brain Region:</strong> Prefrontal Cortex, Parietal Lobe</li>
                            <li><strong>Brodmann Area:</strong> BA 9, BA 10 (Prefrontal), BA 7 (Parietal)</li>
                            <li><strong>EEG Electrode Places:</strong> Fp1, Fp2, Pz</li>
                            <li>
                                <strong>Neurofeedback:</strong>
                                <ul>
                                    <li><strong>Protocol:</strong> Engaging in protocols that aim to enhance beta (13-30 Hz) activity within the prefrontal and parietal areas, which are implicated in planning and spatial navigation.</li>
                                    <li><strong>Objective:</strong> To potentially enhance cognitive processes such as strategic planning and spatial reasoning that are crucial for successful maze navigation.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Biofeedback:</strong>
                                <ul>
                                    <li><strong>Assessment:</strong> Identifying physiological markers such as heart rate variability (HRV) and galvanic skin response (GSR) that might indicate stress or anxiety levels affecting problem-solving abilities.</li>
                                    <li><strong>Protocol:</strong> Implementing techniques like paced breathing or mindfulness exercises to manage stress/anxiety responses.</li>
                                    <li><strong>Objective:</strong> To ensure that stress/anxiety does not obstruct cognitive processes and decision-making pathways essential for maze navigation.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Neuromodulation:</strong>
                                <ul>
                                    <li>
                                        <strong>tDCS:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Dorsolateral Prefrontal Cortex (DLPFC), Parietal Cortex</li>
                                            <li><strong>Protocol:</strong> Utilizing anodal stimulation to potentially elevate neuronal excitability within regions associated with problem-solving and spatial reasoning.</li>
                                            <li><strong>Objective:</strong> To augment cognitive processes such as planning and decision-making that can facilitate efficient maze-solving.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>PBM:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Prefrontal Cortex, Parietal Cortex</li>
                                            <li><strong>Protocol:</strong> Applying PBM to potentially promote neuronal health and enhance metabolic activity in regions implicated in spatial navigation and executive functioning.</li>
                                            <li><strong>Objective:</strong> To foster the wellbeing and activity of neural networks involved in maze navigation, thereby possibly supporting enhanced performance.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Mental Health Assessments and Their Relation:</strong>
                                <ul>
                                    <li><strong>Personality Scale:</strong> Understanding an individual’s personality traits, such as their propensity for careful planning versus impulsivity, can provide valuable context regarding their approach to maze-solving and whether behavioral tendencies might be impacting performance.</li>
                                    <li><strong>SISRI-24 (Short Imagery Self-Report Inventory):</strong> Insight into an individual’s spatial imagery abilities might correlate with their capacity to envisage and navigate maze pathways mentally, providing a potential cognitive strategy that might be leveraged or developed further.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>Considering the individual’s cognitive and emotional profile, as discerned through assessments and QEEG, allows for the development of a targeted intervention strategy. This might encompass neurofeedback and biofeedback protocols aimed at bolstering the cognitive processes integral to maze navigation, alongside neuromodulation techniques aimed at supporting the underlying neural functionality. Furthermore, understanding the individual’s personality and spatial imagery abilities might offer additional strategies or considerations in tailoring interventions and support.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default MazeTest