import { React, useState } from 'react'
import '../../CognitiveTests/Header/cognitiveTestHeader.css'

const FriendshipScaleHeader = () => {
  
  const [showSection1, setShowSection1] = useState(true)
  const [showSection2, setShowSection2] = useState(false)
  const [showSection3, setShowSection3] = useState(false)
  const [showSection4, setShowSection4] = useState(false)
  const [showSection5, setShowSection5] = useState(false)
  const [showSection6, setShowSection6] = useState(false)
  const [showSection7, setShowSection7] = useState(false)

  const handleShowSection1 = () => { // Showing 1st element
    setShowSection1(true)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(false)
    setShowSection7(false)
  }

  const handleShowSection2 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(true)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(false)
    setShowSection7(false)
  }

  const handleShowSection3 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(true)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(false)
    setShowSection7(false)
  }

  const handleShowSection4 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(true)
    setShowSection5(false)
    setShowSection6(false)
    setShowSection7(false)
  }

  const handleShowSection5 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(true)
    setShowSection6(false)
    setShowSection7(false)
  }

  const handleShowSection6 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(true)
    setShowSection7(false)
  }

  const handleShowSection7 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(false)
    setShowSection7(true)
  }

  return (
    <header id='headerDiagnostic' className='d-flex flex-column'>
      <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
        <h1>Mental Health Assessment</h1>
        <div className='d-flex flex-column position-relative'>
          <p className='headerDiagnosticArticleP'>  Anyone feeling overwhelmed, anxious, or down, or those seeking a general emotional health checkup. To identify and address potential emotional disturbances and promote mental well-being.</p>
        </div>
      </article>
      <div className="breakline-diagnostic"></div>

      <div className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
        <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      </div>

      <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
        <div className="headerDiagnostic-options d-flex flex-column align-items-start">
          <button className={showSection1 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection1}>What is it for?</button>
          <button className={showSection2 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection2}>Who is it for?</button>
          <button className={showSection3 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection3}>Why are we doing it?</button>
          <button className={showSection4 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection4}>Relation to QEEG, Biofeedback, and Neurofeedback</button>
          <button className={showSection5 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection5}>Relation to tDCS and PBM</button>
          <button className={showSection6 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection6}>Relation to Cognitive Tests</button>
          <button className={showSection7 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection7}>Why This Holistic Approach Matters</button>
        </div>
        {showSection1 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>What is it for?</h1>
                <p>To measure friendship quality and depth.</p>
              </div>
            </div>
        }{showSection2 &&
          <div className="headerDiagnostic-content d-flex flex-row align-items-start">
            <div className="diagnosticArticle2 d-flex flex-column align-items-start">
              <h1>Who is it for?</h1>
              <p>Anyone seeking insights into social bonds.</p>
            </div>
          </div>
        }{showSection3 &&
          <div className="headerDiagnostic-content d-flex flex-row align-items-start">
            <div className="diagnosticArticle2 d-flex flex-column align-items-start">
              <h1>Why are we doing it?</h1>
              <p>To understand the effects of social connections on well-being.</p>
            </div>
          </div>
        }
        {showSection4 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>Relation to QEEG, Biofeedback, and Neurofeedback</h1>
                <p>The quality and depth of friendships and social connections can influence neural states, especially in regions like the ventromedial prefrontal cortex, known for social cognition. QEEG can provide insights into these neural patterns, complementing the Friendship Scale's findings. With Neurofeedback, Beta training can aid in improving cognitive clarity and social interactions. In the realm of Biofeedback, Respiratory feedback ensures optimal breathing patterns during social situations, while ECG/Pulse feedback offers insights into cardiac responses in social scenarios.</p>
              </div>
            </div>
        }
        {showSection5 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>Relation to tDCS and PBM</h1>
                <p>The quality of social connections may be enhanced by tDCS modulating regions responsible for social cognition. Brain PBM therapy, by stimulating neurogenesis and synaptogenesis, can play a role in understanding the brain's physiological response to social connections, aligning with the insights of the Friendship Scale.</p>
              </div>
            </div>
        }
        {showSection6 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>Relation to Cognitive Tests</h1>
                <p>Friendship and social connections influence various cognitive and emotional aspects of human experience. The quality of these relationships often mirrors our cognitive-emotional state and can serve as indicators for certain cognitive capacities.</p>
                <p><strong>Empathy and Perspective-taking:</strong> High-quality friendships often demand a high level of empathetic understanding and the ability to see things from another's viewpoint. Cognitive tests that assess these capacities can be contextualized better with the results from the Friendship Scale.</p>
                <p><strong>Emotional Regulation and Recognition:</strong> Friendships play a significant role in emotional regulation. The quality of one's friendships can often reflect how one handles emotional nuances. Cognitive tasks related to emotional recognition and regulation can be better understood by correlating results with the Friendship Scale.</p>
                <p><strong>Memory Recall:</strong> Positive social interactions and deep friendships enhance the recall of positive memories, providing a buffer against negative cognitive biases. Evaluating memory tasks in light of the Friendship Scale can provide a broader perspective.</p>
                <p><strong>Problem-solving in Social Contexts:</strong> Friendships often come with their set of challenges, requiring problem-solving in social contexts. A deeper understanding of friendship quality can provide insights into an individual's capacities in this domain.</p>
                <p>In essence, understanding the depth and quality of one's friendships can shed light on various cognitive capacities, especially those rooted in social cognition. Integrating the insights from the Friendship Scale with cognitive test performances provides a richer, more comprehensive view of an individual's cognitive-social landscape.</p>
              </div>
            </div>
        }
        {showSection7 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>Why This Holistic Approach Matters</h1>
                <p>Marrying traditional psychological assessments with advanced neurophysiological tools like QEEG, Biofeedback, and Neurofeedback grants a multi-layered view of mental health. This comprehensive approach ensures not just an understanding of emotions and behaviors but also the brain mechanisms beneath. This holistic perspective paves the way for precise interventions, improved outcomes, and a profound self-understanding.</p>
                <p>For further details or to book a session, <a href="mailto: info@deepsynaps.com">info@deepsynaps.com</a>.</p>
              </div>
            </div>
        }
      </div>
    </header>
  )
}

export default FriendshipScaleHeader