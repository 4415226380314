import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';


const GADCognitiveAssessment = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                        <h1>GAD7</h1>
                        <p>It is composed of 7 questions aimed at identifying the self-perceived risk of suffering from Anxiety.</p>
                        <p>The GAD-7 serves as a diagnostic tool designed with 7 pivotal questions to pinpoint symptoms related to generalized anxiety disorders in individuals.</p>
                        <ul>
                            <li>
                                <strong>Emotional Regulation and Stress Response:</strong>
                                <ul>
                                    <li><strong>Brain Regions and QEEG:</strong> The amygdala, hippocampus, and prefrontal cortex play a central role in anxiety responses and emotional regulation. QEEG patterns might show hyperactivity in the amygdala or altered patterns in the prefrontal cortex among individuals with high GAD-7 scores.</li>
                                    <li><strong>Biofeedback:</strong> Monitoring HRV can provide insights into the autonomic nervous system's response, a system deeply intertwined with stress and anxiety. Lower HRV is often linked to heightened stress and anxiety.</li>
                                    <li><strong>Neurofeedback:</strong> Interventions can be crafted to encourage neural patterns that promote calmness and regulate those fostering anxiety. Enhancing alpha waves and modulating beta wave activity can be particularly beneficial.</li>
                                    <li><strong>tDCS:</strong> By modulating activity in regions such as the dorsolateral prefrontal cortex, it's conceivable to mitigate feelings of anxiety. The application of currents can potentially balance hyperactive regions related to anxiety.</li>
                                    <li><strong>PBM:</strong> Targeting regions like the amygdala and prefrontal cortex with photobiomodulation can amplify cellular energy metabolism, possibly aiding in emotional regulation and dampening anxiety.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Cognitive Symptoms of Anxiety:</strong>
                                <ul>
                                    <li><strong>Brain Regions and QEEG:</strong> The anterior cingulate cortex and prefrontal regions, vital for attention and executive functions, might manifest aberrant patterns on QEEG during cognitive tasks in anxiety-prone individuals.</li>
                                    <li><strong>Biofeedback:</strong> Observing parameters like skin conductance can elucidate on heightened arousal and cognitive disruptions characteristic of anxiety.</li>
                                    <li><strong>Neurofeedback:</strong> Sessions tailored to foster brainwave patterns conducive to focus and cognitive clarity can be of immense value.</li>
                                    <li><strong>tDCS:</strong> Directing currents towards the aforementioned cognitive centers may potentially improve cognitive symptoms linked to anxiety.</li>
                                    <li><strong>PBM:</strong> The strategic application of photobiomodulation can boost neural efficiency in these critical cognitive hubs.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>By harnessing the diagnostic prowess of GAD-7 and combining it with the aforementioned neuroscientific modalities, a holistic comprehension of an individual's anxiety landscape can be achieved. This synergistic approach is invaluable for both diagnostic precision and tailoring therapeutic measures.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default GADCognitiveAssessment