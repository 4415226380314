import { React, useState } from 'react'
import './cooperateHeader.css'
import {Link} from "react-router-dom";

const CorporateClinicHeader = () => {

    return (
      <header id='headerDiagnostic' className='d-flex flex-column'>
        <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
          <h1>Cooperate</h1>
          <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and cognitive abilities, identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
          <a className='navbar-Respbtn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
        </article>
        <div className="breakline-diagnostic"></div>
  
        <div className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
          <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/L38T6fnrVjc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>

        <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                <h1>DeepSynaps Clinic Wellness and Assessment Package:</h1>
                  <ol>
                      <li>
                          <p><b>Diagnostic Assessment:</b></p>
                          <ul>
                              <li>
                                  <p><b>Body Scan:</b></p>
                                  <p>This includes EMG, ECG/Pulse, Respiratory, Temperature, Skin condition, SCP, and EOG scans to provide a comprehensive assessment of the patient's physical health and stress levels.</p>
                              </li>
                              <li>
                                  <p><b>Brain Scan (QEEG Brain Mapping):</b></p>
                                  <p>Both Dry and Wet EEG scans to assess brain activity and identify any abnormalities or areas for improvement.</p>
                              </li>
                          </ul>
                      </li>

                      <li>
                          <p><b>Mental Health Assessment:</b></p>
                          <p>In-depth mental health assessment tools and questionnaires to evaluate patients' emotional well-being, stress levels, and overall mental health.</p>
                      </li>

                      <li>
                          <p><b>Cognitive Assessment:</b></p>
                          <p>Cognitive assessment tests to measure cognitive abilities, memory, attention, and problem-solving skills.</p>
                      </li>

                      <li>
                          <p><b>Intervention and Treatment:</b></p>
                          <ul>
                              <li>
                                  <p><b>Biofeedback Sessions:</b></p>
                                  <p>Customized biofeedback sessions to help patients manage stress, anxiety, and other psychological issues by gaining control over physiological functions.</p>
                              </li>
                              <li>
                                  <p><b>Neurofeedback Sessions:</b></p>
                                  <p>Personalized neurofeedback training to enhance focus, concentration, and overall mental performance.</p>
                              </li>
                              <li>
                                  <p><b>Neuromodulation (TDCS-PBM):</b></p>
                                  <p>Transcranial Direct Current Stimulation (TDCS) and Photobiomodulation (PBM) sessions for neuromodulation to address specific cognitive and mood-related concerns.</p>
                              </li>

                              <li>
                                  <p><b>Brain Stimulation:</b></p>
                                  <p>Advanced brain stimulation techniques if needed for cognitive enhancement or symptom management.</p>
                              </li>
                          </ul>
                      </li>
                      <li>
                          <p><b>Reporting and Monitoring:</b></p>
                          <p>- Detailed reports summarizing assessment results and progress over time.</p>
                          <p>- Regular monitoring of patient data to track improvements and adjust interventions as necessary.</p>
                      </li>
                      <li>
                          <p><b>Training and Support:</b></p>
                          <p>- Training for clinic staff on how to use DeepSynaps equipment and software effectively.</p>
                          <p>- Ongoing support and consultation to ensure successful implementation of the package.</p>
                      </li>
                      <li>
                          <p><b>Equipment and Software:</b></p>
                          <p>DeepSynaps will provide all necessary diagnostic equipment, software licenses, and technical support for seamless integration into the clinic's operations.</p>
                      </li>
                  </ol>
                  <p>This comprehensive package is designed to assist clinics in offering advanced diagnostics and treatment options for their patients, addressing both physical and mental health concerns. It provides a holistic approach to healthcare by combining cutting-edge technology with personalized interventions and ongoing support. DeepSynaps will work closely with the clinic to customize the package to their specific needs and patient demographics.</p>
              </div>
            </div>
        </div>
      </header>
    )
}

export default CorporateClinicHeader