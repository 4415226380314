import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';
import { Link } from 'react-router-dom';

const EriksensFlankerTest = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                        <div className={"row w-100"}>
                            <div className={"col-md-8 col-sm-12"}><h1>Eriksen’s Flanker Test (CAT-EF)</h1></div>
                            <div className={"col-md-4 col-sm-12 text-md-end text-sm-left pt-1"}><Link target={"_blank"} className={"btn btn-outline-dark"} to={'https://www.cognifit.com/gb/battery-of-tests/eriksen-flanker-task'}>Start test now</Link></div>
                        </div>
                        <p>Measure your attentional selectivity and control by responding to target stimuli amidst distractors.</p>
                        <p><strong>Relation to QEEG, Neurofeedback, Biofeedback, and Neuromodulation:</strong></p>
                        <ul>
                            <li><strong>Brain Region:</strong> Anterior Cingulate Cortex, Prefrontal Cortex</li>
                            <li><strong>Broadman Area:</strong> 24, 32 (Anterior Cingulate Cortex); 9, 10, 11 (Prefrontal Cortex)</li>
                            <li><strong>EEG Electrode Places:</strong> Fz, FCz (Standard 10-20 system places, focusing on midline frontal areas)</li>
                            <li>
                                <strong>Neurofeedback:</strong>
                                <ul>
                                    <li><strong>Protocol:</strong> Rewarding the decrease of theta/beta ratio, potentially introducing SMR (sensory motor rhythm) training</li>
                                    <li><strong>Objective:</strong> To enhance sustained attention and reduce impulsivity/rapid responding without proper attention allocation.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Biofeedback:</strong>
                                <ul>
                                    <li><strong>Assessment:</strong> Evaluating GSR (Galvanic Skin Response) and HRV (Heart Rate Variability) during task performance to understand stress and engagement levels.</li>
                                    <li><strong>Protocol:</strong> Utilizing GSR and HRV biofeedback to train the individual in maintaining calmness and balanced arousal during attention-demanding tasks.</li>
                                    <li><strong>Objective:</strong> To minimize stress-related interference and optimize physiological readiness during attention tasks.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Neuromodulation:</strong>
                                <ul>
                                    <li><strong>tDCS:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Dorsolateral Prefrontal Cortex (DLPFC)</li>
                                            <li><strong>Protocol:</strong> Anodal stimulation to the DLPFC to potentially enhance executive attention capacities.</li>
                                            <li><strong>Objective:</strong> Enhancing attentional control and management of distractors.</li>
                                        </ul>
                                    </li>
                                    <li><strong>PBM:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Prefrontal Cortex</li>
                                            <li><strong>Protocol:</strong> Applying photobiomodulation to enhance cerebral blood flow and mitochondrial function.</li>
                                            <li><strong>Objective:</strong> Enhancing cognitive function and attention capacities by improving neural metabolism.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Mental Health Assessments and Their Relation:</strong>
                                <ul>
                                    <li><strong>DASS 21/42:</strong> Elevated levels of stress, anxiety, and depression (as detected by DASS) might correlate with decreased performance on the Flanker Test due to impaired attentional control. Addressing emotional wellbeing may result in enhanced task performance.</li>
                                    <li><strong>Holmes Rahe Life Event Scale:</strong> High life stress scores might inversely correlate with attentional capabilities, potentially hindering the ability to effectively manage distractors.</li>
                                    <li><strong>Personality Scale:</strong> Certain personality traits (e.g., neuroticism, conscientiousness) might correlate with attention and stress management capabilities during attention-demanding tasks.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>This structured interplay of cognitive testing, neurofeedback, biofeedback, and neuromodulation can thereby offer a well-rounded approach towards understanding and enhancing cognitive and emotional facets of attentional control.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default EriksensFlankerTest