import axios from "../../Lib/axios";

export async function getLocations(data) {
    const response = await axios.post('/locations', data);
    return response;
}

export async function getLocation(id) {
    const response = await axios.get('/locations/'+id);
    return response;
}

export async function bookLocation(id, data) {
    const response = await axios.post('/locations/'+id, data);
    return response;
}