import React, { useState } from 'react';
import './pricingPlan.css';
import { PricingPlanV, PricingPlanX } from '../../../assets/images'

const PricingPlan = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };

  const CognitivePlans = [
    {
      id: `Assesment Basic Plan`,
      name: 'Basic',
      description: 'Assessment & Report',
      price: '£300',
    },
    {
      id: `Assesment Standart Plan`,
      name: 'Standard',
      description: 'Assessment & Consultation',
      price: '£500',
    },
    {
      id: `Assesment Premium Plan`,
      name: 'Premium',
      description: 'Assessment & Brain training',
      price: '£1500',
    },
  ];

  /* ... */

  const CognitiveFeatures = [ 
    {
      id: 0,
      title: 'Initial Assessment',
      availability: [PricingPlanV, PricingPlanV, PricingPlanV],
    },
    {
      id: 1,
      title: 'Cognitive Assessments',
      availability: [PricingPlanV, PricingPlanV, PricingPlanV],
    },
    {
      id: 2,
      title: 'Artificial intelligent based personalized Cognitive test report',
      availability: [PricingPlanX, PricingPlanV, PricingPlanV],
    },
    {
      id: 3,
      title: 'Doctor Consultation',
      availability: [PricingPlanX, PricingPlanV, PricingPlanV],
    },
    {
      id: 4,
      title: 'Artificial intelligent based cognitive brain train program',
      availability: [PricingPlanX, PricingPlanX, PricingPlanV,],
    }
  ];


  const plans = CognitivePlans;
  const features = CognitiveFeatures;

  return (
    <>
      <div id="pricingPlan" className="wrapper d-flex flex-column align-items-end">
        <div className="pricingP-content-container d-flex flex-row justify-content-between">
          <div className="pricingP-header-content d-flex flex-column align-items-start">
            <article>
              <p>Assessments</p>
              <h1>Pricing plans</h1>
            </article>
          </div>
          <form className="d-flex flex-row align-items-start" action="" method="post">
            {plans.map((plan, index) => (
              // <div
              //   key={index}
              //   className={`${index === plans.length -1 ? 'radio-input-active-container' : 'radio-input-container'} d-flex flex-column align-items-center position-relative ${
              //     activeIndex === index ? 'active' : ''
              //   }`}
              //   onClick={() => handleItemClick(index)}
              // >
              <div
                key={index}
                className={`radio-input-container d-flex flex-column align-items-center position-relative ${
                  activeIndex === index ? 'activve' : 'inactivve'
                }`}
                onClick={() => handleItemClick(index)}
              >
                <input 
                  type="radio" 
                  checked={activeIndex === index} 
                  readOnly
                />
                <article className={`d-flex flex-column align-items-center justify-content-center`}>
                  <div className="package-name-btn">{plan.name}</div>
                  <p className={`${activeIndex === index ? 'activeText' : 'inactiveText'}`}>{plan.description}</p>
                  <h2 className={`${activeIndex === index ? 'activeText' : 'inactiveText'}`}>{plan.price}</h2>
                </article>
              </div>
            ))}
          </form>
        </div>
        <div className="plans-feature-container d-flex flex-column">
          <ul>
            {features.map((feature, index) => (
              <li className='d-flex flex-row justify-content-between' key={index}>
                <article><p>{feature.title}</p></article>
                <div className='pros-cons d-flex flex-row justify-content-end align-items-center'>
                    {feature.availability.map((avail, availIndex) => (
                    <div className="prosCons-img-container d-flex justify-content-center align-items-center" key={availIndex}>
                      <img src={avail} alt="" />
                    </div>
                  ))}
                </div>
              </li>
            ))}
          </ul>
        </div>
        
        {/* <button className="pricingP-btn" onClick={handleBuyNow}>
          Book Now
        </button> */}

        <a href="https://connect.pabau.com/packages2.php?compid=13311" className="pricingP-btn">Book Now</a>

      </div>
    </>
  );
};

export default PricingPlan;