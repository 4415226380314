// import { createRoot } from 'react-dom/client';
// import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import ScrollToTop from './Components/Includes/Hooks/ScrollToTop';
// import {About, BrainScan, CognitiveTest, Diagnostic, Home, Pricing, Products, Treatments, Whoops404, Contact, Partners, Cooperate} from './Pages';
// import './index.css';
// import reportWebVitals from './reportWebVitals';
// import './assets/fonts/Aeonik-Regular.otf'
// import './assets/fonts/Aeonik-Medium.otf'
// import './assets/fonts/Aeonik-Bold.otf'
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <ScrollToTop />
//       <Routes>
//         <Route path="/" element={<Home />} />
//         <Route path="/home" element={<Home />} />
//         <Route path="/about" element={<About />} />

//         <Route path="/diagnostic" element={<Diagnostic />} />
//         <Route path="/brainscan" element={<BrainScan />} />
//         <Route path='/cognitivetest' element={<CognitiveTest />} />
//         <Route path='/cooperate' element={<Cooperate />} />

//         <Route path="/interventions" element={<Treatments />} />
//         <Route path="/pricing" element={<Pricing />} />
//         <Route path="/products" element={<Products />} />
//         <Route path="/contact" element={<Contact />} />
//         <Route path="/partners" element={<Partners />} />
//         <Route path="*" element={<Whoops404 />} />
//       </Routes>
//     </BrowserRouter>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// reportWebVitals();

import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ScrollToTop from './Components/Includes/Hooks/ScrollToTop';
import {
  About,
  BrainScan,
  BodyScan,
  CognitiveTest,
  Diagnostic,
  Home,
  Pricing,
  Products,
  Treatments,
  Whoops404,
  Contact,
  Partners,
  Cooperate,
  BookLocation,
  MentalHealthAssessment,
  ComponentPsychologicalScale,
  DepressionAnxietyStressScales,
  FriendshipScale,
  HolmesandRaheLifeEventScale,
  Mbti,
  SelfReflectionAndInsightScale,
  SleepScale,
  BiofeedbackService,
  BrainStimulationPrograms,
  NeurofeedbackSerrvices,
  NeuromodulationWithAdvancedAiIntegration,
  CorporateClinic,
  CorporateAthletes,
  CorporateBusinessAndManagement,
  CorporateCareHomes,
  CorporateGymsAndSportCentres,
  CorporateHospitals,
  CorporateMilitary,
  CorporateSchools,
  CorporateStaffWellness,
  CorporateUniversitiesAndResearchInstitutions,
  PrivacyPolicy,
  ASRSCognitiveAssessment,
  ConcentrationCognitiveAssessmentCabAt,
  CoordinationCognitiveAssessment,
  DearyLiewaldTest,
  DrivingCognitiveAssessment,
  EriksensFlankerTest,
  FiftyFiveAndOverCognitiveAssessment,
  GADCognitiveAssessment,
  GeneralCognitiveAssessment,
  InhibitionOfReturnTest,
  MemoryCognitiveAssessment,
  PerceptionCognitiveAssessment,
  PHQCognitiveAssessment,
  ProgressiveMatricesTest,
  ReadingComprehensionCognitiveAssessment,
  ReasoningCognitiveAssessment,
  SelectiveAttentionTest,
  SimonTest,
  SustainedAttentionToResponseTest,
  TowerOfHanoiTest,
  TrailMakingTest,
  VisualEpisodicMemoryTest,
  GeneralCognitiveAssessmentPage,
  DigitSpanTest,
  DistanceEstimationTest,
  DividedAttentionTest,
  EyeHandCoordinationTest,
  EyeHandCoordinationTestHecoor,
  LexicalMemoryTest,
  MazeTest,
  MultiModalLexicalMemoryTest,
  NamingTest,
  NumberSizeCongruencyTest,
  PsychomotorVigilanceTest,
  SpeedEstimationTest,
  StopSignalTest,
  StroopTest,
  TappingTest,
  TimeEstimationTest,
  UsefulFieldOfViewTest,
  VisualMemoryTest,
  VisualOrganizationTest,
  VisualSearchTest,
  VisualWorkingMemorySpanTest, CorporateAcademicResearch, CorporateChildDevelopmentCenter, CorporateCorrectionalFacilityRehabilitation, CorporateMentalHealthClinic,
  CorporateRehabilitationCenterComprehensiveRecovery, CorporateRemoteWorkforceCognitive, CorporateSeniorLivingCommunityCognitiveWellness,
  CorporateSportsPerformanceEnhancement
} from './Pages';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './assets/fonts/Aeonik-Regular.otf';
import './assets/fonts/Aeonik-Medium.otf';
import './assets/fonts/Aeonik-Bold.otf';

import { createRoot } from 'react-dom/client';

// Instead of the older ReactDOM.render method:
const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/diagnostic" element={<Diagnostic />} />
          <Route path="/brainscan" element={<BrainScan />} />
          <Route path="/bodyscan" element={<BodyScan />} />

          <Route path='/mental-health-assessment' element={<MentalHealthAssessment />} />

          <Route path='/mental-health-assessment/dass-21-42' element={<DepressionAnxietyStressScales />} />
          <Route path='/mental-health-assessment/holmes-and-rahe-life-event-scale' element={<HolmesandRaheLifeEventScale />} />
          <Route path='/mental-health-assessment/mbti' element={<Mbti />} />
          <Route path='/mental-health-assessment/component-psychological-scale' element={<ComponentPsychologicalScale />} />
          <Route path='/mental-health-assessment/sisri-24' element={<SelfReflectionAndInsightScale />} />
          <Route path='/mental-health-assessment/sleep-scale' element={<SleepScale />} />
          <Route path='/mental-health-assessment/friendship-scale' element={<FriendshipScale />} />

          <Route path='/general-cognitive-assessment' element={<GeneralCognitiveAssessmentPage />} />
          <Route path='/general-cognitive-assessment/general-cognitive-assessment-cab' element={<GeneralCognitiveAssessment />} />
          <Route path='/general-cognitive-assessment/55-and-over-cognitive-assessment' element={<FiftyFiveAndOverCognitiveAssessment />} />
          <Route path='/general-cognitive-assessment/concentration-cognitive-assessment-cab-at' element={<ConcentrationCognitiveAssessmentCabAt />} />
          <Route path='/general-cognitive-assessment/coordination-cognitive-assessment' element={<CoordinationCognitiveAssessment />} />
          <Route path='/general-cognitive-assessment/driving-cognitive-assessment' element={<DrivingCognitiveAssessment />} />
          <Route path='/general-cognitive-assessment/memory-cognitive-assessment' element={<MemoryCognitiveAssessment />} />
          <Route path='/general-cognitive-assessment/perception-cognitive-assessment' element={<PerceptionCognitiveAssessment />} />
          <Route path='/general-cognitive-assessment/reading-comprehension-cognitive-assessment' element={<ReadingComprehensionCognitiveAssessment />} />
          <Route path='/general-cognitive-assessment/reasoning-cognitive-assessment' element={<ReasoningCognitiveAssessment />} />
          <Route path='/general-cognitive-assessment/asrs' element={<ASRSCognitiveAssessment />} />
          <Route path='/general-cognitive-assessment/gad7' element={<GADCognitiveAssessment />} />
          <Route path='/general-cognitive-assessment/phq9' element={<PHQCognitiveAssessment />} />

          <Route path='/cognitivetest' element={<CognitiveTest />} />
          <Route path='/cognitivetest/trail-making-test' element={<TrailMakingTest />} />
          <Route path='/cognitivetest/eriksens-flanker-test' element={<EriksensFlankerTest />} />
          <Route path='/cognitivetest/simon-test' element={<SimonTest />} />
          <Route path='/cognitivetest/deary-liewald-test' element={<DearyLiewaldTest />} />
          <Route path='/cognitivetest/tower-of-hanoi-test' element={<TowerOfHanoiTest />} />
          <Route path='/cognitivetest/inhibition-of-return-test' element={<InhibitionOfReturnTest />} />
          <Route path='/cognitivetest/visual-episodic-memory-test' element={<VisualEpisodicMemoryTest />} />
          <Route path='/cognitivetest/progressive-matrices-test' element={<ProgressiveMatricesTest />} />
          <Route path='/cognitivetest/selective-attention-test' element={<SelectiveAttentionTest />} />
          <Route path='/cognitivetest/sustained-attention-to-response-test' element={<SustainedAttentionToResponseTest />} />
          <Route path='/cognitivetest/tapping-test' element={<TappingTest />} />
          <Route path='/cognitivetest/psycho-motorvigilance-test' element={<PsychomotorVigilanceTest />} />
          <Route path='/cognitivetest/visual-search-test' element={<VisualSearchTest />} />
          <Route path='/cognitivetest/stop-signal-test' element={<StopSignalTest />} />
          <Route path='/cognitivetest/visual-organization-test' element={<VisualOrganizationTest />} />
          <Route path='/cognitivetest/number-size-congruency-test' element={<NumberSizeCongruencyTest />} />
          <Route path='/cognitivetest/digit-span-test' element={<DigitSpanTest />} />
          <Route path='/cognitivetest/time-estimation-test' element={<TimeEstimationTest />} />
          <Route path='/cognitivetest/eye-hand-coordination-test' element={<EyeHandCoordinationTest />} />
          <Route path='/cognitivetest/maze-test' element={<MazeTest />} />
          <Route path='/cognitivetest/visual-memory-test' element={<VisualMemoryTest />} />
          <Route path='/cognitivetest/stroop-test' element={<StroopTest />} />
          <Route path='/cognitivetest/visual-working-memory-span-test' element={<VisualWorkingMemorySpanTest />} />
          <Route path='/cognitivetest/naming-test' element={<NamingTest />} />
          <Route path='/cognitivetest/multi-modal-lexical-memory-test' element={<MultiModalLexicalMemoryTest />} />
          <Route path='/cognitivetest/lexical-memory-test' element={<LexicalMemoryTest />} />
          <Route path='/cognitivetest/eye-hand-coordination-test-he' element={<EyeHandCoordinationTestHecoor />} />
          <Route path='/cognitivetest/speed-estimation-test' element={<SpeedEstimationTest />} />
          <Route path='/cognitivetest/distance-estimation-test' element={<DistanceEstimationTest />} />
          <Route path='/cognitivetest/divided-attention-test' element={<DividedAttentionTest />} />
          <Route path='/cognitivetest/useful-field-of-view-test' element={<UsefulFieldOfViewTest />} />

          <Route path='/cooperate' element={<Cooperate />} />

          <Route path='/cooperate/academic-and-research' element={<CorporateAcademicResearch />} />
          <Route path='/cooperate/child-development-center' element={<CorporateChildDevelopmentCenter />} />
          <Route path='/cooperate/correctional-facility-rehabilitation' element={<CorporateCorrectionalFacilityRehabilitation />} />
          <Route path='/cooperate/mental-health-clinic' element={<CorporateMentalHealthClinic />} />
          <Route path='/cooperate/rehabilitation-center-comprehensive-recovery' element={<CorporateRehabilitationCenterComprehensiveRecovery />} />
          <Route path='/cooperate/remote-workforce-cognitive-and-emotional-well-being' element={<CorporateRemoteWorkforceCognitive />} />
          <Route path='/cooperate/senior-living-community-cognitive-wellness' element={<CorporateSeniorLivingCommunityCognitiveWellness />} />
          <Route path='/cooperate/sports-performance-enhancement' element={<CorporateSportsPerformanceEnhancement />} />

          <Route path='/cooperate/clinics' element={<CorporateClinic />} />
          <Route path='/cooperate/schools' element={<CorporateSchools />} />
          <Route path='/cooperate/athletes' element={<CorporateAthletes />} />
          <Route path='/cooperate/gyms-and-sport-centres' element={<CorporateGymsAndSportCentres />} />
          <Route path='/cooperate/military' element={<CorporateMilitary />} />
          <Route path='/cooperate/business-and-management' element={<CorporateBusinessAndManagement />} />
          <Route path='/cooperate/universities-and-research-institutions' element={<CorporateUniversitiesAndResearchInstitutions />} />
          <Route path='/cooperate/hospitals' element={<CorporateHospitals />} />

          <Route path='/cooperate/care-homes' element={<CorporateCareHomes />} />
          <Route path='/cooperate/staff-wellness' element={<CorporateStaffWellness />} />

          <Route path="/interventions" element={<Treatments />} />

          <Route path="/interventions/biofeedback" element={<BiofeedbackService />} />
          <Route path="/interventions/brain-stimulation-programs" element={<BrainStimulationPrograms />} />
          <Route path="/interventions/neurofeedback" element={<NeurofeedbackSerrvices />} />
          <Route path="/interventions/neuromodulation-with-advanced-ai-integration" element={<NeuromodulationWithAdvancedAiIntegration />} />

          <Route path="/pricing" element={<Pricing />} />
          <Route path="/products" element={<Products />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/location/:location/book" element={<BookLocation />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<Whoops404 />} />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>,
);

reportWebVitals();
