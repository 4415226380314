import React from 'react'
import './headerHome.css'
import {  HeaderHomeImg } from '../../../assets/images'

const HeaderHome = () => {
  return (
    <>
      <header id='toHeader'>
        <div id="headerHome">
          <article>
            <h1>AI based QEEG, HRV & MCI scan Technology</h1>
            <p>Artificial intelligence based ,</p>
            <ul>
              <li>Brain Scan  (QEEG)</li>
              <li>Heart Rate Check (HRV)</li>
              <li>Alzheimer, Dementia and Mild Cognitive impairments check (MCI)</li>
              <li>Anxiety and depression Check</li>
              <li>Online FDA registered cognitive Tests (Attention, Memory etc)</li>
              <li>Personalized brain training programmes (online-Software)</li>
              <li>Personalized neuro modulation protocols and devices for home use</li>
            </ul>
            <a className='navbarHeader-btn' href="https://connect.pabau.com/bookings.php?compid=13311" target='_blank' rel="noopener noreferrer">Book Now</a>
          </article>
          <div className="image-container"><img src={HeaderHomeImg} alt="" /></div>
          <div className="header-blackSpace">
            <button onClick={() => document.getElementById('info').scrollIntoView()}>Scroll to see more</button> {/* <a href="#info">Scroll to see more</a> */}
            <h3>Unlocking the Power of AI for Better Brain Health</h3>
          </div>
        </div>
      </header>
    </>
  )
}

export default HeaderHome