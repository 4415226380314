import React, {useState} from 'react'
import { SEO, Navbar, HeaderAbout, WhoWeAreAbout, InfoAbout, Culture, Team, Clinic, Footer } from '../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../assets/images/logo192.svg';
import ClinicalTeam from "../Components/Includes/Team/ClinicalTeam";
import '../Components/Cooperate/cooperateHeader.css'
import InternationalBusinessTeam from "../Components/Includes/Team/InternationalBusinessTeam";

const About = () => {

  const [showDiagnostic1, setShowDiagnostic1] = useState(true)
  const [showDiagnostic2, setShowDiagnostic2] = useState(false)
  const [showDiagnostic3, setShowDiagnostic3] = useState(false)

  const showServiceFirst = () => { // Showing 1st element
    setShowDiagnostic1(true)
    setShowDiagnostic2(false)
    setShowDiagnostic3(false)
  }

  const showServiceSecond = () => { // Showing 1st element
    setShowDiagnostic1(false)
    setShowDiagnostic2(true)
    setShowDiagnostic3(false)
  }

  const showServiceThird = () => { // Showing 1st element
    setShowDiagnostic1(false)
    setShowDiagnostic2(false)
    setShowDiagnostic3(true)
  }

  return (
    <>
    <SEO
      title = 'About DeepSynaps - Revolutionizing Brain Health and Cognitive Performance'
      description= 'Learn about DeepSynaps, a leading startup specializing in advanced technologies for optimizing brain health and unlocking cognitive potential. Explore our team, expertise, and commitment to enhancing mental well-being.'
      url = 'https://oxfordmindtech.com/about'
      keywords = 'DeepSynaps, revolutionizing brain health, cognitive performance, advanced technologies, cognitive potential, mental well-being'
    />
    <Navbar />
    <HeaderAbout />
    <WhoWeAreAbout />
    <InfoAbout />
    <div>&nbsp;</div>
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <div id='servicesAbout' className="wrapper d-flex flex-column justify-content-center align-items-center pb-5">
        <article>
          <h1>Our Services</h1>
        </article>
      </div>
      <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
        <div className="headerDiagnostic-options d-flex flex-column align-items-start">
            <button className={showDiagnostic1 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showServiceFirst}>AI-Powered Diagnostics and Scanning</button>
            <button className={showDiagnostic2 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showServiceSecond}>Advanced Intervention Techniques</button>
            <button className={showDiagnostic3 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showServiceThird}>Personalized, Cooperative Programs</button>
        </div>
        {showDiagnostic1 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle3 d-flex flex-column align-items-start">
                <h1>AI-Powered Diagnostics and Scanning</h1>
                <ul>
                  <li><strong>Profound Neural Insights:</strong> Leveraging AI-driven QEEG brain scans, we delve into the intricate tapestries of neurological functionalities, unveiling a landscape rich with data and insights.</li>
                  <li><strong>Holistic Health Panorama:</strong> Our comprehensive assessments, encompassing both mind and body scans, construct a thorough, multidimensional perspective of your overall health.</li>
                  <li><strong>Cognitive Fortitude:</strong> Through Cognifit Cognitive testing and personalized brain stimulation programs, we engineer interventions that sharpen and enhance cognitive prowess.</li>
                  <li><strong>Digital Mental Wellness:</strong> Software-mediated mental health assessments serve as pivotal tools to decode the emotional and psychological layers of your mental state, curating a foundational baseline for our interventions.</li>
                </ul>
              </div>
            </div>
        }
        {showDiagnostic2 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle3 d-flex flex-column align-items-start">
                <h1>Advanced Intervention Techniques</h1>
                <ul>
                  <li><strong>Navigating Neural Pathways:</strong> Biofeedback and neurofeedback become our allies, providing a gateway through which we can tune, train, and hone mental faculties towards robustness and stability.</li>
                  <li><strong>Neurological Nurturing:</strong> Neuromodulation, enabled through tDCS and PBM, provides a nuanced, sensitive approach to stimulate and enhance neurological functions, ensuring a delicate, personalized approach to mental and cognitive optimization.</li>
                </ul>
              </div>
            </div>
        }
        {showDiagnostic3 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle3 d-flex flex-column align-items-start">
                <h1>Personalized, Cooperative Programs</h1>
                <p>Navigating through the multifaceted realms of cognitive function and mental health, DeepSynaps curates a suite of personalized programs, coalescing nuanced insights with innovative techniques to foster a synergetic approach towards cognitive brilliance and mental wellness in various high-stake sectors.</p>
                <ul>
                  <li><strong>NeuroLeadership Excellence:</strong> Defining Leaders in a New Epoch: Unveiling tailored neuro-leadership programs that meticulously blend personalized neural insights and leadership science to craft methodologies that refine and elevate leadership skills, all while being deeply rooted in neural understanding and cognitive mastery.</li>
                  <li><strong>Peak Performance Pathways:</strong> Cultivating Cognitive Excellence Amongst Elites: Collaborating with elite performers, sports professionals, and individuals in high-stakes roles, our programs deploy a confluence of expertise to augment cognitive function, ensure mental stability, and elevate overall performance to peaks previously uncharted.</li>
                  <li>
                    <strong>Strategic Alliances with Various Sectors:</strong>
                    <ul>
                      <li><strong>Clinics:</strong> Offering technologically advanced, AI-powered diagnostic tools and innovative therapeutic strategies, we augment the capabilities of clinics to provide deeply personalized neurological and mental health care.</li>
                      <li><strong>Military:</strong> Providing robust, resilient programs designed to fortify the mental and cognitive capabilities of military personnel, ensuring they navigate their challenging roles with fortified mental resilience and optimized cognitive functionality.</li>
                      <li><strong>Schools:</strong> Crafting programs for educational institutions that cater to the cognitive development and mental wellness of students, ensuring a nurturing environment that fosters not only academic but also cognitive and emotional growth.</li>
                      <li><strong>Sports Clubs:</strong> Partnering with sports entities to provide meticulously crafted programs that elevate the cognitive performance and mental robustness of athletes, ensuring they excel not only in physical prowess but also in mental agility and strength.</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
        }
      </div>

      <div id='contentAbout' className="wrapper d-flex flex-column justify-content-center align-items-center pt-5">
        <dl className="row p-3">
          <dt className="col-sm-4">Diverse Collaborations for Holistic Impact</dt>
          <dd className="col-sm-8">Our engagements span various sectors—including clinics, hospitals, military entities, and elite performance professionals—ensuring that the power of personalized neurological and cognitive optimization is accessible across diverse domains. DeepSynaps' SaaS offerings deliver a symphony of rich, data-driven mental and cognitive health solutions, integrable across various operational frameworks.</dd>

          <dt className="col-sm-4">Unwavering Commitment to Ethical Excellence</dt>
          <dd className="col-sm-8">Navigating with a compass defined by strict adherence to ethical and legal benchmarks, we safeguard your data and well-being with utmost sincerity and diligence, ensuring a journey that is as secure and trusted as it is transformative and enlightening.</dd>

          <dt className="col-sm-4">Join Us in Sculpting a Future Defined by Cognitive Brilliance and Mental Harmony</dt>
          <dd className="col-sm-8">DeepSynaps invites you to navigate the enthralling journey towards unparalleled mental clarity, cognitive excellence, and enriched living. Let us together traverse the paths less tread, exploring, and amplifying the myriad potentials that lie within your mind.</dd>

          <dt className="col-sm-4">Connect with Us</dt>
          <dd className="col-sm-8">Let the journey of a thousand miles begin with a single step. Reach out to us  and commence your expedition towards a future illuminated by mental tranquility and cognitive prowess.</dd>
        </dl>
      </div>

    <Team />
    <ClinicalTeam />
    {/*<InternationalBusinessTeam />*/}
    <Clinic />
    <Footer />
    <WhatsAppWidget 
      sendButtonText={`Start Chat`} 
      companyName={`DeepSynaps`}
      message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
      CompanyIcon={CompanyIcon} 
      phoneNumber="447429910079" 
    />
    </>
  )
}

export default About