import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';


const FiftyFiveAndOverCognitiveAssessment = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                        <h1>55 and Over Cognitive Assessment (CAB-AG)</h1>
                        <p>The 55 and Over Cognitive Assessment (CAB-AG) assesses the cognitive processes that are influenced by aging. Using this too, healthcare professionals can carry out a complete cognitive screening, understand the cognitive strengths and weaknesses of the patient/user. This assessment takes 13-16 minutes to complete.This assessment is suitable for adults and seniors over the age of 55. The assessment can be used cross-culturally, and for both men and women.</p>
                        <p><strong>Relation to the QEEG,Neurofeedback,Biofeedback and Neuromodulation</strong></p>
                        <p>The 55 and Over Cognitive Assessment (CAB-AG) provides invaluable insights into the cognitive shifts often associated with aging. Healthcare professionals utilize this tool for a comprehensive cognitive evaluation, helping discern both strengths and vulnerabilities in older adults and seniors. The universality of the assessment makes it suitable for a broad audience, irrespective of culture, gender, or specific age, as long as the individual is above 55.</p>

                        <ul>
                            <li>
                                <strong>Cognitive Decline due to Aging:</strong>
                                <ul>
                                    <li><strong>Brain Regions and QEEG:</strong> Aging often impacts multiple brain regions but primarily affects the prefrontal cortex, hippocampus, and specific neural networks. QEEG can spotlight variations in brainwave activities that correspond with age-related cognitive changes.</li>
                                    <li><strong>Biofeedback:</strong> Monitoring Heart Rate Variability (HRV) can help deduce stress levels and autonomic balance, both of which can be influenced by cognitive decline.</li>
                                    <li><strong>Neurofeedback:</strong> Alpha/Theta training can help boost relaxation and mental clarity, especially beneficial for older adults.</li>
                                    <li><strong>tDCS:</strong> By stimulating the prefrontal cortex and other regions susceptible to age-related decline, cognitive functions can potentially be rejuvenated or maintained.</li>
                                    <li><strong>PBM:</strong> As aging can reduce the metabolic capacity of neurons, using red to near-infrared light therapy can invigorate these neurons, especially in regions like the hippocampus and prefrontal cortex, thus potentially mitigating cognitive decline.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Memory:</strong>
                                <ul>
                                    <li><strong>Brain Regions and QEEG:</strong> The hippocampus and temporal lobes are crucial for memory. Age-related memory changes might manifest as distinct patterns when assessed using QEEG.</li>
                                    <li><strong>Biofeedback:</strong> Electrocardiography (ECG) monitoring of patterns like HRV can be indicative of memory-related physiological responses.</li>
                                    <li><strong>Neurofeedback:</strong> Enhancing the activity of Alpha brain waves may bolster memory functions.</li>
                                    <li><strong>tDCS:</strong> Direct stimulation of the temporal regions can influence memory functionalities.</li>
                                    <li><strong>PBM:</strong> Directing therapy at the hippocampus can stimulate neuronal activity, potentially benefiting memory processes.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Attention and Focus:</strong>
                                <ul>
                                    <li><strong>Brain Regions and QEEG:</strong> The prefrontal cortex is key for maintaining attention and focus. QEEG might reveal shifts corresponding to aging-related attention changes.</li>
                                    <li><strong>Biofeedback:</strong> Muscle tension gauged through Electromyography (EMG) can be insightful during tasks necessitating focus.</li>
                                    <li><strong>Neurofeedback:</strong> Training of the Sensory Motor Rhythm (SMR) can aid in enhancing attentional control.</li>
                                    <li><strong>tDCS:</strong> Modulating the activity in the prefrontal cortex can refine attention capacities.</li>
                                    <li><strong>PBM:</strong> By stimulating the prefrontal region, one can potentially sustain or improve attention mechanisms.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>Integrating the CAB-AG assessment with these cutting-edge modalities offers a comprehensive viewpoint on cognitive health in those 55 and older. This integration empowers healthcare professionals to design and implement targeted interventions, ensuring a better quality of cognitive life for seniors.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default FiftyFiveAndOverCognitiveAssessment