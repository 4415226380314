import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';


const ASRSCognitiveAssessment = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                        <h1>ASRS</h1>
                        <p>It is composed of 18 questions aimed at identifying the self-perceived risk of ADHD.</p>
                        <p>The Adult ADHD Self-Report Scale (ASRS) is a screening instrument consisting of 18 questions tailored to identify symptoms of ADHD in adults.</p>
                        <ul>
                            <li>
                                <strong>Attention and Concentration:</strong>
                                <ul>
                                    <li><strong>Brain Regions and QEEG:</strong> The prefrontal cortex, especially the dorsolateral portion, and the anterior cingulate cortex are involved in attention and impulse control. QEEG during tasks requiring sustained attention could manifest distinct patterns in individuals with ADHD symptoms.</li>
                                    <li><strong>Biofeedback:</strong> HRV may fluctuate with shifts in attention. In ADHD, there could be more pronounced shifts indicating lapses in concentration.</li>
                                    <li><strong>Neurofeedback:</strong> Reinforcing specific neural frequencies related to focused attention can be beneficial. The aim is to amplify brainwaves conducive to sustained attention and reduce those associated with inattention.</li>
                                    <li><strong>tDCS:</strong> Modulating neural activity in the prefrontal regions can potentially enhance attention span and reduce impulsivity.</li>
                                    <li><strong>PBM:</strong> Stimulating these areas using photobiomodulation can potentially augment neural metabolic processes, fostering better attention regulation.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Impulse Control and Hyperactivity:</strong>
                                <ul>
                                    <li><strong>Brain Regions and QEEG:</strong> The basal ganglia and the prefrontal regions are pivotal for impulse control. Individuals with ADHD may display anomalies in QEEG patterns associated with these areas during impulse control tasks.</li>
                                    <li><strong>Biofeedback:</strong> Monitoring muscle tension could provide insights into hyperactivity and restlessness. A more restless profile could be indicative of higher muscle activity in ADHD individuals.</li>
                                    <li><strong>Neurofeedback:</strong> Tailoring neurofeedback to enhance brainwave patterns that support impulse control can be valuable.</li>
                                    <li><strong>tDCS:</strong> Targeting the basal ganglia and prefrontal areas can be a strategy to modulate impulsivity and hyperactivity.</li>
                                    <li><strong>PBM:</strong> Boosting the cellular processes in these regions through photobiomodulation might assist in better impulse regulation.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>Incorporating insights from the ASRS, in conjunction with these cutting-edge neuroscientific tools, offers a multifaceted perspective on the neural underpinnings of ADHD symptoms. Such a comprehensive approach is crucial for diagnosis, understanding the severity of the condition, and crafting individualized therapeutic interventions.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default ASRSCognitiveAssessment