import React from 'react'
import './monthlySubscription.css'


const MonthlySubscription = () => {

  // const handleRedirectCognitive = () => {
  //   // Redirect page to pabau sign-up page
  //   // window.location.href = 'https://connect.pabau.com/connectreg.php?compid=13311';
  // };

  return (
    <>
      <div id='monthlySub' className="wrapper d-flex flex-column align-items-end">
        <div className="monthlySub d-flex flex-row justify-content-between align-items-center">
          <article>
            <p>Brain Scan</p>
            <h1>Monthly Subscription</h1>
          </article>
          <div className="sub-detail d-flex flex-column justify-content-center">
            <div className="subDetail-header">
              <button className="sub-details-Infobtn">Subscription</button>
              {/* <p>Scans & Treatments</p> */} <br />
              <h2>£400/mth</h2>
            </div>
            <ul>
              <li>2 scan a Year</li>
              <li>2 consultation a year</li>
              <li>2 AI based protocol a year</li>
              <li>Remote Process monitoring and ongoing support from specialist</li>
              <li>Access to new therapies and technologies</li>
              <li>1 neurostimulation device</li>
            </ul>
            <a className="sub-details-btn" href="https://connect.pabau.com/connectreg.php?compid=13311" target='_blank' rel="noreferrer">Subscribe Now</a> {/*  */}
          </div>
        </div>
      </div>
    </>
  )
}

export default MonthlySubscription