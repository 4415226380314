import React from 'react'
import { SEO, Navbar, Footer, HolmesandRaheLifeEventScaleHeader } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';

const HolmesAndRaheLifeEventScale = () => {
  return (
    <>
      <SEO
        title = 'Mental Health Tests by DeepSynaps - Assess and Enhance Your Mental Health Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />
      <Navbar />
      <HolmesandRaheLifeEventScaleHeader />
      <br /> <br />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default HolmesAndRaheLifeEventScale