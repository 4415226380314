import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';
import { Link } from 'react-router-dom';


const NamingTest = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">

                        <div className={"row w-100"}>
                            <div className={"col-md-8 col-sm-12"}><h1>Naming Test</h1></div>
                            <div className={"col-md-4 col-sm-12 text-md-end text-sm-left pt-1"}>
                                <Link target={"_blank"} className={"btn btn-outline-dark"} to={'https://www.cognifit.com/battery-of-tests/viper-nam-test/decoding-test'}>Start test now</Link>
                            </div>
                        </div>

                        <p>Gauge your verbal recall and lexical retrieval ability.</p>
                        <p><strong>- Objective:</strong> This test assesses an individual's verbal recall and lexical retrieval ability, focusing on the cognitive processes of naming and word-finding.</p>
                        <p><strong>Relation to QEEG, Neurofeedback, Biofeedback, and Neuromodulation:</strong></p>
                        <ul>
                            <li><strong>Brain Region:</strong> Left Inferior Frontal Gyrus (Broca’s Area), Temporal Lobes</li>
                            <li><strong>Brodmann Area:</strong> BA 44/45 (Broca’s Area), BA 21/22 (Temporal Lobes)</li>
                            <li><strong>EEG Electrode Places:</strong> F7, T3, T4</li>
                            <li>
                                <strong>Neurofeedback:</strong>
                                <ul>
                                    <li>
                                        <strong>Protocol:</strong>
                                        <ul>
                                            <li>Enhancing alpha (8-12 Hz) asymmetry by promoting left frontal activation which may be associated with language and verbal processing.</li>
                                            <li>Potential targeting of theta (4-8 Hz) and beta (13-30 Hz) frequencies at temporal locations for memory and language processing.</li>
                                        </ul>
                                    </li>
                                    <li><strong>Objective:</strong> To improve lexical retrieval and verbal memory processes, ensuring smooth and efficient verbal communication and expression.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Biofeedback:</strong>
                                <ul>
                                    <li><strong>Assessment:</strong> Monitoring Heart Rate Variability (HRV) and Galvanic Skin Response (GSR) during task performance can give insights into emotional and physiological state during language processing.</li>
                                    <li><strong>Protocol:</strong> Techniques might involve breathwork or mindfulness if stress or anxiety appear to be impacting performance.</li>
                                    <li><strong>Objective:</strong> To create a stable and calm physiological baseline, supporting optimal cognitive functioning and particularly language processing and recall.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Neuromodulation:</strong>
                                <ul>
                                    <li>
                                        <strong>tDCS:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Left Inferior Frontal Gyrus, Temporal Lobes</li>
                                            <li><strong>Protocol:</strong> Anodal stimulation to the left inferior frontal gyrus with the aim to enhance lexical retrieval and word production abilities.</li>
                                            <li><strong>Objective:</strong> Augmenting verbal abilities and linguistic processing by modulating neuronal excitability in related brain regions.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>PBM:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Frontal and Temporal Regions</li>
                                            <li><strong>Protocol:</strong> Implementing photobiomodulation to promote neuronal health and metabolic efficiency in regions related to language processing.</li>
                                            <li><strong>Objective:</strong> Ensure sustained and robust neuronal functionality in regions pertinent to verbal recall and naming, potentially aiding in recovery and sustained cognitive function.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Mental Health Assessments and Their Relation:</strong>
                                <ul>
                                    <li><strong>Holmes Rahe Life Event Scale:</strong> Stress and life changes could impact language processing and recall due to their pervasive effects on cognitive function, making this scale relevant for contextualizing naming test performance.</li>
                                    <li><strong>Personality Scale:</strong> Understanding an individual's personality might give insights into their cognitive testing approach and potential stress responses, which may influence verbal recall and naming capabilities.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>Integrating the understanding of neural, physiological, and mental health contexts can deliver a comprehensive approach to cognitive enhancement and support, ensuring interventions are fine-tuned to an individual’s unique cognitive profile and needs. This multi-faceted approach enables robust and personalized strategies aimed at enhancing verbal recall and lexical retrieval.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default NamingTest