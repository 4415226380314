import React, {useRef, useState} from 'react'
import '../assets/pageCss/partners.css'
import '../Components/Home/Partners/partnersLogo.css'
import {
    addressPointer,
    LogoAlptekin,
    LogoMediaPark,
    LogoPsiKolog,
    LogoBlowellness,
    LogoVolkswagen,
    PlatoScience, Flow, ImediSync, Oxford, CogniFit
} from '../assets/images'
import { SEO, Navbar, Footer, Clinic } from '../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../assets/images/logo192.svg';
import MapComponent from "../Components/Partners/MapComponent";

const Partners = () => {
  return (
    <>
        <SEO
            title = 'Partnerships with DeepSynaps - Collaborating for Advancements in Brain Health'
            description= 'Discover our esteemed partners at DeepSynaps. We collaborate with leading research institutions, technology companies, and healthcare providers to drive advancements in brain health and cognitive performance. Explore our partnerships and mutual benefits.'
            url = 'https://oxfordmindtech.com/partners'
            keywords = ' Partnerships with DeepSynaps, advancements in brain health, research institutions, technology companies, healthcare providers, cognitive performance'
        />    
        <Navbar />
        <br />
        <br />        
        <br />
        <br />
        <MapComponent />
        <Clinic />
        <div id="partnersLogo" className="wrapper d-flex flex-column align-items-center justify-content-center">
            <div className="PartnersContent d-flex flex-row align-items-center justify-content-between">
                <img src={LogoAlptekin} height={150} />
                <img src={LogoMediaPark} height={150} />
                <img src={LogoPsiKolog} height={150} />
            </div>
            <div className="PartnersContent d-flex flex-row align-items-center justify-content-between">
                <img src={LogoBlowellness} height={150} />
                <img src={LogoVolkswagen} height={150} />
            </div>
        </div>

        <div className="wrapper worldMap">

            {/* Button trigger modal UK */}
            <button type="button" className="worldMap-btn uk-btn btn btn-primary" data-bs-toggle="modal" data-bs-target="#UK">
                <img className='pointerImg' src={addressPointer} alt="addressPointer" />
            </button>            

            {/* Button trigger modal Germany */}
            <button type="button" className="worldMap-btn germany-btn btn btn-primary" data-bs-toggle="modal" data-bs-target="#germany">
                <img className='pointerImg' src={addressPointer} alt="addressPointer" />
            </button>
            

            {/* Button trigger modal Turkey */}
            <button type="button" className="worldMap-btn turkey-btn btn btn-primary" data-bs-toggle="modal" data-bs-target="#turkey">
                <img className='pointerImg' src={addressPointer} alt="addressPointer" />
            </button>


            {/* Button trigger modal Africa */}
            <button type="button" className="worldMap-btn africa-btn btn btn-primary" data-bs-toggle="modal" data-bs-target="#africa">
                <img className='pointerImg' src={addressPointer} alt="addressPointer" />
            </button>


            {/* Modal UK */}
            <div className="modal fade" id="UK" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">United Kingdom</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <iframe title='DeepSynaps-uk-clinic' className='Modal-Map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21951094.332068007!2d-42.71485760948994!3d47.809036033070804!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x25a3b1142c791a9%3A0xc4f8a0433288257a!2sUnited%20Kingdom!5e0!3m2!1sen!2sca!4v1686811469174!5m2!1sen!2sca" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal Germany */}
            <div className="modal fade" id="germany" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Germany</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <iframe title='DeepSynaps-germany-clinic' className='Modal-Map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5135966.273598917!2d5.176274552853234!3d51.05685389755396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479a721ec2b1be6b%3A0x75e85d6b8e91e55b!2sGermany!5e0!3m2!1sen!2sca!4v1686810885400!5m2!1sen!2sca" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>


            {/* Modal Turkey */}
            <div className="modal fade" id="turkey" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Türkiye</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <iframe title='DeepSynaps-turkey-clinic' className='Modal-Map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6353024.144916991!2d29.844265637558642!3d38.96823923462896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b0155c964f2671%3A0x40d9dbd42a625f2a!2zVMO8cmtpeWU!5e0!3m2!1sen!2sca!4v1686809943649!5m2!1sen!2sca" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>                        

            {/* Modal Africa */}
            <div className="modal fade" id="africa" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Africa</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <iframe title='DeepSynaps-africa-clinic' className='Modal-Map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d32184322.27962811!2d18.381235378330498!3d-11.795127164358457!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x10a06c0a948cf5d5%3A0x108270c99e90f0b3!2sAfrica!5e0!3m2!1sen!2sca!4v1686811692294!5m2!1sen!2sca" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>                                    

        </div>

        <Footer />
        <WhatsAppWidget 
            sendButtonText={`Start Chat`} 
            companyName={`DeepSynaps`}
            message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
            CompanyIcon={CompanyIcon} 
            phoneNumber="447429910079" 
        />
    </>
  )
}

export default Partners