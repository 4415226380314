import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';


const ReasoningCognitiveAssessment = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                        <h1>Reasoning Cognitive Assessment (CAB-RS)</h1>
                        <p>The Reasoning Cognitive Assessment (CAB-RS) tool helps healthcare professionals quickly and accurately assess the cognitive processes associated with executive functions. This test allows us to carry out a complete cognitive analysis to understand the weaknesses and strengths of the functions related to reasoning. This assessment takes 20-25 minutes to complete. This assessment is suitable for children over 7 years of age, youth, adults, or seniors. The assessment can be used cross-culturally, and for both men and women.</p>
                        <p><strong>Relation to the QEEG,Neurofeedback,Biofeedback and Neuromodulation</strong></p>
                        <p>The Reasoning Cognitive Assessment (CAB-RS) is an advanced instrument that offers healthcare professionals insights into the executive functions linked with reasoning. By providing a comprehensive cognitive analysis, the tool can spotlight specific strengths and potential areas of improvement associated with reasoning capabilities.</p>
                        <ul>
                            <li>
                                <strong>Reasoning and Cognitive Mechanisms:</strong>
                                <ul>
                                    <li><strong>Brain Regions and QEEG:</strong> Reasoning, as a complex cognitive process, involves various brain regions such as the prefrontal cortex, parietal lobe, and anterior cingulate cortex. QEEG can monitor and visualize the activation patterns in these regions when an individual engages in reasoning tasks, offering insights into their cognitive proficiency.</li>
                                    <li><strong>Biofeedback:</strong> The Heart Rate Variability (HRV) can be used to gauge an individual's emotional response or stress during reasoning tasks. Elevated stress might indicate challenges in logical processing or task comprehension.</li>
                                    <li><strong>Neurofeedback:</strong> Neurofeedback sessions can fine-tune brainwave patterns associated with effective reasoning, such as Beta waves for logical thought and Gamma for problem-solving and insight.</li>
                                    <li><strong>tDCS:</strong> Direct stimulation of the prefrontal cortex, which plays a pivotal role in executive functions and reasoning, can potentially bolster logical thinking capabilities.</li>
                                    <li><strong>PBM:</strong> Photobiomodulation directed at the aforementioned brain regions can optimize their metabolic activity. This therapeutic intervention can contribute to enhanced neuronal health, potentially improving reasoning abilities.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Understanding Logical Structures and Cognitive Processing:</strong>
                                <ul>
                                    <li><strong>Brain Regions and QEEG:</strong> Logic processing and reasoning require a synchronized effort between the frontal and parietal lobes. QEEG can provide data showcasing the interplay between these regions during reasoning tasks.</li>
                                    <li><strong>Biofeedback:</strong> Using Electroencephalography (EEG) to monitor brain activity when individuals process logical sequences can offer insights into their cognitive agility with reasoning.</li>
                                    <li><strong>Neurofeedback:</strong> Training sessions targeting cognitive aspects related to logical comprehension can elevate an individual's reasoning skills.</li>
                                    <li><strong>tDCS:</strong> Targeted stimulation of brain regions pivotal for logical comprehension may lead to enhanced reasoning capabilities.</li>
                                    <li><strong>PBM:</strong> Applying photobiomodulation to these brain areas can boost their efficiency, potentially enabling a sharper logical discernment.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>Integrating the CAB-RS with these state-of-the-art neuroscientific methodologies offers a comprehensive perspective on an individual's reasoning skills, laying the foundation for targeted interventions or cognitive enhancement strategies.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default ReasoningCognitiveAssessment