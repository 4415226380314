import { React, useState } from 'react'
import './cooperateHeader.css'
import {Link} from "react-router-dom";

const CoooperateHeaderAthletics = () => {

    return (
      <header id='headerDiagnostic' className='d-flex flex-column'>
        <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
          <h1>Cooperate</h1>
          <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and cognitive abilities, identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
          <a className='navbar-Respbtn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
        </article>
        <div className="breakline-diagnostic"></div>
  
        <div className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
          <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/L38T6fnrVjc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>

        <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                <h1>DeepSynaps Athlete Performance Enhancement Package:</h1>
                  <ol>
                      <li>
                          <p><b>Comprehensive Physical and Cognitive Assessment:</b></p>
                          <ul>
                              <li>
                                  <p><b>Body Scan:</b></p>
                                  <p>Including EMG, ECG/Pulse, Respiratory, Temperature, Skin condition, SCP, and EOG scans to assess athletes' physical health, stress levels, and physiological responses during training and competition.</p>
                              </li>
                              <li>
                                  <p><b>Brain Scan (QEEG Brain Mapping):</b></p>
                                  <p>Both Dry and Wet EEG scans to assess brain activity and identify areas for cognitive improvement.</p>
                              </li>
                          </ul>
                      </li>

                      <li>
                          <p><b>Mental and Cognitive Performance Assessment:</b></p>
                          <p>In-depth assessments of athletes' mental resilience, cognitive abilities, and emotional well-being.</p>
                      </li>

                      <li>
                          <p><b>Personalized Training and Enhancement:</b></p>
                          <ul>
                              <li>
                                  <p><b>Neurofeedback Sessions:</b></p>
                                  <p>Tailored neurofeedback training to improve focus, concentration, and overall mental performance, helping athletes achieve peak cognitive states.</p>
                              </li>
                              <li>
                                  <p><b>Neuromodulation (TDCS-PBM):</b></p>
                                  <p>Transcranial Direct Current Stimulation (TDCS) and Photobiomodulation (PBM) sessions to enhance recovery, reduce muscle fatigue, and promote peak physical performance.</p>
                              </li>
                              <li>
                                  <p><b>Biofeedback Sessions:</b></p>
                                  <p>Customized biofeedback sessions to manage stress, anxiety, and optimize mental states before and during competitions.</p>
                              </li>
                              <li>
                                  <p><b>Strength and Conditioning Assessment:</b></p>
                                  <p>Evaluation of athletes' physical strength and conditioning needs, followed by personalized workout plans.</p>
                              </li>
                          </ul>
                      </li>

                      <li>
                          <p><b>Coaching and Guidance:</b></p>
                          <p>- Expert guidance from sports psychologists and performance coaches to help athletes develop mental toughness, set goals, and overcome psychological barriers.</p>
                          <p>- Assistance in creating personalized training and competition strategies based on cognitive and physiological assessments.</p>
                      </li>
                      <li>
                          <p><b>Reporting and Monitoring:</b></p>
                          <p>- Detailed reports summarizing athletes' physical and mental assessment results and progress over time.</p>
                          <p>- Regular monitoring of athlete data to track improvements and fine-tune training programs.</p>
                      </li>
                      <li>
                          <p><b>Equipment and Software:</b></p>
                          <p>DeepSynaps will provide state-of-the-art diagnostic equipment, software licenses, and technical support to facilitate optimal training and performance tracking.</p>
                      </li>
                      <li>
                          <p><b>Team Integration:</b></p>
                          <p>Support for coaches and teams to integrate cognitive and psychological training into their overall training regimen.</p>
                      </li>
                  </ol>
                  <p>This comprehensive package is designed to support athletes in achieving peak performance through a combination of physical and cognitive training. It aims to enhance mental resilience, optimize cognitive performance, and improve physical conditioning to help athletes excel in their respective sports. DeepSynaps will work closely with athletes, coaches, and sports organizations to tailor the package to specific sports and individual needs.</p>
              </div>
            </div>
        </div>
      </header>
    )
}

export default CoooperateHeaderAthletics