import { React, useState } from 'react'
import './cooperateHeader.css'
import {Link} from "react-router-dom";

const CoooperateHeaderStaffWellness = () => {

    return (
      <header id='headerDiagnostic' className='d-flex flex-column'>
        <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
          <h1>Cooperate</h1>
          <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and cognitive abilities, identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
          <a className='navbar-Respbtn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
        </article>
        <div className="breakline-diagnostic"></div>
  
        <div className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
          <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/L38T6fnrVjc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>

        <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                <h1>DeepSynaps Staff Wellness and Employee Well-being Package::</h1>
                  <ol>
                      <li>
                          <p><b>Employee Wellness Assessments:</b></p>
                          <ul>
                              <li>
                                  <p><b>Body Scan:</b></p>
                                  <p>Including EMG, ECG/Pulse, Respiratory, Temperature, Skin condition, SCP, and EOG scans to assess employees' physical health, stress levels, and physiological responses.</p>
                              </li>
                              <li>
                                  <p><b>Mental Health Assessment:</b></p>
                                  <p>Assessment tools and questionnaires to evaluate employees' emotional well-being and stress levels.</p>
                              </li>
                          </ul>
                      </li>

                      <li>
                          <p><b>Cognitive Well-being Support:</b></p>
                          <p>- Comprehensive cognitive assessments to measure employees' cognitive abilities, memory, attention, and problem-solving skills.</p>
                          <p>- Personalized neurofeedback training to enhance focus, concentration, and cognitive performance among employees.</p>
                      </li>

                      <li>
                          <p><b>Stress Management and Mental Resilience:</b></p>
                          <p>- Customized biofeedback sessions to assist employees in managing stress, anxiety, and optimizing mental states.</p>
                          <p>- Workshops and training sessions on stress management techniques, emotional resilience, and work-life balance.</p>
                      </li>

                      <li>
                          <p><b>Employee Assistance Programs:</b></p>
                          <p>- Confidential counseling and support services for employees dealing with personal or work-related stressors.</p>
                          <p>- Access to mental health professionals and resources for employees facing emotional challenges.</p>
                      </li>

                      <li>
                          <p><b>Reporting and Progress Tracking:</b></p>
                          <p>- Detailed reports summarizing employee wellness assessment results and progress over time.</p>
                          <p>- Regular monitoring of employee data to track improvements and adapt wellness programs as needed.</p>
                      </li>

                      <li>
                          <p><b>Equipment and Software:</b></p>
                          <p>DeepSynaps will provide diagnostic equipment, software licenses, and technical support for seamless implementation of wellness programs.</p>
                      </li>

                      <li>
                          <p><b>Team Building and Employee Engagement:</b></p>
                          <p>- Team-building workshops and activities to promote a positive workplace culture and enhance collaboration.</p>
                          <p>- Initiatives to increase employee engagement, satisfaction, and overall well-being.</p>
                      </li>

                      <li>
                          <p><b>Confidentiality and Data Security:</b></p>
                          <p>Ensuring the highest level of data security and confidentiality for employee information.</p>
                      </li>

                  </ol>
                  <p>This package is designed to promote the mental and physical well-being of employees within organizations. By offering comprehensive wellness assessments, mental health support, and stress management techniques, it aims to create a healthier and more productive workforce. DeepSynaps will collaborate closely with organizations to customize the package to meet their specific employee needs and workplace culture, ultimately contributing to a more resilient and engaged workforce.</p>
              </div>
            </div>
        </div>
      </header>
    )
}

export default CoooperateHeaderStaffWellness