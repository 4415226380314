import React from 'react'
import './headerPricing.css'

const HeaderPricing = () => {
  return (
    <>
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Pricing</h1>
                <p className={"customPriceP"}>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues thatmay be impacting their overall well-being. Our diagnostic services include:</p>
            </article>
            <div className="breakline-diagnostic"></div>
        </header>
    </>
  )
}

export default HeaderPricing