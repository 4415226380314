import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';
import { Link } from 'react-router-dom';


const LexicalMemoryTest = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">

                        <div className={"row w-100"}>
                            <div className={"col-md-8 col-sm-12"}><h1>Lexical Memory Test</h1></div>
                            <div className={"col-md-4 col-sm-12 text-md-end text-sm-left pt-1"}>
                                <Link target={"_blank"} className={"btn btn-outline-dark"} to={'https://www.cognifit.com/battery-of-tests/rest-com-test/inquiry-test'}>Start test now</Link>
                            </div>
                        </div>

                        <p>The aim is to evaluate an individual's ability to store and retrieve word-based and language-related information, implicating both short-term and long-term memory faculties in relation to lexical items.</p>
                        <p><strong>Relation to QEEG, Neurofeedback, Biofeedback, and Neuromodulation:</strong></p>
                        <ul>
                            <li><strong>Brain Region:</strong> Temporal Lobe, Hippocampus, and potentially the Frontal Lobe.</li>
                            <li><strong>Brodmann Area:</strong> BA 21/22 (Middle and Superior Temporal Gyri, respectively), BA 45/46 (Frontal Lobe areas involved in language and memory).</li>
                            <li><strong>EEG Electrode Places:</strong> F7, F8, T3, T4, P3, P4</li>
                            <li>
                                <strong>Neurofeedback:</strong>
                                <ul>
                                    <li><strong>Protocol:</strong> Emphasizing stability and engagement in theta (4-7 Hz) and alpha (8-12 Hz) frequencies within language and memory-related brain regions.</li>
                                    <li><strong>Objective:</strong> To foster coherent and optimal function within neural networks underpinning lexical memory and retrieval.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Biofeedback:</strong>
                                <ul>
                                    <li><strong>Assessment:</strong> HRV (Heart Rate Variability) for general emotional and cognitive engagement, and potentially EMG (Electromyography) to assess and manage muscular tension especially in facial muscles during recall.</li>
                                    <li><strong>Protocol:</strong> Employing techniques to manage stress and maintain optimal physiological state during memory encoding and retrieval tasks.</li>
                                    <li><strong>Objective:</strong> To mitigate any physiological impediments to efficient memory function and lexical recall.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Neuromodulation:</strong>
                                <ul>
                                    <li>
                                        <strong>tDCS:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Temporal and Frontal Lobes.</li>
                                            <li><strong>Protocol:</strong> Utilizing anodal tDCS to enhance excitability and functional connectivity within language and memory networks.</li>
                                            <li><strong>Objective:</strong> To boost neural dynamics and efficiency in regions relevant to lexical memory and retrieval.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>PBM:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Temporal and Frontal Lobes.</li>
                                            <li><strong>Protocol:</strong> Using photobiomodulation to support neural health, energy metabolism, and functionality within language and memory networks.</li>
                                            <li><strong>Objective:</strong> To uphold and potentially enhance neural integrity and function in regions pertinent to lexical memory.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Mental Health Assessments and Their Relation:</strong>
                                <ul>
                                    <li><strong>DASS 21/42:</strong> Depression, anxiety, and stress can adversely impact memory functions, particularly impacting the retrieval of lexical items and language processing.</li>
                                    <li><strong>Personality Scale:</strong> Personality traits, such as openness or neuroticism, might have implications for memory processes, whereby different personality types might employ varied strategies or exhibit divergent capabilities in lexical memory.</li>
                                    <li><strong>SISRI-24 (Social Interaction Self-Esteem Inventory):</strong> Social interaction and self-esteem may have indirect impacts on cognitive processes like memory, particularly in socially mediated contexts, or situations where social anxiety might be a factor.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>The incorporation of psychological, cognitive, and physiological assessments and interventions allows for a comprehensive and holistic approach to understanding and enhancing lexical memory capabilities. All the aforementioned strategies should ideally be undertaken under the guidance and supervision of an experienced professional to assure both efficacy and safety in their application.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default LexicalMemoryTest