import { React, useState } from 'react'
import './cooperateHeader.css'
import {Link} from "react-router-dom";

const CoooperateHeaderUnivertiesAndResearchInstitutions = () => {

    return (
      <header id='headerDiagnostic' className='d-flex flex-column'>
        <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
          <h1>Cooperate</h1>
          <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and cognitive abilities, identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
          <a className='navbar-Respbtn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
        </article>
        <div className="breakline-diagnostic"></div>
  
        <div className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
          <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/L38T6fnrVjc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>

        <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                <h1>DeepSynaps Academic and Research Enhancement Package:</h1>
                  <ol>
                      <li>
                          <p><b>Cognitive and Mental Fitness Assessment:</b></p>
                          <ul>
                              <li>
                                  <p><b>Brain Scan (QEEG Brain Mapping):</b></p>
                                  <p>Both Dry and Wet EEG scans to assess brain activity and identify cognitive patterns and areas for improvement among researchers and students.</p>
                              </li>
                              <li>
                                  <p><b>Cognitive Assessment:</b></p>
                                  <p>Comprehensive cognitive assessments to measure cognitive abilities, memory, attention, and problem-solving skills among students and faculty members.</p>
                              </li>
                          </ul>
                      </li>

                      <li>
                          <p><b>Researcher Support:</b></p>
                          <p>- Personalized neurofeedback training sessions for researchers to enhance focus, creativity, and cognitive performance during research projects.</p>
                          <p>- Neuromodulation (TDCS-PBM) sessions to optimize cognitive function, reduce mental fatigue, and promote problem-solving abilities.</p>
                          <p>- Biofeedback sessions for stress management and maintaining optimal mental states during research.</p>
                      </li>

                      <li>
                          <p><b>Cognitive Enhancement Workshops:</b></p>
                          <p>- Workshops and seminars for students and faculty on enhancing cognitive performance, managing stress, and improving research productivity.</p>
                          <p>- Research-focused training sessions on data analysis and research methodologies.</p>
                      </li>

                      <li>
                          <p><b>Academic Performance and Student Success:</b></p>
                          <p>- Student-specific neurofeedback and cognitive training to improve focus, concentration, and academic performance.</p>
                          <p>- Support for students with learning difficulties or cognitive challenges to enhance their academic success.</p>
                      </li>

                      <li>
                          <p><b>Reporting and Progress Tracking:</b></p>
                          <p>- Detailed reports summarizing assessment results and progress over time for both researchers and students.</p>
                          <p>- Regular monitoring of data to track improvements and adapt training and coaching programs as needed.</p>
                      </li>
                      <li>
                          <p><b>Equipment and Software:</b></p>
                          <p>DeepSynaps will provide diagnostic equipment, software licenses, and technical support to facilitate effective assessments and training.</p>
                      </li>
                      <li>
                          <p><b>Research Collaboration:</b></p>
                          <p>Support for collaborative research projects that leverage cognitive assessment and enhancement techniques.</p>
                      </li>

                      <li>
                          <p><b>Confidentiality and Data Security:</b></p>
                          <p>Ensuring the highest level of data security and confidentiality for sensitive research and student information.</p>
                      </li>
                  </ol>
                  <p>This package is designed to enhance the cognitive capabilities and mental well-being of students and researchers within universities and research institutions. By offering advanced cognitive assessments, training, and support, it aims to improve research productivity, academic performance, and overall well-being. DeepSynaps will work closely with institutions to tailor the package to their specific academic and research goals, contributing to a more innovative and successful academic community.</p>
              </div>
            </div>
        </div>
      </header>
    )
}

export default CoooperateHeaderUnivertiesAndResearchInstitutions