import { React, useState } from 'react'
import './cooperateHeader.css'
import {Link} from "react-router-dom";

const CorporateSeniorLivingCommunityCognitiveWellnessHeader = () => {

    return (
      <header id='headerDiagnostic' className='d-flex flex-column'>
        <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
          <h1>Cooperate</h1>
          <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and cognitive abilities, identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
          <a className='navbar-Respbtn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
        </article>
        <div className="breakline-diagnostic"></div>
  
        <div className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
          <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/L38T6fnrVjc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>

        <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                <h1>DeepSynaps Senior Living Community Cognitive Wellness Package:</h1>
                  <ol>
                      <li>
                          <p><b>Comprehensive Health and Cognitive Assessment:</b></p>
                          <ul>
                              <li>
                                  <p><b>Body Scan:</b></p>
                                  <p>Includes EMG, ECG/Pulse, Respiratory, Temperature, Skin condition, SCP, and EOG scans to assess residents' physical health, stress levels, and physiological responses.</p>
                              </li>
                              <li>
                                  <p><b>Cognitive Assessment:</b></p>
                                  <p>Comprehensive cognitive assessments to measure cognitive abilities, memory, attention, and problem-solving skills among elderly residents.</p>
                              </li>
                          </ul>
                      </li>

                      <li>
                          <p><b>Personalized Cognitive Wellness Plans:</b></p>
                          <p>Individualized cognitive wellness plans for each resident based on their cognitive assessment results, incorporating the following services.</p>
                      </li>

                      <li>
                          <p><b>Cognitive Stimulation Activities:</b></p>
                          <p>Cognitive stimulation programs designed to engage residents in activities that promote cognitive health and brain fitness. Activities may include puzzles, memory games, and brain training exercises.</p>
                      </li>

                      <li>
                          <p><b>Memory Enhancement Programs:</b></p>
                          <p>Specialized memory enhancement programs aimed at improving residents' memory retention, recall, and overall cognitive function. These programs incorporate memory exercises and techniques.</p>
                      </li>

                      <li>
                          <p><b>Emotional Well-being Support:</b></p>
                          <p>Emotional well-being workshops and support groups to help residents manage stress, anxiety, and maintain a positive outlook on life. This includes techniques for emotional resilience and relaxation.</p>
                      </li>

                      <li>
                          <p><b>Biofeedback for Stress Management:</b></p>
                          <p>Customized biofeedback sessions to assist residents in managing stress, anxiety, and optimizing their emotional states.</p>
                      </li>

                      <li>
                          <p><b>Neurofeedback Training:</b></p>
                          <p>Personalized neurofeedback training sessions to enhance focus, concentration, and cognitive performance among elderly residents.</p>
                      </li>

                      <li>
                          <p><b>Neuromodulation (TDCS-PBM):</b></p>
                          <p>Transcranial Direct Current Stimulation (TDCS) and Photobiomodulation (PBM) sessions to support mood stabilization, cognitive enhancement, and overall well-being for elderly residents.</p>
                      </li>

                      <li>
                          <p><b>Progress Tracking and Reporting:</b></p>
                          <p>- Detailed reports summarizing resident assessment results and progress over time.</p>
                          <p>- Regular monitoring of resident data to track improvements and adapt cognitive wellness plans as necessary.</p>
                      </li>

                      <li>
                          <p><b>Equipment and Software:</b></p>
                          <p>DeepSynaps will provide diagnostic equipment, software licenses, and technical support for seamless implementation of cognitive wellness programs within the senior living community.</p>
                      </li>

                      <li>
                          <p><b>Resident Engagement and Activities:</b></p>
                          <p>Integration of cognitive wellness activities into the senior living community's daily routines and recreational programs, ensuring residents remain mentally active and engaged.</p>
                      </li>

                      <li>
                          <p><b>Confidentiality and Resident Privacy:</b></p>
                          <p>Ensuring the highest level of data security and confidentiality for resident information, prioritizing resident privacy and consent.</p>
                      </li>
                  </ol>
                  <p>This comprehensive package is designed to create an enriching and supportive environment within senior living communities, focusing on promoting cognitive health, emotional well-being, and overall quality of life among elderly residents. DeepSynaps will collaborate closely with senior living communities to tailor the package to meet the specific needs and preferences of their residents, ultimately contributing to a more vibrant and fulfilling living experience for seniors.</p>
              </div>
            </div>
        </div>
      </header>
    )
}

export default CorporateSeniorLivingCommunityCognitiveWellnessHeader