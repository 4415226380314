import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';


const DrivingCognitiveAssessment = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                        <h1>Driving Cognitive Assessment (DAB)</h1>
                        <p>The Driving Cognitive Assessment (DAB) helps the healthcare professional evaluate the cognitive/ psychological processes involved in driving and assesses the driver’s skills, abilities and performance. Healthcare professionals use this assessment to evaluate the risk level or accident tendency index. This assessment takes 30-40 minutes to complete.</p>
                        <p>This assessment is appropriate for adolescents looking to obtain their license, as well as adults and seniors. The battery can be used cross-culturally, and for both males and females. It can be used to assess teens learning how to drive, driving adults, and seniors.</p>
                        <p><strong>Relation to the QEEG,Neurofeedback,Biofeedback and Neuromodulation</strong></p>
                        <p>The Coordination Cognitive Assessment (CAB-CO) provides a comprehensive insight into an individual's motor-related cognitive functions, revealing both strengths and weaknesses pertaining to coordination. Designed for individuals from children over 7 to seniors and adaptable for all genders and cultural backgrounds, this assessment is a valuable tool for healthcare professionals.</p>
                        <ul>
                            <li>
                                <strong>Coordination and Motor Control:</strong>
                                <ul>
                                    <li><strong>Brain Regions and QEEG:</strong> Coordination and motor control are primarily regulated by the cerebellum and the basal ganglia. QEEG can effectively map brainwave patterns associated with these activities, offering objective data on coordination.</li>
                                    <li><strong>Biofeedback:</strong> Through Electromyography (EMG), we can gauge muscle activity and tension, pivotal indicators of coordination.</li>
                                    <li><strong>Neurofeedback:</strong> Focusing on Sensory Motor Rhythm (SMR) training can potentially enhance motor control and overall coordination.</li>
                                    <li><strong>tDCS:</strong> Targeting the cerebellum or the motor cortex using tDCS can modulate their activity, influencing coordination skills.</li>
                                    <li><strong>PBM:</strong> Photobiomodulation targeted towards the cerebellum or basal ganglia might optimize their functionality, promoting better coordination.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Spatial Perception:</strong>
                                <ul>
                                    <li><strong>Brain Regions and QEEG:</strong> Spatial cognition, which aids coordination, is largely overseen by the parietal lobe, especially its right hemisphere. By employing QEEG, spatial cognition patterns can be isolated and analyzed.</li>
                                    <li><strong>Biofeedback:</strong> Techniques like Electrooculography (EOG) can be instrumental in assessing spatial perception, by monitoring eye movements.</li>
                                    <li><strong>Neurofeedback:</strong> Training in the Alpha frequency range can sharpen spatial awareness and, by extension, coordination.</li>
                                    <li><strong>tDCS:</strong> Applying tDCS to the parietal regions might enhance spatial cognitive capabilities.</li>
                                    <li><strong>PBM:</strong> Focusing light therapy on the parietal lobe can rejuvenate its functions, thus potentially boosting spatial perception.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Reaction Time:</strong>
                                <ul>
                                    <li><strong>Brain Regions and QEEG:</strong> The basal ganglia and the prefrontal cortex play crucial roles in reaction times, a foundational element of coordination. Through QEEG, neural patterns linked to response dynamics can be detected.</li>
                                    <li><strong>Biofeedback:</strong> Using Electrocardiography (ECG) to monitor Heart Rate Variability (HRV) provides insights into physiological readiness and reactivity, which often parallel reaction times.</li>
                                    <li><strong>Neurofeedback:</strong> Promoting Gamma frequency training can expedite reaction times.
                                    </li>
                                    <li><strong>tDCS:</strong> Focusing on the prefrontal cortex or basal ganglia with tDCS can potentially refine reaction times.</li>
                                    <li><strong>PBM:</strong> Administering light therapy to these regions may amplify neural responsiveness, benefitting reaction times.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>The amalgamation of the CAB-CO's findings with state-of-the-art neurological and physiological tools grants a multi-dimensional perspective on an individual's coordination abilities. This holistic approach not only deciphers motor behaviors but also delves into the neural mechanics behind them, fostering precise and effective interventions tailored to diverse age and cultural groups.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default DrivingCognitiveAssessment