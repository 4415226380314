import React, { useRef, useState } from 'react'
import { GoogleMap, useJsApiLoader, Autocomplete, LoadScript, MarkerF, InfoWindowF, MARKER_LAYER, MarkerClusterer, OverlayView} from '@react-google-maps/api';
import './MapComponent.css'
import {getLocations} from "../../Services/Apis/Location.service";
import {Link} from "react-router-dom";

const containerStyle = {
  height: '90vh'
};

const MapComponent = () => {
  const [map, setMap] = React.useState(null)
  const [center, setCenter] = useState({
      lat: 51.5072178,
      lng: -0.1275862
  });

  const [zoom, setZoom] = useState(10);

  const [markers, setMarkers] = useState([])
  const [activeMarker, setActiveMarker] = useState([])
  const [showLocationsCount, setShowLocationsCount] = useState(false);
  const [searchingLocation, setSearchingLocation] = useState(false);

  const [autocomplete, setAutocomplete] = useState(null)

  const autocompleteRef = useRef();

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
      if (map.current) {
          const bounds = new window.google.maps.LatLngBounds(center);
          map.current.fitBounds(bounds);

          setMap(map)
      }

    const autocomplete = autocompleteRef.current
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

    const onPlaceChanged = async () => {

      if (autocomplete.getPlace()) {
          setMarkers([]);
          setActiveMarker([]);
          setSearchingLocation(true);
          setShowLocationsCount(false);

          let locationType = autocomplete.getPlace().types;

          let response = await getLocations({
              lat: autocomplete.getPlace().geometry.viewport.getSouthWest().lat(),
              lat1: autocomplete.getPlace().geometry.viewport.getNorthEast().lat(),
              long: autocomplete.getPlace().geometry.viewport.getSouthWest().lng(),
              long1: autocomplete.getPlace().geometry.viewport.getNorthEast().lng(),
              address: autocomplete.getPlace().formatted_address,
              centerLat: autocomplete.getPlace().geometry.viewport.getCenter().lat(),
              centerLong: autocomplete.getPlace().geometry.viewport.getCenter().lng()
          })

          setSearchingLocation(false);
          setShowLocationsCount(true);

          setMarkers(response.data);
          setShowLocationsCount(true);

          if (locationType.includes('country')) {
              setZoom(7);
          } else if(locationType.includes('locality')) {
              if (response.data.length <= 5) {
                  setZoom(14);
              } else {
                  setZoom(10);
              }
          } else {
              setZoom(16);
          }

          if (
              (response.data.length && (!locationType.includes('country') && !locationType.includes('locality'))) ||
              (response.data.length <= 5 && locationType.includes('locality'))
          ) {
              setCenter({
                  lat: response.data[0].lat,
                  lng: response.data[0].long
              });
          } else {
              setCenter({
                  lat: autocomplete.getPlace().geometry.location.lat(),
                  lng: autocomplete.getPlace().geometry.location.lng()
              });
          }
      }
    }

    const onloadAutocomplete = (autocomplete) => {
      setAutocomplete(autocomplete);
    }

    const handleOnLoad = (map) => {
      setMap(map);
        // const bounds = new window.google.maps.LatLngBounds(center);

        // markers.forEach(({ position }) => {
        //     bounds.extend(position);
        //     console.log(position);
        // });

        // map.fitBounds(bounds);
    };

    const handleMarkerClick = (clickedMarker) => {
        setActiveMarker(clickedMarker);
    }

  return (
      <>
          <LoadScript id="script-loader" googleMapsApiKey="AIzaSyCYwXgzAqZX4jyzm1AlKe9uezU2TqIjE84" libraries={["places"]}>
              <div className="wrapper locationWrapper mt-3">
                  <div className="row mb-3">
                      <div className={"col-md-12 col-sm-12"}>
                          <h1>Locations</h1>
                      </div>
                  </div>
                  <div className="row mt-4">
                      <div className={"col-md-6 col-sm-12 locationsLeft"}>
                          <div className={"form-group search-box"}>
                              <label>Location</label>
                              <Autocomplete onPlaceChanged={(place) => onPlaceChanged(place)} onLoad={onloadAutocomplete} options={{strictBounds: true}}>
                                  <div className="textbox-row">
                                      <i>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                               fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                              <path
                                                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                          </svg></i>
                                      <input
                                          type="text"
                                          placeholder="Search for a location"
                                          className="form-control"
                                          style={{
                                              textOverflow: `ellipses`,
                                              borderLeft: `none`
                                          }}
                                      />
                                  </div>
                              </Autocomplete>
                          </div>
                      </div>
                  </div>
                  <div className="row mb-5 mt-3">
                      <div className={"col-md-5 col-sm-12 locationsLeft"}>
                          {searchingLocation &&
                            <p>Finding locations for you... please wait</p>
                          }
                          {showLocationsCount &&
                              <div className={"form-group"} style={{
                                  borderBottom: "1px solid #cccccc",
                                  paddingBottom: "15px",
                                  paddingTop: "5px"
                              }}><h4>{markers?.length} location{markers?.length > 1 ? 's' : ''}.</h4></div>
                          }
                          <div className={"locations"}>
                              <div className={"row"}>
                                  { markers?.length > 0 &&
                                      <>
                                          { markers?.map((obj, i) => (
                                              <div className={"col-md-6 col-sm-12 locationsRow"} onClick={() => handleMarkerClick((i + 1))}>
                                                  <div className={"locationName"}>{ obj.name }</div>
                                                  <div className={"locationAddress"}>{ obj.address }</div>
                                                  <div className={"locationButton"}>
                                                      <Link to={'/location/'+obj.id+'/book'} className={"btn btn-dark btn-sm"}>Book now <svg
                                                          xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                          fill="currentColor" className="bi bi-arrow-right-short"
                                                          viewBox="0 0 16 16">
                                                          <path fill-rule="evenodd"
                                                                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                                      </svg></Link>
                                                  </div>
                                              </div>
                                          ))}
                                      </>
                                  }

                                  {(showLocationsCount && markers?.length <= 0) &&
                                      <>
                                          <p className={"mt-2"}><strong>No results were found matching your search.</strong></p>
                                      </>
                                  }
                              </div>
                          </div>
                      </div>

                      <div className={"col-md-7 col-sm-12"}>
                            <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={zoom} onLoad={handleOnLoad} options={{styles: [
                                    {
                                        "featureType": "poi",
                                        "elementType": "labels.icon",
                                        "stylers": [
                                            {
                                                "visibility": "off"
                                            }
                                        ]
                                    }]}}>
                                <OverlayView
                                    position={{ lat: -34.397, lng: 150.644 }}
                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                >
                                    <MarkerClusterer gridSize={60} minimumClusterSize={3} options={{zoomOnClick: true}}>
                                        {(clusterer) => (
                                            <div>
                                                { markers?.map((obj, i) => (
                                                    <div key={`marker-`+i} onClick={() => handleMarkerClick((i + 1))}>
                                                        <MarkerF
                                                            key={i}
                                                            position={{lat: obj.lat, lng: obj.long}}
                                                            clusterer={clusterer}
                                                            label={{text: obj.name, className: 'markerLable'}}
                                                            onClick={() => handleMarkerClick((i + 1))}
                                                        >
                                                            { (activeMarker && activeMarker === (i + 1)) &&
                                                                <InfoWindowF position={{lat: obj.lat, lng: obj.long}}>
                                                                    <>
                                                                        <div className={"infowindowName"}>{ obj.name }</div>
                                                                        <div className={"infowindowAddress"}>{ obj.address }</div>
                                                                    </>
                                                                </InfoWindowF>
                                                            }
                                                        </MarkerF>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </MarkerClusterer>
                                </OverlayView>
                            </GoogleMap>
                      </div>
                  </div>
              </div>
          </LoadScript>
      </>
  )
}

export default MapComponent