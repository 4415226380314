import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';
import { Link } from 'react-router-dom';


const ProgressiveMatricesTest = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                        <div className={"row w-100"}>
                            <div className={"col-md-8 col-sm-12"}><h1>Progressive Matrices Test (CAT-PM)</h1></div>
                            <div className={"col-md-4 col-sm-12 text-md-end text-sm-left pt-1"}>
                                <Link target={"_blank"} className={"btn btn-outline-dark"} to={'https://www.cognifit.com/gb/battery-of-tests/progressive-matrices-test'}>Start test now</Link>
                            </div>
                        </div>

                        <p>Engage in a non-verbal assessment of your abstract reasoning and problem-solving skills.</p>
                        <p><strong>Relation to QEEG, Neurofeedback, Biofeedback, and Neuromodulation:</strong></p>
                        <ul>
                            <li><strong>Brain Region:</strong> Prefrontal Cortex, Parietal Lobe</li>
                            <li><strong>Broadman Area:</strong> BA 9, BA 40 </li>
                            <li><strong>EEG Electrode Places:</strong> Fp1, Fp2, P3, P4</li>
                            <li>
                                <strong>Neurofeedback:</strong>
                                <ul>
                                    <li><strong>Protocol:</strong> Enhancing beta activity in the prefrontal and parietal areas, while modulating theta/beta ratios for improved focus and reasoning.</li>
                                    <li><strong>Objective:</strong> To support logical, non-verbal problem-solving and maintain sustained cognitive engagement through the regulation of relevant brainwave patterns.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Biofeedback:</strong>
                                <ul>
                                    <li><strong>Assessment:</strong> HRV and GSR, recognizing stress and arousal levels which can impact abstract reasoning and problem-solving capacities.</li>
                                    <li><strong>Protocol:</strong> Training to optimize HRV for physiological regulation and managing GSR to counteract the physiological manifestations of cognitive strain or stress.</li>
                                    <li><strong>Objective:</strong> Facilitating a conducive physiological state that supports optimal cognitive performance and minimizes the detriments of stress or anxiety on reasoning.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Neuromodulation:</strong>
                                <ul>
                                    <li>
                                        <strong>tDCS:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Dorsolateral Prefrontal Cortex (DLPFC)</li>
                                            <li><strong>Protocol:</strong> Anodal stimulation to enhance neuronal excitability and stimulate circuits involved in reasoning and problem-solving.</li>
                                            <li><strong>Objective:</strong> Amplifying neural processing within circuits pertinent to logical reasoning and abstract thinking.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>PBM:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Prefrontal Cortex, Parietal Lobe</li>
                                            <li><strong>Protocol:</strong> Using photobiomodulation to facilitate cellular energy production and possibly enhance synaptic connectivity.</li>
                                            <li><strong>Objective:</strong> Bolstering neural efficiency within networks implicated in abstract reasoning and non-verbal problem-solving.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Mental Health Assessments and Their Relation:</strong>
                                <ul>
                                    <li><strong>SISRI-24 (Situational Interest Scale):</strong> Understanding intrinsic interest in tasks might offer insights into motivation levels which can impact engagement and performance on reasoning tasks.</li>
                                    <li><strong>Sleep Scale:</strong> Evaluating sleep quality and duration, which are crucial for cognitive functions like problem-solving and logical reasoning, ensuring interventions might also target sleep optimization to bolster cognitive functioning.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>The strategic alignment between the cognitive assessment, neurophysiological understandings, and psychological assessments ensures a holistic approach to cognitive enhancement and wellness. This includes understanding the intrinsic and extrinsic factors influencing performance and deploying targeted interventions across neural, cognitive, and physiological domains to foster comprehensive cognitive well-being.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default ProgressiveMatricesTest