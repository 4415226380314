import React, { useState } from 'react'
import './headerTreatments.css'
import {Link} from "react-router-dom";

const HeaderBrainSimulationPrograms = () => {
  const [showDiagnostic1, setShowDiagnostic1] = useState(true)
  const [showDiagnostic2, setShowDiagnostic2] = useState(false)
  const [showDiagnostic3, setShowDiagnostic3] = useState(false)
  const [showDiagnostic4, setShowDiagnostic4] = useState(false)
  const [showDiagnostic5, setShowDiagnostic5] = useState(false)
  const [showDiagnostic6, setShowDiagnostic6] = useState(false)
  const [showDiagnostic7, setShowDiagnostic7] = useState(false)

  const showSection1 = () => { // Showing 1st element
    setShowDiagnostic1(true)
    setShowDiagnostic2(false)
    setShowDiagnostic3(false)
    setShowDiagnostic4(false)
    setShowDiagnostic5(false)
    setShowDiagnostic6(false)
    setShowDiagnostic7(false)
  }

  const showSection2 = () => { // Showing 2nd element
    setShowDiagnostic1(false)
    setShowDiagnostic2(true)
    setShowDiagnostic3(false)
    setShowDiagnostic4(false)
    setShowDiagnostic5(false)
    setShowDiagnostic6(false)
    setShowDiagnostic7(false)
  }

  const showSection3 = () => { // Showing 3rd element
    setShowDiagnostic1(false)
    setShowDiagnostic2(false)
    setShowDiagnostic3(true)
    setShowDiagnostic4(false)
    setShowDiagnostic5(false)
    setShowDiagnostic6(false)
    setShowDiagnostic7(false)
  }

  const showSection4 = () => { // Showing 4th element
    setShowDiagnostic1(false)
    setShowDiagnostic2(false)
    setShowDiagnostic3(false)
    setShowDiagnostic4(true)
    setShowDiagnostic5(false)
    setShowDiagnostic6(false)
    setShowDiagnostic7(false)
  }

  const showSection5 = () => { // Showing 4th element
    setShowDiagnostic1(false)
    setShowDiagnostic2(false)
    setShowDiagnostic3(false)
    setShowDiagnostic4(false)
    setShowDiagnostic5(true)
    setShowDiagnostic6(false)
    setShowDiagnostic7(false)
  }


  const showSection6 = () => { // Showing 4th element
    setShowDiagnostic1(false)
    setShowDiagnostic2(false)
    setShowDiagnostic3(false)
    setShowDiagnostic4(false)
    setShowDiagnostic5(false)
    setShowDiagnostic6(true)
    setShowDiagnostic7(false)
  }

  const showSection7 = () => { // Showing 4th element
    setShowDiagnostic1(false)
    setShowDiagnostic2(false)
    setShowDiagnostic3(false)
    setShowDiagnostic4(false)
    setShowDiagnostic5(false)
    setShowDiagnostic6(false)
    setShowDiagnostic7(true)
  }

  return (
    <>
      <header id='headerDiagnostic' className='d-flex flex-column'>
        <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
          <h1>Interventions</h1>
          <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
          <a className='navbar-Respbtn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
        </article>
        <div className="breakline-diagnostic"></div>


        <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
          <div className="headerDiagnostic-options d-flex flex-column align-items-start">
            <button className={showDiagnostic1 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection1}>What is Brain Stimulation Training?</button>
            <button className={showDiagnostic2 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection2}>Our Specialized Brain Stimulation Programs</button>
            <button className={showDiagnostic3 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection3}>Additional Benefits of Brain Stimulation Training</button>
            <button className={showDiagnostic4 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection4}>Why Brain Stimulation Training is the Future</button>
            <button className={showDiagnostic5 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection5}>Who is Brain Stimulation Training For</button>
            <button className={showDiagnostic6 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection6}>Your Brain Stimulation Training Experience: What to Anticipate</button>
            <button className={showDiagnostic7 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection7}>Why Partner With Us?</button>
          </div>
          {showDiagnostic1 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle3 d-flex flex-column align-items-start">
                <h1>What is Brain Stimulation Training?</h1>
                <p>Brain stimulation training, leveraging advanced cognitive science, aims to fine-tune the brain's performance. Through specialized tasks and exercises, individuals can enhance specific cognitive skills, improve neural networks, and maintain optimum brain health.</p>
              </div>
            </div>
          }{showDiagnostic2 &&
            <div className="headerDiagnostic-content">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>Our Specialized Brain Stimulation Programs</h1>
                <div className="howItWorksOL">
                  <p>With a plethora of choices, our training programs are designed to cater to a wide range of needs:</p>
                  <p><b>1. Personalized Brain Stimulation:</b> Professionals tailor a training regime, giving them control over session durations and frequencies to match patient needs. <Link to={"https://www.cognifit.com/cognitive-brain-training"}>Learn more</Link></p>
                  <p><b>2. 55 and Over Training:</b> A dedicated program for those aged 55 and above. This training boosts brain plasticity, fostering neural network development and enhancing essential cognitive functions. <Link to={"https://www.cognifit.com/brain-training-active-aging"}>Learn more</Link></p>
                  <p><b>3. Memory Brain Training:</b> Specifically targets various memory types including working memory, short-term phonological memory, and more. <Link to={"https://www.cognifit.com/online-memory-games-training"}>Learn more</Link></p>
                  <p><b>4. Reasoning Brain Training:</b> A focus on planning and other executive functions. This program aids in daily task planning, adaptation to change, and processing complex information. <Link to={"https://www.cognifit.com/online-memory-games-training"}>Learn more</Link></p>
                  <p><b>5. Reading Comprehension Brain Training:</b> Enhance reading comprehension skills, training cognitive skills like short-term visual memory and working memory. <Link to={"https://www.cognifit.com/reading-comprehension"}>Learn more</Link></p>
                  <p><b>6. Concentration Brain Training:</b> Develop and hone cognitive processes pivotal for information processing and daily task execution. This program targets skills like inhibition, divided attention, focused attention, and updating. <Link to={"https://www.cognifit.com/attention-brain-games-training"}>Learn more</Link></p>
                  <p><b>7. Driving Brain Training:</b> Aimed at enhancing the cognitive skills crucial for driving. It focuses on faculties like divided attention, focused attention, hand-eye coordination, and reaction time, among others. <Link to={"https://www.cognifit.com/driving-brain-training"}>Learn more</Link></p>
                  <p><b>8. Perception Brain Training:</b> This training is tailored to bolster perception skills, delving into spatial, visual, and auditory perception. It's an invaluable tool for those wishing to refine their recognition and estimation abilities. <Link to={"https://www.cognifit.com/perception-training"}>Learn more</Link></p>
                  <p><b>9. Coordination Brain Training:</b> A specialized regimen to train and enhance motor coordination. Suitable for all age groups, it engages users in exercises that stimulate hand-eye coordination, balance, and precision. <Link to={"https://www.cognifit.com/coordination-training"}>Learn more</Link></p>
                </div>
              </div>
            </div>
          }{showDiagnostic3 &&
            <div className="headerDiagnostic-content">
              <div className="diagnosticArticle3 d-flex flex-column align-items-start">
                <h1>Additional Benefits of Brain Stimulation Training</h1>
                <div className="conditionsUL d-flex flex-row">
                  <div className="workingAreas">
                    <p><b>1. Flexible Learning:</b> Our platform allows users to train at their own pace and according to their schedules, ensuring a stress-free experience.</p>
                    <p><b>2. Data-Driven Insights:</b> Post each session, users can access detailed analytics of their performance, enabling them to identify trends and areas of improvement.</p>
                    <p><b>3. Community Engagement:</b> Join a global community of users where you can share experiences, tips, and success stories, fostering a collaborative learning environment.</p>
                    <p><b>4. Expert Assistance:</b> Our team of cognitive scientists and experts is always available to guide, answer queries, and ensure that every user gets the most out of their training.</p>
                  </div>
                </div>
              </div>
            </div>
          }{showDiagnostic4 &&
            <div className="headerDiagnostic-content">
              <div className="diagnosticArticle4 d-flex flex-column align-items-start">
                <h1>Why Brain Stimulation Training is the Future</h1>
                <p>In our rapidly evolving world, maintaining and enhancing cognitive abilities isn't just a choice; it's a necessity. From students to professionals, from the young to the elderly, everyone stands to gain from sharper, more resilient brain functions. Our suite of programs ensures that no matter what your need or goal, there's a training regimen for you.</p>
                <p>For deeper insights, consultation, or to start your cognitive enhancement journey, please contact us at <a href="mailto: info@deepsynaps.com">info@deepsynaps.com</a>.</p>
                <p>Every brain holds a universe of potential. Let's explore yours together.</p>
              </div>
            </div>
          }
          {showDiagnostic5 &&
              <div className="headerDiagnostic-content">
                <div className="diagnosticArticle4 d-flex flex-column align-items-start">
                  <h1>Who is Brain Stimulation Training For?</h1>
                  <p><b>Elderly:</b> Retain cognitive prowess and boost daily life skills.</p>
                  <p><b>Students:</b> Enhance memory, concentration, and academic performance.</p>
                  <p><b>Professionals:</b> Sharpen reasoning, planning, and execution capabilities.</p>
                  <p><b>Rehabilitation Patients:</b> Recover from cognitive deficits post-injury or ailment.</p>
                  <p><b>Performance Seekers:</b> Elevate specific cognitive abilities for professional or personal pursuits.</p>
                </div>
              </div>
          }
          {showDiagnostic6 &&
              <div className="headerDiagnostic-content">
                <div className="diagnosticArticle4 d-flex flex-column align-items-start">
                  <h1>Your Brain Stimulation Training Experience: What to Anticipate</h1>
                  <p><b>Personalized Assessment:</b> Understand your current cognitive strengths and areas of improvement.</p>
                  <p><b>Curated Sessions:</b> Engage in training modules handpicked for your needs.</p>
                  <p><b>During the Session:</b> Engross yourself in engaging tasks and exercises that stimulate your brain.</p>
                  <p><b>Progress Tracking:</b> Post-session, delve into insights, improvements, and areas to focus on in future sessions.</p>
                </div>
              </div>
          }
          {showDiagnostic7 &&
              <div className="headerDiagnostic-content">
                <div className="diagnosticArticle4 d-flex flex-column align-items-start">
                  <h1>Why Partner With Us?</h1>
                  <p>Backed by rigorous research and state-of-the-art methodologies, our brain stimulation programs promise a journey of self-discovery and cognitive enhancement. We're not just about training; we're about empowering each individual to harness the vast potential within.</p>
                  <p>For further information or inquiries, connect with us at <a href="mailto: info@deepsynaps.com">info@deepsynaps.com</a>.</p>
                  <p>Join us in this captivating journey and unlock the myriad capabilities your brain offers.</p>
                </div>
              </div>
          }
        </div>
      </header>
    </>
  )
}

export default HeaderBrainSimulationPrograms