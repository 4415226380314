import React, { useState } from 'react'
import './headerDiagnostic.css'
import { Assesment1, Assesment2, Assesment3, Assesment4, Assesment5, Assesment6, Assesment7, Assesment8, Assesment9, Assesment10, Assesment11, Assesment12, Assesment13, Assesment14, Assesment15, Assesment16, Assesment17} from '../../../assets/images';


const HeaderDiagnostic = () => {

  const [showDiagnostic1, setShowDiagnostic1] = useState(true)
  const [showDiagnostic2, setShowDiagnostic2] = useState(false)
  const [showDiagnostic3, setShowDiagnostic3] = useState(false)
  const [showDiagnostic4, setShowDiagnostic4] = useState(false)

  const showWhatItIs = () => { // Showing 1st element
    setShowDiagnostic1(true)
    setShowDiagnostic2(false)
    setShowDiagnostic3(false)
    setShowDiagnostic4(false)
  }

  const showHowItWorks = () => { // Showing 2nd element
    setShowDiagnostic1(false)
    setShowDiagnostic2(true)
    setShowDiagnostic3(false)
    setShowDiagnostic4(false)
  }

  const showConditions = () => { // Showing 3rd element
    setShowDiagnostic1(false)
    setShowDiagnostic2(false)
    setShowDiagnostic3(true)
    setShowDiagnostic4(false)
  }

  const showBenefits = () => { // Showing 4th element
    setShowDiagnostic1(false)
    setShowDiagnostic2(false)
    setShowDiagnostic3(false)
    setShowDiagnostic4(true)
  }

  const cogniFitAssesments = [
    {
      assesmentImg: Assesment1,
      h5: 'General Cognitive Assessment (CAB)',
      p: 'How can I know if my brain is healthy?',
      learnBtn: '',
      startBtn: '',
    },{
      assesmentImg: Assesment2,
      h5: '65 and Over Cognitive Assessment (CAB-AG)',
      p: 'Test how fast is your brain aging',
      learnBtn: '',
      startBtn: '',
    },{
      assesmentImg: Assesment3,
      h5: 'Academic Cognitive Assessment (CAB-A)',
      p: 'Cognitive test that was created specifically for schools',
      learnBtn: '',
      startBtn: '',
    },{
      assesmentImg: Assesment4,
      h5: 'Concentration Cognitive Assessment (CAB-AT)',
      p: 'How good is your concentration?',
      learnBtn: '',
      startBtn: '',
    },{
      assesmentImg: Assesment5,
      h5: 'Coordination Cognitive Assessment (CAB-CO)',
      p: 'How good is your coordination?',
      learnBtn: '',
      startBtn: '',
    },{
      assesmentImg: Assesment6,
      h5: 'Deary-Liewald Task (CAT-DL)',
      p: `Measuring the user's reaction time in simple situations`,
      learnBtn: '',
      startBtn: '',
    },{
      assesmentImg: Assesment7,
      h5: 'Driving Cognitive Assessment (DAB)',
      p: 'Are You Fit To Drive?',
      learnBtn: '',
      startBtn: '',
    },{
      assesmentImg: Assesment8,
      h5: `Eriksen's Flanker Task (CAT-EF)`,
      p: 'Are You Fit To Drive?',
      learnBtn: '',
      startBtn: '',
    },{
      assesmentImg: Assesment9,
      h5: 'Inhibition of Return Task (CAT-IOR)',
      p: 'This task seeks to measure how much interference is generated by the presentation of adjacent incongruent and irrelevant visual information.',
      learnBtn: '',
      startBtn: '',
    },{
      assesmentImg: Assesment10,
      h5: 'Memory Cognitive Assessment (CAB-ME)',
      p: 'How good is your memory?',
      learnBtn: '',
      startBtn: '',
    },{
      assesmentImg: Assesment11,
      h5: 'Perception Cognitive Assessment (CAB-PC)',
      p: 'How good is your perception?',
      learnBtn: '',
      startBtn: '',
    },{
      assesmentImg: Assesment12,
      h5: 'Reading Comprehension Cognitive Assessment (CAB-RC)',
      p: 'Do you have reading difficulties?',
      learnBtn: '',
      startBtn: '',
    },{
      assesmentImg: Assesment13,
      h5: 'Reasoning Cognitive Assessment (CAB-RS)',
      p: 'How good is your reasoning?',
      learnBtn: '',
      startBtn: '',
    },{
      assesmentImg: Assesment14,
      h5: 'Simon Task (CAT-ST)',
      p: 'Identify how much interference is produced when spatial information (word position) conflicts with semantic information (word meaning).',
      learnBtn: '',
      startBtn: '',
    },{
      assesmentImg: Assesment15,
      h5: 'Tower of Hanoi (CAT-TOH)',
      p: 'Identify how much interference is produced in the user when spatial information (word position) conflicts with semantic information',
      learnBtn: '',
      startBtn: '',
    },{
      assesmentImg: Assesment16,
      h5: 'Trail Making Test (CAT-TMT)',
      p: 'Cognitive abilities mainly related to executive functions such as planning, visual imagery, abstract thinking, working memory',
      learnBtn: '',
      startBtn: '',
    },{
      assesmentImg: Assesment17,
      h5: 'Visual Episodic Memory Test (CAT-VEM)',
      p: 'Process speed, cognitive flexibility, the ability to make an effective visual scan, as well as other underlying executive functions.',
      learnBtn: '',
      startBtn: '',
    }
  ]

  const firstHalf = cogniFitAssesments.slice(0, 9); // İlk 10 öğeyi alır
  const secondHalf = cogniFitAssesments.slice(9); // 11. öğeden sonuncu öğeye kadar olanları alır


  // const firstHalf = cogniFitAssesments.slice(0, cogniFitAssesments.length / 2);
  // const secondHalf = cogniFitAssesments.slice(cogniFitAssesments.length / 2);

  return (
    <header id='headerDiagnostic' className='d-flex flex-column'>
      <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
        <h1>Diagnostic</h1>
        <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
      </article>
      <div className="breakline-diagnostic"></div>

      <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
        <div className="headerDiagnostic-options d-flex flex-column align-items-start">
          <button className={showDiagnostic1 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showWhatItIs}>What it is</button>
          <button className={showDiagnostic2 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showHowItWorks}>How it works</button>
          <button className={showDiagnostic3 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showConditions}>Conditions</button>
          <button className={showDiagnostic4 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showBenefits}>Benefits</button>
        </div>
        {showDiagnostic1 && 
          <div className="headerDiagnostic-content d-flex flex-row align-items-start">
            <div className="articleLeft d-flex flex-column align-items-start">
              <h1>AI-based QEEG scans</h1>
              <p>We use non-invasive AI-based technology to assess brain function and identify any areas of concern. This allows us to provide a detailed analysis of brain activity and develop personalized treatment plans that are tailored to each individual's unique needs.</p>
            </div>
            <div className="articleRight d-flex flex-column align-items-start">
              <h1>Online Tests</h1>
              <p>We use a variety of neuropsychological tests to evaluate cognitive function and identify any cognitive deficits. This allows us to develop treatment plans that focus on improving cognitive function and overall brain health.</p>
              <p>We provide comprehensive psychological evaluations to assess emotional and behavioral health. This helps us to identify any underlying issues that may be impacting overall well-being, and develop personalized treatment plans to address those issues.</p>
              {/* Button trigger modal */}
              <button type="button" className="cognifitTests btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">Book a Test</button>
              
              {/* Modal */}
              <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">Book an Online Tests</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                      <div className="modal-body-content">  
                        <div className="assesment-container d-flex flex-column align-items-start">
                          {firstHalf.map((assesment, index) => (
                            <div className="assesment" key={index}>
                              <img className='assesmentImg' src={assesment.assesmentImg} alt='' />
                              <div className='assesmentArticle'>
                                <h5>{assesment.h5}</h5>
                                <p>{assesment.p}</p>
                              </div>
                              <div className="assesment-btnContainer">
                                <a className='learnMore-btn' href={assesment.learnBtn}>Learn More</a>
                                <a className='start-btn' href={assesment.startBtn}>Start</a>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="assesment-container d-flex flex-column align-items-start">
                          {secondHalf.map((assesment, index) => (
                            <div className="assesment" key={index}>
                              <img className='assesmentImg' src={assesment.assesmentImg} alt='' />
                              <div className='assesmentArticle'>
                                <h5>{assesment.h5}</h5>
                                <p>{assesment.p}</p>
                              </div>
                              <div className="assesment-btnContainer">
                                <a className='learnMore-btn' href={assesment.learnBtn}>Learn More</a>
                                <a className='start-btn' href={assesment.startBtn}>Start</a>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        }{showDiagnostic2 && 
          <div className="headerDiagnostic-content">
            <div className="diagnosticArticle2 d-flex flex-column align-items-start">
              <h1>How it works</h1>
              <div className="howItWorksOL">
                <ol className='howItWorksOL-twoColumn'>
                  <li>Face to face or online initial consultation</li>
                  <br />
                  <li>QEEG ,HRV and MCI Scans and Standard initial assessments</li>
                  <br />
                  <li>QEEG,HRV and MCI report and consultation</li>
                  <br />
                  <li>AI based QEEG guided protocols preparations</li>
                  <br />
                  <li>Treatments</li>
                  <br />
                  <li>Post scan and Post assessments</li>
                  <br />
                  <li>Post report with consultation</li>
                </ol>
              </div>
            </div>
          </div>
        }{showDiagnostic3 &&
          <div className="headerDiagnostic-content">
            <div className="diagnosticArticle3 d-flex flex-column align-items-start">
              <h1>Conditions</h1>
              <div className="conditionsUL d-flex flex-row">
                <ul>
                  <li>Depression</li>
                  <li>Addiction</li>
                  <li>Post traumatic stress disorders</li>
                  <li>OCD</li>
                  <li>Autism</li>
                  <li>ADHD</li>
                  <li>Learning difficulties</li>
                </ul>
                <ul>
                  <li>ALS</li>
                  <li>Anxiety</li>
                  <li>Borderline personality disorders</li>
                  <li>Alzheimer</li>
                  <li>Dementia</li>
                  <li>Long Covid</li>
                </ul>
              </div>
            </div>
          </div>
        }{showDiagnostic4 &&
          <div className="headerDiagnostic-content">
            <div className="diagnosticArticle4 d-flex flex-column align-items-start">
              <h1>Benefits</h1>
              <p>There are many benefits to receiving diagnostic services at DeepSynaps, including:</p>
              <p><b>Accurate diagnosis:</b> Our diagnostic services provide an accurate and comprehensive analysis of brain function, which can help identify underlying issues and provide a clear diagnosis.</p>
              <p><b>Personalized treatment plans:</b> With the information we gather through our diagnostic services, we are able to develop personalized treatment plans that are tailored to each individual's unique needs and goals.</p>
              <p><b>Improved outcomes:</b> By identifying and addressing underlying issues, we are able to improve overall brain health and well-being, leading to improved outcomes for our clients.</p>
              <p><b>Non-invasive technology:</b> Our AI-based QEEG scans are non-invasive, which means they are a safe and comfortable way to assess brain function.</p>
            </div>
          </div>
        }
      </div>
    </header>
  )
}

export default HeaderDiagnostic