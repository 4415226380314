import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';
import { Link } from 'react-router-dom';

const DearyLiewaldTest = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                        <h1></h1>
                        <div className={"row w-100"}>
                            <div className={"col-md-8 col-sm-12"}><h1>Deary-Liewald Test (CAT-DL)</h1></div>
                            <div className={"col-md-4 col-sm-12 text-md-end text-sm-left pt-1"}>
                                <Link target={"_blank"} className={"btn btn-outline-dark"} to={'https://www.cognifit.com/gb/battery-of-tests/deary-liewald-task'}>Start test now</Link>
                            </div>
                        </div>
                        <p>Engage in a fluid intelligence evaluation through mental speed and accuracy in responding to varied stimuli.</p>
                        <p><strong>Relation to QEEG, Neurofeedback, Biofeedback, and Neuromodulation:</strong></p>
                        <ul>
                            <li><strong>Brain Region:</strong> Prefrontal Cortex, Parietal Lobes</li>
                            <li><strong>Broadman Area:</strong> 9, 10 (Prefrontal Cortex); 7, 40 (Parietal Lobes)</li>
                            <li><strong>EEG Electrode Places:</strong> Fz, Pz (Standard 10-20 system places, focusing on midline frontal and parietal areas)</li>
                            <li>
                                <strong>Neurofeedback:</strong>
                                <ul>
                                    <li><strong>Protocol:</strong> Targeting beta wave enhancement in prefrontal areas and potentially promoting alpha coherence.</li>
                                    <li><strong>Objective:</strong> To enhance mental speed, cognitive flexibility, and fluid intelligence.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Biofeedback:</strong>
                                <ul>
                                    <li><strong>Assessment:</strong> Monitoring GSR and HRV to assess physiological responses (stress, arousal) during cognitive processing and response selection.</li>
                                    <li><strong>Protocol:</strong> Utilizing GSR and HRV biofeedback to manage physiological stress responses, enhancing calm and focus during intelligence testing.</li>
                                    <li><strong>Objective:</strong> To ensure optimal physiological states for peak cognitive performance and accurate response selection.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Neuromodulation:</strong>
                                <ul>
                                    <li>
                                        <strong>tDCS:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Dorsolateral Prefrontal Cortex (DLPFC)</li>
                                            <li><strong>Protocol:</strong> Anodal stimulation to DLPFC to facilitate improved cognitive flexibility and processing speed.</li>
                                            <li><strong>Objective:</strong> To bolster cognitive processing, facilitating improved performance on fluid intelligence tasks.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>PBM:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Prefrontal Cortex</li>
                                            <li><strong>Protocol:</strong> Leveraging photobiomodulation to enhance neuronal metabolism and blood flow in the prefrontal area.</li>
                                            <li><strong>Objective:</strong> To support neural functionality in areas pertinent to fluid intelligence, aiding in task performance.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Mental Health Assessments and Their Relation:</strong>
                                <ul>
                                    <li><strong>SISRI-24:</strong> Relating self-insight regarding stress and resilience (via the SISRI-24) to cognitive performance may offer insights into how self-perceived stress resistance relates to fluid intelligence capabilities.</li>
                                    <li><strong>Sleep Scale:</strong> The quality and quantity of sleep, as assessed via sleep scales, directly correlate with cognitive functionality, with impacts on mental speed and accuracy in tasks requiring fluid intelligence.</li>
                                    <li><strong>Friendship Scale:</strong> Social support and networks, as assessed through the Friendship Scale, potentially influence cognitive performance by affecting emotional and psychological wellbeing, indirectly influencing fluid intelligence.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>Implementing the Deary Liewald Test alongside targeted neuromodulation, neurofeedback, and biofeedback can facilitate a deeper understanding and tailored enhancement of fluid intelligence, ensuring the individual's cognitive capabilities are optimized and supported amidst various mental and physiological states. This holistic approach intertwines cognitive performance and mental wellbeing, providing a thorough, multidimensional method for cognitive and psychological enhancement.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default DearyLiewaldTest