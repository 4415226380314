import { React, useState } from 'react'
import './cooperateHeader.css'
import {Link} from "react-router-dom";

const CorporateSportsPerformanceEnhancementHeader = () => {

    return (
      <header id='headerDiagnostic' className='d-flex flex-column'>
        <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
          <h1>Cooperate</h1>
          <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and cognitive abilities, identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
          <a className='navbar-Respbtn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
        </article>
        <div className="breakline-diagnostic"></div>
  
        <div className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
          <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/L38T6fnrVjc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>

        <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                <h1>DeepSynaps Sports Performance Enhancement Package:</h1>
                  <ol>
                      <li>
                          <p><b>Athlete Assessments:</b></p>
                          <ul>
                              <li>
                                  <p><b>Cognitive Assessments for Athletes:</b></p>
                                  <p>Comprehensive assessments to measure athletes' cognitive abilities, memory, attention, and problem-solving skills, with a focus on enhancing mental resilience and performance.</p>
                              </li>
                              <li>
                                  <p><b>Physical Assessments:</b></p>
                                  <p>Evaluation of athletes' physical health and fitness levels, including EMG, ECG/Pulse, Respiratory, Temperature, Skin condition, SCP, and EOG scans.</p>
                              </li>
                          </ul>
                      </li>

                      <li>
                          <p><b>Personalized Performance Enhancement Plans:</b></p>
                          <p>Individualized performance enhancement plans for each athlete based on their assessment results, incorporating the following services.</p>
                      </li>

                      <li>
                          <p><b>Neurofeedback and Cognitive Training:</b></p>
                          <p>- Personalized neurofeedback training sessions to improve focus, concentration, and cognitive performance among athletes.</p>
                          <p>- Cognitive training exercises designed to enhance decision-making, strategic thinking, and problem-solving abilities on and off the field.</p>
                      </li>

                      <li>
                          <p><b>Neuromodulation (TDCS-PBM):</b></p>
                          <p>Transcranial Direct Current Stimulation (TDCS) and Photobiomodulation (PBM) sessions to optimize cognitive and physical recovery, mood stabilization, and performance enhancement.</p>
                      </li>

                      <li>
                          <p><b>Cognitive Resilience Training:</b></p>
                          <p>Training sessions to enhance mental resilience, stress management, and emotional control, providing athletes with tools to thrive under pressure.</p>
                      </li>

                      <li>
                          <p><b>Physical Performance Enhancement:</b></p>
                          <p>Customized physical training programs tailored to athletes' specific sport and performance goals, including strength and conditioning, agility, and injury prevention.</p>
                      </li>

                      <li>
                          <p><b>Progress Tracking and Reporting:</b></p>
                          <p>- Regular assessments and performance tracking to monitor athletes' cognitive and physical progress.</p>
                          <p>- Detailed reports summarizing assessment results, performance improvements, and recommendations for adjustments to training plans.</p>
                      </li>

                      <li>
                          <p><b>Equipment and Software:</b></p>
                          <p>DeepSynaps will provide diagnostic equipment, software licenses, and technical support for implementing cognitive and physical performance enhancement programs within the sports performance center.</p>
                      </li>

                      <li>
                          <p><b>Coach and Trainer Collaboration:</b></p>
                          <p>Collaboration with coaches and trainers to integrate cognitive assessments and enhancement techniques into athletes' training plans and strategies.</p>
                      </li>

                      <li>
                          <p><b>Confidentiality and Data Security:</b></p>
                          <p>Ensuring the highest level of data security and confidentiality for athlete information, prioritizing athlete privacy.</p>
                      </li>
                  </ol>
                  <p>This package is designed to help athletes at sports performance centers optimize their cognitive and physical performance. By offering comprehensive assessments, personalized performance enhancement plans, and targeted support, it aims to maximize athletes' competitive advantage, enhance their decision-making abilities, and contribute to peak performance on the field or in competition. DeepSynaps will collaborate closely with sports performance centers to tailor the package to meet the specific needs and goals of their athletes, ultimately leading to improved athletic performance and success.</p>
              </div>
            </div>
        </div>
      </header>
    )
}

export default CorporateSportsPerformanceEnhancementHeader