import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';
import { Link } from 'react-router-dom';


const SustainedAttentionToResponseTest = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                        <div className={"row w-100"}>
                            <div className={"col-md-8 col-sm-12"}><h1>Sustained Attention to Response Test (CAT-STA)</h1></div>
                            <div className={"col-md-4 col-sm-12 text-md-end text-sm-left pt-1"}>
                                <Link target={"_blank"} className={"btn btn-outline-dark"} to={'https://www.cognifit.com/gb/battery-of-tests/sustained-attention-to-response-test'}>Start test now</Link>
                            </div>
                        </div>

                        <p>Assess your capacity to maintain focused attention and inhibit responses over extended periods.</p>
                        <p><strong>Relation to QEEG, Neurofeedback, Biofeedback, and Neuromodulation:</strong></p>
                        <ul>
                            <li><strong>Brain Region:</strong> Prefrontal Cortex, Anterior Cingulate Cortex</li>
                            <li><strong>Broadman Area:</strong> BA 10, BA 24/32</li>
                            <li><strong>EEG Electrode Places:</strong> Fz, Cz</li>
                            <li>
                                <strong>Neurofeedback:</strong>
                                <ul>
                                    <li><strong>Protocol:</strong> Focused on modulating theta/beta ratios and SMR (sensorimotor rhythm) activity in the frontal and central regions.</li>
                                    <li><strong>Objective:</strong> Enhance sustained attention and response inhibition by optimizing neural oscillations associated with focus and impulse control.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Biofeedback:</strong>
                                <ul>
                                    <li><strong>Assessment:</strong> HRV (Heart Rate Variability) for understanding systemic arousal and maintaining optimal physiological state conducive to sustained attention; GSR (Galvanic Skin Response) to gauge stress and cognitive load.</li>
                                    <li><strong>Protocol:</strong> Implementing HRV and GSR training to manage physiological arousal and stress, which can impact sustained attention and response inhibition capabilities.</li>
                                    <li><strong>Objective:</strong> Ensuring physiological factors do not hinder the cognitive process of maintaining attention and inhibiting pre-potent responses.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Neuromodulation:</strong>
                                <ul>
                                    <li>
                                        <strong>tDCS:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Dorsolateral Prefrontal Cortex (DLPFC), Anterior Cingulate Cortex</li>
                                            <li><strong>Protocol:</strong> Anodal stimulation to DLPFC and Anterior Cingulate Cortex to enhance neuronal excitability and promote neural circuits integral to sustained attention and response inhibition.</li>
                                            <li><strong>Objective:</strong> Enhancing the neurobiological substrates essential for maintaining focus and managing response inhibition over prolonged periods.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>PBM:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Prefrontal and Anterior Cingulate Regions</li>
                                            <li><strong>Protocol:</strong> Using photobiomodulation to support neuronal metabolism and facilitate functional connectivity within networks subserving sustained attention and inhibitory control.</li>
                                            <li><strong>Objective:</strong> Sustaining optimal neural function and health in regions critical for sustained attention and response inhibition.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Mental Health Assessments and Their Relation:</strong>
                                <ul>
                                    <li><strong>SISRI-24 (Short Imaginal Processes Inventory):</strong> Insights into the utilization and reliance on imaginal processes could indicate strategies used by individuals to maintain focus and inhibit responses, providing a contextual understanding of sustained attention capacities.</li>
                                    <li><strong>Sleep Scale:</strong> Sleep quality and duration are vital for optimal cognitive function. Understanding sleep patterns provides an essential backdrop against which sustained attention capacities can be understood and improved.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>Sustained attention and response inhibition are pivotal for numerous daily activities and cognitive tasks. Enhancing these capacities involves a coordinated approach that not only targets the inherent cognitive processes but also ensures the physical and emotional wellbeing of the individual, thus providing a comprehensive and sustainable strategy for cognitive enhancement and wellness.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default SustainedAttentionToResponseTest