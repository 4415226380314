import React from 'react'

const Whoops404 = () => {
  return (
    <>
      <h1>Whoops404</h1> 
    </>
  )
}

export default Whoops404