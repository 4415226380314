import React, { useState } from 'react'
import './headerTreatments.css'

const HeaderNeuromodulation = () => {
  const [showDiagnostic1, setShowDiagnostic1] = useState(true)
  const [showDiagnostic2, setShowDiagnostic2] = useState(false)
  const [showDiagnostic3, setShowDiagnostic3] = useState(false)
  const [showDiagnostic4, setShowDiagnostic4] = useState(false)
  const [showDiagnostic5, setShowDiagnostic5] = useState(false)
  const [showDiagnostic6, setShowDiagnostic6] = useState(false)
  const [showDiagnostic7, setShowDiagnostic7] = useState(false)
  const [showDiagnostic8, setShowDiagnostic8] = useState(false)
  const [showDiagnostic9, setShowDiagnostic9] = useState(false)

  const showSection1 = () => { // Showing 1st element
    setShowDiagnostic1(true)
    setShowDiagnostic2(false)
    setShowDiagnostic3(false)
    setShowDiagnostic4(false)
    setShowDiagnostic5(false)
    setShowDiagnostic6(false)
    setShowDiagnostic7(false)
    setShowDiagnostic8(false)
    setShowDiagnostic9(false)
  }

  const showSection2 = () => { // Showing 2nd element
    setShowDiagnostic1(false)
    setShowDiagnostic2(true)
    setShowDiagnostic3(false)
    setShowDiagnostic4(false)
    setShowDiagnostic5(false)
    setShowDiagnostic6(false)
    setShowDiagnostic7(false)
    setShowDiagnostic8(false)
    setShowDiagnostic9(false)
  }

  const showSection3 = () => { // Showing 3rd element
    setShowDiagnostic1(false)
    setShowDiagnostic2(false)
    setShowDiagnostic3(true)
    setShowDiagnostic4(false)
    setShowDiagnostic5(false)
    setShowDiagnostic6(false)
    setShowDiagnostic7(false)
    setShowDiagnostic8(false)
    setShowDiagnostic9(false)
  }

  const showSection4 = () => { // Showing 4th element
    setShowDiagnostic1(false)
    setShowDiagnostic2(false)
    setShowDiagnostic3(false)
    setShowDiagnostic4(true)
    setShowDiagnostic5(false)
    setShowDiagnostic6(false)
    setShowDiagnostic7(false)
    setShowDiagnostic8(false)
    setShowDiagnostic9(false)
  }

  const showSection5 = () => { // Showing 4th element
    setShowDiagnostic1(false)
    setShowDiagnostic2(false)
    setShowDiagnostic3(false)
    setShowDiagnostic4(false)
    setShowDiagnostic5(true)
    setShowDiagnostic6(false)
    setShowDiagnostic7(false)
    setShowDiagnostic8(false)
    setShowDiagnostic9(false)
  }


  const showSection6 = () => { // Showing 4th element
    setShowDiagnostic1(false)
    setShowDiagnostic2(false)
    setShowDiagnostic3(false)
    setShowDiagnostic4(false)
    setShowDiagnostic5(false)
    setShowDiagnostic6(true)
    setShowDiagnostic7(false)
    setShowDiagnostic8(false)
    setShowDiagnostic9(false)
  }

  const showSection7 = () => { // Showing 4th element
    setShowDiagnostic1(false)
    setShowDiagnostic2(false)
    setShowDiagnostic3(false)
    setShowDiagnostic4(false)
    setShowDiagnostic5(false)
    setShowDiagnostic6(false)
    setShowDiagnostic7(true)
    setShowDiagnostic8(false)
    setShowDiagnostic9(false)
  }

  const showSection8 = () => { // Showing 4th element
    setShowDiagnostic1(false)
    setShowDiagnostic2(false)
    setShowDiagnostic3(false)
    setShowDiagnostic4(false)
    setShowDiagnostic5(false)
    setShowDiagnostic6(false)
    setShowDiagnostic7(false)
    setShowDiagnostic8(true)
    setShowDiagnostic9(false)
  }

  const showSection9 = () => { // Showing 4th element
    setShowDiagnostic1(false)
    setShowDiagnostic2(false)
    setShowDiagnostic3(false)
    setShowDiagnostic4(false)
    setShowDiagnostic5(false)
    setShowDiagnostic6(false)
    setShowDiagnostic7(false)
    setShowDiagnostic8(false)
    setShowDiagnostic9(true)
  }

  return (
    <>
      <header id='headerDiagnostic' className='d-flex flex-column'>
        <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
          <h1>Interventions</h1>
          <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
          <a className='navbar-Respbtn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
        </article>
        <div className="breakline-diagnostic"></div>


        <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
          <div className="headerDiagnostic-options d-flex flex-column align-items-start">
            <button className={showDiagnostic1 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection1}>What is Neuromodulation?</button>
            <button className={showDiagnostic2 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection2}>Our AI-Based Neuromodulation Services</button>
            <button className={showDiagnostic3 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection3}>Who Can Benefit from Neuromodulation?</button>
            <button className={showDiagnostic4 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection4}>What to Expect During Neuromodulation Sessions?</button>
            <button className={showDiagnostic5 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection5}>AI-Driven qEEG Brain Mapping</button>
            <button className={showDiagnostic6 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection6}>Personalized Treatment Planning</button>
            <button className={showDiagnostic7 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection7}>Home-Based Therapies</button>
            <button className={showDiagnostic8 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection8}>After the Session</button>
            <button className={showDiagnostic9 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection9}>Why Choose Us for Neuromodulation?</button>
          </div>
          {showDiagnostic1 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle3 d-flex flex-column align-items-start">
                <h1>What is Neuromodulation?</h1>
                <p>Neuromodulation is a technology-driven approach that involves directly stimulating the nervous system with electrical or pharmaceutical agents to alter its activity. The aim is to modulate neural function to achieve desired therapeutic effects, often used in addressing a range of neurological and psychological conditions.</p>
              </div>
            </div>
          }{showDiagnostic2 &&
            <div className="headerDiagnostic-content">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>Our AI-Based Neuromodulation Services</h1>
                <div className="howItWorksOL">
                  <p><b>1. AI-Driven qEEG Brain Scan and Mapping:</b> Leveraging advanced AI algorithms, our qEEG scans offer an in-depth and precise assessment of brain activities. AI aids in identifying unique patterns and anomalies associated with various neurological and psychological conditions.</p>
                  <p><b>2. Personalized AI-Based intervention protocols:</b> Our advanced systems use AI to tailor neuromodulation treatments specifically to an individual's needs, optimizing outcomes and ensuring maximum therapeutic benefits.</p>
                  <p><b>3. tDCS (Transcranial Direct Current Stimulation):</b> A home-based treatment where a mild electric current is applied to the brain to enhance cognitive function and alleviate symptoms in certain conditions.</p>
                  <p><b>4. PBM (Photobiomodulation):</b> Also offered as a home-based service, PBM involves the use of red or near-infrared light to stimulate the brain.</p>
                </div>
              </div>
            </div>
          }{showDiagnostic3 &&
            <div className="headerDiagnostic-content">
              <div className="diagnosticArticle3 d-flex flex-column align-items-start">
                <h1>Who Can Benefit from Neuromodulation?</h1>
                <div className="conditionsUL d-flex flex-row">
                  <div className="workingAreas">
                    <p><b>Individuals with different mental issues:</b> TMS, in particular, has been effective for treatment-resistant depression, Anxiety, OCD,PTSD etc.</p>
                    <p><b>Chronic Pain Sufferers:</b> Neuromodulation can provide relief from certain types of chronic pain.</p>
                    <p><b>Those with Cognitive Impairments:</b> Techniques like tDCS have shown promise in enhancing cognitive functions.</p>
                    <p><b>Brain Injury Recovery:</b> Neuromodulation can support the rehabilitation process post brain injuries.</p>
                    <p><b>Performance Enhancement:</b> For those looking to boost cognitive abilities or athletic performance.</p>
                  </div>
                </div>
              </div>
            </div>
          }{showDiagnostic4 &&
            <div className="headerDiagnostic-content">
              <div className="diagnosticArticle4 d-flex flex-column align-items-start">
                <h1>What to Expect During Neuromodulation Sessions?</h1>
                <p><b>Initial Assessment</b><br />Our journey begins with an in-depth understanding of your neurological profile, medical history, and specific needs or symptoms.</p>
                <p><b>Mental Health Assessments</b><br />Our journey begins with an in-depth understanding of your neurological profile, medical history, and specific needs or symptoms.</p>
                <p><b>Body Scan (Physiological stress test)</b></p>
                <p><b>1. EMG (Electromyography):</b> Our EMG scans detect and analyze the electrical activity of muscles. It's invaluable for those aiming to control muscle tension, especially beneficial for ailments like muscle spasms and certain headache types.</p>
                <p><b>2. ECG/Pulse (Electrocardiography):</b> We offer comprehensive cardiac scans measuring the heart's electrical activity. By understanding heart rate and rhythms, individuals can be better equipped to modify their responses to stress or improve specific cardiac conditions.</p>
                <p><b>3. Respiratory Scan:</b> Our services provide insights into your breathing rate and pattern. Such knowledge can empower individuals with techniques to relax, manage anxiety, or handle specific medical challenges.</p>
                <p><b>4. Temperature Scan:</b> Monitoring skin temperature aids in increasing blood flow to the extremities. This is especially crucial for those battling conditions like Raynaud's disease or seeking general relaxation techniques.</p>
                <p><b>5. Skin Conduction (Galvanic Skin Response or GSR):</b> Our scans assess the skin's electrical conductance levels, which can shift with moisture levels. It's a trusted indicator of psychological or physiological arousal.</p>
                <p><b>6. SCP (Slow Cortical Potential) Scan:</b> We capture slow-moving electrical potentials in the brain's cortex. SCP biofeedback training has shown promising results for conditions such as ADHD.</p>
                <p><b>7. EOG (Electrooculography) Scan:</b> Our EOG service measures the potential difference between the cornea and retina. It's a pivotal tool for detecting eye movements, playing a key role in sleep studies and specific neurological assessments.</p>
              </div>
            </div>
          }
          {showDiagnostic5 &&
              <div className="headerDiagnostic-content">
                <div className="diagnosticArticle4 d-flex flex-column align-items-start">
                  <h1>AI-Driven qEEG Brain Mapping</h1>
                  <p>Using the power of AI, we capture a detailed map of your brain's activity, providing an unparalleled foundation for tailored interventions.</p>
                </div>
              </div>
          }
          {showDiagnostic6 &&
              <div className="headerDiagnostic-content">
                <div className="diagnosticArticle4 d-flex flex-column align-items-start">
                  <h1>Personalized Treatment Planning</h1>
                  <p>Based on AI insights from the qEEG mapping and Body scan we create a customized neuromodulation treatment plan with biofeedback plan which will cover TMS at a nearby clinic or at-home sessions with tDCS or PBM. Every 20 sessions the client will have a QEEG brain scan and biofeedback body scan.</p>
                </div>
              </div>
          }
          {showDiagnostic7 &&
              <div className="headerDiagnostic-content">
                <div className="diagnosticArticle4 d-flex flex-column align-items-start">
                  <h1>Home-Based Therapies</h1>
                  <p>For Neuromodulation according to your scan you may use  tDCS or PBM at home with  a biofeedback tool (Polar H10 ) which will allow clinician to monitor your body during the treatments. We provide you with comprehensive guidelines, equipment, and support for safe and effective treatment in the comfort of your home.</p>
                </div>
              </div>
          }
          {showDiagnostic8 &&
              <div className="headerDiagnostic-content">
                <div className="diagnosticArticle4 d-flex flex-column align-items-start">
                  <h1>After the Session</h1>
                  <p>Our dedicated team reviews the data and progress, refining your treatment plan as needed. We stay committed to ensuring you achieve the desired therapeutic outcomes.</p>
                </div>
              </div>
          }
          {showDiagnostic9 &&
              <div className="headerDiagnostic-content">
                <div className="diagnosticArticle4 d-flex flex-column align-items-start">
                  <h1>Why Choose Us for Neuromodulation?</h1>
                  <p>Harnessing the latest in AI technology and neuromodulation techniques, we stand at the forefront of neurological interventions. Our comprehensive and personalized approach ensures that you receive the best care tailored to your unique neural profile.</p>
                  <p>For more details or to discover how neuromodulation can usher in a new chapter in your health journey, please reach out to our expert team. Join us in harnessing the potential of the brain through advanced technological interventions!</p>
                </div>
              </div>
          }
        </div>
      </header>
    </>
  )
}

export default HeaderNeuromodulation