import React from 'react'
import './partnersLogo.css'
import { CogniFit, Flow, ImediSync, Oxford, PlatoScience } from '../../../assets/images'

const PartnersLogo = () => {
  return (
    <>
        <div id="partnersLogo" className="wrapper d-flex flex-column align-items-center justify-content-center">
            <div className="PartnersContent d-flex flex-row align-items-center justify-content-between">
                <img src={PlatoScience} alt="PlatoScience-Medical" />
                <img src={Flow} alt="Flow-Neuroscience" />
                <img src={ImediSync} alt="IMediSync" />
                <img src={CogniFit} alt="CogniFit" />
            </div>
        </div>
    </>
  )
}

export default PartnersLogo