import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';


const ConcentrationCognitiveAssessmentCabAt = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                        <h1>Concentration Cognitive Assessment (CAB-AT)</h1>
                        <p>The Concentration Cognitive Assessment (CAB-AT) studies brain function, performs a complete cognitive screening and assesses the cognitive processes that influence concentration. This assessment takes 15-20 minutes to completeThis assessment is suitable for children over 7 years of age, youth, adults, or seniors. The assessment can be used cross-culturally, and for both men and women.</p>
                        <p>Concentration Cognitive Assessment (CAB-AT) and its Relation to Quantitative Electroencephalography (QEEG), Brain Parts, Biofeedback, Neurofeedback, Transcranial Direct Current Stimulation (tDCS), and Photobiomodulation (PBM):</p>
                        <p><strong>Relation to the QEEG,Neurofeedback,Biofeedback and Neuromodulation</strong></p>
                        <p>The Concentration Cognitive Assessment (CAB-AT) serves as a potent tool to explore the facets of brain function that pivot around concentration. Tailored for a broad demographic spanning from children above 7 to seniors, the CAB-AT offers a comprehensive cognitive screening irrespective of gender or cultural background.</p>
                        <ul>
                            <li>
                                <strong>Concentration and Attention:</strong>
                                <ul>
                                    <li><strong>Brain Regions and QEEG:</strong> The prefrontal cortex plays a pivotal role in concentration and maintaining focus. QEEG can capture shifts in brainwave activities linked with concentration variations.</li>
                                    <li><strong>Biofeedback:</strong> Monitoring patterns like Heart Rate Variability (HRV) via Electrocardiography (ECG) can offer insights into physiological responses tied to attention and concentration.</li>
                                    <li><strong>Neurofeedback:</strong> Enhancing the activity of the Sensory Motor Rhythm (SMR) can significantly augment attention and concentration.</li>
                                    <li><strong>tDCS:</strong> Targeting the prefrontal cortex can modulate concentration capacities, potentially bolstering attentional control.</li>
                                    <li><strong>PBM:</strong> Photobiomodulation directed at the prefrontal region may potentially amplify neural activity and aid in concentration.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Working Memory:</strong>
                                <ul>
                                    <li><strong>Brain Regions and QEEG:</strong> Areas like the dorsolateral prefrontal cortex and parietal cortex are instrumental for working memory which supports concentration. QEEG might detect distinct patterns that correlate with working memory capacities.</li>
                                    <li><strong>Biofeedback:</strong> Electroencephalography (EEG) monitoring during memory tasks can help track and enhance cognitive performance.</li>
                                    <li><strong>Neurofeedback:</strong> Beta wave training can support information processing and memory retention.</li>
                                    <li><strong>tDCS:</strong> Stimulating regions such as the dorsolateral prefrontal cortex can offer enhancements in working memory.</li>
                                    <li><strong>PBM:</strong> Focusing light therapy on regions linked with working memory can invigorate neuronal activity, potentially aiding in tasks that require sustained attention and memory recall.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Cognitive Processing Speed:</strong>
                                <ul>
                                    <li><strong>Brain Regions and QEEG:</strong> Regions like the basal ganglia and the prefrontal cortex are linked with processing speed. QEEG can reveal the brainwave patterns that might correspond to the speed of cognitive processing.</li>
                                    <li><strong>Biofeedback:</strong> Skin Conductance Response (SCR) can give insights into physiological arousal during tasks that require swift cognitive responses.</li>
                                    <li><strong>Neurofeedback:</strong> Training on both Beta and Gamma frequencies can potentially boost processing speeds.</li>
                                    <li><strong>tDCS:</strong> By modulating activity in regions like the basal ganglia, cognitive processing speeds might be influenced.</li>
                                    <li><strong>PBM:</strong> Using light therapy on key brain regions can stimulate neuronal activity, potentially leading to swifter cognitive processing.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>Incorporating the insights from the CAB-AT with these state-of-the-art neurological and physiological techniques, healthcare professionals are equipped with a multifaceted understanding of an individual's concentration capabilities. This comprehensive analysis paves the way for well-informed interventions to enhance and support optimal cognitive performance across ages.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default ConcentrationCognitiveAssessmentCabAt