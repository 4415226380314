import { React, useState } from 'react'
import './brainScanHeader.css'

const BrainScanHeader = () => {

    const [showDiagnostic1, setShowDiagnostic1] = useState(false)
    const [showDiagnostic2, setShowDiagnostic2] = useState(false)
    const [showDiagnostic3, setShowDiagnostic3] = useState(false)
    const [showDiagnostic4, setShowDiagnostic4] = useState(false)
    const [showDiagnostic5, setShowDiagnostic5] = useState(true)
    const [showDiagnostic6, setShowDiagnostic6] = useState(false)
    const [showDiagnostic7, setShowDiagnostic7] = useState(false)
    const [showDiagnostic8, setShowDiagnostic8] = useState(false)
    const [showDiagnostic9, setShowDiagnostic9] = useState(false)
    const [showDiagnostic10, setShowDiagnostic10] = useState(false)
  
    const showWhatItIs = () => { // Showing 1st element
      setShowDiagnostic1(true)
      setShowDiagnostic2(false)
      setShowDiagnostic3(false)
      setShowDiagnostic4(false)
      setShowDiagnostic5(false)
      setShowDiagnostic6(false)
      setShowDiagnostic7(false)
      setShowDiagnostic8(false)
      setShowDiagnostic9(false)
      setShowDiagnostic10(false)
    }
  
    const showHowItWorks = () => { // Showing 2nd element
      setShowDiagnostic1(false)
      setShowDiagnostic2(true)
      setShowDiagnostic3(false)
      setShowDiagnostic4(false)
      setShowDiagnostic5(false)
      setShowDiagnostic6(false)
      setShowDiagnostic7(false)
      setShowDiagnostic8(false)
      setShowDiagnostic9(false)
      setShowDiagnostic10(false)
    }
  
    const showConditions = () => { // Showing 3rd element
      setShowDiagnostic1(false)
      setShowDiagnostic2(false)
      setShowDiagnostic3(true)
      setShowDiagnostic4(false)
      setShowDiagnostic5(false)
      setShowDiagnostic6(false)
      setShowDiagnostic7(false)
      setShowDiagnostic8(false)
      setShowDiagnostic9(false)
      setShowDiagnostic10(false)
    }
  
    const showBenefits = () => { // Showing 4th element
      setShowDiagnostic1(false)
      setShowDiagnostic2(false)
      setShowDiagnostic3(false)
      setShowDiagnostic4(true)
      setShowDiagnostic5(false)
      setShowDiagnostic6(false)
      setShowDiagnostic7(false)
      setShowDiagnostic8(false)
      setShowDiagnostic9(false)
      setShowDiagnostic10(false)
    }

    const showIntroduction = () => { // Showing 4th element
        setShowDiagnostic1(false)
        setShowDiagnostic2(false)
        setShowDiagnostic3(false)
        setShowDiagnostic4(false)
        setShowDiagnostic5(true)
        setShowDiagnostic6(false)
        setShowDiagnostic7(false)
        setShowDiagnostic8(false)
        setShowDiagnostic9(false)
        setShowDiagnostic10(false)
    }

    const showWhatIsQEEG = () => { // Showing 4th element
        setShowDiagnostic1(false)
        setShowDiagnostic2(false)
        setShowDiagnostic3(false)
        setShowDiagnostic4(false)
        setShowDiagnostic5(false)
        setShowDiagnostic6(true)
        setShowDiagnostic7(false)
        setShowDiagnostic8(false)
        setShowDiagnostic9(false)
        setShowDiagnostic10(false)
    }

    const showPreparationSteps = () => { // Showing 4th element
        setShowDiagnostic1(false)
        setShowDiagnostic2(false)
        setShowDiagnostic3(false)
        setShowDiagnostic4(false)
        setShowDiagnostic5(false)
        setShowDiagnostic6(false)
        setShowDiagnostic7(true)
        setShowDiagnostic8(false)
        setShowDiagnostic9(false)
        setShowDiagnostic10(false)
    }

    const showExpectDuringSession = () => { // Showing 4th element
        setShowDiagnostic1(false)
        setShowDiagnostic2(false)
        setShowDiagnostic3(false)
        setShowDiagnostic4(false)
        setShowDiagnostic5(false)
        setShowDiagnostic6(false)
        setShowDiagnostic7(false)
        setShowDiagnostic8(true)
        setShowDiagnostic9(false)
        setShowDiagnostic10(false)
    }

    const showPostSessionInformation = () => { // Showing 4th element
        setShowDiagnostic1(false)
        setShowDiagnostic2(false)
        setShowDiagnostic3(false)
        setShowDiagnostic4(false)
        setShowDiagnostic5(false)
        setShowDiagnostic6(false)
        setShowDiagnostic7(false)
        setShowDiagnostic8(false)
        setShowDiagnostic9(true)
        setShowDiagnostic10(false)
    }

    const showContactInformation = () => { // Showing 4th element
        setShowDiagnostic1(false)
        setShowDiagnostic2(false)
        setShowDiagnostic3(false)
        setShowDiagnostic4(false)
        setShowDiagnostic5(false)
        setShowDiagnostic6(false)
        setShowDiagnostic7(false)
        setShowDiagnostic8(false)
        setShowDiagnostic9(false)
        setShowDiagnostic10(true)
    }
  
    return (
      <header id='headerDiagnostic' className='d-flex flex-column'>
        <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
          <h1>Brain Scan</h1>
          <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and cognitive abilities, identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
          <a className='navbar-Respbtn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
        </article>
        <div className="breakline-diagnostic"></div>
        
        <div className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
          <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/fxev4Nl3kmw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>

        <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
          <div className="headerDiagnostic-options d-flex flex-column align-items-start">
            <button className={showDiagnostic5 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showIntroduction}>Introduction</button>
            <button className={showDiagnostic6 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showWhatIsQEEG}>What is a QEEG Brain Scan?</button>
            <button className={showDiagnostic7 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showPreparationSteps}>Preparation Steps</button>
            <button className={showDiagnostic8 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showExpectDuringSession}>What to Expect During the Session</button>
            <button className={showDiagnostic9 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showPostSessionInformation}>Post-Session Information</button>
            <button className={showDiagnostic10 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showContactInformation}>Contact Information</button>
          </div>
          {showDiagnostic5 &&
            <div className="headerDiagnostic-content">
                <div className="diagnosticArticle4 d-flex flex-column align-items-start">
                    <h1>Introduction</h1>
                    <p>Our dedication is to ensure you undergo a comprehensive and illuminating QEEG Brain Scan experience. To achieve the most accurate results, proper preparation is vital. This guide offers essential insights into preparing for your QEEG Brain Scan appointment.</p>
                </div>
            </div>
          }

            {showDiagnostic6 &&
              <div className="headerDiagnostic-content">
                <div className="diagnosticArticle4 d-flex flex-column align-items-start">
                  <h1>What is a QEEG Brain Scan?</h1>
                  <p>QEEG (Quantitative Electroencephalography) is a diagnostic tool that captures and quantifies the electrical activity in the brain. It offers a detailed visual representation of the dynamic changes that are continuously occurring in the brain.</p>
                 </div>
              </div>
            }

            {showDiagnostic7 &&
                <div className="headerDiagnostic-content">
                    <div className="diagnosticArticle4 d-flex flex-column align-items-start">
                        <h1>Preparation Steps</h1>
                        <ul>
                            <li>
                                <p><b>Hair Cleanliness:</b></p>
                                <p>- Wash your hair the night before or the morning of the test.</p>
                                <p>- Avoid using conditioners, hair sprays, or other hair products on the day of the test.</p>
                            </li>

                            <li>
                                <p><b>Clothing:</b></p>
                                <p>- Wear comfortable clothing without significant metal components.</p>
                                <p>- As with the biofeedback, avoid metallic accessories as they can interfere with readings.</p>
                            </li>

                            <li>
                                <p><b>Dietary Restrictions:</b></p>
                                <p>- Abstain from caffeine-containing products (e.g., coffee, teas, chocolates, energy drinks) for at least 24 hours before the scan.</p>
                                <p>- Eat a balanced meal a few hours before the test. Avoid foods that are excessively sugary or spicy.</p>
                            </li>

                            <li>
                                <p><b>Medications:</b></p>
                                <p>- Continue taking any prescribed medications unless directed otherwise by your doctor.</p>
                                <p>- Inform our team about any medications you are taking, especially those impacting brain activity, such as sedatives or stimulants.</p>
                            </li>

                            <li>
                                <p><b>Rest:</b></p>
                                <p>- A good night's sleep is crucial, as fatigue might alter brain activity patterns.</p>
                            </li>

                            <li>
                                <p><b>Arrival Time:</b></p>
                                <p>- Please ensure you arrive at least 20 minutes prior to your appointment to allow for setup and to address any last-minute concerns.</p>
                            </li>

                            <li>
                                <p><b>Mental State:</b></p>
                                <p>- Come with a relaxed and open mind. Inform our staff if you feel apprehensive or uncertain about any aspect of the procedure.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            }

            {showDiagnostic8 &&
                <div className="headerDiagnostic-content">
                    <div className="diagnosticArticle4 d-flex flex-column align-items-start">
                        <h1>What to Expect During the Session</h1>
                        <p>- The QEEG Brain Scan typically lasts between [specific time, e.g., "30 to 60 minutes"], inclusive of preparation, scanning, and initial feedback.</p>
                        <p>- You will wear a special cap fitted with sensors to capture the brain's electrical activity.</p>
                        <p>- You might be asked to perform specific tasks, close your eyes, or relax during the scan, depending on the assessment's objectives.</p>
                        <p>- The environment will be kept distraction-free to ensure accurate readings.</p>
                    </div>
                </div>
            }

            {showDiagnostic9 &&
                <div className="headerDiagnostic-content">
                    <div className="diagnosticArticle4 d-flex flex-column align-items-start">
                        <h1>Post-Session Information</h1>
                        <p>After the QEEG Brain Scan, our specialists will analyze the data to gain insights into your brain's function. A follow-up session will be scheduled to discuss the findings in detail, providing a comprehensive understanding of your brain activity and any recommendations for further action or interventions.</p>
                    </div>
                </div>
            }

            {showDiagnostic10 &&
                <div className="headerDiagnostic-content">
                    <div className="diagnosticArticle4 d-flex flex-column align-items-start">
                        <h1>Contact Information</h1>
                        <p>For any further queries, clarifications, or information about your upcoming QEEG Brain Scan, please reach out to us at <a href="mailto: info@deepsynaps.com">info@deepsynaps.com</a></p>
                    </div>
                </div>
            }
        </div>
      </header>
    )
}

export default BrainScanHeader