import React from 'react'
import { SEO, Navbar, Footer, BrainScanHeader, BrainPricing, BrainSubs } from '../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../assets/images/logo192.svg';


const BrainScan = () => {
  return (
    <>
      <SEO
        title = 'Brain Scan Services by DeepSynaps - Advanced Neuroimaging for Brain Health'
        description= 'Discover our cutting-edge brain scan services at DeepSynaps. Our QEEG scans provide valuable insights into brain activity and function, helping to optimize cognitive performance, detect cognitive impairments, and improve overall brain health.'
        url = 'https://oxfordmindtech.com/brainscan'
        keywords = 'Brain scan services, DeepSynaps, neuroimaging, QEEG scans, brain health, cognitive performance, cognitive impairments, brain activity'
      />    
      <Navbar />
      <BrainScanHeader/>
      <br /> <br />
      <BrainPricing />
      <BrainSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />
    </>
  )
}

export default BrainScan