import * as yup from "yup";

const bookLocationSchema = yup.object({
    name: yup.string().required('Please enter name.'),
    surname: yup.string().required('Please enter surname.'),
    phone_number: yup.string().required('Please enter phone number.'),
    email: yup.string().trim().required('Please enter email address.').email('Please enter valid email address.'),
    service: yup.string().required('Please select at lease one service.'),
    booking_date: yup.string().required('Please select booking date.'),
    booking_time: yup.string().required('Please select booking time.'),
    subscribe: yup.string()
}).required();

export default bookLocationSchema;