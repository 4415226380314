import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';
import { Link } from 'react-router-dom';


const SelectiveAttentionTest = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                        <div className={"row w-100"}>
                            <div className={"col-md-8 col-sm-12"}><h1>Selective Attention Test (CAT-SLA)</h1></div>
                            <div className={"col-md-4 col-sm-12 text-md-end text-sm-left pt-1"}>
                                <Link target={"_blank"} className={"btn btn-outline-dark"} to={'https://www.cognifit.com/gb/battery-of-tests/selective-attention-test'}>Start test now</Link>
                            </div>
                        </div>

                        <p>Focus on identifying specific stimuli amidst distractors to measure your selective attention.</p>
                        <p><strong>Relation to QEEG, Neurofeedback, Biofeedback, and Neuromodulation:</strong></p>
                        <ul>
                            <li><strong>Brain Region:</strong> Parietal Lobe, Frontal Lobe</li>
                            <li><strong>Broadman Area:</strong> BA 7, BA 8</li>
                            <li><strong>EEG Electrode Places:</strong> F3, F4, P3, P4</li>
                            <li>
                                <strong>Neurofeedback:</strong>
                                <ul>
                                    <li><strong>Protocol:</strong> Enhancing alpha/beta activity in the parietal and frontal areas, maintaining optimal levels of focus and attentional selectivity amidst distractors.</li>
                                    <li><strong>Objective:</strong> To sustain focused attention, improve selectivity, and mitigate distractibility by modulating relevant brainwave activity.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Biofeedback:</strong>
                                <ul>
                                    <li><strong>Assessment:</strong> GSR for recognizing cognitive load or stress during attentional tasks, HRV for assessing systemic arousal and attentional effort.</li>
                                    <li><strong>Protocol:</strong> Utilizing GSR and HRV feedback to manage stress responses and maintain optimal arousal for sustained attentional engagement.</li>
                                    <li><strong>Objective:</strong> Assuring that the physiological responses to cognitive demands are supportive of maintained attention and do not detrimentally impact performance.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Neuromodulation:</strong>
                                <ul>
                                    <li>
                                        <strong>tDCS:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Dorsolateral Prefrontal Cortex (DLPFC), Parietal Cortex</li>
                                            <li><strong>Protocol:</strong> Anodal stimulation to promote excitability and stimulate circuits integral for selective attention and focus.</li>
                                            <li><strong>Objective:</strong> To fortify the neural underpinnings of selective attention and focus amidst cognitive distractors.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>PBM:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Frontal and Parietal Cortex</li>
                                            <li><strong>Protocol:</strong> Leveraging photobiomodulation to potentially support neural metabolism and facilitate connectivity within attentional networks.</li>
                                            <li><strong>Objective:</strong> To ensure neural resources and networks underpinning selective attention are optimally functioning and supported.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Mental Health Assessments and Their Relation:</strong>
                                <ul>
                                    <li><strong>DASS 21/42 (Depression, Anxiety, and Stress Scales):</strong> Stress and anxiety levels can detrimentally impact selective attention, providing a vital insight into any emotional factors potentially influencing attentional capacities.</li>
                                    <li><strong>Holmes Rahe Life Event Scale:</strong> Life events and resultant stress can impact cognitive function, including attention, hence, understanding these elements can ensure interventions are holistically targeted.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>Implementing a multidimensional approach to understanding and enhancing selective attention ensures that interventions are not only targeted towards inherent cognitive processes but also consider the psychological and physiological context within which these cognitive functions are deployed. This enables a nuanced and individualized strategy to enhance and optimize cognitive wellness in a sustainable and supportive manner.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default SelectiveAttentionTest