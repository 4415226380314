import { React, useState } from 'react'
import './cooperateHeader.css'
import {Link} from "react-router-dom";

const CoooperateHeaderGymsAndSportCentres = () => {

    return (
      <header id='headerDiagnostic' className='d-flex flex-column'>
        <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
          <h1>Cooperate</h1>
          <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and cognitive abilities, identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
          <a className='navbar-Respbtn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
        </article>
        <div className="breakline-diagnostic"></div>
  
        <div className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
          <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/L38T6fnrVjc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>

        <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                <h1>DeepSynaps Gym and Sports Center Performance Enhancement Package:</h1>
                  <ol>
                      <li>
                          <p><b>Comprehensive Fitness and Wellness Assessment:</b></p>
                          <ul>
                              <li>
                                  <p><b>Body Scan:</b></p>
                                  <p>Including EMG, ECG/Pulse, Respiratory, Temperature, Skin condition, SCP, and EOG scans to assess members' physical health, stress levels, and physiological responses during workouts.</p>
                              </li>
                              <li>
                                  <p><b>Brain Scan (QEEG Brain Mapping):</b></p>
                                  <p>Both Dry and Wet EEG scans to assess mental readiness and cognitive performance.</p>
                              </li>
                          </ul>
                      </li>

                      <li>
                          <p><b>Personalized Fitness and Mental Training:</b></p>
                          <ul>
                              <li>
                                  <p><b>Fitness Assessment:</b></p>
                                  <p>Evaluation of members' current fitness levels and goals, followed by personalized workout and nutrition plans.</p>
                              </li>
                              <li>
                                  <p><b>Neurofeedback Sessions:</b></p>
                                  <p>Tailored neurofeedback training to improve focus, concentration, and mental resilience during workouts.</p>
                              </li>
                              <li>
                                  <p><b>Neuromodulation (TDCS-PBM):</b></p>
                                  <p>Transcranial Direct Current Stimulation (TDCS) and Photobiomodulation (PBM) sessions to enhance muscle recovery, reduce fatigue, and improve overall physical performance.</p>
                              </li>

                              <li>
                                  <p><b>Biofeedback Sessions:</b></p>
                                  <p>Customized biofeedback sessions to manage stress, anxiety, and optimize mental states for better workout results.</p>
                              </li>
                          </ul>
                      </li>

                      <li>
                          <p><b>Group Classes and Training:</b></p>
                          <p>- Offering group neurofeedback and biofeedback sessions to enhance members' mental fitness and stress management skills.</p>
                          <p>- Nutrition workshops and seminars to educate members on healthy eating habits.</p>
                      </li>

                      <li>
                          <p><b>Equipment Integration:</b></p>
                          <p>Integration of DeepSynaps technology into gym equipment, allowing members to monitor and optimize their performance in real-time during workouts.</p>
                      </li>
                      <li>
                          <p><b>Reporting and Progress Tracking:</b></p>
                          <p>- Detailed reports summarizing members' physical and mental assessment results and progress over time.</p>
                          <p>- Regular monitoring of member data to track fitness improvements and cognitive performance enhancements.</p>
                      </li>
                      <li>
                          <p><b>Staff Training:</b></p>
                          <p>Training for gym staff on how to use DeepSynaps equipment and software effectively to provide guidance and support to members.</p>
                      </li>
                      <li>
                          <p><b>Marketing and Member Engagement:</b></p>
                          <p>- Marketing materials and strategies to promote the gym or sports center as a holistic wellness destination.</p>
                          <p>- Member engagement programs to encourage participation in mental fitness training and stress management sessions.</p>
                      </li>
                  </ol>
                  <p>This tailored package is designed to help gyms and sports centers enhance their members' overall well-being and physical performance. By integrating cognitive and physical training, it aims to create a unique and holistic fitness experience that attracts and retains members seeking not only physical fitness but also mental fitness and stress management. DeepSynaps will collaborate closely with the facility to customize the package to their specific goals and member demographics.</p>
              </div>
            </div>
        </div>
      </header>
    )
}

export default CoooperateHeaderGymsAndSportCentres