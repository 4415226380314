import { React, useState } from 'react'
import '../../BrainScan/Header/brainScanHeader.css'

const BodyScanHeader = () => {

    const [showDiagnostic1, setShowDiagnostic1] = useState(true)
    const [showDiagnostic2, setShowDiagnostic2] = useState(false)
    const [showDiagnostic3, setShowDiagnostic3] = useState(false)
    const [showDiagnostic4, setShowDiagnostic4] = useState(false)
    const [showDiagnostic5, setShowDiagnostic5] = useState(false)
    const [showDiagnostic6, setShowDiagnostic6] = useState(false)
    const [showDiagnostic7, setShowDiagnostic7] = useState(false)
    const [showDiagnostic8, setShowDiagnostic8] = useState(false)

    const showFirstOption = () => { // Showing 1st element
      setShowDiagnostic1(true)
      setShowDiagnostic2(false)
      setShowDiagnostic3(false)
      setShowDiagnostic4(false)
      setShowDiagnostic5(false)
      setShowDiagnostic6(false)
      setShowDiagnostic7(false)
      setShowDiagnostic8(false)
    }
  
    const showSecondOption = () => { // Showing 2nd element
      setShowDiagnostic1(false)
      setShowDiagnostic2(true)
      setShowDiagnostic3(false)
      setShowDiagnostic4(false)
      setShowDiagnostic5(false)
      setShowDiagnostic6(false)
      setShowDiagnostic7(false)
      setShowDiagnostic8(false)
    }
  
    const showThirdOption = () => { // Showing 3rd element
      setShowDiagnostic1(false)
      setShowDiagnostic2(false)
      setShowDiagnostic3(true)
      setShowDiagnostic4(false)
      setShowDiagnostic5(false)
      setShowDiagnostic6(false)
      setShowDiagnostic7(false)
      setShowDiagnostic8(false)
    }
  
    const showFourthOption = () => { // Showing 4th element
      setShowDiagnostic1(false)
      setShowDiagnostic2(false)
      setShowDiagnostic3(false)
      setShowDiagnostic4(true)
      setShowDiagnostic5(false)
      setShowDiagnostic6(false)
      setShowDiagnostic7(false)
      setShowDiagnostic8(false)
    }

    const showFifthOption = () => { // Showing 4th element
        setShowDiagnostic1(false)
        setShowDiagnostic2(false)
        setShowDiagnostic3(false)
        setShowDiagnostic4(false)
        setShowDiagnostic5(true)
        setShowDiagnostic6(false)
        setShowDiagnostic7(false)
        setShowDiagnostic8(false)
    }

    const showSixthOption = () => { // Showing 4th element
        setShowDiagnostic1(false)
        setShowDiagnostic2(false)
        setShowDiagnostic3(false)
        setShowDiagnostic4(false)
        setShowDiagnostic5(false)
        setShowDiagnostic6(true)
        setShowDiagnostic7(false)
        setShowDiagnostic8(false)
    }

    const showSeventhOption = () => { // Showing 4th element
        setShowDiagnostic1(false)
        setShowDiagnostic2(false)
        setShowDiagnostic3(false)
        setShowDiagnostic4(false)
        setShowDiagnostic5(false)
        setShowDiagnostic6(false)
        setShowDiagnostic7(true)
        setShowDiagnostic8(false)
    }

    const showEighthOption = () => { // Showing 4th element
        setShowDiagnostic1(false)
        setShowDiagnostic2(false)
        setShowDiagnostic3(false)
        setShowDiagnostic4(false)
        setShowDiagnostic5(false)
        setShowDiagnostic6(false)
        setShowDiagnostic7(false)
        setShowDiagnostic8(true)
    }
  
    return (
      <header id='headerDiagnostic' className='d-flex flex-column'>
        <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
          <h1>Body Scan</h1>
          <p className='headerDiagnosticArticleP'><strong>Discover Cutting-Edge AI-Enhanced Scanning Services:</strong><br />Delve into the comprehensive suite of brain and body scanning services we offer, designed to empower individuals with insights and control over their physiological functions. Our state-of-the-art, AI-enhanced techniques offer an unparalleled depth of analysis, backed by a team dedicated to your health and well-being.</p>
          <a className='navbar-Respbtn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
        </article>
        <div className="breakline-diagnostic"></div>
        
        <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
          <div className="headerDiagnostic-options d-flex flex-column align-items-start">
            <button className={showDiagnostic1 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showFirstOption}>Comprehensive Body Scan Offerings</button>
            <button className={showDiagnostic2 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSecondOption}>DeepSynaps: Probing Beneath the Surface</button>
            <button className={showDiagnostic3 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showThirdOption}>Why Opt for Our Scanning Services?</button>
            <button className={showDiagnostic4 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showFourthOption}>Biofeedback: A Journey of Self-Discovery</button>
            <button className={showDiagnostic5 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showFifthOption}>How to Prepare for Your Biofeedback Session</button>
            <button className={showDiagnostic6 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSixthOption}>What Awaits During the Session</button>
            <button className={showDiagnostic7 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSeventhOption}>Post-Session Insights</button>
            <button className={showDiagnostic8 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showEighthOption}>Post-Session Insights</button>
          </div>
          {showDiagnostic1 && 
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle3 d-flex flex-column align-items-start">
                <h1>Comprehensive Body Scan Offerings</h1>
                  <ul>
                      <li><strong>Real-Time Brain Activity:</strong> Gain immediate insights into your neural functions and the vibrancy of your cognitive processes.</li>
                      <li><strong>Blood Volume Pulse (PPG):</strong> Explore the ebb and flow of your circulatory system, revealing the pulsating rhythm of your cardiovascular health.</li>
                      <li><strong>Slow Cortical Potentials (SCP):</strong> Dive into the subtle, gradual shifts in your brain’s electrical landscape, providing a lens into deeper neurological activities.</li>
                      <li><strong>Muscle Activity:</strong> A window into your muscular world, identifying strength zones and areas necessitating targeted intervention or relaxation.</li>
                      <li><strong>Heart Rate:</strong> Steadfast monitoring of your cardiac rhythm, enabling precise, health-empowering adaptations to exercise, stress, and rest.</li>
                      <li><strong>Skin Conductance (GSR):</strong> Navigate the waters of emotional and physiological arousal with insightful data from your skin’s electrical properties.</li>
                      <li><strong>Body Temperature:</strong> Constant vigilance over your thermal regulation assists in identifying and mitigating potential health risks, maintaining your bodily harmony.</li>
                      <li><strong>Respiration:</strong> Breathe with ease as we analyze your inhalation and exhalation patterns, tailoring interventions to optimize lung health and stress management.</li>
                  </ul>
              </div>
            </div>
          }
            {showDiagnostic2 &&
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start">
                        <h1>DeepSynaps: Probing Beneath the Surface</h1>
                        <p>Embark on a journey of profound discovery with our comprehensive body scans. From the gentle pulsations of your heart to the subtle electrical dances in your brain, we bring your inner physiological world into the light, crafting a foundation upon which robust health and mental well-being are constructed. With real-time data and insightful analysis, we traverse the landscapes of your body and mind, ensuring that your wellness journey is informed, personalized, and deeply rooted in scientific expertise.</p>
                    </div>
                </div>
            }

          {showDiagnostic3 &&
            <div className="headerDiagnostic-content">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
              <h1>Why Opt for Our Scanning Services?</h1>
                <p>Harness the potential of AI-enhanced scanning techniques. Experience an in-depth understanding of your physiological state, leading to improved health, well-being, and emotional balance. With our services, embark on a transformative journey, tapping into the intricate depths of your body and brain.</p>
              </div>
            </div>
          }{showDiagnostic4 &&
            <div className="headerDiagnostic-content">
              <div className="diagnosticArticle3 d-flex flex-column align-items-start">
                <h1>Biofeedback: A Journey of Self-Discovery</h1>
                <p>Biofeedback is an innovative technique providing real-time feedback on physiological functions. With expert guidance and training, you can refine these functions to elevate your overall health and emotional state.</p>
              </div>
            </div>
          }{showDiagnostic5 &&
            <div className="headerDiagnostic-content">
              <div className="diagnosticArticle4 d-flex flex-column align-items-start">
                <h1>How to Prepare for Your Biofeedback Session</h1>
                <ul>
                    <li><b>Cleanliness:</b> Free your skin from lotions or oils.</li>
                    <li><b>Clothing:</b> Opt for comfortable clothing without significant metal components.</li>
                    <li><b>Dietary Restrictions:</b> Limit caffeine and nicotine intake 3 hours before the session.</li>
                    <li><b>Medications:</b> Inform us of any current medications, especially those affecting the nervous system.</li>
                    <li><b>Rest:</b> Ensure you're well-rested.</li>
                    <li><b>Arrival Time:</b> Arrive 15 minutes before your appointment.</li>
                    <li><b>Mental State:</b> Approach the session with a receptive mindset.</li>
                </ul>
              </div>
            </div>
          }

          {showDiagnostic6 &&
            <div className="headerDiagnostic-content">
                <div className="diagnosticArticle4 d-flex flex-column align-items-start">
                    <h1>What Awaits During the Session</h1>
                    <p>Expect a session lasting around 60 minutes. Our technician will attach sensors, and you'll receive real-time feedback. Exercises or relaxation techniques might be incorporated based on the session's goals.</p>
                </div>
            </div>
          }

            {showDiagnostic7 &&
              <div className="headerDiagnostic-content">
                <div className="diagnosticArticle4 d-flex flex-column align-items-start">
                  <h1>Post-Session Insights</h1>
                  <p>Post your biofeedback session, we'll discuss your experience and provide insights. Recommendations for subsequent sessions will be given based on your needs and aspirations.</p>
                 </div>
              </div>
            }

            {showDiagnostic8 &&
                <div className="headerDiagnostic-content">
                    <div className="diagnosticArticle4 d-flex flex-column align-items-start">
                        <h1>Reach Out to Us</h1>
                        <p>For queries or further information about our advanced scanning and biofeedback services, get in touch with our team at <a href="mailto: info@deepsynaps.com">info@deepsynaps.com</a>.</p>
                        <p>Equip yourself with the knowledge and tools to navigate the intricacies of your physiological state. Join us on this empowering journey today!</p>
                    </div>
                </div>
            }

        </div>
      </header>
    )
}

export default BodyScanHeader