import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';


const CoordinationCognitiveAssessment = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                        <h1>Coordination Cognitive Assessment (CAB-CO)</h1>
                        <p>The Coordination Cognitive Assessment (CAB-CO) is a scientific tool that allows healthcare professionals to carry out a complete screening, understand the cognitive strengths and weaknesses related to coordination, and evaluate motor-related ability. This assessment takes 20-30 minutes to complete. This assessment is suitable for children over 7 years of age, youth, adults, or seniors. The assessment can be used cross-culturally, and for both men and women.</p>
                        <p><strong>Relation to the QEEG,Neurofeedback,Biofeedback and Neuromodulation</strong></p>
                        <p>The Coordination Cognitive Assessment (CAB-CO) is a precise tool that delves into understanding an individual's coordination capabilities, shining a spotlight on their motor-related cognitive strengths and weaknesses. Applicable to a wide age range from children above 7 to seniors, it respects no boundaries of gender or cultural delineation.</p>
                        <ul>
                            <li>
                                <strong>Coordination and Motor Control:</strong>
                                <ul>
                                    <li><strong>Brain Regions and QEEG:</strong> The cerebellum and the basal ganglia stand as central pillars for coordination and motor control. By utilizing QEEG, we can track the unique brainwave patterns that mirror these coordination-related activities.</li>
                                    <li><strong>Biofeedback:</strong> Techniques such as Electromyography (EMG) can give valuable insights into muscle activity and tension, which directly relate to coordination.</li>
                                    <li><strong>Neurofeedback:</strong> Training protocols focusing on the Sensory Motor Rhythm (SMR) can potentially aid in refining motor control and coordination.</li>
                                    <li><strong>tDCS:</strong> Modulating the activity of the cerebellum or the motor cortex can influence coordination and motor capabilities.</li>
                                    <li><strong>PBM:</strong> Directing photobiomodulation towards the cerebellum or basal ganglia might energize neuronal networks, potentially bolstering coordination.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Spatial Perception:</strong>
                                <ul>
                                    <li><strong>Brain Regions and QEEG:</strong> The parietal lobe, particularly its right hemisphere, is paramount for spatial perception which assists in coordination. QEEG can pinpoint patterns in line with spatial cognition.</li>
                                    <li><strong>Biofeedback:</strong> Techniques such as Electrooculography (EOG) which monitors eye movement can be pivotal when evaluating spatial perception tasks.</li>
                                    <li><strong>Neurofeedback:</strong> Alpha training might enhance spatial awareness and perception, subsequently aiding coordination.</li>
                                    <li><strong>tDCS:</strong> Stimulating the parietal regions can potentially boost spatial cognition.</li>
                                    <li><strong>PBM:</strong> Focusing light therapy on the parietal lobe could invigorate its function, enhancing spatial perception.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Reaction Time:</strong>
                                <ul>
                                    <li><strong>Brain Regions and QEEG:</strong> The prefrontal cortex and the basal ganglia play key roles in determining one's reaction time, a crucial element of coordination. QEEG offers the capability to capture the neural patterns associated with swift reactive processes.</li>
                                    <li><strong>Biofeedback:</strong> Monitoring the Heart Rate Variability (HRV) using Electrocardiography (ECG) can gauge the physiological readiness and reactivity which can correlate with response times.</li>
                                    <li><strong>Neurofeedback:</strong> Enhancing Gamma frequencies might accelerate reaction times.</li>
                                    <li><strong>tDCS:</strong> Targeting the prefrontal cortex or basal ganglia can influence and potentially refine reaction times.</li>
                                    <li><strong>PBM:</strong> Applying light therapy to these regions can potentiate neural responsiveness, likely benefiting reaction times.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>Merging the insights from the CAB-CO with advanced neurological and physiological modalities gives healthcare professionals a layered perspective of a person's coordination capabilities. This intricate approach ensures not just the understanding of motor behaviors, but also the neural underpinnings behind them, leading to more effective and tailored interventions across various age groups.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default CoordinationCognitiveAssessment