import React, { useState } from 'react'
import './team.css'
import { HavaD, HacerY, SinemC, ZeynepC } from '../../../assets/images'

const Team = () => {
  const memberBios = [
    {
      id: 1,
      name: 'Dr. Hava Drummond',
      role: 'Phycologist',
      image: HavaD,
      bio: "<p>I was born and educated in Turkey until my early 20s. Following an initial career in engineering, I decided to retrain as a psychologist. Since 1998, I have been studying and working in the field of psychology and psychological therapies.</p>" +
          "<p>Over the years, I have worked with individuals and groups presenting with a range of psychological problems across the moderate to severe end of the mental health spectrum.  I have experience of working with adults, young people, couples and groups in a variety of settings.  In the National Health Service (NHS) I worked in the following settings: Community Mental Health Teams (CMHTs), GP surgeries, psychiatric hospitals, psychology & psychotherapy departments, Improving Access to Therapies Service (IAPT) etc.). I also provided therapy at voluntary / charitable counselling centres, university / colleges and in private practice.  In the last ten years I’ve been running my own private practice in Reading, Berkshire and London.</p>" +
          "<p>Professional Qualifications:</p>" +
          "<ul><li>Doctor of Counselling Psychology (Regent’s University, London)</li>" +
          "<li>MA in Counselling and Psychotherapy  (West London University, London)</li>" +
          "<li>BA Psychology & Sociology (The University of Reading)</li></ul>" +
          "<p>Memberships of Professional Bodies and Organisations:</p><ul>" +
          "<li>Chartered member (CPsychol) of the British Psychological Society BPS Division of Counselling Psychology</li>" +
          "<li>Registered with Health and Care Professions Council (HCPC)</li>" +
          "<li>Accredited Member of the British Association for Behavioural and Cognitive Psychotherapies (BABCP)</li>" +
          "<li>Member of the EMDR Association UK (EMDR UK)</li>" +
          "<li>Member of The British Emotionally Focused Therapy Centre (BEFT) and (ICEEFT), Public and Professional Liability Insurance with the Psychologists Protection Society (PPS)</li>" +
          "</ul>"
    },
    {
      id: 2,
      name: 'Dr. Cansu Armağan',
      role: 'Phycologist',
      image: ZeynepC,
      bio: "Life can be overwhelming and sometimes even exhausting, but I’m here to guide you through obstacles you may face. My goal is to help you understand yourself more deeply and better cope with change. I use evidence based therapeutic techniques to make this a journey of building resilience and flexibility that will affect all areas of your life, your mind, body, and relationships. I have a BA in Psychology from Koç University, MA in Clinical Psychology from Üsküdar University, and a PhD in Psychology from Arel University in Turkey. I have qualifications for CBT from the European Association for Behavioural and Cognitive Therapies (EABCT). I am also a certified Schema Therapist with the International Society of Schema Therapy.",

    },
    {
      id: 3,
      name: 'Hacer Yildirim',
      role: 'Occupational Therapist',
      image: HacerY,
      bio: "Hacer Yildirim is a dedicated Occupational Therapist specializing in TMS (Transcranial Magnetic Stimulation) and tDCS (Transcranial Direct Current Stimulation) therapies. With a degree from Oxford Brookes University, she brings a wealth of knowledge and experience in the field of Occupational Therapy. Hacer has been instrumental in providing therapeutic interventions and support to patients at the Oxford Health NHS Foundation Trust. Her areas of interest lie in sensory motor function and neuro occupational therapy, where she focuses on helping individuals optimize their cognitive and physical abilities. With her expertise in TMS and tDCS, Hacer plays a crucial role in assisting patients in their journey towards improved well-being and enhanced quality of life."
    },
    {
      id: 4,
      name: 'Dr. Sinem Cankardas',
      role: 'Clinical Phycologist',
      image: SinemC,
      bio: "<p>I have been working as a clinical psychologist for over 10 years, actively involved in both training clinical psychologists and participating in therapy processes. After graduating from the Department of Psychology at Ege University in 2009, I completed my master's degree in Health-Oriented Clinical Psychology and my doctorate in Clinical Psychology. In 2017, I completed my doctoral internship at the University of Central Lancashire and during this time, I conducted research on the mental health of immigrant women living in the UK. In addition, I have been involved in several international projects. I am currently a researcher in the ongoing project titled \"Multi-Sectoral Responses to Child Abuse and Neglect in Europe,\" which involves the participation of 34 countries. In 2023, I obtained the title of associate professor in the field of Clinical Psychology. Since 2023, I have been providing services in the UK as a Chartered Psychologist registered with the British Psychological Society.</p>" +
          "<p>As the Deputy Coordinator for Psychological Support in Disasters at the Turkish Psychologists Association, I provide training to volunteer psychologists who offer psychological support in disaster situations, and I volunteered in field coordination after the Kahramanmaraş Earthquake. Additionally, I provide supervision to master's students in Clinical Psychology. With expertise extending beyond the therapy room, I specialize in trauma, anxiety, stress-related issues, adverse childhood experiences, and the impact of interpersonal violence on mental health. With over a decade of clinical experience in this field, I offer services by utilizing my knowledge and expertise to support individuals on their journey towards healing and resilience. I believe in a holistic approach that combines evidence-based treatments with a personalized understanding of each individual's unique circumstances, shaping my clinical practices within this framework.</p>"
    }
  ]

  const [selectedMember, setSelectedMember] = useState(memberBios[0]);
  const [showDiv, setShowDiv] = useState(false);

  const firstHalf = memberBios.slice(0, memberBios.length / 5);
  const secondHalf = memberBios.slice(memberBios.length / 5);

  const handleCardClick = (member) => {
    setSelectedMember(member);
    setShowDiv(true); // Open the bio-container
    document.getElementById('clinical-team').scrollIntoView()
  };

  const handleButtonClick = () => {
    setShowDiv(false);
  };

  return (
      <>
        <div id='clinical-team' className="wrapper d-flex flex-column justify-content-center align-items">
          {showDiv && (
              <div id="bio-container" className="member-bios d-flex flex-row align-items-center">
                <div className="bios-image-container d-flex flex-column justify-content-center align-items-center">
                  <img src={selectedMember.image} alt="" />
                  <br />
                  <h6>{selectedMember.name}</h6>
                  <p className="memberS-title" href="#">{selectedMember.role}</p>
                </div>
                <article dangerouslySetInnerHTML={{__html: selectedMember.bio}}></article>
                <button onClick={handleButtonClick} className="member-bios-closingBtn d-flex flex-row justify-content-center align-items-center">X</button>
              </div>
          )}
          <div className="member-card-container d-flex flex-row justify-content-center">
            <h2>Clinical Team</h2>
            {firstHalf.map(member =>
                <div key={member.id} className="member-card d-flex flex-column justify-content-center align-items-center">
                  <div className="member-img-contanier d-flex flex-column align-items-center">
                    <img className='member-card-img' src={member.image} alt={`${member.name}'s`} />
                    <br />
                    <h6>{member.name}</h6>
                    <p className='memberS-title' href="#">{member.role}</p>
                  </div>
                  <button onClick={() => handleCardClick(member)} className='member-card-btn'>Learn More</button>
                </div>
            )}
          </div>
          <div className="member-card-container d-flex flex-row align-items-start">
            {secondHalf.map(member =>
                    <div key={member.id} className="member-card d-flex flex-column justify-content-center align-items-center">
                      <div className="member-img-contanier d-flex flex-column align-items-center">
                        <img className='member-card-img' src={member.image} alt={`${member.name}'s`}></img>
                        <br />
                        <h6>{member.name}</h6>
                        <p className='memberS-title'>{member.role}</p>
                      </div>
                      <button onClick={() => handleCardClick(member)} className='member-card-btn'>Learn More</button>
                    </div>
                // <div key={member.id} className="member-card d-flex flex-column justify-content-center align-items-center">
                //  <div className="member-img-container d-flex flex-column align-items-center">
                //     <img className='member-card-img' src={member.image} alt={`${member.name}'s picture`} />
                //     <br />
                //     <h6>{member.name}</h6>
                //     <a className='memberS-title' href="#">{member.role}</a>
                //   </div>
                //   <button onClick={() => handleCardClick(member)} className='member-card-btn'>Learn More</button>
                // </div>
            )}
          </div>
        </div>
      </>
  )
}
/**/
export default Team
