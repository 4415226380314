import { React, useState } from 'react'
import './cooperateHeader.css'
import {Link} from "react-router-dom";

const CoooperateHeaderCareHomes = () => {

    return (
      <header id='headerDiagnostic' className='d-flex flex-column'>
        <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
          <h1>Cooperate</h1>
          <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and cognitive abilities, identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
          <a className='navbar-Respbtn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
        </article>
        <div className="breakline-diagnostic"></div>
  
        <div className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
          <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/L38T6fnrVjc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>

        <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                <h1>DeepSynaps Care Home and Residential Wellness Package:</h1>
                  <ol>
                      <li>
                          <p><b>Comprehensive Health and Cognitive Assessment:</b></p>
                          <ul>
                              <li>
                                  <p><b>Body Scan:</b></p>
                                  <p>Including EMG, ECG/Pulse, Respiratory, Temperature, Skin condition, SCP, and EOG scans to assess residents' physical health, stress levels, and physiological responses.</p>
                              </li>
                              <li>
                                  <p><b>Brain Scan (QEEG Brain Mapping):</b></p>
                                  <p>Both Dry and Wet EEG scans to assess cognitive function and detect cognitive patterns among residents.</p>
                              </li>
                          </ul>
                      </li>

                      <li>
                          <p><b>Mental Health and Cognitive Support:</b></p>
                          <p>- In-depth mental health assessments to evaluate residents' emotional well-being, stress levels, and overall mental health.</p>
                          <p>- Cognitive assessments to measure cognitive abilities, memory, attention, and problem-solving skills, particularly for residents with dementia or cognitive impairments.</p>
                      </li>

                      <li>
                          <p><b>Personalized Care Plans:</b></p>
                          <ul>
                              <li>
                                  <p><b>Neurofeedback Sessions:</b></p>
                                  <p>Tailored neurofeedback training for residents with cognitive challenges to improve cognitive function, memory, and emotional well-being.</p>
                              </li>
                              <li>
                                  <p><b>Neuromodulation (TDCS-PBM):</b></p>
                                  <p>Sessions to enhance cognitive function and emotional well-being for residents with cognitive decline.</p>
                              </li>
                              <li>
                                  <p><b>Biofeedback:</b></p>
                                  <p>Sessions for stress management and emotional support for residents.</p>
                              </li>
                          </ul>
                      </li>

                      <li>
                          <p><b>Dementia Care and Cognitive Rehabilitation:</b></p>
                          <p>- Integration of neurofeedback and neuromodulation into dementia care programs to enhance cognitive function and memory in residents with dementia.</p>
                          <p>- Customized cognitive rehabilitation plans for residents with cognitive impairments, focusing on improving daily functioning.</p>
                      </li>


                      <li>
                          <p><b>Reporting and Monitoring:</b></p>
                          <p>- Detailed reports summarizing resident assessment results and progress over time.</p>
                          <p>- Regular monitoring of resident data to track improvements and adapt care plans as necessary.</p>
                      </li>

                      <li>
                          <p><b>Equipment and Software:</b></p>
                          <p>DeepSynaps will provide diagnostic equipment, software licenses, and technical support for seamless integration into care home facilities.</p>
                      </li>

                      <li>
                          <p><b>Staff Training and Collaboration:</b></p>
                          <p>- Training for care home staff on how to use DeepSynaps equipment and software effectively in resident care.</p>
                          <p>- Collaboration with care home teams to integrate cognitive assessment and enhancement techniques into resident care plans.</p>
                      </li>

                      <li>
                          <p><b>Confidentiality and Data Security:</b></p>
                          <p>Ensuring the highest level of data security and confidentiality for sensitive resident information.</p>
                      </li>
                  </ol>
                  <p>This package is designed to enhance the well-being and cognitive function of residents in care homes and residential facilities. By combining advanced diagnostic assessments with personalized cognitive and mental health interventions, it aims to improve the quality of life for residents, especially those with cognitive impairments or dementia. DeepSynaps will collaborate closely with care homes to customize the package to meet the specific needs and conditions of their residents, ultimately contributing to improved resident care and well-being.</p>
              </div>
            </div>
        </div>
      </header>
    )
}

export default CoooperateHeaderCareHomes