import { React, useState } from 'react'
import '../../CognitiveTests/Header/cognitiveTestHeader.css'

const MbtiHeader = () => {
  
  const [showSection1, setShowSection1] = useState(true)
  const [showSection2, setShowSection2] = useState(false)
  const [showSection3, setShowSection3] = useState(false)
  const [showSection4, setShowSection4] = useState(false)
  const [showSection5, setShowSection5] = useState(false)
  const [showSection6, setShowSection6] = useState(false)

  const handleShowSection1 = () => { // Showing 1st element
    setShowSection1(true)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(false)
  }

  const handleShowSection2 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(true)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(false)
  }

  const handleShowSection3 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(true)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(false)
  }

  const handleShowSection4 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(true)
    setShowSection5(false)
    setShowSection6(false)
  }

  const handleShowSection5 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(true)
    setShowSection6(false)
  }

  const handleShowSection6 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(true)
  }

  const handleShowSection7 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(false)
  }

  return (
    <header id='headerDiagnostic' className='d-flex flex-column'>
      <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
        <h1>Mental Health Assessment</h1>
        <div className='d-flex flex-column position-relative'>
          <p className='headerDiagnosticArticleP'>  Anyone feeling overwhelmed, anxious, or down, or those seeking a general emotional health checkup. To identify and address potential emotional disturbances and promote mental well-being.</p>
        </div>
      </article>
      <div className="breakline-diagnostic"></div>

      <div className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
        <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      </div>

      <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
        <div className="headerDiagnostic-options d-flex flex-column align-items-start">
          <button className={showSection1 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection1}>What is it for?</button>
          <button className={showSection2 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection2}>Who is it for?</button>
          <button className={showSection3 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection3}>Why are we doing it?</button>
          <button className={showSection4 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection4}>Relation to QEEG, Biofeedback, and Neurofeedback</button>
          <button className={showSection5 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection5}>Relation to tDCS and PBM</button>
          <button className={showSection6 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection6}>Relation to Cognitive Tests</button>
        </div>
        {showSection1 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>What is it for?</h1>
                <p>To identify personality preferences and how people perceive the world and make decisions.</p>
              </div>
            </div>
        }{showSection2 &&
          <div className="headerDiagnostic-content d-flex flex-row align-items-start">
            <div className="diagnosticArticle2 d-flex flex-column align-items-start">
              <h1>Who is it for?</h1>
              <p>Individuals seeking clarity on their personality type and interpersonal dynamics.</p>
            </div>
          </div>
        }{showSection3 &&
          <div className="headerDiagnostic-content d-flex flex-row align-items-start">
            <div className="diagnosticArticle2 d-flex flex-column align-items-start">
              <h1>Why are we doing it?</h1>
              <p>To facilitate personal growth, improve communication, and enhance relationship dynamics.</p>
            </div>
          </div>
        }
        {showSection4 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>Relation to QEEG, Biofeedback, and Neurofeedback</h1>
                <p>Different personality types might exhibit unique neural activity patterns, particularly in regions associated with decision-making and perception, such as the frontal and temporal lobes. QEEG can help map and elucidate these patterns, providing a neuroscientific angle to the subjective insights of MBTI. Neurofeedback protocols, like Gamma training, can be explored to enhance cognitive processing, a trait often seen in certain personality types. Using Biofeedback, Skin Conduction feedback aids in understanding an individual's physiological arousal in varied social situations, reflecting on aspects of their personality type.</p>
              </div>
            </div>
        }
        {showSection5 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>Relation to tDCS and PBM</h1>
                <p>Personality insights from MBTI can be complemented by tDCS applied to regions like the frontal lobe to bolster cognitive functions. Brain PBM therapy, on the other hand, promotes cellular health and improved cognition by enhancing the metabolic capacity of neurons, potentially supporting personality-linked skills.</p>
              </div>
            </div>
        }
        {showSection6 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>Relation to Cognitive Tests</h1>
                <p>The Myers-Briggs Type Indicator, while determining personality preferences, often indirectly highlights cognitive inclinations and strengths. Relating MBTI results to cognitive assessments can provide layered insights into an individual's cognitive style and strengths in line with their personality type.</p>
                <p><strong>Attention and Focus:</strong> Some personality types, like ISTJs or INTJs, are often described as detail-oriented and methodical. Cognitive tests can explore whether this translates to enhanced attention spans or focus levels compared to other types.</p>
                <p><strong>Memory:</strong> Personalities with a preference for Sensing, such as ISTP or ISFJ, might have a different memory recall pattern compared to their Intuitive counterparts. Cognitive tests centered on memory can assess the nuances of recall, recognition, and associative memory linked to these preferences.</p>
                <p><strong>Executive Functioning:</strong> Types with dominant Thinking and Judging components, like ENTJ, may exhibit a specific pattern in decision-making and problem-solving faculties. Cognitive tests can further decipher these patterns, determining the nature of their logical reasoning, problem-solving approach, and multitasking capabilities.</p>
                <p><strong>Perception and Processing:</strong> The distinction between Sensing and Intuition in the MBTI can hint at perceptual inclinations. Cognitive tests can further analyze the depth and breadth of perception and information processing.</p>
                <p>Integrating MBTI findings with cognitive test results offers a comprehensive understanding of the interplay between personality and cognition. Such integration allows for tailored strategies that enhance both personal and professional lives, ensuring individuals operate optimally according to their innate strengths and preferences.</p>
              </div>
            </div>
        }
      </div>
    </header>
  )
}

export default MbtiHeader