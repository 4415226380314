import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';


const PerceptionCognitiveAssessment = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                        <h1>Perception Cognitive Assessment (CAB-PC)</h1>
                        <p>The Perception Cognitive Assessment (CAB-PC) helps healthcare professionals to carry out a complete cognitive screening, to understand the weaknesses and strengths related to the functioning of perception. This assessment takes 20-30 minutes to complete.This assessment is suitable for children over 7 years of age, youth, adults, or seniors. The assessment can be used cross-culturally, and for both men and women.</p>
                        <p><strong>Relation to the QEEG,Neurofeedback,Biofeedback and Neuromodulation</strong></p>
                        <p>The Perception Cognitive Assessment (CAB-PC) offers healthcare professionals a comprehensive tool to scrutinize the cognitive processes intricately associated with perception. The assessment elucidates the relative strengths and weaknesses tied to perceptual abilities.</p>
                        <ul>
                            <li>
                                <strong>Understanding Perception Mechanisms:</strong>
                                <ul>
                                    <li><strong>Brain Regions and QEEG:</strong> Perception primarily involves the occipital lobe, parietal lobe, and certain areas of the temporal lobe. The QEEG can capture specific oscillatory activities in these regions during perceptual tasks, providing insights into visual and spatial processing.</li>
                                    <li><strong>Biofeedback:</strong> Monitoring Heart Rate Variability (HRV) using Electrocardiography (ECG) allows for understanding physiological responses during challenging perceptual tasks, revealing the interplay between perception and emotional responses.</li>
                                    <li><strong>Neurofeedback:</strong> Training within the Alpha frequency band can refine visual and spatial perceptual abilities, while SMR (Sensory Motor Rhythm) training can assist in sensory integration.</li>
                                    <li><strong>tDCS:</strong> Focusing on the occipital and parietal regions can modulate visual and spatial perception capabilities, potentially aiding in clearer and more accurate perceptual recognition.</li>
                                    <li><strong>PBM:</strong> Directing light therapy toward the occipital lobe and associated perceptual processing regions can enhance their overall efficiency and responsiveness.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Spatial and Visual Processing:</strong>
                                <ul>
                                    <li><strong>Brain Regions and QEEG:</strong> Regions like the posterior parietal cortex play vital roles in spatial awareness and processing. QEEG provides an objective measurement of these neural patterns and their synchronicity during perceptual tasks.</li>
                                    <li><strong>Biofeedback:</strong> Using Electroencephalography (EEG) during spatial tasks can offer a moment-to-moment understanding of cognitive load and attention distribution.</li>
                                    <li><strong>Neurofeedback:</strong> Targeted training can help fine-tune spatial awareness and depth perception.</li>
                                    <li><strong>tDCS:</strong> Stimulating regions involved in spatial cognition can augment abilities like depth perception and spatial resolution.</li>
                                    <li><strong>PBM:</strong> Photobiomodulation of regions involved in spatial processing can potentially enhance their functionality and connectivity, aiding in more robust perceptual outcomes.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>Utilizing the Perception Cognitive Assessment alongside these cutting-edge neuroscientific tools offers a detailed landscape of an individual's perceptual faculties, paving the way for focused therapeutic or enhancement approaches.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default PerceptionCognitiveAssessment