import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';
import { Link } from 'react-router-dom';

const SimonTest = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                        <div className={"row w-100"}>
                            <div className={"col-md-8 col-sm-12"}><h1>Simon Test (CAT-ST)</h1></div>
                            <div className={"col-md-4 col-sm-12 text-md-end text-sm-left pt-1"}><Link target={"_blank"} className={"btn btn-outline-dark"} to={'https://www.cognifit.com/gb/battery-of-tests/simon-task'}>Start test now</Link></div>
                        </div>
                        <p>Understand your ability to manage conflicting spatial stimulus-response associations.</p>
                        <p><strong>Relation to QEEG, Neurofeedback, Biofeedback, and Neuromodulation:</strong></p>
                        <ul>
                            <li><strong>Brain Region:</strong> Prefrontal Cortex, Anterior Cingulate Cortex</li>
                            <li><strong>Broadman Area:</strong> 9, 10, 11 (Prefrontal Cortex); 24, 32 (Anterior Cingulate Cortex)</li>
                            <li><strong>EEG Electrode Places:</strong> Fz, FCz (Standard 10-20 system places, focusing on midline frontal areas)</li>
                            <li>
                                <strong>Neurofeedback:</strong>
                                <ul>
                                    <li><strong>Protocol:</strong> Rewarding the decrease of theta/beta ratio, and potentially focusing on enhancing alpha coherence.</li>
                                    <li><strong>Objective:</strong> To improve attentional control and the capacity to manage conflicting stimuli effectively.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Biofeedback:</strong>
                                <ul>
                                    <li><strong>Assessment:</strong> Monitoring GSR and HRV to assess physiological responses (stress, arousal) to conflicting stimuli.</li>
                                    <li><strong>Protocol:</strong> Engage in GSR and HRV biofeedback to optimize stress and arousal management amidst cognitive conflict.</li>
                                    <li><strong>Objective:</strong> To manage physiological arousal and maintain optimal performance during tasks demanding cognitive control amidst conflicting stimuli.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Neuromodulation:</strong>
                                <ul>
                                    <li>
                                        <strong>tDCS:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Dorsolateral Prefrontal Cortex (DLPFC)</li>
                                            <li><strong>Protocol:</strong> Employing anodal stimulation to the DLPFC to potentially improve executive function and cognitive control.</li>
                                            <li><strong>Objective:</strong> Enhancing the ability to manage cognitive conflict and improving response selection in the presence of conflicting stimuli.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>PBM:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Prefrontal Cortex</li>
                                            <li><strong>Protocol:</strong> Using photobiomodulation to improve cerebral blood flow and neuronal metabolism.</li>
                                            <li><strong>Objective:</strong> To enhance cognitive function and improve performance on tasks requiring attention and cognitive control.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Mental Health Assessments and Their Relation:</strong>
                                <ul>
                                    <li><strong>DASS 21/42:</strong> Anxiety and stress levels, as measured by the DASS, may influence the ability to manage cognitive conflicts and execute correct responses amidst spatially conflicting stimuli. Addressing emotional aspects may indirectly benefit cognitive performance.</li>
                                    <li><strong>Holmes Rahe Life Event Scale:</strong> Elevated stress due to significant life changes may affect cognitive control, potentially impairing the ability to manage conflicting information effectively.</li>
                                    <li><strong>Personality Scale:</strong> Different personality traits may relate to the capacity to handle cognitive conflict and the stress it may induce; understanding this can tailor approaches to manage stress and enhance cognitive control during the Simon Test.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>The Simon Test, in conjunction with the mentioned protocols and assessments, can provide a thorough understanding of an individual's cognitive control capabilities and offer targeted strategies to enhance them through neuromodulation, neurofeedback, and biofeedback. This ensures a multifaceted approach towards cognitive optimization and mental health well-being.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default SimonTest