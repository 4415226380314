import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';
import { Link } from 'react-router-dom';


const DividedAttentionTest = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">

                        <div className={"row w-100"}>
                            <div className={"col-md-8 col-sm-12"}><h1>Divided Attention Test</h1></div>
                            <div className={"col-md-4 col-sm-12 text-md-end text-sm-left pt-1"}>
                                <Link target={"_blank"} className={"btn btn-outline-dark"} to={'https://www.cognifit.com/battery-of-tests/diat-shif-test/simultaneity-test'}>Start test now</Link>
                            </div>
                        </div>

                        <p>To evaluate the individual's capability to process and respond to multiple stimuli at the same time, assessing their cognitive control and multitasking abilities.</p>
                        <p><strong>Relation to QEEG, Neurofeedback, Biofeedback, and Neuromodulation:</strong></p>
                        <ul>
                            <li><strong>Brain Region:</strong> Prefrontal Cortex, Parietal Lobe.</li>
                            <li><strong>Brodmann Area:</strong> BA 9, BA 46 (Prefrontal), BA 7 (Parietal).</li>
                            <li><strong>EEG Electrode Places:</strong> Fz, F3, F4, Pz, P3, P4.</li>
                            <li>
                                <strong>Neurofeedback:</strong>
                                <ul>
                                    <li><strong>Protocol:</strong> Targeting beta (12-30 Hz) and possibly theta (4-8 Hz) depending on the specific demands of the divided attention task and individual baseline.</li>
                                    <li><strong>Objective:</strong> To facilitate cognitive control and maintain attentive states, adapting neural activity to support divided attention skills.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Biofeedback:</strong>
                                <ul>
                                    <li><strong>Assessment:</strong> Monitoring HRV and GSR to assess stress and engagement levels during tasks requiring divided attention.</li>
                                    <li><strong>Protocol:</strong> Utilizing techniques to manage physiological arousal and promote a calm, focused state, which may involve breathing exercises and cognitive strategies.</li>
                                    <li><strong>Objective:</strong> Manage physiological responses to enhance attentional control and sustain engagement with multiple stimuli without undue stress.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Neuromodulation:</strong>
                                <ul>
                                    <li>
                                        <strong>tDCS:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Prefrontal Cortex.</li>
                                            <li><strong>Protocol:</strong> Applying anodal tDCS to enhance the functionality of prefrontal networks involved in cognitive control and attention regulation.</li>
                                            <li><strong>Objective:</strong> Potentially enhance neural excitability and promote networks that support the ability to divide attention effectively.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>PBM:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Prefrontal Cortex.</li>
                                            <li><strong>Protocol:</strong> Utilizing photobiomodulation to boost cellular activity and potentially support neural health in regions relevant to attention and cognitive control.</li>
                                            <li><strong>Objective:</strong> Encourage optimal functionality in prefrontal networks, possibly impacting divided attention capabilities positively.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Mental Health Assessments and Their Relation:</strong>
                                <ul>
                                    <li><strong>Holmes and Rahe Life Event Scale:</strong> Stressful life events and resultant stress levels can compromise cognitive resources, potentially impacting the efficiency of dividing attention.</li>
                                    <li><strong>SISRI-24 (Silencing the Inner Self Scale):</strong> Those who engage in persistent self-silencing may experience cognitive and emotional burdens that impact attentional resources.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>Understanding the participant's cognitive style, mental health status, and stress level could provide meaningful context for both the application and interpretation of cognitive tests and related interventions. Always ensure that these interventions and assessments are conducted under the guidance of relevant healthcare or research professionals.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default DividedAttentionTest