import { React, useState } from 'react'
import '../../CognitiveTests/Header/cognitiveTestHeader.css'

const SelfReflectionAndInsightScaleHeader = () => {
  
  const [showSection1, setShowSection1] = useState(true)
  const [showSection2, setShowSection2] = useState(false)
  const [showSection3, setShowSection3] = useState(false)
  const [showSection4, setShowSection4] = useState(false)
  const [showSection5, setShowSection5] = useState(false)
  const [showSection6, setShowSection6] = useState(false)

  const handleShowSection1 = () => { // Showing 1st element
    setShowSection1(true)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(false)
  }

  const handleShowSection2 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(true)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(false)
  }

  const handleShowSection3 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(true)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(false)
  }

  const handleShowSection4 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(true)
    setShowSection5(false)
    setShowSection6(false)
  }

  const handleShowSection5 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(true)
    setShowSection6(false)
  }

  const handleShowSection6 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(true)
  }

  const handleShowSection7 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(false)
  }

  return (
    <header id='headerDiagnostic' className='d-flex flex-column'>
      <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
        <h1>Mental Health Assessment</h1>
        <div className='d-flex flex-column position-relative'>
          <p className='headerDiagnosticArticleP'>  Anyone feeling overwhelmed, anxious, or down, or those seeking a general emotional health checkup. To identify and address potential emotional disturbances and promote mental well-being.</p>
        </div>
      </article>
      <div className="breakline-diagnostic"></div>

      <div className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
        <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      </div>

      <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
        <div className="headerDiagnostic-options d-flex flex-column align-items-start">
          <button className={showSection1 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection1}>What is it for?</button>
          <button className={showSection2 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection2}>Who is it for?</button>
          <button className={showSection3 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection3}>Why are we doing it?</button>
          <button className={showSection4 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection4}>Relation to QEEG, Biofeedback, and Neurofeedback</button>
          <button className={showSection5 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection5}>Relation to tDCS and PBM</button>
          <button className={showSection6 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection6}>Relation to Cognitive Tests</button>
        </div>
        {showSection1 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>What is it for?</h1>
                <p>To gauge capacity for introspection and self-understanding.</p>
              </div>
            </div>
        }{showSection2 &&
          <div className="headerDiagnostic-content d-flex flex-row align-items-start">
            <div className="diagnosticArticle2 d-flex flex-column align-items-start">
              <h1>Who is it for?</h1>
              <p>Those wanting to explore self-awareness.</p>
            </div>
          </div>
        }{showSection3 &&
          <div className="headerDiagnostic-content d-flex flex-row align-items-start">
            <div className="diagnosticArticle2 d-flex flex-column align-items-start">
              <h1>Why are we doing it?</h1>
              <p>To improve personal growth.</p>
            </div>
          </div>
        }
        {showSection4 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>Relation to QEEG, Biofeedback, and Neurofeedback</h1>
                <p>Self-reflection and insight might be linked to brain activity in areas like the insular cortex and the anterior cingulate cortex, regions connected with self-awareness and introspection. QEEG monitors these activities, offering a deeper dive into the neural basis of introspection. With Neurofeedback, Alpha training can promote a deeper state of relaxation, facilitating introspective activities. Biofeedback techniques, such as monitoring skin temperature, can give insights into physiological relaxation states, while HRV (Heart Rate Variability) provides cues about cardiac responses during introspection.</p>
              </div>
            </div>
        }
        {showSection5 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>Relation to tDCS and PBM</h1>
                <p>Introspective capacities may be enhanced by tDCS targeting regions like the insular cortex. Brain PBM therapy can stimulate neurogenesis and synaptogenesis in these areas, possibly adding depth to introspective processes and complementing the insights from SISRI-24.</p>
              </div>
            </div>
        }
        {showSection6 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>Relation to Cognitive Tests</h1>
                <p>The SISRI-24, focusing on self-reflection and insight, provides valuable information about an individual's metacognitive abilities, or the understanding of one's own thought processes. Metacognition plays a crucial role in several cognitive tasks and is often seen as the 'thinking about thinking' process.</p>
                <p><strong>Problem-solving and Decision Making:</strong> An individual's capacity for introspection, as measured by SISRI-24, can provide insights into their problem-solving strategies. Those with heightened self-awareness might approach challenges with more deliberation, considering multiple perspectives before arriving at a decision.</p>
                <p><strong>Learning and Adaptability:</strong> Self-reflection is pivotal in the learning process. Individuals scoring high on SISRI-24 might exhibit superior adaptability in cognitive tests, as they are more likely to evaluate their mistakes, learn from them, and adjust their strategies accordingly.</p>
                <p><strong>Attention and Concentration:</strong> Self-aware individuals might display heightened attentional control, as they are more attuned to their internal thought processes. This can be cross-referenced with cognitive tests focusing on attention span and focus.</p>
                <p><strong>Emotional Regulation:</strong> Introspection and self-understanding are closely tied to emotional intelligence. Cognitive tests gauging emotional regulation, recognition, and response can provide complementary data to the SISRI-24 scores.</p>
                <p>In essence, the ability to reflect on one's own thinking processes, emotions, and behaviors is a foundational skill that interlinks with various cognitive abilities. By understanding an individual's introspective capacities through SISRI-24 and juxtaposing it with cognitive assessments, a richer profile of their metacognitive and cognitive landscape can be mapped. This serves as a comprehensive base for interventions aimed at enhancing both introspection and cognition.</p>
              </div>
            </div>
        }
      </div>
    </header>
  )
}

export default SelfReflectionAndInsightScaleHeader