import { React, useState } from 'react'
import './cooperateHeader.css'
import {Link} from "react-router-dom";

const CorporateCorrectionalFacilityRehabilitationHeader = () => {

    return (
      <header id='headerDiagnostic' className='d-flex flex-column'>
        <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
          <h1>Cooperate</h1>
          <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and cognitive abilities, identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
          <a className='navbar-Respbtn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
        </article>
        <div className="breakline-diagnostic"></div>
  
        <div className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
          <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/L38T6fnrVjc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>

        <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                <h1>DeepSynaps Correctional Facility Rehabilitation and Mental Health Package:</h1>
                  <ol>
                      <li>
                          <p><b>Comprehensive Inmate Assessments:</b></p>
                          <ul>
                              <li>
                                  <p><b>Body Scan:</b></p>
                                  <p>Includes EMG, ECG/Pulse, Respiratory, Temperature, Skin condition, SCP, and EOG scans to assess inmates' physical health, stress levels, and physiological responses.</p>
                              </li>
                              <li>
                                  <p><b>Mental Health Assessment</b></p>
                                  <p>Comprehensive assessments to evaluate inmates' emotional well-being, stress levels, and mental health conditions.</p>
                              </li>
                          </ul>
                      </li>

                      <li>
                          <p><b>Cognitive Assessments:</b></p>
                          <p>Cognitive assessments to measure cognitive abilities, impulse control, anger management, and problem-solving skills, with a focus on identifying cognitive challenges that may contribute to behavioral issues.</p>
                      </li>

                      <li>
                          <p><b>Personalized Rehabilitation Plans:</b></p>
                          <p>Individualized rehabilitation plans for each inmate based on their assessment results, incorporating the following services:</p>
                      </li>

                      <li>
                          <p><b>Cognitive Rehabilitation Programs:</b></p>
                          <p>Cognitive rehabilitation programs designed to address cognitive deficits, improve emotional regulation, impulse control, and decision-making among inmates.</p>
                      </li>

                      <li>
                          <p><b>Anger Management and Stress Reduction:</b></p>
                          <p>Anger management workshops and stress reduction techniques to help inmates manage and express their emotions constructively.</p>
                      </li>

                      <li>
                          <p><b>Substance Abuse Rehabilitation:</b></p>
                          <p>Substance abuse rehabilitation programs for inmates struggling with addiction, incorporating both cognitive and emotional support.</p>
                      </li>

                      <li>
                          <p><b>Biofeedback for Stress and Emotional Regulation:</b></p>
                          <p>Customized biofeedback sessions to assist inmates in managing stress, anxiety, and optimizing emotional states as part of their rehabilitation.</p>
                      </li>

                      <li>
                          <p><b>Neurofeedback and Neuromodulation Services:</b></p>
                          <p>- Personalized neurofeedback training sessions to enhance emotional regulation, reduce anxiety, and improve impulse control and cognitive function.</p>
                          <p>- Neuromodulation (TDCS-PBM) sessions to support mood stabilization and cognitive enhancement for inmates with mood disorders.</p>
                      </li>

                      <li>
                          <p><b>Progress Tracking and Reporting:</b></p>
                          <p>- Detailed reports summarizing inmate assessment results and progress over time.</p>
                          <p>- Regular monitoring of inmate data to track improvements and adapt rehabilitation plans as necessary.</p>
                      </li>

                      <li>
                          <p><b>Equipment and Software:</b></p>
                          <p>DeepSynaps will provide diagnostic equipment, software licenses, and technical support for implementing cognitive and behavioral rehabilitation programs within the correctional facility.</p>
                      </li>

                      <li>
                          <p><b>Staff Training and Collaboration:</b></p>
                          <p>- Training for correctional facility staff on how to use DeepSynaps technology and techniques effectively in inmate rehabilitation.</p>
                          <p>- Collaboration with mental health professionals, counselors, and correctional staff to integrate cognitive assessments and enhancement techniques into rehabilitation plans.</p>
                      </li>

                      <li>
                          <p><b>Confidentiality and Data Security:</b></p>
                          <p>Ensuring the highest level of data security and confidentiality for inmate information, adhering to correctional facility privacy and security protocols.</p>
                      </li>
                  </ol>
                  <p>This package is specifically designed to support inmate rehabilitation efforts within correctional facilities and prisons, with a focus on addressing behavioral issues, improving emotional well-being, and increasing inmates' chances of successful reintegration into society upon release. DeepSynaps will work closely with correctional facilities to tailor the package to meet the specific needs and requirements of their inmate population, ultimately contributing to safer and more rehabilitative correctional environments.</p>
              </div>
            </div>
        </div>
      </header>
    )
}

export default CorporateCorrectionalFacilityRehabilitationHeader