import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';
import {Link} from "react-router-dom";


const TrailMakingTest = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                        <div className={"row w-100"}>
                            <div className={"col-md-8 col-sm-12"}><h1>Trail Making Test (CAT-TMT)</h1></div>
                            <div className={"col-md-4 col-sm-12 text-md-end text-sm-left pt-1"}><Link target={"_blank"} className={"btn btn-outline-dark"} to={'https://www.cognifit.com/gb/battery-of-tests/trail-making-test'}>Start test now</Link></div>
                        </div>
                        <h1></h1>
                        <p>Navigate through numerical and alphabetical sequences to evaluate visual attention and task-switching capabilities.</p>
                        <p><strong>Relation to QEEG, Neurofeedback, Biofeedback, and Neuromodulation</strong></p>
                        <ul>
                            <li><strong>Electrode Places & Brain Region:</strong>
                                <ul>
                                    <li>Prefrontal cortex, Parietal lobes</li>
                                    <li>Broadmann Areas: 9, 10, 39, 40</li>
                                    <li>QEEG: Particularly observed through Fz, Fp1, Fp2, Pz, P3, P4 electrode placements.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Neurofeedback Protocols:</strong>
                                <ul>
                                    <li>
                                        <strong>Enhancing Cognitive Flexibility:</strong>
                                        <ul>
                                            <li>Targeting theta/beta ratio at frontal sites (Fz, F3, F4).</li>
                                            <li>Lowering theta power while enhancing low beta at Pz.</li>
                                        </ul>
                                    </li>

                                    <li>
                                        <strong>Improving Processing Speed:</strong>
                                        <ul>
                                            <li>Alpha asymmetry training could be explored, focusing on P3 and P4.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Biofeedback Protocols:</strong>
                                <ul>
                                    <li>
                                        <strong>Stress & Anxiety Management:</strong>
                                        <ul>
                                            <li>Heart Rate Variability (HRV) training to enhance emotional regulation.</li>
                                            <li>Skin Conductance Level (SCL) feedback for anxiety and stress management during testing.</li>
                                        </ul>
                                    </li>

                                    <li>
                                        <strong>Focus & Attention:</strong>
                                        <ul>
                                            <li>EMG biofeedback to ensure physical relaxation and mitigate muscle tension affecting concentration.</li>
                                            <li>Respiratory biofeedback to establish a balanced breathing pattern, supporting cognitive performance.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Neuromodulation Protocols:</strong>
                                <ul>
                                    <li>
                                        <strong>Transcranial Direct Current Stimulation (tDCS):</strong>
                                        <ul>
                                            <li>Anodal stimulation to the dorsolateral prefrontal cortex (DLPFC; roughly under F3/F4) to potentially enhance executive functions.</li>
                                            <li>Cathodal stimulation over the dominant motor cortex (C3/C4) may be explored to mitigate impulsive errors.</li>
                                        </ul>
                                    </li>

                                    <li>
                                        <strong>Photobiomodulation (PBM):</strong>
                                        <ul>
                                            <li>Targeting the prefrontal areas to promote optimal functioning and potentially support neuronal health.</li>
                                            <li>Considering intranasal PBM to potentially influence the deeper, midline brain structures which may be involved in attention and executive functions.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Connection to Mental Health:</strong>
                                <ul>
                                    <li>
                                        <strong>Anxiety and Stress (assessed via DASS):</strong>
                                        <ul>
                                            <li>Could impair performance due to reduced attentional capacity and increased impulsivity.</li>
                                            <li>Implementation of stress-reducing interventions, such as relaxation training through biofeedback, might be beneficial.</li>
                                        </ul>
                                    </li>

                                    <li>
                                        <strong>Sleep Quality:</strong>
                                        <ul>
                                            <li>Poor sleep, as measured through Sleep Scale assessments, can impede cognitive functions like attention and processing speed.</li>
                                            <li>Neurofeedback protocols aimed at enhancing sleep quality and PBM for potentially supporting circadian rhythm regulation might be beneficial.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p><strong>Notes:</strong> The application and efficacy of these interventions can vary among individuals. It is crucial to align protocols with thorough individual assessments and continuously monitor and adjust interventions as needed. Tailoring interventions based on ongoing assessment and feedback is vital to achieving desired outcomes in cognitive and mental health enhancements.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default TrailMakingTest