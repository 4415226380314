import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';
import { Link } from 'react-router-dom';


const DistanceEstimationTest = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                        <h1></h1>
                        <div className={"row w-100"}>
                            <div className={"col-md-8 col-sm-12"}><h1>Distance Estimation Test</h1></div>
                            <div className={"col-md-4 col-sm-12 text-md-end text-sm-left pt-1"}>
                                <Link target={"_blank"} className={"btn btn-outline-dark"} to={'https://www.cognifit.com/battery-of-tests/est-iii-test/estimation-test'}>Start test now</Link>
                            </div>
                        </div>

                        <p>To evaluate an individual’s spatial reasoning and the ability to estimate distances, implicating skills in spatial awareness, perception, and cognitive estimation.</p>
                        <p><strong>Relation to QEEG, Neurofeedback, Biofeedback, and Neuromodulation:</strong></p>
                        <ul>
                            <li><strong>Brain Region:</strong> Parietal Lobe, Occipital Lobe.</li>
                            <li><strong>Brodmann Area:</strong> BA 7 (Parietal), BA 18/19 (Occipital).</li>
                            <li><strong>EEG Electrode Places:</strong> P3, P4, Pz, Oz, O1, O2</li>
                            <li>
                                <strong>Neurofeedback:</strong>
                                <ul>
                                    <li><strong>Protocol:</strong> Targeting theta (4-8 Hz) for enhancing spatial reasoning and beta (12-30 Hz) for cognitive processing.</li>
                                    <li><strong>Objective:</strong> To enhance and optimize neural network functioning in brain areas associated with spatial awareness and cognitive estimation.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Biofeedback:</strong>
                                <ul>
                                    <li><strong>Assessment:</strong> Monitor Heart Rate Variability (HRV) and Galvanic Skin Response (GSR) to assess physiological responses related to cognitive stress or load during distance estimation tasks.</li>
                                    <li><strong>Protocol:</strong> Implementing strategies to manage physiological responses that may impede optimal spatial reasoning and estimation.</li>
                                    <li><strong>Objective:</strong> Ensure the physiological state is conducive to effective cognitive processing and decision-making during distance estimation tasks.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Neuromodulation:</strong>
                                <ul>
                                    <li>
                                        <strong>tDCS:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Parietal and Occipital Lobes.</li>
                                            <li><strong>Protocol:</strong> Using anodal tDCS to stimulate areas involved in spatial reasoning and visual perception.</li>
                                            <li><strong>Objective:</strong> Enhance neural activity in regions crucial for spatial perception and cognitive estimation related to distance.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>PBM:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Parietal and Occipital Lobes.</li>
                                            <li><strong>Protocol:</strong> Employing photobiomodulation to foster neural health and activity within networks pertinent to spatial awareness and cognitive estimation.</li>
                                            <li><strong>Objective:</strong> To nurture and potentially elevate neural functionality in regions relevant to distance estimation.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Mental Health Assessments and Their Relation:</strong>
                                <ul>
                                    <li><strong>DASS 21/42:</strong> Levels of depression, anxiety, and stress can potentially influence cognitive performance, potentially impacting an individual's accuracy and efficiency in distance estimation tasks.</li>
                                    <li><strong>Personality Scale:</strong> Personality traits might correlate with an individual’s approach to estimation tasks; for example, individuals high in neuroticism may second-guess their estimates, while those high in conscientiousness may be more systematic and accurate in their estimations.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>Understanding individual differences, cognitive strategies, and mental health context is crucial for tailoring neurofeedback, biofeedback, and neuromodulation interventions. These interventions should always be conducted by a qualified healthcare professional to ensure efficacy and safety.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default DistanceEstimationTest