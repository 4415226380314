import React from 'react'
import './whoWeAreAbout.css'

const WhoWeAreAbout = () => {
  
  const handleRedirect = () => {
    // Redirect page to /contact
    window.location.href = '/contact';
  };

  return (
    <>
      <div id="whoWeAre" className="wrapper d-flex justify-content-center align-items-center">
        <div className="whoWeAre-content-container d-flex flex-row align-items-center justify-content-between">
          <article className='d-flex flex-column '>
            <h1>Who We Are</h1>
            <p>Embarking on an uncharted journey into the corridors of the human mind, DeepSynaps specializes in conceiving personalized health and cognitive optimization strategies. Our multifaceted offerings—from AI-powered QEEG brain scans and multifarious body scanning technologies to nuanced cognitive assessments and pioneering neurological enhancement techniques—serve as the heartbeat of our operations. Our revered team of neuroscientists, AI technologists, and mental health professionals breathe life into our commitment, crafting tailored neurological experiences and mental health solutions with a touch of compassionate understanding and scientific rigor.</p>
          </article>
          <button className='whoWeAre-btn' onClick={handleRedirect}>Contact Us</button>
        </div>
      </div>
    </>
  )
}

export default WhoWeAreAbout