import React from 'react'

const HeaderProducts = () => {
  return (
    <>
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Products</h1>
                <p>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
            </article>
            <div className="breakline-diagnostic"></div>
        </header>
        <br />
        <br />
        <br />
    </>
  )
}

export default HeaderProducts