import React, { useState } from 'react'
import './headerTreatments.css'

const HeaderNeurofeedback = () => {
  const [showDiagnostic1, setShowDiagnostic1] = useState(true)
  const [showDiagnostic2, setShowDiagnostic2] = useState(false)
  const [showDiagnostic3, setShowDiagnostic3] = useState(false)
  const [showDiagnostic4, setShowDiagnostic4] = useState(false)
  const [showDiagnostic5, setShowDiagnostic5] = useState(false)
  const [showDiagnostic6, setShowDiagnostic6] = useState(false)

  const showSection1 = () => { // Showing 1st element
    setShowDiagnostic1(true)
    setShowDiagnostic2(false)
    setShowDiagnostic3(false)
    setShowDiagnostic4(false)
    setShowDiagnostic5(false)
    setShowDiagnostic6(false)
  }

  const showSection2 = () => { // Showing 2nd element
    setShowDiagnostic1(false)
    setShowDiagnostic2(true)
    setShowDiagnostic3(false)
    setShowDiagnostic4(false)
    setShowDiagnostic5(false)
    setShowDiagnostic6(false)
  }

  const showSection3 = () => { // Showing 3rd element
    setShowDiagnostic1(false)
    setShowDiagnostic2(false)
    setShowDiagnostic3(true)
    setShowDiagnostic4(false)
    setShowDiagnostic5(false)
    setShowDiagnostic6(false)
  }

  const showSection4 = () => { // Showing 4th element
    setShowDiagnostic1(false)
    setShowDiagnostic2(false)
    setShowDiagnostic3(false)
    setShowDiagnostic4(true)
    setShowDiagnostic5(false)
    setShowDiagnostic6(false)
  }

  const showSection5 = () => { // Showing 4th element
    setShowDiagnostic1(false)
    setShowDiagnostic2(false)
    setShowDiagnostic3(false)
    setShowDiagnostic4(false)
    setShowDiagnostic5(true)
    setShowDiagnostic6(false)
  }


  const showSection6 = () => { // Showing 4th element
    setShowDiagnostic1(false)
    setShowDiagnostic2(false)
    setShowDiagnostic3(false)
    setShowDiagnostic4(false)
    setShowDiagnostic5(false)
    setShowDiagnostic6(true)
  }

  return (
    <>
      <header id='headerDiagnostic' className='d-flex flex-column'>
        <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
          <h1>Interventions</h1>
          <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
          <a className='navbar-Respbtn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
        </article>
        <div className="breakline-diagnostic"></div>


        <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
          <div className="headerDiagnostic-options d-flex flex-column align-items-start">
            <button className={showDiagnostic1 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection1}>What is Neurofeedback?</button>
            <button className={showDiagnostic2 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection2}>Our Comprehensive Neurofeedback Services</button>
            <button className={showDiagnostic3 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection3}>Who Can Benefit From Neurofeedback?</button>
            <button className={showDiagnostic4 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection4}>Your Neurofeedback Journey: What to Expect</button>
            <button className={showDiagnostic5 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection5}>Preparation Guide for Neurofeedback Session</button>
            <button className={showDiagnostic6 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection6}>Why Choose Us?</button>
          </div>
          {showDiagnostic1 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle3 d-flex flex-column align-items-start">
                <h1>What is Neurofeedback?</h1>
                <p>Neurofeedback, a subset of biofeedback, specifically targets and trains the brain. By monitoring brainwave activity, we can provide real-time feedback to the user. This allows individuals to learn how to self-regulate certain brain functions, aiming to enhance cognitive abilities, emotional regulation, and overall brain performance.</p>
              </div>
            </div>
          }{showDiagnostic2 &&
            <div className="headerDiagnostic-content">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>Our Comprehensive Neurofeedback Services</h1>
                <div className="howItWorksOL">
                  <p>With our state-of-the-art equipment, we provide tailored neurofeedback sessions based on individual needs:</p>
                  <p><b>1. qEEG Brain Mapping:</b> A diagnostic tool that captures and assesses electrical patterns of the brain, helping to tailor neurofeedback interventions.</p>
                  <p><b>2. Brainwave Training:</b> Target specific brainwave frequencies to aid in conditions like ADHD, anxiety, and more.</p>
                  <p><b>3. Deep State Training:</b> Promote relaxation and manage conditions like PTSD or chronic pain.</p>
                  <p><b>4. Peak Performance Training:</b> Ideal for athletes, artists, and executives seeking enhanced cognitive performance.</p>
                </div>
              </div>
            </div>
          }{showDiagnostic3 &&
            <div className="headerDiagnostic-content">
              <div className="diagnosticArticle3 d-flex flex-column align-items-start">
                <h1>Who Can Benefit From Neurofeedback?</h1>
                <div className="conditionsUL d-flex flex-row">
                  <div className="workingAreas">
                    <p>A wide spectrum of individuals can harness the advantages of neurofeedback:</p>
                    <p><b>Individuals with ADHD:</b> Enhance focus and reduce hyperactivity.</p>
                    <p><b>Anxiety & Depression Patients:</b> Manage symptoms and promote mental well-being.</p>
                    <p><b>Brain Injury Survivors:</b> Aid in recovery and cognitive restoration.</p>
                    <p><b>Elderly with Memory Concerns:</b> Enhance memory and slow cognitive decline.</p>
                    <p><b>Performance Enhancement Seekers:</b> Elevate cognitive functions and focus.</p>
                    <p> ... and many more!</p>
                  </div>
                </div>
              </div>
            </div>
          }{showDiagnostic4 &&
            <div className="headerDiagnostic-content">
              <div className="diagnosticArticle4 d-flex flex-column align-items-start">
                <h1>Your Neurofeedback Journey: What to Expect</h1>
                <p><b>1. Initial Brain Mapping:</b> A qEEG session to understand your brain's current electrical patterns.</p>
                <p><b>2. Tailored Neurofeedback Sessions:</b> Based on your qEEG results, we'll have sessions targeting specific brainwaves or regions.</p>
                <p><b>3. During The Session:</b> You'll be seated comfortably while we monitor your brainwave activity. Feedback is typically provided through visual or auditory cues.</p>
                <p><b>4. Post-Session Analysis:</b> Discuss the session's outcomes and potential adjustments for subsequent sessions.</p>
              </div>
            </div>
          }
          {showDiagnostic5 &&
              <div className="headerDiagnostic-content">
                <div className="diagnosticArticle4 d-flex flex-column align-items-start">
                  <h1>Preparation Guide for Neurofeedback Session</h1>
                  <p><b>Cleanliness:</b> Ensure scalp is clean and free from heavy oils or products.</p>
                  <p><b>Clothing:</b> Wear comfortable attire, avoiding metal components.</p>
                  <p><b>Diet:</b> Refrain from caffeine or nicotine 3 hours prior. Consume a balanced, light meal/snack.</p>
                  <p><b>Medications:</b> Update us about any neurological or psychiatric medications.</p>
                  <p><b>Rest:</b> Being well-rested ensures accurate readings.</p>
                  <p><b>Mental State:</b> Approach with curiosity and an open mind.</p>
                </div>
              </div>
          }
          {showDiagnostic6 &&
              <div className="headerDiagnostic-content">
                <div className="diagnosticArticle4 d-flex flex-column align-items-start">
                  <h1>Why Choose Us?</h1>
                  <p>Our top-tier neurofeedback system allows us to provide an in-depth view of your brain's functioning. Whether you're seeking enhanced mental performance or therapeutic interventions, we are dedicated to your cognitive well-being.</p>
                  <p>For questions or more details, reach out to us at <a href="mailto: info@deepsynaps.com">info@deepsynaps.com</a>.</p>
                  <p>Embark on a journey of cognitive enhancement and deeper brain understanding with us! Discover the limitless potential your brain holds.</p>
                </div>
              </div>
          }
        </div>
      </header>
    </>
  )
}

export default HeaderNeurofeedback