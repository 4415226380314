import { React, useState } from 'react'
import '../../CognitiveTests/Header/cognitiveTestHeader.css'
import learnMore from '../../CognitiveTests/Header/learnMore.module.css'
import { Assesment1, Assesment2, Assesment3, Assesment4, Assesment5, Assesment6, Assesment7, Assesment8, Assesment9, Assesment10, Assesment11, Assesment12, Assesment13, Assesment14, Assesment15, Assesment16, Assesment17} from '../../../assets/images';
import {Link} from "react-router-dom";

const GeneralCognitiveAssessmentHeader = () => {
  
  const [showDiagnostic1, setShowDiagnostic1] = useState(true)
  const [showDiagnostic2, setShowDiagnostic2] = useState(false)
  const [showDiagnostic3, setShowDiagnostic3] = useState(false)
  const [showDiagnostic4, setShowDiagnostic4] = useState(false)

  const showWhatItIs = () => { // Showing 1st element
    setShowDiagnostic1(true)
    setShowDiagnostic2(false)
    setShowDiagnostic3(false)
    setShowDiagnostic4(false)
  }

  const showHowItWorks = () => { // Showing 2nd element
    setShowDiagnostic1(false)
    setShowDiagnostic2(true)
    setShowDiagnostic3(false)
    setShowDiagnostic4(false)
  }

  const showConditions = () => { // Showing 3rd element
    setShowDiagnostic1(false)
    setShowDiagnostic2(false)
    setShowDiagnostic3(true)
    setShowDiagnostic4(false)
  }

  const showBenefits = () => { // Showing 4th element
    setShowDiagnostic1(false)
    setShowDiagnostic2(false)
    setShowDiagnostic3(false)
    setShowDiagnostic4(true)
  }

  const cogniFitAssesments = [
    {
      assesmentImg: Assesment1,
      h5: 'General Cognitive Assessment (CAB)',
      p: 'How can I know if my brain is healthy?',
      learnBtn: 'CAB',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment2,
      h5: '65 and Over Cognitive Assessment (CAB-AG)',
      p: 'Test how fast is your brain aging',
      learnBtn: 'CABAG',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment3,
      h5: 'Academic Cognitive Assessment (CAB-A)',
      p: 'Cognitive test that was created specifically for schools',
      learnBtn: 'CABA',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment4,
      h5: 'Concentration Cognitive Assessment (CAB-AT)',
      p: 'How good is your concentration?',
      learnBtn: 'CABAT',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment5,
      h5: 'Coordination Cognitive Assessment (CAB-CO)',
      p: 'How good is your coordination?',
      learnBtn: 'CABCO',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment6,
      h5: 'Deary-Liewald Task (CAT-DL)',
      p: `Measuring the user's reaction time in simple situations`,
      learnBtn: 'CATDL',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment7,
      h5: 'Driving Cognitive Assessment (DAB)',
      p: 'Are You Fit To Drive?',
      learnBtn: 'DAB',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment8,
      h5: `Eriksen's Flanker Task (CAT-EF)`,
      p: 'Are You Fit To Drive?',
      learnBtn: 'CATEF',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment9,
      h5: 'Inhibition of Return Task (CAT-IOR)',
      p: 'This task seeks to measure how much interference is generated by the presentation of adjacent incongruent and irrelevant visual information.',
      learnBtn: 'CATIOR',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment10,
      h5: 'Memory Cognitive Assessment (CAB-ME)',
      p: 'How good is your memory?',
      learnBtn: 'CABME',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment11,
      h5: 'Perception Cognitive Assessment (CAB-PC)',
      p: 'How good is your perception?',
      learnBtn: 'CABPC',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment12,
      h5: 'Reading Comprehension Cognitive Assessment (CAB-RC)',
      p: 'Do you have reading difficulties?',
      learnBtn: 'CABRC',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment13,
      h5: 'Reasoning Cognitive Assessment (CAB-RS)',
      p: 'How good is your reasoning?',
      learnBtn: 'CABRS',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment14,
      h5: 'Simon Task (CAT-ST)',
      p: 'Identify how much interference is produced when spatial information (word position) conflicts with semantic information (word meaning).',
      learnBtn: 'CATST',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment15,
      h5: 'Tower of Hanoi (CAT-TOH)',
      p: 'Identify how much interference is produced in the user when spatial information (word position) conflicts with semantic information',
      learnBtn: 'CATTOH',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment16,
      h5: 'Trail Making Test (CAT-TMT)',
      p: 'Cognitive abilities mainly related to executive functions such as planning, visual imagery, abstract thinking, working memory',
      learnBtn: 'CATTMT',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment17,
      h5: 'Visual Episodic Memory Test (CAT-VEM)',
      p: 'Process speed, cognitive flexibility, the ability to make an effective visual scan, as well as other underlying executive functions.',
      learnBtn: 'CATVEM',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    }
  ]

  const firstHalf = cogniFitAssesments.slice(0, 9); // İlk 10 öğeyi alır
  const secondHalf = cogniFitAssesments.slice(9); // 11. öğeden sonuncu öğeye kadar olanları alır

  // const firstHalf = cogniFitAssesments.slice(0, cogniFitAssesments.length / 2);
  // const secondHalf = cogniFitAssesments.slice(cogniFitAssesments.length / 2);

  return (
    <header id='headerDiagnostic' className='d-flex flex-column'>
      <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
        <h1>General Cognitive Assessment</h1>
        <div className='d-flex flex-column position-relative'>
          <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
          <br /> 
          {/* Button trigger modal */}
          <button type="button" className="cognifitTest btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">Book a Test</button>
          {/*<a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>*/}

          {/* Cognitive tests Modal */}
          <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title modal-titleTest" id="exampleModalLabel">Book an Online Tests</h4>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div className="modal-body-content">  
                    <div className="assesment-container d-flex flex-column align-items-start">
                      {firstHalf.map((assesment, index) => (
                        <div className="assesment" key={index}>
                          <img className='assesmentImg' src={assesment.assesmentImg} alt='' />
                          <div className='assesmentArticle'>
                            <h5>{assesment.h5}</h5>
                            <p>{assesment.p}</p>
                          </div>
                          <div className="assesment-btnContainer">
                            {/* <a className='learnMore-btn' href={assesment.learnBtn}>Learn More</a> */}
                            <button type="button" className='learnMore-btn' data-bs-toggle="modal" data-bs-target={`#${assesment.learnBtn}`}>Learn More</button>
                            <a className='start-btn' href={assesment.startBtn} target='_blank' rel="noopener noreferrer">Start</a>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="assesment-container d-flex flex-column align-items-start">
                      {secondHalf.map((assesment, index) => (
                        <div className="assesment" key={index}>
                          <img className='assesmentImg' src={assesment.assesmentImg} alt='' />
                          <div className='assesmentArticle'>
                            <h5>{assesment.h5}</h5>
                            <p>{assesment.p}</p>
                          </div>
                          <div className="assesment-btnContainer">
                            {/* <a className='learnMore-btn' href={assesment.learnBtn}>Learn More</a> */}
                            <button type="button" className='learnMore-btn' data-bs-toggle="modal" data-bs-target={`#${assesment.learnBtn}`}>Learn More</button>
                            <a className='start-btn' href={assesment.startBtn} target='_blank' rel="noopener noreferrer">Start</a>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Modal ends */}
          {/* --------------------LearnMore Modals---------- */}
          {/* Assesment1 */}
          <div className="modal fade" id="CAB" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">General Cognitive Assessment (CAB)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment1} alt='General Cognitive Assessment (CAB)' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>The General Assessment Battery (CAB) helps healthcare professionals precisely evaluate a wide range of abilities and detect cognitive well-being (high-moderate-low) in patients. With this tool, the health professional can identify strengths and weaknesses in the areas of memory, concentration/attention, executive functions, planning, and coordination, as well as, discover the general cognitive profile of the patient. This test is suitable for children 7+, teens, adults or seniors. The battery can be used cross-culturally, and for both males and females.</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assesment2 */}
          <div className="modal fade" id="CABAG" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">65 and Over Cognitive Assessment (CAB-AG)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment2} alt='65 and Over Cognitive Assessment (CAB-AG)' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>The 65 and Over Cognitive Assessment (CAB-AG) assesses the cognitive processes that are influenced by aging. Using this too, healthcare professionals can carry out a complete cognitive screening, understand the cognitive strengths and weaknesses of the patient/user. This assessment takes 13-16 minutes to complete. This assessment is suitable for adults and seniors over the age of 65. The assessment can be used cross-culturally, and for both men and women.</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assesment4 */}
          <div className="modal fade" id="CABAT" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Concentration Cognitive Assessment (CAB-AT)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment4} alt='Concentration Cognitive Assessment (CAB-AT)' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>The Concentration Cognitive Assessment (CAB-AT) studies brain function, performs a complete cognitive screening and assesses the cognitive processes that influence concentration. This assessment takes 15-20 minutes to complete This assessment is suitable for children over 7 years of age, youth, adults, or seniors. The assessment can be used cross-culturally, and for both men and women.</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assesment5 */}
          <div className="modal fade" id="CABCO" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Coordination Cognitive Assessment (CAB-CO)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment5} alt='Coordination Cognitive Assessment (CAB-CO)' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>The Coordination Cognitive Assessment (CAB-CO) is a scientific tool that allows healthcare professionals to carry out a complete screening, understand the cognitive strengths and weaknesses related to coordination, and evaluate motor-related ability. This assessment takes 20-30 minutes to complete This assessment is suitable for children over 7 years of age, youth, adults, or seniors. The assessment can be used cross-culturally, and for both men and women.</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assesment6 */}
          <div className="modal fade" id="CATDL" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Deary-Liewald Test (CAT-DL)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment6} alt='Deary-Liewald Test (CAT-DL)' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>The performance of the task will allow measuring the user’s reaction time in simple situations (a single button response) and in more complex situations (four alternative responses). The difference in accuracy and reaction time between the two conditions allows for isolating the “choice effect”. Evaluated cognitive abilities: Reaction time and processing speed.</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assesment7 */}
          <div className="modal fade" id="DAB" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Driving Cognitive Assessment (DAB)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment7} alt='' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>The Driving Cognitive Assessment (DAB) helps the healthcare professional evaluate the cognitive/ psychological processes involved in driving and assesses the driver&apos;s skills, abilities and performance. Healthcare professionals use this assessment to evaluate the risk level or accident tendency index. This assessment takes 30-40 minutes to complete. This assessment is appropriate for adolescents looking to obtain their license, as well as adults and seniors. The battery can be used cross-culturally, and for both males and females. It can be used to assess teens learning how to drive, driving adults, and seniors.</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assesment8 */}
          <div className="modal fade" id="CATEF" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Eriksen's Flanker Task (CAT-EF)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment8} alt="Eriksen's Flanker Task (CAT-EF)" />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>Eriksen's Flanker Test is a digitized replica of the task of the same name (Eriksen and Eriksen, 1974). This task seeks to measure how much interference is generated by the presentation of adjacent incongruent and irrelevant visual information. The difference in reaction time between incongruent and congruent trials responded to correctly is what is known as the Flankers Effect.</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assesment9 */}
          <div className="modal fade" id="CATIOR" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Inhibition of Return Test (CAT-IOR)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment9} alt='Inhibition of Return Test (CAT-IOR)' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>Inhibition of return (IOR) test is a digitized replica of the homonymous task (Posner & Cohen, 1984). This task allows the assessment of the phenomenon known as "inhibition of return", which consists of people responding more slowly to stimuli located in the position where at least 300 milliseconds before a task-irrelevant stimulus was shown.</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assesment10 */}
          <div className="modal fade" id="CABME" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Memory Cognitive Assessment (CAB-ME)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment10} alt='Memory Cognitive Assessment (CAB-ME)' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>The Memory Cognitive Assessment (CAB-ME) assesses the cognitive processes that influence the different types of memory. Healthcare professionals can use this evaluation to quickly and accurately assess the cognitive processes associated with memory. This assessment takes 15-20 minutes to complete. This assessment is suitable for children over 7 years of age, youth, adults, or seniors. The assessment can be used cross-culturally, and for both men and women.</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assesment11 */}
          <div className="modal fade" id="CABPC" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Perception Cognitive Assessment (CAB-PC)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment11} alt='Perception Cognitive Assessment (CAB-PC)' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>The Perception Cognitive Assessment (CAB-PC) helps healthcare professionals to carry out a complete cognitive screening, to understand the weaknesses and strengths related to the functioning of perception. This assessment takes 20-30 minutes to complete. This assessment is suitable for children over 7 years of age, youth, adults, or seniors. The assessment can be used cross-culturally, and for both men and women.</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assesment12 */}
          <div className="modal fade" id="CABRC" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Reading Comprehension Cognitive Assessment (CAB-RC)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment12} alt='Reading Comprehension Cognitive Assessment (CAB-RC)' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>The Reading Comprehension Cognitive Assessment (CAB-RC) is a leading professional tool that allows to understand, through digitized neuropsychological tasks, the state of the different mental abilities related to reading comprehension. The results obtained in this test show relevant information about the state of the cognitive skills involved in reading comprehension (low-medium-high). This assessment takes 10-15 minutes to complete. This assessment is suitable for children over 7 years of age, youth, adults, or seniors. The assessment can be used cross-culturally, and for both men and women.</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assesment13 */}
          <div className="modal fade" id="CABRS" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Reasoning Cognitive Assessment (CAB-RS)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment13} alt='Reasoning Cognitive Assessment (CAB-RS)' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>The Reasoning Cognitive Assessment (CAB-RS) tool helps healthcare professionals quickly and accurately assess the cognitive processes associated with executive functions. This test allows us to carry out a complete cognitive analysis to understand the weaknesses and strengths of the functions related to reasoning. This assessment takes 20-25 minutes to complete. This assessment is suitable for children over 7 years of age, youth, adults, or seniors. The assessment can be used cross-culturally, and for both men and women.</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assesment14 */}
          <div className="modal fade" id="CATST" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Simon Task (CAT-ST)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment14} alt='Simon Task (CAT-ST)' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>Simon Task is a digitized replica of the task of the same name (Simon and Wolf, 1963). The performance of the task will allow identifying the difference between the reaction time between incongruent and congruent trials that have been answered correctly, which is known as the Simon Effect. This allows us to identify how much interference is produced in the user when spatial information (word position) conflicts with semantic information (word meaning).</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assesment15 */}
          <div className="modal fade" id="CATTOH" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Tower of Hanoi (CAT-TOH)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment15} alt='Tower of Hanoi (CAT-TOH)' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>Tower of Hanoi Test (TOH) is a digitized replica of the task of the same name (Hinz, 1989). This task seeks to measure high-order cognitive problem solving and learning of complex cognitive procedures, providing information about the cognitive abilities mainly related to executive functions such as planning, visual imagery, abstract thinking, working memory and self-monitoring.</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assesment16 */}
          <div className="modal fade" id="CATTMT" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Trail Making Test (CAT-TMT)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment16} alt='Trail Making Test (CAT-TMT)' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>Trail Making Test (TMT) is a digitized replica of the task of the same name (Reitan, 1955; Reitan, 1958). This task tests processing speed, cognitive flexibility, the ability to make an effective visual scan, as well as other underlying executive functions.</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assesment17 */}
          <div className="modal fade" id="CATVEM" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Visual Episodic Memory Test (CAT-VEM)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment17} alt='Visual Episodic Memory Test (CAT-VEM)' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>Visual Episodic Memory Test is a version of the Memtrax memory assessment task (Ashford, 2005). This task seeks to measure episodic memory through items of a visual nature, identifying only whether the displayed image has been previously displayed or not.</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  
          <div className="modal fade" id="" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel"></h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={} alt='' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}></p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          */}
        </div>
      </article>
      <div className="breakline-diagnostic"></div>

      <div className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
        <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      </div>

      <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
        <div className="headerDiagnostic-options d-flex flex-column align-items-start">
          <button className={showDiagnostic1 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showWhatItIs}>What it is?</button>
          <button className={showDiagnostic2 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showHowItWorks}>How it works?</button>
          <button className={showDiagnostic3 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showConditions}>Skills</button>
          <button className={showDiagnostic4 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showBenefits}>Benefits</button>
        </div>
        {showDiagnostic1 && 
          <div className="headerDiagnostic-content d-flex flex-row align-items-start">
            <div className="diagnosticArticle2 d-flex flex-column align-items-start">
              <h1>What it is?</h1>
              <p>Cognitive  assessments are designed to measure a variety of cognitive skills, including attention, memory, processing speed, and executive function. Cognitive  assessments are used by a variety of people, including:</p>
              <ul>
                <li><b>Healthcare professionals:</b> Cognitive  assessments can be used by healthcare professionals to assess cognitive function in people with a variety of conditions, including Alzheimer's disease, ADHD, and traumatic brain injury.</li>
                <li><b>Organizations:</b> Cognitive  assessments can be used by organizations to assess cognitive function in their employees. This can be helpful for identifying employees who may be at risk for developing cognitive problems, as well as for tracking the cognitive health of employees over time.</li>
                <li><b>Individuals:</b> Cogniitive  assessments can be used by individuals to assess their own cognitive function. This can be helpful for people who are concerned about their cognitive health, as well as for people who are looking for ways to improve their cognitive function.</li>
              </ul>
              <p>Cognitive  assessments are based on a scientific foundation. They have been developed by a team of experts in neuroscience, psychology, and education. Cognitive assessments are also validated. This means that they have been shown to be accurate and reliable.</p>
            </div>
          </div>
        }{showDiagnostic2 && 
          <div className="headerDiagnostic-content">
            <div className="diagnosticArticle2 d-flex flex-column align-items-start">
              <h1>How it works?</h1>
              <p><b>Cognitive assessments work by using a variety of methods to measure cognitive function. These methods include:</b></p>
              <ul>
                <li><b>Computerized tasks:</b> Cognitive  assessments uses a variety of computerized tasks to measure cognitive function. These tasks are designed to assess a variety of cognitive skills, including attention, memory, processing speed, and executive function.</li>
                <li><b>Neuroimaging:</b> Cognitive assessments  uses neuroimaging to measure brain activity. This information can be used to identify areas of the brain that are involved in cognitive function.</li>
                <li><b>Self-report:</b> Cognitive assessments  also uses self-report to measure cognitive function. This involves asking the individual to complete a questionnaire about their cognitive abilities.</li>
                <li>The results of cognitive assessments are used to create a personalized cognitive profile. This profile can be used to identify areas where the individual needs to improve, as well as to track their progress over time.</li>
              </ul>
              <p><b>Cognitive assessments are a safe and effective way to measure cognitive function. They are used by a variety of people, including healthcare professionals, organizations, and individuals.<br /><br />Here are some of the cognitive skills that  cognitive assessments measure:</b></p>
              <ul>
                <li><b>Attention:</b> This includes the ability to focus on a task and to resist distractions.</li>
                <li><b>Memory:</b> This includes the ability to store and recall information.</li>
                <li><b>Processing speed:</b> This includes the ability to think quickly and to make decisions.</li>
                <li><b>Executive function:</b> This includes the ability to plan, organize, and manage tasks.</li>
                <li>cognitive assessments can be used to improve cognitive function. The assessments are designed to challenge the individual and to help them to improve their performance. Over time, this can lead to improvements in cognitive function.</li>
              </ul>
              <iframe className='embed-ytb howItWorks-ytb' src="https://www.youtube-nocookie.com/embed/is6WYnb280U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>
          </div>
        }{showDiagnostic3 &&
          <div className="headerDiagnostic-content">
            <div className="diagnosticArticle3 d-flex flex-column align-items-start">
              <h1>Skills</h1>
              <p><b>Cognitive assessments measure a variety of cognitive skills, including:</b></p>
              <ul>
                <li><b>Attention:</b> This includes the ability to focus on a task and to resist distractions.</li>
                <li><b>Memory:</b> This includes the ability to store and recall information.</li>
                <li><b>Processing speed:</b> This includes the ability to think quickly and to make decisions.</li>
                <li><b>Executive function:</b> This includes the ability to plan, organize, and manage tasks.</li>
                <li><b>Coordination:</b> This includes the ability to control the body's movements.</li>
                <li><b>Visual-spatial reasoning:</b> This includes the ability to understand and visualize spatial relationships.</li>
                <li><b>Language:</b> This includes the ability to understand and use language.</li>
                <li><b>Working memory:</b> This includes the ability to hold information in mind while working on a task.</li>
              </ul>
              <p><b>Cognitive assessments can be used to measure a variety of conditions, including:</b></p>
              <ul>
                <li><b>Alzheimer's disease:</b> This is a neurodegenerative disease that causes progressive memory loss and cognitive decline.</li>
                <li><b>ADHD:</b> This is a neurodevelopmental disorder that is characterized by inattention, hyperactivity, and impulsivity.</li>
                <li><b>Traumatic brain injury:</b> This is an injury to the brain that can cause a variety of cognitive problems, including memory loss, difficulty concentrating, and problems with decision-making.</li>
              </ul>
              <p>Cognitive assessments can also be used to measure cognitive function in healthy individuals. This can be helpful for identifying people who may be at risk for developing cognitive problems, as well as for tracking the cognitive health of healthy individuals over time.</p>
            </div>
          </div>
        }{showDiagnostic4 &&
          <div className="headerDiagnostic-content">
            <div className="diagnosticArticle4 d-flex flex-column align-items-start">
              <h1>Benefits</h1>
              <p><b>The benefits of using cognitive assessments include:</b></p>
              <ul style={{gap:'12px'}} className='d-flex flex-column'>
                <li><b>Accurate:</b> Cognitive assessments are accurate and reliable. They have been shown to be effective in measuring a variety of cognitive skills.</li>
                <li><b>Personalized:</b> Cognitive assessments are personalized. This means that they are tailored to the individual's specific needs and goals.</li>
                <li><b>Easy to use:</b> Cognitive assessments are easy to use. They can be completed online or on a mobile device.</li>
                <li><b>Fun:</b> Cognitive assessments are fun and engaging. This makes them more likely to be completed by the individual.</li>
                <li><b>Improve cognitive function:</b> cognitive assessments can be used to improve cognitive function. The assessments are designed to challenge the individual and to help them to improve their performance. Over time, this can lead to improvements in cognitive function.</li>
                <li><b>Identify areas for improvement:</b> cognitive assessments can help you to identify areas where you need to improve. This can be helpful for setting goals and for developing a plan to improve your cognitive function.</li>
                <li><b>Track progress over time:</b> cognitive assessments can be used to track your progress over time. This can help you to see how your cognitive function is changing, and it can also help you to stay motivated.</li>
              </ul>              
            </div>
          </div>
        }
      </div>

      <div className="wrapper w-100 justify-content-between align-items-start">
        <div className="accordion cognitiveAccordion" id="cognitiveAccordion">
          <div className="accordion-item">
            <h2 className="accordion-header" id="heading1">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1"
                      aria-expanded="true" aria-controls="collapse1">
                General Cognitive Assessment (CAB)
              </button>
            </h2>
            <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>General Cognitive Assessment (CAB)</h3>
                <p>The General Assessment Battery (CAB) helps healthcare professionals precisely evaluate a wide range of abilities and detect cognitive well-being (high-moderate-low) in patients. With this tool, the health professional can identify strengths and weaknesses in the areas of memory, concentration/attention, executive functions, planning, and coordination, as well as, discover the general cognitive profile of the patient. The General Assessment Battery (CAB) serves as an essential tool in the hands of healthcare professionals, aiming to deliver a comprehensive evaluation of a spectrum of cognitive capabilities, thereby determining the cognitive well-being spectrum from high to low. When used in tandem with various tools and therapies, it can provide a more comprehensive insight into cognitive functioning. This test is suitable for children 7+, teens, adults or seniors. The battery can be used cross-culturally, and for both males and females.</p>
                <p><Link to={'/general-cognitive-assessment/general-cognitive-assessment-cab'}>Learn more</Link></p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="heading2">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                55 and Over Cognitive Assessment (CAB-AG)
              </button>
            </h2>
            <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>55 and Over Cognitive Assessment (CAB-AG)</h3>
                <p>The 55 and Over Cognitive Assessment (CAB-AG) assesses the cognitive processes that are influenced by aging. Using this too, healthcare professionals can carry out a complete cognitive screening, understand the cognitive strengths and weaknesses of the patient/user. This assessment takes 13-16 minutes to complete.This assessment is suitable for adults and seniors over the age of 55. The assessment can be used cross-culturally, and for both men and women.</p>
                <p><Link to={'/general-cognitive-assessment/55-and-over-cognitive-assessment'}>Learn more</Link></p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="heading3">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                Concentration Cognitive Assessment (CAB-AT)
              </button>
            </h2>
            <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Concentration Cognitive Assessment (CAB-AT)</h3>
                <p>The Concentration Cognitive Assessment (CAB-AT) studies brain function, performs a complete cognitive screening and assesses the cognitive processes that influence concentration. This assessment takes 15-20 minutes to completeThis assessment is suitable for children over 7 years of age, youth, adults, or seniors. The assessment can be used cross-culturally, and for both men and women.</p>
                <p><Link to={'/general-cognitive-assessment/concentration-cognitive-assessment-cab-at'}>Learn more</Link></p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="heading4">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                Coordination Cognitive Assessment (CAB-CO)
              </button>
            </h2>
            <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Coordination Cognitive Assessment (CAB-CO)</h3>
                <p>The Coordination Cognitive Assessment (CAB-CO) is a scientific tool that allows healthcare professionals to carry out a complete screening, understand the cognitive strengths and weaknesses related to coordination, and evaluate motor-related ability. This assessment takes 20-30 minutes to complete. This assessment is suitable for children over 7 years of age, youth, adults, or seniors. The assessment can be used cross-culturally, and for both men and women.</p>
                <p><Link to={'/general-cognitive-assessment/coordination-cognitive-assessment'}>Learn more</Link></p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="heading6">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                Driving Cognitive Assessment (DAB)
              </button>
            </h2>
            <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading6"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Driving Cognitive Assessment (DAB)</h3>
                <p>The Driving Cognitive Assessment (DAB) helps the healthcare professional evaluate the cognitive/ psychological processes involved in driving and assesses the driver’s skills, abilities and performance. Healthcare professionals use this assessment to evaluate the risk level or accident tendency index. This assessment takes 30-40 minutes to complete.</p>
                <p><Link to={'/general-cognitive-assessment/driving-cognitive-assessment'}>Learn more</Link></p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="heading10">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                Memory Cognitive Assessment (CAB-ME)
              </button>
            </h2>
            <div id="collapse10" className="accordion-collapse collapse" aria-labelledby="heading10"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Memory Cognitive Assessment (CAB-ME)</h3>
                <p>The Memory Cognitive Assessment (CAB-ME) assesses the cognitive processes that influence the different types of memory. Healthcare professionals can use this evaluation to quickly and accurately assess the cognitive processes associated with memory. This assessment takes 15-20 minutes to complete.</p>
                <p>This assessment is suitable for children over 7 years of age, youth, adults, or seniors. The assessment can be used cross-culturally, and for both men and women.</p>
                <p><Link to={'/general-cognitive-assessment/memory-cognitive-assessment'}>Learn more</Link></p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="heading11">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse11" aria-expanded="false" aria-controls="collapse11">
                Perception Cognitive Assessment (CAB-PC)
              </button>
            </h2>
            <div id="collapse11" className="accordion-collapse collapse" aria-labelledby="heading11"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Perception Cognitive Assessment (CAB-PC)</h3>
                <p>The Perception Cognitive Assessment (CAB-PC) helps healthcare professionals to carry out a complete cognitive screening, to understand the weaknesses and strengths related to the functioning of perception. This assessment takes 20-30 minutes to complete.This assessment is suitable for children over 7 years of age, youth, adults, or seniors. The assessment can be used cross-culturally, and for both men and women.</p>
                <p><Link to={'/general-cognitive-assessment/perception-cognitive-assessment'}>Learn more</Link></p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="heading13">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse13" aria-expanded="false" aria-controls="collapse13">
                Reading Comprehension Cognitive Assessment (CAB-RC)
              </button>
            </h2>
            <div id="collapse13" className="accordion-collapse collapse" aria-labelledby="heading13"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Reading Comprehension Cognitive Assessment (CAB-RC)</h3>
                <p>The Reading Comprehension Cognitive Assessment (CAB-RC) is a leading professional tool that allows to understand, through digitized neuropsychological tasks, the state of the different mental abilities related to reading comprehension. The results obtained in this test show relevant information about the state of the cognitive skills involved in reading comprehension (low-medium-high). This assessment takes 10-15 minutes to complete.</p>
                <p>This assessment is suitable for children over 7 years of age, youth, adults, or seniors. The assessment can be used cross-culturally, and for both men and women.</p>
                <p><Link to={'/general-cognitive-assessment/reading-comprehension-cognitive-assessment'}>Learn more</Link></p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="heading14">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse14" aria-expanded="false" aria-controls="collapse14">
                Reasoning Cognitive Assessment (CAB-RS)
              </button>
            </h2>
            <div id="collapse14" className="accordion-collapse collapse" aria-labelledby="heading14"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Reasoning Cognitive Assessment (CAB-RS)</h3>
                <p>The Reasoning Cognitive Assessment (CAB-RS) tool helps healthcare professionals quickly and accurately assess the cognitive processes associated with executive functions. This test allows us to carry out a complete cognitive analysis to understand the weaknesses and strengths of the functions related to reasoning. This assessment takes 20-25 minutes to complete. This assessment is suitable for children over 7 years of age, youth, adults, or seniors. The assessment can be used cross-culturally, and for both men and women.</p>
                <p><Link to={'/general-cognitive-assessment/reasoning-cognitive-assessment'}>Learn more</Link></p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="heading22">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse22" aria-expanded="false" aria-controls="collapse22">
                ASRS
              </button>
            </h2>
            <div id="collapse22" className="accordion-collapse collapse" aria-labelledby="heading22"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>ASRS</h3>
                <p>It is composed of 18 questions aimed at identifying the self-perceived risk of ADHD.</p>
                <p>The Adult ADHD Self-Report Scale (ASRS) is a screening instrument consisting of 18 questions tailored to identify symptoms of ADHD in adults.</p>
                <p><Link to={'/general-cognitive-assessment/asrs'}>Learn more</Link></p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="heading23">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse23" aria-expanded="false" aria-controls="collapse23">
                GAD7
              </button>
            </h2>
            <div id="collapse23" className="accordion-collapse collapse" aria-labelledby="heading23"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>GAD7</h3>
                <p>It is composed of 7 questions aimed at identifying the self-perceived risk of suffering from Anxiety.</p>
                <p>The GAD-7 serves as a diagnostic tool designed with 7 pivotal questions to pinpoint symptoms related to generalized anxiety disorders in individuals.</p>
                <p><Link to={'/general-cognitive-assessment/gad7'}>Learn more</Link></p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="heading24">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse24" aria-expanded="false" aria-controls="collapse24">
                PHQ9
              </button>
            </h2>
            <div id="collapse24" className="accordion-collapse collapse" aria-labelledby="heading24"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>PHQ9</h3>
                <p>It is composed of 9 questions aimed at identifying the self-perceived risk of suffering Depression.</p>
                <p>The PHQ-9 stands out as a prominent tool, equipped with nine critical questions, aimed at delineating symptoms associated with depressive disorders in individuals.</p>
                <p><Link to={'/general-cognitive-assessment/phq9'}>Learn more</Link></p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </header>
  )
}

export default GeneralCognitiveAssessmentHeader