import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';
import { Link } from 'react-router-dom';


const StroopTest = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">

                        <div className={"row w-100"}>
                            <div className={"col-md-8 col-sm-12"}><h1>Stroop Test</h1></div>
                            <div className={"col-md-4 col-sm-12 text-md-end text-sm-left pt-1"}>
                                <Link target={"_blank"} className={"btn btn-outline-dark"} to={'https://www.cognifit.com/battery-of-tests/inh-rest-test/equivalencies-test'}>Start test now</Link>
                            </div>
                        </div>

                        <p>Understand your cognitive flexibility and ability to manage conflicting information.</p>
                        <p><strong>Relation to QEEG, Neurofeedback, Biofeedback, and Neuromodulation:</strong></p>
                        <ul>
                            <li><strong>Brain Region:</strong> Prefrontal Cortex, Anterior Cingulate Cortex</li>
                            <li><strong>Brodmann Area:</strong> BA 24 (Anterior Cingulate Cortex), BA 46 (Dorsolateral Prefrontal Cortex)</li>
                            <li><strong>EEG Electrode Places:</strong> Fz, F3, F4, FCz, Cz</li>
                            <li>
                                <strong>Neurofeedback:</strong>
                                <ul>
                                    <li><strong>Protocol:</strong> Focus on moderating beta wave (13-30 Hz) activity in the prefrontal and anterior cingulate areas. Balancing beta to alpha (8-12 Hz) ratios might be beneficial.</li>
                                    <li><strong>Objective:</strong> Facilitate an optimal balance of attention and cognitive flexibility, aiding the ability to manage conflicting stimuli and inhibit automatic responses effectively.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Biofeedback:</strong>
                                <ul>
                                    <li><strong>Assessment:</strong> Observing Heart Rate Variability (HRV) and Galvanic Skin Response (GSR) during task performance, giving insight into stress and arousal levels.</li>
                                    <li><strong>Protocol:</strong> Incorporate stress-management and relaxation techniques, such as deep breathing, especially if increases in stress markers are noted during tasks requiring cognitive control.</li>
                                    <li><strong>Objective:</strong> Enhance task performance by aiding effective stress management and ensuring cognitive resources are not unduly impacted by stress or anxiety.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Neuromodulation:</strong>
                                <ul>
                                    <li>
                                        <strong>tDCS:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Dorsolateral Prefrontal Cortex</li>
                                            <li><strong>Protocol:</strong> Applying anodal tDCS to potentially amplify neuronal excitability and function in the region, possibly bolstering cognitive control capacities.</li>
                                            <li><strong>Objective:</strong> To enhance the neural conditions for inhibitory control and cognitive flexibility during task performance.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>PBM:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Prefrontal Cortex</li>
                                            <li><strong>Protocol:</strong> Use PBM to support neuronal health and metabolism, potentially aiding sustained cognitive function and recovery post-task.</li>
                                            <li><strong>Objective:</strong> To maintain and support the neuronal conditions and networks pivotal for cognitive control and flexibility.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Mental Health Assessments and Their Relation:</strong>
                                <ul>
                                    <li><strong>Personality Scale:</strong> Personality traits, particularly those relating to neuroticism or conscientiousness, may relate to how individuals manage conflicting information and exert cognitive control.</li>
                                    <li><strong>SISRI-24 (Short Imaginal Processes Inventory):</strong> Considering imaginative processes might offer insights into cognitive styles, which could interact with how conflicting information is managed and processed.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>Understanding these aspects in the context of the Stroop Test performance can offer nuanced insights into the individual’s cognitive style and control capacities. Tailoring interventions to account for these can thus ensure that strategies are applicable and supportive to the individual's unique cognitive profile. Combining neurofeedback, biofeedback, and neuromodulation may create a synergistic effect that enhances overall cognitive function and specific aspects like cognitive flexibility and inhibitory control, as assessed via the Stroop Test.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default StroopTest