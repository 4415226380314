import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';
import { Link } from 'react-router-dom';


const TappingTest = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">

                        <div className={"row w-100"}>
                            <div className={"col-md-8 col-sm-12"}><h1>Tapping Test</h1></div>
                            <div className={"col-md-4 col-sm-12 text-md-end text-sm-left pt-1"}>
                                <Link target={"_blank"} className={"btn btn-outline-dark"} to={'https://www.cognifit.com/battery-of-tests/rest-hecoor-test/speed-test'}>Start test now</Link>
                            </div>
                        </div>

                        <p>Examining motor speed and coordination through rapid and precise tapping sequences.</p>
                        <p><strong>Relation to QEEG, Neurofeedback, Biofeedback, and Neuromodulation:</strong></p>
                        <ul>
                            <li><strong>Brain Region:</strong> Primary Motor Cortex, Supplementary Motor Area, Cerebellum</li>
                            <li><strong>Broadman Area:</strong> BA 4, BA 6</li>
                            <li><strong>EEG Electrode Places:</strong> C3, C4</li>
                            <li>
                                <strong>Neurofeedback:</strong>
                                <ul>
                                    <li><strong>Protocol:</strong> Targeting SMR (sensorimotor rhythm) at central locations to optimize motor coordination and speed.</li>
                                    <li><strong>Objective:</strong> Promote neural patterns associated with optimal motor control and inhibition of extraneous movement.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Biofeedback:</strong>
                                <ul>
                                    <li><strong>Assessment:</strong> EMG (Electromyography) to evaluate muscle activity during the tapping task and identify patterns/irregularities.</li>
                                    <li><strong>Protocol:</strong> Utilize EMG biofeedback to help individuals understand and modulate their muscle activity for improved motor coordination.</li>
                                    <li><strong>Objective:</strong> Ensuring that muscle activation patterns are efficient and controlled to facilitate rapid and precise motor actions.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Neuromodulation:</strong>
                                <ul>
                                    <li>
                                        <strong>tDCS:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Primary Motor Cortex (M1), Supplementary Motor Area</li>
                                            <li><strong>Protocol:</strong> Anodal stimulation to M1 to enhance excitability and potentially improve motor speed and coordination.</li>
                                            <li><strong>Objective:</strong> Potentially enhancing motor coordination and speed by modulating excitability in the relevant motor areas.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>PBM:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Motor Cortex, Cerebellum</li>
                                            <li><strong>Protocol:</strong> Use photobiomodulation to enhance metabolic activity and health in motor regions.</li>
                                            <li><strong>Objective:</strong> Support neuronal health and connectivity in motor areas to ensure optimal function and performance.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Mental Health Assessments and Their Relation:</strong>
                                <ul>
                                    <li><strong>DASS 21/42 (Depression, Anxiety, and Stress Scales):</strong> Understand the emotional context that may influence motor performance, as stress, anxiety, and depression can impact motor coordination and speed.</li>
                                    <li><strong>Holmes-Rahe Life Event Scale:</strong> Evaluate the potential impact of life stressors on motor performance, as higher stress levels may impact both focused attention and motor control during tasks like the Tapping Test.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>The Tapping Test provides insights into motor speed and coordination, which can be integral for various functional and occupational activities. Through a coordinated application of neurofeedback, biofeedback, and neuromodulation, an individual’s motor performance can be assessed and optimized within the context of their overall emotional and physiological wellbeing, providing a holistic approach to enhancing function and performance.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default TappingTest