import React from 'react'
import { SEO, Navbar, HeaderNeuromodulation, Footer, CombinationPricing, CombinationSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';


const NeuromodulationWithAdvancedAiIntegration = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Interventions by DeepSynaps - Unlock Your Cognitive Potential'
        description= 'Discover our range of personalized cognitive interventions at DeepSynaps. From brain training programs to non-invasive neuromodulation therapies, our interventions are designed to enhance cognitive abilities, improve focus, and promote mental well-being.'
        url = 'https://oxfordmindtech.com/interventions'
        keywords = 'Cognitive interventions, DeepSynaps, brain training programs, non-invasive neuromodulation therapies, cognitive abilities, mental well-being'
      />    
      <Navbar />
      <HeaderNeuromodulation />
      <CombinationPricing />
      <CombinationSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />
    </>
  )
}

export default NeuromodulationWithAdvancedAiIntegration