import React from 'react'
import { SEO, Navbar, HeaderPricing, Footer, CombinationPricing, CombinationSubs } from '../Components'


const Pricing = () => {

    return (
    <>
      <SEO robots = 'noindex, nofollow' url='https://oxfordmindtech.com/pricing'/>
      <Navbar />
      <HeaderPricing />
      <CombinationPricing />
      <CombinationSubs />
      <Footer />
    </>
  )
}

export default Pricing