import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';
import { Link } from 'react-router-dom';


const VisualWorkingMemorySpanTest = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">

                        <div className={"row w-100"}>
                            <div className={"col-md-8 col-sm-12"}><h1>Visual Working Memory Span Test</h1></div>
                            <div className={"col-md-4 col-sm-12 text-md-end text-sm-left pt-1"}>
                                <Link target={"_blank"} className={"btn btn-outline-dark"} to={'https://www.cognifit.com/battery-of-tests/vismem-plan-test/concentration-test'}>Start test now</Link>
                            </div>
                        </div>

                        <p>Assess your visual working memory capacity and how you manage spatial information.</p>
                        <p><strong>- Objective:</strong> To assess the capacity of visual working memory and the management of spatial information.</p>
                        <p><strong>Relation to QEEG, Neurofeedback, Biofeedback, and Neuromodulation:</strong></p>
                        <ul>
                            <li><strong>Brain Region:</strong> Prefrontal Cortex, Posterior Parietal Cortex</li>
                            <li><strong>Brodmann Area:</strong> BA 46 (Dorsolateral Prefrontal Cortex), BA 7 (Posterior Parietal Cortex)</li>
                            <li><strong>EEG Electrode Places:</strong> F3, F4, P3, P4</li>
                            <li>
                                <strong>Neurofeedback:</strong>
                                <ul>
                                    <li><strong>Protocol:</strong> Attention to theta (4-8 Hz) and low beta (12-15 Hz) activities in prefrontal and parietal regions. Balancing theta to beta ratios might be pivotal.</li>
                                    <li><strong>Objective:</strong> Aiming to optimize working memory function by enhancing the efficiency of neural activities and networks associated with memory and spatial processing.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Biofeedback:</strong>
                                <ul>
                                    <li><strong>Assessment:</strong> Exploring Heart Rate Variability (HRV) and Galvanic Skin Response (GSR) during task performance to gain insights into physiological arousal and stress responses.</li>
                                    <li><strong>Protocol:</strong> Employing techniques, such as diaphragmatic breathing and mindfulness, if elevated stress or arousal is noted, which might hinder optimal cognitive function.</li>
                                    <li><strong>Objective:</strong> Facilitating a physiological state conducive to sustained attention and optimal working memory function.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Neuromodulation:</strong>
                                <ul>
                                    <li>
                                        <strong>tDCS:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Dorsolateral Prefrontal Cortex</li>
                                            <li><strong>Protocol:</strong> Anodal stimulation to enhance excitability and thereby potentially bolstering working memory capacity and efficacy.</li>
                                            <li><strong>Objective:</strong> To enhance neural functioning in regions pivotal for visual working memory and spatial processing.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>PBM:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Prefrontal Cortex</li>
                                            <li><strong>Protocol:</strong> Applying PBM to facilitate cellular metabolism and neural health in regions pivotal for working memory.</li>
                                            <li><strong>Objective:</strong> Ensure optimal neuronal function and recovery post-task, supporting sustained cognitive function across time.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Mental Health Assessments and Their Relation:</strong>
                                <ul>
                                    <li><strong>DASS 21/42 (Depression, Anxiety, and Stress Scale):</strong> Emotional states can impact cognitive function. Stress, depression, or anxiety may impede working memory, therefore understanding emotional wellbeing is crucial in interpreting results.</li>
                                    <li><strong>SLEEP SCALE:</strong> Sleep is pivotal for cognitive function. Exploring relationships between sleep quality/quantity and working memory can provide valuable insights into cognitive health and function.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>By combining these technologies and strategies, individuals might better understand their working memory capacities, allowing them to harness insights into their cognitive health and functioning. Strategies derived from such insights can be utilized to enhance visual working memory and spatial information management, ensuring interventions are maximally supportive and effective across varied cognitive tasks.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default VisualWorkingMemorySpanTest