import { React, useState } from 'react'
import '../../CognitiveTests/Header/cognitiveTestHeader.css'
import {Link} from "react-router-dom";

const MentalHealthAssessmentHeader = () => {
  
  const [showSection1, setShowSection1] = useState(true)
  const [showSection2, setShowSection2] = useState(false)
  const [showSection3, setShowSection3] = useState(false)
  const [showSection4, setShowSection4] = useState(false)
  const [showSection5, setShowSection5] = useState(false)
  const [showSection6, setShowSection6] = useState(false)
  const [showSection7, setShowSection7] = useState(false)

  const [showCognitiveTest, setShowCognitiveTest] = useState('cognitive-test-1');

  const handleShowSection1 = () => { // Showing 1st element
    setShowSection1(true)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(false)
    setShowSection7(false)
  }

  const handleShowSection2 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(true)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(false)
    setShowSection7(false)
  }

  const handleShowSection3 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(true)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(false)
    setShowSection7(false)
  }

  const handleShowSection4 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(true)
    setShowSection5(false)
    setShowSection6(false)
    setShowSection7(false)
  }

  const handleShowSection5 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(true)
    setShowSection6(false)
    setShowSection7(false)
  }

  const handleShowSection6 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(true)
    setShowSection7(false)
  }

  const handleShowSection7 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(false)
    setShowSection7(true)
  }

  return (
    <header id='headerDiagnostic' className='d-flex flex-column'>
      <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
        <h1>Mental Health Assessment</h1>
        <div className='d-flex flex-column position-relative'>
          <p className='headerDiagnosticArticleP'> DeepSynaps Mental Health Platform that can assess and monitor 80+ DSM conditions from a single adaptive ML assessment using adaptive Augmented Intelligence. DeepSynaps lowers the clinical burden of executing high quality mental health care while improving clinical and administrative workflow.</p>
          <br />
          <a className='cognifitTest btn btn-primary' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
        </div>
      </article>
      <div className="breakline-diagnostic"></div>

      <div className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
        <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      </div>

      <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
        <div className="headerDiagnostic-options d-flex flex-column align-items-start">
          <button className={showSection1 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection1}>Who we help?</button>
          <button className={showSection2 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection2}>How is it work?</button>
          <button className={showSection3 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection3}>Welcome to a Revolutionary Approach to Mental Health Assessment</button>
          <button className={showSection4 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection4}>How DeepSynaps Elevates Your Assessment Experience</button>
          <button className={showSection5 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection5}>DeepSynaps’s Commitment to Excellence</button>
          <button className={showSection6 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection6}>Why Trust DeepSynaps?</button>
          <button className={showSection7 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection7}>Begin Your Journey with DeepSynaps Today</button>
        </div>
        {showSection1 &&
          <div className="headerDiagnostic-content d-flex flex-row align-items-start">
            <div className="diagnosticArticle2 d-flex flex-column align-items-start">
              <h1>Who we help?</h1>
              <p className={"pb-0 mb-0"}><strong>Effective And Personalized Mental Health Care</strong></p>
              <p>Leveraging our innovative data-driven adaptive clinical intelligence, we assist an expansive range of professionals and institutions, enhancing their ability to provide effective and personalized mental health care like never before. We're passionate about transforming mental healthcare and ensuring every person gets a chance to be heard, understood, and not rushed through the system. No matter your industry, field, agency, or institution, we're here to help you deliver better, more personalized mental health care for all.</p>
              <p>Here's a closer look at the verticals we serve and how DeepSynaps aids each one of them:</p>
              <p className={"pb-0 mb-0"}><strong>DeepSynaps mental health  Serves All Clinicians</strong></p>
              <p>DeepSynaps empowers clinicians of all types with the ability to provide comprehensive mental health evaluations to patients by sending one link to our adaptive intelligence that assesses for 80+ mental health conditions autonomously. DeepSynaps automates follow-ups, and lowers clinical documentation burdens, resulting in improved patient outcomes and streamlined practice management across the entire continuum of clinicians.</p>
              <p>From social workers, counselors, and nurse practitioners to primary care physicians and psychiatrists, DeepSynaps fits every clinical setting and is uniquely dynamic. DeepSynaps empowers clinicians while providing insightful statistics and outcome measures on demand for MIPS, HEDIS, or other value-based programs.</p>
              <p className={"pb-0 mb-0"}><strong>DeepSynaps mental health Is Used In Most Provider Settings</strong></p>
              <p>From mental health clinics to emergency rooms, DeepSynaps facilitates comprehensive, standardized mental health assessments, enhancing diagnosis accuracy and treatment effectiveness. It can be utilized in various settings like pediatric practices, inpatient hospitals, mental health clinics, CMHOs, primary care, urgent care, TMS centers, at-home care, SUDS, and IOP settings. </p>
              <p>DeepSynaps serves all provider settings, enabling advanced value-based care and metric-based care across all these provider types while ensuring high-quality data collection at the highest regulatory standards. DeepSynaps can also deliver on-demand population health data at any cohort level.</p>
              <p className={"pb-0 mb-0"}><strong>Digital Mental Health Platforms</strong></p>
              <p>DeepSynaps easily integrates with digital platforms to provide adaptive intelligence, augmenting the capabilities of telehealth services, digital therapeutics, and mobile health technology platforms. DeepSynaps can also empower these organizations with our ready-made APIs and analytics platforms to provide on-demand analytics and outcomes measures.</p>
              <p>DeepSynaps allows executive teams at these organizations to rest easy, knowing that each of their team members is providing the best possible care and that there is a ubiquitous standard for clinical documentation to ensure audit readiness at all times. DeepSynaps can also provide Phase II-IIIB FDA trial data via our decentralized data-gathering tools and life sciences toolkit.</p>
              <p className={"pb-0 mb-0"}><strong>Substance Use Treatment/Recovery Organizations</strong></p>
              <p>DeepSynaps aids in the identification, diagnosis, and treatment monitoring of substance use disorders, contributing to the effectiveness of any recovery programs. DeepSynaps has been battle-tested in some of the most challenging addiction centers and IOPs in the country, including homeless populations seeking help from substance abuse. Even in these most challenging settings, DeepSynaps truly shines and empowers both patients and clinicians with dignity, equity, and access.</p>
              <p className={"pb-0 mb-0"}><strong>Community Settings</strong></p>
              <p>DeepSynaps assists various community settings in population health triaging and care navigation. Many community settings and clinical settings are understaffed and underfunded. DeepSynaps empowers these organizations with a tool to assess an unlimited size population for pennies on the dollar to a high clinical standard autonomously. It seamlessly guides these organizations to assist in optimizing resource allocations and enhance triaging to the correct level and costs of care, all while implementing in-depth mental health assessments and treatments, promoting a healthier, happier population from youth services to senior living.</p>
              <p className={"pb-0 mb-0"}><strong>Health Systems</strong></p>
              <p>DeepSynaps enhances care across entire health systems and is the vehicle and highway of mental health care for health systems. From initial population health triaging and care navigation to integrated monitoring and on-demand cohort and provider-level outcomes monitoring, DeepSynaps provides data-driven insights for optimizing outcomes and resources, enhancing patient care delivery across the entire care continuum.</p>
              <p className={"pb-0 mb-0"}><strong>Population Health</strong></p>
              <p>DeepSynaps's advanced tools have the unique ability to triage an unlimited number of persons in many languages, remotely and autonomously, for most mental health conditions and social determinants of health. DeepSynaps supports public health initiatives, from suicide prevention and crisis management to healthcare burnout mitigation, empowering any enterprise or small care systems to address community needs proactively and triage cases immediately to the right level of care.</p>
              <p>DeepSynaps has the power to stop school shootings and veteran suicides with our powerful triage and population health tools. DeepSynaps can also empower any crisis hotline and crisis intervention team in any setting from suburbia to war zones and natural disaster mitigations.</p>
              <p className={"pb-0 mb-0"}><strong>Human Resources & EAPs</strong></p>
              <p>DeepSynaps uniquely provides tools that assist EAP and Compliance departments in maintaining the mental well-being of employees, fostering a healthier, more productive workplace. DeepSynaps is private and encrypted and can be shared with an independent care navigator while maintaining an employee's confidentiality.</p>
              <p className={"pb-0 mb-0"}><strong>Pharmaceutical & Life Sciences Research And Development</strong></p>
              <p>DeepSynaps supports clinical research organizations and pharmaceutical companies with real-world evidence and patient-reported outcomes, aiding in more effective clinical trial designs and improved population health studies. DeepSynaps assists with site selection and optimization while allowing sponsors and CROs to test protocol designs prior to full study launch to assess viability and uncover any needed amendments before studies randomize their first subjects.</p>
              <p>DeepSynaps nearly eliminates screen failures and gathers all data in an FDA CH 21 Part 11 compliant manner, ensuring that data gathered in our decentralized clinical trial tool can be used for submission to regulatory agencies. DeepSynaps is a fantastic tool to gather post-marketing data on any CNS drug, device, or DTx. Imagine reducing drug development cost while speeding up your clinical trial. DeepSynaps delivers this and much more for life sciences.</p>
              <p className={"pb-0 mb-0"}><strong>Education</strong></p>
              <p>DeepSynaps helps educational institutions monitor and manage the mental health of students, promoting a supportive and inclusive learning environment. From large multi-campus universities to primary school and after-school programs, DeepSynaps is serving students of all ages to find a voice and a safe place to be heard and understood with no constraints on settings or time. DeepSynaps is a safe place for students to be themselves and finally be understood.</p>
              <p className={"pb-0 mb-0"}><strong>Government & Military</strong></p>
              <p>DeepSynaps provides a crucial tool for the Veterans Administration and military hospitals, aiding in the assessment of service members' mental health and their journey towards recovery. DeepSynaps can serve the military and governments alike. From pre- and post-deployment screenings to mental health evaluations for sensitive roles, DeepSynaps is a safe, non-biased way to privately and confidentially get help without the fear of retaliation or being labeled.</p>
              <p>DeepSynaps can also be deployed in theater to any hot zone, increasing access and equity in delivering care to all our brave soldiers while increasing bandwidth for resource-tight clinical teams in these high-stress settings.</p>
            </div>
          </div>
        }{showSection2 &&
          <div className="headerDiagnostic-content d-flex flex-row align-items-start">
            <div className="diagnosticArticle2 d-flex flex-column align-items-start">
              <h1>How is it work?</h1>
              <p className={"pb-0 mb-0"}><strong>Invite Your Patient</strong></p>
              <p>The clinician uses DeepSynaps’s invite feature to email or text a secure encrypted link to your patient or patient’s guardian. The patient can register on any Internet-capable device such as cell phone, tablet or computer. Clinicians also have the option to complete the assessment themselves with the patient present.</p>
              <p className={"pb-0 mb-0"}><strong>Complete Assessment</strong></p>
              <p>The clinician and/or patient begins a DeepSynaps online assessment on any Computer or mobile device with internet access. The patient can save and continue at any point allowing them to complete the assessment in a safe, comfortable environment at their own pace at a convenient time.</p>
              <p className={"pb-0 mb-0"}><strong>DeepSynaps Assessment Algorithm</strong></p>
              <p>Client will go through the DeepSynaps adaptive machine learning algorithm. DeepSynaps is customized and adapted to each person based on their answers and the severity of those answers. DeepSynaps never forgets to ask the right questions.</p>
              <p className={"pb-0 mb-0"}><strong>Clinician Review</strong></p>
              <p>Clinician can view completed assessments in the Patient Portal. Clinicians and clinical staff can see completed assessments in addition to incomplete patient assessments. Clinician can review the detailed DeepSynaps report unique to each patient. Clinician reviews the easy to read patient summary and detailed report during the initial consult. Learn more about your patient and get off to a faster start.</p>
              <p className={"pb-0 mb-0"}><strong>Empowering Clinical Decision Making</strong></p>
              <p>DeepSynaps enables proper evaluation and management which enhances clinical decision-making, assisting clinicians to produce robust documentation, to optimize treatment planning and clinical outcomes.</p>
              <p className={"pb-0 mb-0"}><strong>Accept, Reject or Rule out suggested Diagnoses</strong></p>
              <p>With our Patent Pending process, DeepSynaps can suggest over 80+ different psychiatric conditions based on assessment responses. Our proprietary Accept/Reject feature allows clinicians to retain ultimate diagnostic control. This process enhances our sensitivity and specificity while creating a unique feedback loop that incorporates clinical knowledge with machine-learning.</p>
              <p className={"pb-0 mb-0"}><strong>Insurance Reimbursement</strong></p>
              <p>Increase practice revenue with DeepSynaps, which is reimbursable through most insurance carriers. DeepSynaps increases value-based care for patients and clinicians while reducing overall mental health costs for insurance providers. </p>
              <p className={"pb-0 mb-0"}><strong>Follow Up & Treatment Planning</strong></p>
              <p>Follow-ups allow you to track and monitor a patient's progress over time, including treatment and medication efficacy. Clinicians can conveniently schedule patient follow-up assessments through the system's intuitive calendar function. The system conveniently suggests follow-up assessments for the clinician to choose from based on the accepted diagnosis.</p>
              <p>Follow-ups can be given based on medical necessity using DeepSynaps's robust list of Gold-Standard and proprietary assessments. DeepSynaps offers unique assessments, including standardized scales, proprietary DSM5-based scales and informational questionnaires. Follow-ups can be sent to the patient directly, or to third-party reporters such as family members, teachers, coaches, care-givers.</p>
            </div>
          </div>
        }{showSection3 &&
          <div className="headerDiagnostic-content d-flex flex-row align-items-start">
            <div className="diagnosticArticle2 d-flex flex-column align-items-start">
              <h1>Welcome to a Revolutionary Approach to Mental Health Assessment</h1>
              <p>Embark on a profound journey towards understanding and nurturing your mental health with DeepSynaps’s scientifically-backed, adaptive, and compassionate assessment system. Our collaboration with DeepSynaps ensures you a comprehensive, accurate, and supportive pathway through your mental well-being exploration.</p>
            </div>
          </div>
        }
        {showSection4 &&
          <div className="headerDiagnostic-content d-flex flex-row align-items-start">
            <div className="diagnosticArticle2 d-flex flex-column align-items-start">
              <h1>How DeepSynaps Elevates Your Assessment Experience</h1>
              <ul>
                <li>Seamless Invites: Clinicians send secure, encrypted links via email or text, enabling you to register and begin assessments on any device, at your own pace.</li>
                <li>Adaptive Assessments: Engage with DeepSynaps’s innovative machine-learning algorithm, personalized and adapted to each individual’s responses for precision.</li>
                <li>Clinician Review: Experts review detailed reports, ensuring a thorough understanding of your mental state during consultations.</li>
                <li>Empowering Decisions: DeepSynaps supports clinicians in enhancing evaluation, management, and robust documentation, optimizing treatment planning and outcomes.</li>
                <li>Diagnostic Excellence:  With the capability to suggest over 80+ psychiatric conditions, clinicians retain ultimate control over diagnostic decisions, ensuring accuracy and reliability.</li>
                <li>Continuous Support: Follow-ups, scheduled through a convenient system, ensure continual monitoring and adaptation of treatment and management plans.</li>
              </ul>
            </div>
          </div>
        }
        {showSection5 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>DeepSynaps’s Commitment to Excellence</h1>
                <p>DeepSynaps, through years of rigorous development since 2003, has established proprietary methodologies and algorithms. With copyrights, patents, and trademarks in place across multiple regions, DeepSynaps assures an unparalleled, scientific, and legally protected approach towards mental health assessments.</p>
              </div>
            </div>
        }
        {showSection6 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>Why Trust DeepSynaps?</h1>
                <p>- DSM5 Alignment: DeepSynaps’s exhaustive library of patient-facing questions and corresponding criteria ensures compatibility with the DSM5’s recognized mental health conditions.</p>
                <p>- Enhanced Standardization: In addition to proprietary assessments, DeepSynaps also licenses various standardized behavioral assessments, providing a comprehensive suite for thorough mental health evaluation and management.</p>
              </div>
            </div>
        }
        {showSection7 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>Begin Your Journey with DeepSynaps Today</h1>
                <p>Your path towards understanding, managing, and flourishing through your mental health journey is just a click away. Initiate your assessment and embrace the supportive, scientific, and nurturing approach of DeepSynaps, here at <a href="mailto: info@deepsynaps.com">info@deepsynaps.com</a></p>
              </div>
            </div>
        }
      </div>
    </header>
  )
}

export default MentalHealthAssessmentHeader