import React from 'react'
import '../index.css'
import { Navbar, HeaderProducts, Footer, ProductsComponent, SubsComponent, SEO } from '../Components'


const Products = () => {
  return (
    <>
      <SEO robots = 'noindex, nofollow' url='https://oxfordmindtech.com/products'/>
      <Navbar />
      <HeaderProducts />
      <ProductsComponent className='proComp'/>
      <SubsComponent />
      <Footer />
    </>
  )
}

export default Products