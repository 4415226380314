import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';


const MemoryCognitiveAssessment = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                        <h1>Memory Cognitive Assessment (CAB-ME)</h1>
                        <p>The Memory Cognitive Assessment (CAB-ME) assesses the cognitive processes that influence the different types of memory. Healthcare professionals can use this evaluation to quickly and accurately assess the cognitive processes associated with memory. This assessment takes 15-20 minutes to complete.</p>
                        <p>This assessment is suitable for children over 7 years of age, youth, adults, or seniors. The assessment can be used cross-culturally, and for both men and women.</p>
                        <p><strong>Relation to the QEEG,Neurofeedback,Biofeedback and Neuromodulation</strong></p>
                        <p>The Memory Cognitive Assessment (CAB-ME) evaluates the cognitive processes that affect the different facets of memory. This tool enables healthcare professionals to swiftly and precisely gauge the cognitive intricacies intertwined with memory.</p>
                        <ul>
                            <li>
                                <strong>Types of Memory and Associated Neural Pathways:</strong>
                                <ul>
                                    <li><strong>Brain Regions and QEEG:</strong> The hippocampus, amygdala, and different parts of the frontal and temporal lobes are instrumental in memory processing. QEEG can provide an in-depth understanding of oscillatory activities in these regions during memory tasks.</li>
                                    <li><strong>Biofeedback:</strong> By monitoring Heart Rate Variability (HRV) with Electrocardiography (ECG), we can gauge physiological responses linked with memory recall and retention phases.</li>
                                    <li><strong>Neurofeedback:</strong> Alpha and Theta frequency training can potentially aid in refining memory recall and enhancing memory consolidation.</li>
                                    <li><strong>tDCS:</strong> Focusing on the temporal lobe, especially the hippocampal region, can modulate memory recall and consolidation capacities.</li>
                                    <li><strong>PBM:</strong> Using light therapy on the hippocampus and surrounding regions can optimize their functioning, potentially benefiting memory storage and recall processes.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Cognitive Processing and Memory Retrieval:</strong>
                                <ul>
                                    <li><strong>Brain Regions and QEEG:</strong> The prefrontal cortex plays a pivotal role in decision-making and strategic retrieval of memories. QEEG can chart neural patterns associated with these processes.</li>
                                    <li><strong>Biofeedback:</strong> Electroencephalography (EEG) can trace real-time brain activity during memory retrieval, offering insights into cognitive load.</li>
                                    <li><strong>Neurofeedback:</strong> Beta frequency training can be instrumental in honing decision-making and memory retrieval processes.</li>
                                    <li><strong>tDCS:</strong> Targeted stimulation of the prefrontal cortex can enhance memory retrieval capabilities and cognitive processing during recall.</li>
                                    <li><strong>PBM:</strong> Photobiomodulation directed towards the frontal regions may enhance cognitive processing rates and strategic memory recall.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>Incorporating the Memory Cognitive Assessment with these avant-garde neuroscientific methods provides a holistic perspective on memory functions, challenges in recall and retention, and avenues for tailored interventions.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default MemoryCognitiveAssessment