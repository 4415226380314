import React from 'react'
import { SEO, Navbar, Footer, HeaderHome, Info, PartnersLogo, ProductsComponent, Clinic, Innovation } from '../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../assets/images/logo192.svg';

const Home = () => {
  return (
    <>
      <SEO
          title = 'DeepSynaps - Revolutionizing Brain Health and Cognitive Performance'
          description= 'DeepSynaps is a cutting-edge startup dedicated to optimizing brain health and unlocking cognitive potential. Discover our advanced technologies, personalized interventions, and innovative solutions to enhance cognitive abilities, improve mental well-being, and unlock the full potential of the human brain. Explore our QEEG scans, cognitive tests, interventions, partnerships, and cooperate services. Join us on the forefront of brain health and cognitive optimization'
          keywords = 'DeepSynaps, brain health, cognitive performance, AI technology, QEEG scans, cognitive assessments, brain training programs, neuromodulation therapies, mental well-being'
      />
      <Navbar />
      {/* <Mycomponent /> */}
      <HeaderHome />
      <Info />
      <PartnersLogo />
      {/* <Services /> */}
      <ProductsComponent />
      {/* <Team /> */}
      <Clinic />
      <Innovation />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />
    </>
  )
}

export default Home

