import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';
import { Link } from 'react-router-dom';


const VisualEpisodicMemoryTest = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                        <div className={"row w-100"}>
                            <div className={"col-md-8 col-sm-12"}><h1>Visual Episodic Memory Test (CAT-VEM)</h1></div>
                            <div className={"col-md-4 col-sm-12 text-md-end text-sm-left pt-1"}>
                                <Link target={"_blank"} className={"btn btn-outline-dark"} to={'https://www.cognifit.com/gb/battery-of-tests/visual-episodic-memory-task'}>Start test now</Link>
                            </div>
                        </div>

                        <p>Evaluate the power of your visual memory and recognition through episodic recall.</p>
                        <p><strong>Relation to QEEG, Neurofeedback, Biofeedback, and Neuromodulation:</strong></p>
                        <ul>
                            <li><strong>Brain Region:</strong> Hippocampus, Prefrontal Cortex</li>
                            <li><strong>Broadman Area:</strong> 28, 32 (Prefrontal Cortex)</li>
                            <li><strong>EEG Electrode Places:</strong> Fp1, Fp2 (Prefrontal); no standard surface electrodes directly cover the hippocampus, yet temporal lobe electrodes (T5, T6) might indirectly capture relevant activity.</li>
                            <li>
                                <strong>Neurofeedback:</strong>
                                <ul>
                                    <li><strong>Protocol:</strong> Enhancing theta activity in the medial temporal lobe and/or prefrontal region, modulating alpha/theta ratios, particularly in prefrontal areas.</li>
                                    <li><strong>Objective:</strong> To support memory encoding, storage, and retrieval processes through the regulation of relevant brainwave patterns.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Biofeedback:</strong>
                                <ul>
                                    <li><strong>Assessment:</strong> HRV and GSR to understand stress and arousal levels, which can impact memory recall and recognition.</li>
                                    <li><strong>Protocol:</strong> Training towards optimizing HRV for emotional and physiological regulation and managing GSR in response to cognitive demand.</li>
                                    <li><strong>Objective:</strong> Facilitating a balanced physiological state to foster optimal memory function and minimize the adverse impacts of stress or anxiety on recall.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Neuromodulation:</strong>
                                <ul>
                                    <li>
                                        <strong>tDCS:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Prefrontal Cortex</li>
                                            <li><strong>Protocol:</strong> Anodal stimulation to enhance excitability and support neural pathways involved in memory processes.</li>
                                            <li><strong>Objective:</strong> Strengthening neural circuits related to memory encoding and recall.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>PBM:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Temporal Lobes, Prefrontal Cortex</li>
                                            <li><strong>Protocol:</strong> Employing photobiomodulation to support cellular energy production and potentially foster synaptic connectivity and plasticity.</li>
                                            <li><strong>Objective:</strong> Enhancing neural efficiency and resilience within memory circuits.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Mental Health Assessments and Their Relation:</strong>
                                <ul>
                                    <li><strong>DASS 21/42:</strong> Assessing stress, anxiety, and depressive symptoms that might influence cognitive function, especially memory recall and recognition, underpinning tailored interventions.</li>
                                    <li><strong>Holmes Rahe Life Event Scale:</strong> Understanding life stressors and their potential impact on cognitive functions, offering an insight into external factors potentially affecting memory performance.</li>
                                    <li><strong>Personality Scale:</strong> Acknowledging trait variables that might influence coping, stress response, and cognitive strategies, providing a contextual understanding of how personality variables might intersect with cognitive performance.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>Utilizing these intricate relations between cognitive assessments, neurophysiological interventions, and mental health metrics, a comprehensive cognitive wellness strategy can be formulated. This strategy would not only focus on remediating deficits but also on leveraging strengths and scaffolding supports across neurological, psychological, and physiological domains to foster sustained cognitive well-being.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default VisualEpisodicMemoryTest