import React, { useState, useEffect } from 'react'
import './products.css'
import { FlowHeadset, TdcsDevices, PbmDevices } from '../../../assets/images'
import { TbTriangleInvertedFilled } from "react-icons/tb";


const ProductsComponent = (props) => {
  
  const ProductsExp = [{
    name: 'Flow (tDCS):',
    explanation: `Flow Neuroscience's tDCS device is designed for treating mental health conditions, particularly depression. This device can be a valuable addition to your offerings for clients seeking help with mental health issues.`,
    style: 'bottomTri-markerLeft',
  },{
    name: 'PlatoScience(tDCS):',
    explanation: 'Work is a transcranial direct current stimulation (tDCS) device that is designed to improve cognitive function and reduce symptoms of depression. tDCS is a non-invasive procedure that uses a weak electrical current to stimulate the brain. PlatoScience Work is a wearable device that is easy to use. PlatoScience Work has been shown to be effective in improving cognitive function and reducing symptoms of depression in clinical trials. PlatoScience Work can be a valuable addition to your offerings for clients seeking help with mental health issues.',    
    style: 'bottomTri-markerMiddle',
  },{
    name: 'Which device we are using for PBM (Photobiomodulation)? :',
    explanation: 'iMedSyncs RPBM (Photobiomodulation) device is a cutting-edge technology that utilizes low-level light therapy to stimulate cellular activity. The iMedSyncs RPBM device is backed by scientific research and has gained recognition for its efficacy and safety. However, it is important to consult with a healthcare professional before using the device to ensure its suitability for your specific condition and to determine the optimal treatment parameters.',
    style: 'bottomTri-markerRight',
  }];

  const [selectedDevice, setSelectedDevice] = useState(null);

  const handleCardClick = (product) => {
    setSelectedDevice(product);
  };

  useEffect(() => {
    if (selectedDevice) {
      document.getElementById('productEXP').scrollIntoView();
    }
  }, [selectedDevice]);

  const handleButtonClick = () => {
    setSelectedDevice(null); // closing button
  };



  return (
    <>
      <div id='products' className={`d-flex flex-column justify-content-center align-items-center wrapper ${props.className}`}> {/* className="d-flex flex-column justify-content-center align-items-center wrapper" */}
        <div className="d-flex flex-row justify-content-between align-items-center products-article-container">
          <article>
            <h1>Devices</h1>
            <p>We have got clinical partnerships all around the world.</p>
          </article>
          {/* <button className='products-article-container-button'>Buy Now</button> */}
        </div>
        <div className="d-flex flex-row align-items-start products-content-container">
          <div onClick={() => handleCardClick(ProductsExp[0])} className="d-flex flex-column align-items-center products-content-cards">
            <div className="product-cards-imgContainer">
              <img className='product-card-img1' src={FlowHeadset} alt="" />
              {/* <button className='product-cards-imgContainer-btn'>Online Purchase</button> */}
            </div>
            <article><h3>Flow Headset</h3></article>
          </div>

          <div onClick={() => handleCardClick(ProductsExp[1])} className="d-flex flex-column align-items-center products-content-cards">
            <div className="product-cards-imgContainer">
              <img className='product-card-img2' src={TdcsDevices} alt="" />
              {/* <button className='product-cards-imgContainer-btn'>Online Purchase</button> */}
            </div>
            <article><h3>TDCS Devices & Software</h3></article>
          </div>

          <div onClick={() => handleCardClick(ProductsExp[2])} className="d-flex flex-column align-items-center products-content-cards">
            <div className="product-cards-imgContainer">
              <img className='product-card-img3' src={PbmDevices} alt="" />
            </div>
            <article><h3>PBM Devices & Software</h3></article>
          </div>

        </div>

        {selectedDevice && (
          <div id='productEXP' className='productBio-container d-flex flex-column align-items-center'>
            <TbTriangleInvertedFilled className={selectedDevice.style}/>
            <div id='product-bio' className="productsExplanation-container position-relative">
            <button onClick={handleButtonClick} className="productsExp-btn d-flex flex-row justify-content-center align-items-center">X</button>
              <h3>{selectedDevice.name}</h3>
              <p>{selectedDevice.explanation}</p>
            </div>
          </div>
        )}

      </div>
    </>
  )
}

export default ProductsComponent