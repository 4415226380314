import React from 'react'
import { SEO, Navbar, Footer, CorporateClinicHeader } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';

const CorporateClinic = () => {
    return (
        <>
            <SEO
                title = 'Cooperate with DeepSynaps - Enhance Employee Well-being and Productivity'
                description= 'Partner with DeepSynaps to optimize the brain health and cognitive performance of your workforce. Explore our tailored corporate wellness programs, brain optimization interventions, and personalized solutions for cognitive enhancement and well-being.'
                url = 'https://oxfordmindtech.com/cooperate'
                keywords = 'Cooperate with DeepSynaps, employee well-being, productivity, corporate wellness programs, brain optimization interventions, cognitive enhancement'
            />
            <Navbar />
            <CorporateClinicHeader />
            <br /><br />
            <Footer />
            <WhatsAppWidget
                sendButtonText={`Start Chat`}
                companyName={`DeepSynaps`}
                message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`}
                CompanyIcon={CompanyIcon}
                phoneNumber="447429910079"
            />
        </>
    )
}

export default CorporateClinic