import { React, useState } from 'react'
import './cognitiveTestHeader.css'
import learnMore from './learnMore.module.css'
import { Assesment1, Assesment2, Assesment3, Assesment4, Assesment5, Assesment6, Assesment7, Assesment8, Assesment9, Assesment10, Assesment11, Assesment12, Assesment13, Assesment14, Assesment15, Assesment16, Assesment17} from '../../../assets/images';
import {Link} from "react-router-dom";

const CognitiveTestHeader = () => {
  
  const [showDiagnostic1, setShowDiagnostic1] = useState(true)
  const [showDiagnostic2, setShowDiagnostic2] = useState(false)
  const [showDiagnostic3, setShowDiagnostic3] = useState(false)
  const [showDiagnostic4, setShowDiagnostic4] = useState(false)

  const showWhatItIs = () => { // Showing 1st element
    setShowDiagnostic1(true)
    setShowDiagnostic2(false)
    setShowDiagnostic3(false)
    setShowDiagnostic4(false)
  }

  const showHowItWorks = () => { // Showing 2nd element
    setShowDiagnostic1(false)
    setShowDiagnostic2(true)
    setShowDiagnostic3(false)
    setShowDiagnostic4(false)
  }

  const showConditions = () => { // Showing 3rd element
    setShowDiagnostic1(false)
    setShowDiagnostic2(false)
    setShowDiagnostic3(true)
    setShowDiagnostic4(false)
  }

  const showBenefits = () => { // Showing 4th element
    setShowDiagnostic1(false)
    setShowDiagnostic2(false)
    setShowDiagnostic3(false)
    setShowDiagnostic4(true)
  }

  const cogniFitAssesments = [
    {
      assesmentImg: Assesment1,
      h5: 'General Cognitive Assessment (CAB)',
      p: 'How can I know if my brain is healthy?',
      learnBtn: 'CAB',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment2,
      h5: '65 and Over Cognitive Assessment (CAB-AG)',
      p: 'Test how fast is your brain aging',
      learnBtn: 'CABAG',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment3,
      h5: 'Academic Cognitive Assessment (CAB-A)',
      p: 'Cognitive test that was created specifically for schools',
      learnBtn: 'CABA',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment4,
      h5: 'Concentration Cognitive Assessment (CAB-AT)',
      p: 'How good is your concentration?',
      learnBtn: 'CABAT',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment5,
      h5: 'Coordination Cognitive Assessment (CAB-CO)',
      p: 'How good is your coordination?',
      learnBtn: 'CABCO',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment6,
      h5: 'Deary-Liewald Task (CAT-DL)',
      p: `Measuring the user's reaction time in simple situations`,
      learnBtn: 'CATDL',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment7,
      h5: 'Driving Cognitive Assessment (DAB)',
      p: 'Are You Fit To Drive?',
      learnBtn: 'DAB',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment8,
      h5: `Eriksen's Flanker Task (CAT-EF)`,
      p: 'Are You Fit To Drive?',
      learnBtn: 'CATEF',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment9,
      h5: 'Inhibition of Return Task (CAT-IOR)',
      p: 'This task seeks to measure how much interference is generated by the presentation of adjacent incongruent and irrelevant visual information.',
      learnBtn: 'CATIOR',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment10,
      h5: 'Memory Cognitive Assessment (CAB-ME)',
      p: 'How good is your memory?',
      learnBtn: 'CABME',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment11,
      h5: 'Perception Cognitive Assessment (CAB-PC)',
      p: 'How good is your perception?',
      learnBtn: 'CABPC',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment12,
      h5: 'Reading Comprehension Cognitive Assessment (CAB-RC)',
      p: 'Do you have reading difficulties?',
      learnBtn: 'CABRC',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment13,
      h5: 'Reasoning Cognitive Assessment (CAB-RS)',
      p: 'How good is your reasoning?',
      learnBtn: 'CABRS',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment14,
      h5: 'Simon Task (CAT-ST)',
      p: 'Identify how much interference is produced when spatial information (word position) conflicts with semantic information (word meaning).',
      learnBtn: 'CATST',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment15,
      h5: 'Tower of Hanoi (CAT-TOH)',
      p: 'Identify how much interference is produced in the user when spatial information (word position) conflicts with semantic information',
      learnBtn: 'CATTOH',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment16,
      h5: 'Trail Making Test (CAT-TMT)',
      p: 'Cognitive abilities mainly related to executive functions such as planning, visual imagery, abstract thinking, working memory',
      learnBtn: 'CATTMT',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    },{
      assesmentImg: Assesment17,
      h5: 'Visual Episodic Memory Test (CAT-VEM)',
      p: 'Process speed, cognitive flexibility, the ability to make an effective visual scan, as well as other underlying executive functions.',
      learnBtn: 'CATVEM',
      startBtn: 'https://connect.pabau.com/bookings.php?compid=13311',
    }
  ]

  const firstHalf = cogniFitAssesments.slice(0, 9); // İlk 10 öğeyi alır
  const secondHalf = cogniFitAssesments.slice(9); // 11. öğeden sonuncu öğeye kadar olanları alır

  // const firstHalf = cogniFitAssesments.slice(0, cogniFitAssesments.length / 2);
  // const secondHalf = cogniFitAssesments.slice(cogniFitAssesments.length / 2);

  return (
    <header id='headerDiagnostic' className='d-flex flex-column'>
      <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
        <h1>Cognitive Test</h1>
        <div className='d-flex flex-column position-relative'>
          <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
          <br /> 
          {/* Button trigger modal */}
          <button type="button" className="cognifitTest btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">Book a Test</button>
          <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>

          {/* Cognitive tests Modal */}
          <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title modal-titleTest" id="exampleModalLabel">Book an Online Tests</h4>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div className="modal-body-content">  
                    <div className="assesment-container d-flex flex-column align-items-start">
                      {firstHalf.map((assesment, index) => (
                        <div className="assesment" key={index}>
                          <img className='assesmentImg' src={assesment.assesmentImg} alt='' />
                          <div className='assesmentArticle'>
                            <h5>{assesment.h5}</h5>
                            <p>{assesment.p}</p>
                          </div>
                          <div className="assesment-btnContainer">
                            {/* <a className='learnMore-btn' href={assesment.learnBtn}>Learn More</a> */}
                            <button type="button" className='learnMore-btn' data-bs-toggle="modal" data-bs-target={`#${assesment.learnBtn}`}>Learn More</button>
                            <a className='start-btn' href={assesment.startBtn} target='_blank' rel="noopener noreferrer">Start</a>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="assesment-container d-flex flex-column align-items-start">
                      {secondHalf.map((assesment, index) => (
                        <div className="assesment" key={index}>
                          <img className='assesmentImg' src={assesment.assesmentImg} alt='' />
                          <div className='assesmentArticle'>
                            <h5>{assesment.h5}</h5>
                            <p>{assesment.p}</p>
                          </div>
                          <div className="assesment-btnContainer">
                            {/* <a className='learnMore-btn' href={assesment.learnBtn}>Learn More</a> */}
                            <button type="button" className='learnMore-btn' data-bs-toggle="modal" data-bs-target={`#${assesment.learnBtn}`}>Learn More</button>
                            <a className='start-btn' href={assesment.startBtn} target='_blank' rel="noopener noreferrer">Start</a>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Modal ends */}
          {/* --------------------LearnMore Modals---------- */}
          {/* Assesment1 */}
          <div className="modal fade" id="CAB" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">General Cognitive Assessment (CAB)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment1} alt='General Cognitive Assessment (CAB)' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>The General Assessment Battery (CAB) helps healthcare professionals precisely evaluate a wide range of abilities and detect cognitive well-being (high-moderate-low) in patients. With this tool, the health professional can identify strengths and weaknesses in the areas of memory, concentration/attention, executive functions, planning, and coordination, as well as, discover the general cognitive profile of the patient. This test is suitable for children 7+, teens, adults or seniors. The battery can be used cross-culturally, and for both males and females.</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assesment2 */}
          <div className="modal fade" id="CABAG" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">65 and Over Cognitive Assessment (CAB-AG)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment2} alt='65 and Over Cognitive Assessment (CAB-AG)' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>The 65 and Over Cognitive Assessment (CAB-AG) assesses the cognitive processes that are influenced by aging. Using this too, healthcare professionals can carry out a complete cognitive screening, understand the cognitive strengths and weaknesses of the patient/user. This assessment takes 13-16 minutes to complete. This assessment is suitable for adults and seniors over the age of 65. The assessment can be used cross-culturally, and for both men and women.</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assesment4 */}
          <div className="modal fade" id="CABAT" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Concentration Cognitive Assessment (CAB-AT)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment4} alt='Concentration Cognitive Assessment (CAB-AT)' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>The Concentration Cognitive Assessment (CAB-AT) studies brain function, performs a complete cognitive screening and assesses the cognitive processes that influence concentration. This assessment takes 15-20 minutes to complete This assessment is suitable for children over 7 years of age, youth, adults, or seniors. The assessment can be used cross-culturally, and for both men and women.</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assesment5 */}
          <div className="modal fade" id="CABCO" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Coordination Cognitive Assessment (CAB-CO)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment5} alt='Coordination Cognitive Assessment (CAB-CO)' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>The Coordination Cognitive Assessment (CAB-CO) is a scientific tool that allows healthcare professionals to carry out a complete screening, understand the cognitive strengths and weaknesses related to coordination, and evaluate motor-related ability. This assessment takes 20-30 minutes to complete This assessment is suitable for children over 7 years of age, youth, adults, or seniors. The assessment can be used cross-culturally, and for both men and women.</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assesment6 */}
          <div className="modal fade" id="CATDL" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Deary-Liewald Test (CAT-DL)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment6} alt='Deary-Liewald Test (CAT-DL)' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>The performance of the task will allow measuring the user’s reaction time in simple situations (a single button response) and in more complex situations (four alternative responses). The difference in accuracy and reaction time between the two conditions allows for isolating the “choice effect”. Evaluated cognitive abilities: Reaction time and processing speed.</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assesment7 */}
          <div className="modal fade" id="DAB" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Driving Cognitive Assessment (DAB)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment7} alt='' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>The Driving Cognitive Assessment (DAB) helps the healthcare professional evaluate the cognitive/ psychological processes involved in driving and assesses the driver&apos;s skills, abilities and performance. Healthcare professionals use this assessment to evaluate the risk level or accident tendency index. This assessment takes 30-40 minutes to complete. This assessment is appropriate for adolescents looking to obtain their license, as well as adults and seniors. The battery can be used cross-culturally, and for both males and females. It can be used to assess teens learning how to drive, driving adults, and seniors.</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assesment8 */}
          <div className="modal fade" id="CATEF" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Eriksen's Flanker Task (CAT-EF)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment8} alt="Eriksen's Flanker Task (CAT-EF)" />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>Eriksen's Flanker Test is a digitized replica of the task of the same name (Eriksen and Eriksen, 1974). This task seeks to measure how much interference is generated by the presentation of adjacent incongruent and irrelevant visual information. The difference in reaction time between incongruent and congruent trials responded to correctly is what is known as the Flankers Effect.</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assesment9 */}
          <div className="modal fade" id="CATIOR" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Inhibition of Return Test (CAT-IOR)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment9} alt='Inhibition of Return Test (CAT-IOR)' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>Inhibition of return (IOR) test is a digitized replica of the homonymous task (Posner & Cohen, 1984). This task allows the assessment of the phenomenon known as "inhibition of return", which consists of people responding more slowly to stimuli located in the position where at least 300 milliseconds before a task-irrelevant stimulus was shown.</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assesment10 */}
          <div className="modal fade" id="CABME" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Memory Cognitive Assessment (CAB-ME)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment10} alt='Memory Cognitive Assessment (CAB-ME)' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>The Memory Cognitive Assessment (CAB-ME) assesses the cognitive processes that influence the different types of memory. Healthcare professionals can use this evaluation to quickly and accurately assess the cognitive processes associated with memory. This assessment takes 15-20 minutes to complete. This assessment is suitable for children over 7 years of age, youth, adults, or seniors. The assessment can be used cross-culturally, and for both men and women.</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assesment11 */}
          <div className="modal fade" id="CABPC" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Perception Cognitive Assessment (CAB-PC)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment11} alt='Perception Cognitive Assessment (CAB-PC)' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>The Perception Cognitive Assessment (CAB-PC) helps healthcare professionals to carry out a complete cognitive screening, to understand the weaknesses and strengths related to the functioning of perception. This assessment takes 20-30 minutes to complete. This assessment is suitable for children over 7 years of age, youth, adults, or seniors. The assessment can be used cross-culturally, and for both men and women.</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assesment12 */}
          <div className="modal fade" id="CABRC" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Reading Comprehension Cognitive Assessment (CAB-RC)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment12} alt='Reading Comprehension Cognitive Assessment (CAB-RC)' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>The Reading Comprehension Cognitive Assessment (CAB-RC) is a leading professional tool that allows to understand, through digitized neuropsychological tasks, the state of the different mental abilities related to reading comprehension. The results obtained in this test show relevant information about the state of the cognitive skills involved in reading comprehension (low-medium-high). This assessment takes 10-15 minutes to complete. This assessment is suitable for children over 7 years of age, youth, adults, or seniors. The assessment can be used cross-culturally, and for both men and women.</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assesment13 */}
          <div className="modal fade" id="CABRS" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Reasoning Cognitive Assessment (CAB-RS)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment13} alt='Reasoning Cognitive Assessment (CAB-RS)' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>The Reasoning Cognitive Assessment (CAB-RS) tool helps healthcare professionals quickly and accurately assess the cognitive processes associated with executive functions. This test allows us to carry out a complete cognitive analysis to understand the weaknesses and strengths of the functions related to reasoning. This assessment takes 20-25 minutes to complete. This assessment is suitable for children over 7 years of age, youth, adults, or seniors. The assessment can be used cross-culturally, and for both men and women.</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assesment14 */}
          <div className="modal fade" id="CATST" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Simon Task (CAT-ST)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment14} alt='Simon Task (CAT-ST)' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>Simon Task is a digitized replica of the task of the same name (Simon and Wolf, 1963). The performance of the task will allow identifying the difference between the reaction time between incongruent and congruent trials that have been answered correctly, which is known as the Simon Effect. This allows us to identify how much interference is produced in the user when spatial information (word position) conflicts with semantic information (word meaning).</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assesment15 */}
          <div className="modal fade" id="CATTOH" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Tower of Hanoi (CAT-TOH)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment15} alt='Tower of Hanoi (CAT-TOH)' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>Tower of Hanoi Test (TOH) is a digitized replica of the task of the same name (Hinz, 1989). This task seeks to measure high-order cognitive problem solving and learning of complex cognitive procedures, providing information about the cognitive abilities mainly related to executive functions such as planning, visual imagery, abstract thinking, working memory and self-monitoring.</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assesment16 */}
          <div className="modal fade" id="CATTMT" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Trail Making Test (CAT-TMT)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment16} alt='Trail Making Test (CAT-TMT)' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>Trail Making Test (TMT) is a digitized replica of the task of the same name (Reitan, 1955; Reitan, 1958). This task tests processing speed, cognitive flexibility, the ability to make an effective visual scan, as well as other underlying executive functions.</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assesment17 */}
          <div className="modal fade" id="CATVEM" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Visual Episodic Memory Test (CAT-VEM)</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={Assesment17} alt='Visual Episodic Memory Test (CAT-VEM)' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}>Visual Episodic Memory Test is a version of the Memtrax memory assessment task (Ashford, 2005). This task seeks to measure episodic memory through items of a visual nature, identifying only whether the displayed image has been previously displayed or not.</p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  
          <div className="modal fade" id="" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel"></h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body ${learnMore.modalBody}`}>
                  <img className={learnMore.modalBodyImg} src={} alt='' />
                  <div className={`d-flex flex-column position-relative justify-content-stretch ${learnMore.modalBodyArticle}`}>
                    <p className={`modalBodyP ${learnMore.modalBodyP}`}></p>
                    <a className={`start-btn ${learnMore.startBtn}`} href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Start</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          */}
        </div>
      </article>
      <div className="breakline-diagnostic"></div>

      <div className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
        <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      </div>

      {/*<div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">*/}
      {/*  <div className="headerDiagnostic-options d-flex flex-column align-items-start">*/}
      {/*    <button className={showDiagnostic1 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showWhatItIs}>What it is?</button>*/}
      {/*    <button className={showDiagnostic2 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showHowItWorks}>How it works?</button>*/}
      {/*    <button className={showDiagnostic3 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showConditions}>Skills</button>*/}
      {/*    <button className={showDiagnostic4 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showBenefits}>Benefits</button>*/}
      {/*  </div>*/}
      {/*  {showDiagnostic1 && */}
      {/*    <div className="headerDiagnostic-content d-flex flex-row align-items-start">*/}
      {/*      <div className="diagnosticArticle2 d-flex flex-column align-items-start">*/}
      {/*        <h1>What it is?</h1>*/}
      {/*        <p>Cognitive  assessments are designed to measure a variety of cognitive skills, including attention, memory, processing speed, and executive function. Cognitive  assessments are used by a variety of people, including:</p>*/}
      {/*        <ul>*/}
      {/*          <li><b>Healthcare professionals:</b> Cognitive  assessments can be used by healthcare professionals to assess cognitive function in people with a variety of conditions, including Alzheimer's disease, ADHD, and traumatic brain injury.</li>*/}
      {/*          <li><b>Organizations:</b> Cognitive  assessments can be used by organizations to assess cognitive function in their employees. This can be helpful for identifying employees who may be at risk for developing cognitive problems, as well as for tracking the cognitive health of employees over time.</li>*/}
      {/*          <li><b>Individuals:</b> Cogniitive  assessments can be used by individuals to assess their own cognitive function. This can be helpful for people who are concerned about their cognitive health, as well as for people who are looking for ways to improve their cognitive function.</li>*/}
      {/*        </ul>*/}
      {/*        <p>Cognitive  assessments are based on a scientific foundation. They have been developed by a team of experts in neuroscience, psychology, and education. Cognitive assessments are also validated. This means that they have been shown to be accurate and reliable.</p>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  }{showDiagnostic2 && */}
      {/*    <div className="headerDiagnostic-content">*/}
      {/*      <div className="diagnosticArticle2 d-flex flex-column align-items-start">*/}
      {/*        <h1>How it works?</h1>*/}
      {/*        <p><b>Cognitive assessments work by using a variety of methods to measure cognitive function. These methods include:</b></p>*/}
      {/*        <ul>*/}
      {/*          <li><b>Computerized tasks:</b> Cognitive  assessments uses a variety of computerized tasks to measure cognitive function. These tasks are designed to assess a variety of cognitive skills, including attention, memory, processing speed, and executive function.</li>*/}
      {/*          <li><b>Neuroimaging:</b> Cognitive assessments  uses neuroimaging to measure brain activity. This information can be used to identify areas of the brain that are involved in cognitive function.</li>*/}
      {/*          <li><b>Self-report:</b> Cognitive assessments  also uses self-report to measure cognitive function. This involves asking the individual to complete a questionnaire about their cognitive abilities.</li>*/}
      {/*          <li>The results of cognitive assessments are used to create a personalized cognitive profile. This profile can be used to identify areas where the individual needs to improve, as well as to track their progress over time.</li>*/}
      {/*        </ul>*/}
      {/*        <p><b>Cognitive assessments are a safe and effective way to measure cognitive function. They are used by a variety of people, including healthcare professionals, organizations, and individuals.<br /><br />Here are some of the cognitive skills that  cognitive assessments measure:</b></p>*/}
      {/*        <ul>*/}
      {/*          <li><b>Attention:</b> This includes the ability to focus on a task and to resist distractions.</li>*/}
      {/*          <li><b>Memory:</b> This includes the ability to store and recall information.</li>*/}
      {/*          <li><b>Processing speed:</b> This includes the ability to think quickly and to make decisions.</li>*/}
      {/*          <li><b>Executive function:</b> This includes the ability to plan, organize, and manage tasks.</li>*/}
      {/*          <li>cognitive assessments can be used to improve cognitive function. The assessments are designed to challenge the individual and to help them to improve their performance. Over time, this can lead to improvements in cognitive function.</li>*/}
      {/*        </ul>*/}
      {/*        <iframe className='embed-ytb howItWorks-ytb' src="https://www.youtube-nocookie.com/embed/is6WYnb280U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  }{showDiagnostic3 &&*/}
      {/*    <div className="headerDiagnostic-content">*/}
      {/*      <div className="diagnosticArticle3 d-flex flex-column align-items-start">*/}
      {/*        <h1>Skills</h1>*/}
      {/*        <p><b>Cognitive assessments measure a variety of cognitive skills, including:</b></p>*/}
      {/*        <ul>*/}
      {/*          <li><b>Attention:</b> This includes the ability to focus on a task and to resist distractions.</li>*/}
      {/*          <li><b>Memory:</b> This includes the ability to store and recall information.</li>*/}
      {/*          <li><b>Processing speed:</b> This includes the ability to think quickly and to make decisions.</li>*/}
      {/*          <li><b>Executive function:</b> This includes the ability to plan, organize, and manage tasks.</li>*/}
      {/*          <li><b>Coordination:</b> This includes the ability to control the body's movements.</li>*/}
      {/*          <li><b>Visual-spatial reasoning:</b> This includes the ability to understand and visualize spatial relationships.</li>*/}
      {/*          <li><b>Language:</b> This includes the ability to understand and use language.</li>*/}
      {/*          <li><b>Working memory:</b> This includes the ability to hold information in mind while working on a task.</li>*/}
      {/*        </ul>*/}
      {/*        <p><b>Cognitive assessments can be used to measure a variety of conditions, including:</b></p>*/}
      {/*        <ul>*/}
      {/*          <li><b>Alzheimer's disease:</b> This is a neurodegenerative disease that causes progressive memory loss and cognitive decline.</li>*/}
      {/*          <li><b>ADHD:</b> This is a neurodevelopmental disorder that is characterized by inattention, hyperactivity, and impulsivity.</li>*/}
      {/*          <li><b>Traumatic brain injury:</b> This is an injury to the brain that can cause a variety of cognitive problems, including memory loss, difficulty concentrating, and problems with decision-making.</li>*/}
      {/*        </ul>*/}
      {/*        <p>Cognitive assessments can also be used to measure cognitive function in healthy individuals. This can be helpful for identifying people who may be at risk for developing cognitive problems, as well as for tracking the cognitive health of healthy individuals over time.</p>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  }{showDiagnostic4 &&*/}
      {/*    <div className="headerDiagnostic-content">*/}
      {/*      <div className="diagnosticArticle4 d-flex flex-column align-items-start">*/}
      {/*        <h1>Benefits</h1>*/}
      {/*        <p><b>The benefits of using cognitive assessments include:</b></p>*/}
      {/*        <ul style={{gap:'12px'}} className='d-flex flex-column'>*/}
      {/*          <li><b>Accurate:</b> Cognitive assessments are accurate and reliable. They have been shown to be effective in measuring a variety of cognitive skills.</li>*/}
      {/*          <li><b>Personalized:</b> Cognitive assessments are personalized. This means that they are tailored to the individual's specific needs and goals.</li>*/}
      {/*          <li><b>Easy to use:</b> Cognitive assessments are easy to use. They can be completed online or on a mobile device.</li>*/}
      {/*          <li><b>Fun:</b> Cognitive assessments are fun and engaging. This makes them more likely to be completed by the individual.</li>*/}
      {/*          <li><b>Improve cognitive function:</b> cognitive assessments can be used to improve cognitive function. The assessments are designed to challenge the individual and to help them to improve their performance. Over time, this can lead to improvements in cognitive function.</li>*/}
      {/*          <li><b>Identify areas for improvement:</b> cognitive assessments can help you to identify areas where you need to improve. This can be helpful for setting goals and for developing a plan to improve your cognitive function.</li>*/}
      {/*          <li><b>Track progress over time:</b> cognitive assessments can be used to track your progress over time. This can help you to see how your cognitive function is changing, and it can also help you to stay motivated.</li>*/}
      {/*        </ul>              */}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  }*/}
      {/*</div>*/}

      <div className="wrapper w-100 justify-content-between align-items-start">
        <div className="accordion cognitiveAccordion" id="cognitiveAccordion">
          <div className="accordion-item">
            <h2 className="accordion-header" id="heading1">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                Trail Making Test (CAT-TMT)
              </button>
            </h2>
            <div id="collapse1" className="accordion-collapse collapse" aria-labelledby="heading1"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Trail Making Test (CAT-TMT)</h3>
                <p>Navigate through numerical and alphabetical sequences to evaluate visual attention and task-switching capabilities.</p>
                <p>
                  <Link to={'/cognitivetest/trail-making-test'}>Learn more</Link>
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="heading2">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                Eriksen’s Flanker Test (CAT-EF)
              </button>
            </h2>
            <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Eriksen’s Flanker Test (CAT-EF)</h3>
                <p>Measure your attentional selectivity and control by responding to target stimuli amidst distractors.</p>
                <p>
                  <Link to={'/cognitivetest/eriksens-flanker-test'}>Learn more</Link>
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="heading3">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                Simon Test (CAT-ST)
              </button>
            </h2>
            <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Simon Test (CAT-ST)</h3>
                <p>Understand your ability to manage conflicting spatial stimulus-response associations.</p>
                <p>
                  <Link to={'/cognitivetest/simon-test'}>Learn more</Link>
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="heading4">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                Deary-Liewald Test (CAT-DL)
              </button>
            </h2>
            <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Deary-Liewald Test (CAT-DL)</h3>
                <p>Engage in a fluid intelligence evaluation through mental speed and accuracy in responding to varied stimuli.</p>
                <p>
                  <Link to={'/cognitivetest/deary-liewald-test'}>Learn more</Link>
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="heading5">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                Tower of Hanoi Test (CAT-TOH)
              </button>
            </h2>
            <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Tower of Hanoi Test (CAT-TOH)</h3>
                <p>Gauge your planning, organization, and problem-solving abilities through strategic movement of discs.</p>
                <p>
                  <Link to={'/cognitivetest/tower-of-hanoi-test'}>Learn more</Link>
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="heading6">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                Inhibition of Return Test (CAT-IOR)
              </button>
            </h2>
            <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading6"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Inhibition of Return Test (CAT-IOR)</h3>
                <p>Analyze attentional mechanisms and the behavioral inhibition of returning to previously inspected locations.</p>
                <p>
                  <Link to={'/cognitivetest/inhibition-of-return-test'}>Learn more</Link>
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="heading7">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                Visual Episodic Memory Test (CAT-VEM)
              </button>
            </h2>
            <div id="collapse7" className="accordion-collapse collapse" aria-labelledby="heading7"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Visual Episodic Memory Test (CAT-VEM)</h3>
                <p>Evaluate the power of your visual memory and recognition through episodic recall.</p>
                <p>
                  <Link to={'/cognitivetest/visual-episodic-memory-test'}>Learn more</Link>
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="heading8">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                Progressive Matrices Test (CAT-PM)
              </button>
            </h2>
            <div id="collapse8" className="accordion-collapse collapse" aria-labelledby="heading8"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Progressive Matrices Test (CAT-PM)</h3>
                <p>Engage in a non-verbal assessment of your abstract reasoning and problem-solving skills.</p>
                <p>
                  <Link to={'/cognitivetest/progressive-matrices-test'}>Learn more</Link>
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="heading9">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
                Selective Attention Test (CAT-SLA)
              </button>
            </h2>
            <div id="collapse9" className="accordion-collapse collapse" aria-labelledby="heading9"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Selective Attention Test (CAT-SLA)</h3>
                <p>Focus on identifying specific stimuli amidst distractors to measure your selective attention.</p>
                <p>
                  <Link to={'/cognitivetest/selective-attention-test'}>Learn more</Link>
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="heading10">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                Sustained Attention to Response Test (CAT-STA)
              </button>
            </h2>
            <div id="collapse10" className="accordion-collapse collapse" aria-labelledby="heading10"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Sustained Attention to Response Test (CAT-STA)</h3>
                <p>Assess your capacity to maintain focused attention and inhibit responses over extended periods.</p>
                <p>
                  <Link to={'/cognitivetest/sustained-attention-to-response-test'}>Learn more</Link>
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="heading11">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse11" aria-expanded="false" aria-controls="collapse11">
                Tapping Test
              </button>
            </h2>
            <div id="collapse11" className="accordion-collapse collapse" aria-labelledby="heading11"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Tapping Test</h3>
                <p>Examining motor speed and coordination through rapid and precise tapping sequences.</p>
                <p>
                  <Link to={'/cognitivetest/tapping-test'}>Learn more</Link>
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="heading12">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse12" aria-expanded="false" aria-controls="collapse12">
                Psychomotor Vigilance Test
              </button>
            </h2>
            <div id="collapse12" className="accordion-collapse collapse" aria-labelledby="heading12"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Psychomotor Vigilance Test</h3>
                <p>Assessing sustained and focused attention over time to gauge mental alertness and vigilance.</p>
                <p>
                  <Link to={'/cognitivetest/psycho-motorvigilance-test'}>Learn more</Link>
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="heading13">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse13" aria-expanded="false" aria-controls="collapse13">
                Visual Search Test
              </button>
            </h2>
            <div id="collapse13" className="accordion-collapse collapse" aria-labelledby="heading13"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Visual Search Test</h3>
                <p>Investigate your visual scanning and selective attention by identifying target stimuli amongst distractors.</p>
                <p>
                  <Link to={'/cognitivetest/visual-search-test'}>Learn more</Link>
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="heading14">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse14" aria-expanded="false" aria-controls="collapse14">
                Stop Signal Test
              </button>
            </h2>
            <div id="collapse14" className="accordion-collapse collapse" aria-labelledby="heading14"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Stop Signal Test</h3>
                <p>Evaluate your inhibitory control and cognitive flexibility by responding to go/stop signal changes.</p>
                <p>
                  <Link to={'/cognitivetest/stop-signal-test'}>Learn more</Link>
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="heading15">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse15" aria-expanded="false" aria-controls="collapse15">
                Visual Organization Test
              </button>
            </h2>
            <div id="collapse15" className="accordion-collapse collapse" aria-labelledby="heading15"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Visual Organization Test</h3>
                <p>Investigate your capabilities to organize and mentally manipulate visual information.</p>
                <p>
                  <Link to={'/cognitivetest/visual-organization-test'}>Learn more</Link>
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="heading16">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse16" aria-expanded="false" aria-controls="collapse16">
                Number Size Congruency Test
              </button>
            </h2>
            <div id="collapse16" className="accordion-collapse collapse" aria-labelledby="heading16"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Number Size Congruency Test</h3>
                <p>Explore your ability to manage conflicting numerical and physical size information.</p>
                <p>
                  <Link to={'/cognitivetest/number-size-congruency-test'}>Learn more</Link>
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="heading17">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse17" aria-expanded="false" aria-controls="collapse17">
                Digit Span Test
              </button>
            </h2>
            <div id="collapse17" className="accordion-collapse collapse" aria-labelledby="heading17"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Digit Span Test</h3>
                <p>Measure your working memory capacity and attention through sequence recall.</p>
                <p>
                  <Link to={'/cognitivetest/digit-span-test'}>Learn more</Link>
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="heading18">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse18" aria-expanded="false" aria-controls="collapse18">
                Time Estimation Test
              </button>
            </h2>
            <div id="collapse18" className="accordion-collapse collapse" aria-labelledby="heading18"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Time Estimation Test</h3>
                <p>Gauge your time perception accuracy without relying on external time cues.</p>
                <p>
                  <Link to={'/cognitivetest/time-estimation-test'}>Learn more</Link>
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="heading19">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse19" aria-expanded="false" aria-controls="collapse19">
                Eye-Hand Coordination Test
              </button>
            </h2>
            <div id="collapse19" className="accordion-collapse collapse" aria-labelledby="heading19"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Eye-Hand Coordination Test</h3>
                <p>Investigate the synchronization between visual information processing and motor responses.</p>
                <p>
                  <Link to={'/cognitivetest/eye-hand-coordination-test'}>Learn more</Link>
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="heading20">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse20" aria-expanded="false" aria-controls="collapse20">
                Maze Test
              </button>
            </h2>
            <div id="collapse20" className="accordion-collapse collapse" aria-labelledby="heading20"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Maze Test</h3>
                <p>Navigate through complex paths to assess planning and problem-solving skills.</p>
                <p>
                  <Link to={'/cognitivetest/maze-test'}>Learn more</Link>
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="heading21">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse21" aria-expanded="false" aria-controls="collapse21">
                Visual Memory Test
              </button>
            </h2>
            <div id="collapse21" className="accordion-collapse collapse" aria-labelledby="heading21"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Visual Memory Test</h3>
                <p>Evaluate your ability to remember and recall visual information over short periods.</p>
                <p>
                  <Link to={'/cognitivetest/visual-memory-test'}>Learn more</Link>
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="heading22">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse22" aria-expanded="false" aria-controls="collapse22">
                Stroop Test
              </button>
            </h2>
            <div id="collapse22" className="accordion-collapse collapse" aria-labelledby="heading22"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Stroop Test</h3>
                <p>Understand your cognitive flexibility and ability to manage conflicting information.</p>
                <p>
                  <Link to={'/cognitivetest/stroop-test'}>Learn more</Link>
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="heading23">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse23" aria-expanded="false" aria-controls="collapse23">
                Visual Working Memory Span Test
              </button>
            </h2>
            <div id="collapse23" className="accordion-collapse collapse" aria-labelledby="heading23"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Visual Working Memory Span Test</h3>
                <p>Assess your visual working memory capacity and how you manage spatial information.</p>
                <p>
                  <Link to={'/cognitivetest/visual-working-memory-span-test'}>Learn more</Link>
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="heading24">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse24" aria-expanded="false" aria-controls="collapse24">
                Naming Test
              </button>
            </h2>
            <div id="collapse24" className="accordion-collapse collapse" aria-labelledby="heading24"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Naming Test</h3>
                <p>Gauge your verbal recall and lexical retrieval ability.</p>
                <p>This test assesses an individual's verbal recall and lexical retrieval ability, focusing on the cognitive processes of naming and word-finding.</p>
                <p>
                  <Link to={'/cognitivetest/naming-test'}>Learn more</Link>
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="heading25">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse25" aria-expanded="false" aria-controls="collapse25">
                Multi-Modal Lexical Memory Test
              </button>
            </h2>
            <div id="collapse25" className="accordion-collapse collapse" aria-labelledby="heading25"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Multi-Modal Lexical Memory Test</h3>
                <p>The test focuses on exploring memory functions with varied lexical and semantic stimuli, gauging the individual's ability to recall and recognize words across different presentation modalities and contextual cues.</p>
                <p>
                  <Link to={'/cognitivetest/multi-modal-lexical-memory-test'}>Learn more</Link>
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="heading26">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse26" aria-expanded="false" aria-controls="collapse26">
                Lexical Memory Test
              </button>
            </h2>
            <div id="collapse26" className="accordion-collapse collapse" aria-labelledby="heading26"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Lexical Memory Test</h3>
                <p>The aim is to evaluate an individual's ability to store and retrieve word-based and language-related information, implicating both short-term and long-term memory faculties in relation to lexical items.</p>
                <p>
                  <Link to={'/cognitivetest/lexical-memory-test'}>Learn more</Link>
                </p>
              </div>
            </div>
          </div>


          <div className="accordion-item">
            <h2 className="accordion-header" id="heading27">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse27" aria-expanded="false" aria-controls="collapse27">
                Eye-Hand Coordination Test
              </button>
            </h2>
            <div id="collapse27" className="accordion-collapse collapse" aria-labelledby="heading27"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Eye-Hand Coordination Test</h3>
                <p>Evaluate the ability to coordinate visual input with hand movements, which is crucial for numerous daily activities and specific occupational tasks.</p>
                <p>
                  <Link to={'/cognitivetest/eye-hand-coordination-test'}>Learn more</Link>
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="heading28">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse28" aria-expanded="false" aria-controls="collapse28">
                Speed Estimation Test
              </button>
            </h2>
            <div id="collapse28" className="accordion-collapse collapse" aria-labelledby="heading28"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Speed Estimation Test</h3>
                <p>This test assesses an individual’s ability to estimate and understand speed-related variables, often utilized to gauge perceptual and cognitive capacities related to dynamic spatial-temporal reasoning.</p>
                <p>
                  <Link to={'/cognitivetest/speed-tstimation-test'}>Learn more</Link>
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="heading29">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse29" aria-expanded="false" aria-controls="collapse29">
                Distance Estimation Test
              </button>
            </h2>
            <div id="collapse29" className="accordion-collapse collapse" aria-labelledby="heading29"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Distance Estimation Test</h3>
                <p>To evaluate an individual’s spatial reasoning and the ability to estimate distances, implicating skills in spatial awareness, perception, and cognitive estimation.</p>
                <p>
                  <Link to={'/cognitivetest/distance-tstimation-test'}>Learn more</Link>
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="heading30">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse30" aria-expanded="false" aria-controls="collapse30">
                Divided Attention Test
              </button>
            </h2>
            <div id="collapse30" className="accordion-collapse collapse" aria-labelledby="heading30"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Divided Attention Test</h3>
                <p>To evaluate the individual's capability to process and respond to multiple stimuli at the same time, assessing their cognitive control and multitasking abilities.</p>
                <p>
                  <Link to={'/cognitivetest/divided-attention-test'}>Learn more</Link>
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="heading31">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse31" aria-expanded="false" aria-controls="collapse31">
                Useful Field of View Test
              </button>
            </h2>
            <div id="collapse31" className="accordion-collapse collapse" aria-labelledby="heading31"
                 data-bs-parent="#cognitiveAccordion">
              <div className="accordion-body">
                <h3>Useful Field of View Test</h3>
                <p>This test is designed to assess an individual’s visual attention span, especially pertaining to the ability to process and respond to peripheral visual information without moving the eyes or head.</p>
                <p>
                  <Link to={'/cognitivetest/useful-field-of-view-test'}>Learn more</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default CognitiveTestHeader