import React, { useState } from 'react'; //, { useState }
import './navbar.css';
import { Link, NavLink } from 'react-router-dom';
import { navLogo, caretDownSvg, caretUpSvg, logoPng } from '../../../assets/images';

const NavbarMindtech = () => {
  // const [showServices, setShowServices] = useState(false); // Button click status
  // const [isMenuOpen, setIsMenuOpen] = useState(false);

  // const toggleMenu = () => {
  //   setIsMenuOpen(!isMenuOpen);
  // };

  // const closeMenu = () => {
  //   setIsMenuOpen(false);
  // }


  // const handleServicesClick = () => {
  //   setShowServices(!showServices); // Reverse state each time the button is clicked
  // };

  // const handleCloseServices = () => {
  //   setShowServices(false); // Close services div
  // };

  const [showScanSubMenu, setShowScanSubMenu] = useState(false)
  const [showAssessmentSubMenu, setShowAssessmentSubMenu] = useState(false)

  const handleScanMenu = (e) => {
    setShowScanSubMenu(showScanSubMenu === true ? false : true);
    e.preventDefault();
    e.stopPropagation();
  }

  const handleAssessmentMenu = (e) => {
    setShowAssessmentSubMenu(showAssessmentSubMenu === true ? false : true);
    e.preventDefault();
    e.stopPropagation();
  }

  return (
    <>
      <nav>
        <div className={"wrapper"}>
          <div className="desktopVOf-navbar d-flex">
            <button className='navbar-logo' onClick={() => document.getElementById('toHeader').scrollIntoView()}><Link to='/'><img src={navLogo} alt="MindetchLogo" /></Link></button>
            <ul className=''>
              <li><NavLink className="dropdown-item" to='/about'>About</NavLink></li>
              {/* <div className="dropdown">
                <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Services
                </button>
                <ul className="dropdown-menu">
                  <li><Link className="dropdown-item" to='/diagnostic'>Diagnostic</Link></li>
                  <li><Link className="dropdown-item" to='/treatments'>Treatments</Link></li>
                </ul>
              </div> */}
              {/* <NavLink to='/diagnostic'><li className='nav-about'>Diagnostic</li></NavLink> */}
              <li>
                <div className="dropdown">
                  <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Scan
                  </button>
                  <ul className="dropdown-menu">
                    <li><Link className="dropdown-item" to='/bodyscan'>Body Scan</Link></li>
                    <li><Link className="dropdown-item" to='/brainscan'>Brain Scan</Link></li>
                  </ul>
                </div>
              </li>
              {/*<NavLink to='/brainscan'><li className='nav-about'>Scan</li></NavLink>*/}
              <li>
                <div className="dropdown">
                  <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Assessments
                  </button>
                  <ul className="dropdown-menu">
                    <li><Link className="dropdown-item" to='/general-cognitive-assessment'>General Cognitive Assessment</Link></li>
                    <li><Link className="dropdown-item" to='/cognitivetest'>Cognitive Test</Link></li>
                    <li><Link className="dropdown-item" to='/mental-health-assessment'>Mental Health Assessment</Link></li>
                  </ul>
                </div>
              </li>
              <li><NavLink className="dropdown-item" to='/interventions'>Interventions</NavLink></li>
              <li><Link className="dropdown-item" to='/cooperate'>Cooperate</Link></li>
              <li><NavLink className="dropdown-item" to='/partners'>Partners</NavLink></li>
              <li><NavLink className="dropdown-item" to='/pricing'>Prices</NavLink></li>
            </ul>
            <div className='navbar-btn-container'>
              <a className='navbar-btn' href='https://connect.pabau.com/index.php?compid=13311' target='_blank' rel="noopener noreferrer">Sign-in</a>
            </div>
            {/* <div className='navbar-btn-container'>
              <a className='navbar-btn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
            </div> */}
          </div>
        </div>

        <div className="responsiveVOf-navbar d-flex flex-row justify-content-between align-items-center">
          <button className='navbar-logo' onClick={() => document.getElementById('toHeader').scrollIntoView()}><Link to='/'><img src={navLogo} alt="MindetchLogo" /></Link></button>
          
          <div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle hamburgerMenu" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
              
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
              <li><Link className="dropdown-item" to='/about'>About Us</Link></li>

              <li><a href={"#"} className="dropdown-item" onClick={(e) => handleScanMenu(e)}>Scan&nbsp;&nbsp;<img width={12} src={showScanSubMenu === true ? caretUpSvg : caretDownSvg} /></a></li>

              {showScanSubMenu &&
                  <>
                    <li><Link className="dropdown-item" to='/bodyscan'>&nbsp;&nbsp;Body Scan</Link></li>
                    <li><Link className="dropdown-item" to='/brainscan'>&nbsp;&nbsp;Brain Scan</Link></li>
                  </>
              }

              <a href={"#"} className="dropdown-item" onClick={(e) => handleAssessmentMenu(e)}>Assessments&nbsp;&nbsp;<img width={12} src={showAssessmentSubMenu === true ? caretUpSvg : caretDownSvg} /></a>

              {showAssessmentSubMenu &&
                  <>
                    <li><Link className="dropdown-item" to='/cognitivetest'>&nbsp;&nbsp;General Cognitive
                      Assessment</Link></li>
                    <li><Link className="dropdown-item" to='/mental-health-assessment'>&nbsp;&nbsp;Mental Health
                      Assessment</Link></li>
                  </>
              }


              <li><Link className="dropdown-item" to='/interventions'>Interventions</Link></li>
              <li><Link className="dropdown-item" to='/cooperate'>Cooperate</Link></li>
              <li><Link className="dropdown-item" to='/partners'>Partners</Link></li>
              <li><Link className="dropdown-item" to='/pricing'>Prices</Link></li>

              {/* <li><Link className="dropdown-item" to='/pricing'>Pricing</Link></li> */}
              {/* <li><Link className="dropdown-item" to='/products'>Products</Link></li> */}
              <br />
              <li style={{paddingLeft: "15px", display: "inline-block"}}><a className='navbar-Respbtn' href='https://connect.pabau.com/index.php?compid=13311' target='_blank' rel="noopener noreferrer">Sign-in</a></li>
              <br />
              {/* <li><a className='navbar-Respbtn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a></li> */}

            </ul>
          </div>

        </div>
      </nav>
    </>
  );
}

export default NavbarMindtech;
