import React from 'react'
import './clinic.css'

const ClinicAbout = () => {

  const handleRedirect = () => {
    // Sayfayı /contact'a yönlendir
    window.location.href = '/contact';
  };



  return (
    <>
      <div id='clinicAbout' className='wrapper d-flex flex-column justify-content-center align-items-center '>
        <article className='d-flex flex-column justify-content-start'>
          <h1>Become a partner</h1>
          <p>We have got clinical partnerships all around the world.</p>
        </article>
          <button onClick={handleRedirect} className="clinicAbout-btn">Contact Us</button>
        <div className="d-flex flex-row align-items-start clinicAbout-contentDiv">
          <div className="d-flex flex-column align-items-start clinicAbout-Content clinicAbout-Content1">
            <p>Fill the application form</p>
          </div>

          <div className="d-flex flex-column align-items-start clinicAbout-Content clinicAbout-Content2">
            <p>DeepSynaps team will assess your application outcomes</p>
          </div>

          <div className="d-flex flex-column align-items-start clinicAbout-Content clinicAbout-Content3">
            <p>DeepSynaps head office will contact with you about the application outcomes</p>
          </div>

          <div className="d-flex flex-column align-items-start clinicAbout-Content clinicAbout-Content4">
            <p>Work with our law team to complete sign process</p>
          </div>
          
          <div className="d-flex flex-column align-items-start clinicAbout-Content clinicAbout-Content5">
            <p>Discuss launching plan the header office</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default ClinicAbout