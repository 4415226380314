import { useEffect, useRef, useState } from 'react';
import './infoAbout.css'
import { AiBased, HqIcon } from '../../../assets/images'

const InfoAbout = () => {

  const [posX1, setPosX1] = useState(0);
  const [posY1, setPosY1] = useState(0);
  const [directionX1, setDirectionX1] = useState(1);
  const [directionY1, setDirectionY1] = useState(1);

  const [posX2, setPosX2] = useState(0);
  const [posY2, setPosY2] = useState(0);
  const [directionX2, setDirectionX2] = useState(1);
  const [directionY2, setDirectionY2] = useState(1);

  const container1Ref = useRef(null);
  const container2Ref = useRef(null);

  const animationId1 = useRef();
  const animationId2 = useRef();

  const box1Style = {
    position: 'absolute',
    top: `20px`,
    left: `${posX1}px`
    // top: `${posY1}px`,
    // left: `20px`
  };

  const box2Style = {
    position: 'absolute',
    bottom: `20px`,
    // bottom: `${posY2}px`,
    // bottom: `20px`,
    right: `${posX2}px`
  };

  useEffect(() => {
    const moveBox1 = () => {
      if (container1Ref.current) {
        // const containerWidth = container1Ref.current.offsetWidth;
        const containerWidth = container1Ref.current.offsetWidth - 300;
        const containerHeight = container1Ref.current.offsetHeight - 350;

        // Horizontal movement
        if (posX1 > containerWidth - 224) {
          setDirectionX1(-1);
        } else if (posX1 <= 0) {
          setDirectionX1(1);
        }
        setPosX1(posX1 + directionX1 * 0.5);
        // setPosX1(posX1 + directionX1 * 2);

        // Vertical movement
        if (posY1 > containerHeight - 110) {
          setDirectionY1(-1);
        } else if (posY1 <= 0) {
          setDirectionY1(1);
        }
        setPosY1(posY1 + directionY1 * 0.5);
        // setPosY1(posY1 + directionY1 * 2);
      }

      // Continue the animation
      animationId1.current = window.requestAnimationFrame(moveBox1);
    }

    const moveBox2 = () => {
      if (container2Ref.current) {
        const containerWidth = container2Ref.current.offsetWidth - 120;
        const containerHeight = container2Ref.current.offsetHeight;

        // Horizontal movement
        if (posX2 > containerWidth - 343) {
          setDirectionX2(-1);
        } else if (posX2 <= 0) {
          setDirectionX2(1);
        }
        setPosX2(posX2 + directionX2 * 0.5);
        // setPosX2(posX2 + directionX2 * 2);

        // Vertical movement
        if (posY2 > containerHeight - 110) {
          setDirectionY2(-1);
        } else if (posY2 <= 0) {
          setDirectionY2(1);
        }
        setPosY2(posY2 + directionY2 * 0.5);
        // setPosY2(posY2 + directionY2 * 2);
      }

      // Continue the animation
      animationId2.current = window.requestAnimationFrame(moveBox2);
    }

    // Start the animations
    animationId1.current = window.requestAnimationFrame(moveBox1);
    animationId2.current = window.requestAnimationFrame(moveBox2);

    // Cleanup function to stop the animations when the component is unmounted
    return () => {
      window.cancelAnimationFrame(animationId1.current);
      window.cancelAnimationFrame(animationId2.current);
    }
  }, [posX1, posY1, directionX1, directionY1, posX2, posY2, directionX2, directionY2]);

  return (
    <>
      <br  id='info'/>
      <div id='info-containerAbout' className="wrapper" /*style={{border:'1px dashed red'}}*/>
        <div className='d-flex align-items-center flex-row info'>
          <div className='infoImg-container1About' ref={container1Ref}>
            <div className="infoAbout-img1" style={box1Style}>
              <img src={AiBased} alt="purple-net-icon" />
              <h3>AI-based</h3>
            </div>
          </div>
          <article className='d-flex flex-column justify-content-center align-items-start infoAbout-article1'>
            <button className='infoArticle-button'>About DeepSynaps</button>
            <h1>Our Mission</h1>
            <p>Elevating human cognition and mental health through intricately personalized, AI-facilitated interventions, ensuring every mind navigates its distinctive journey toward well-being and peak performance with precision and understanding.</p>
          </article>
        </div>
        <div className="d-flex align-items-center  flex-row info">
          <article className='d-flex flex-column justify-content-center align-items-end infoAbout-article2'>
            <button className='infoArticle-button'>About DeepSynaps</button>
            <h1>Pioneering Personalized Neural Experiences</h1>
            <p>In a world ever-increasingly aware of the nuances of mental and cognitive health, DeepSynaps propels traditional practices into the future, employing AI-driven methodologies to carve out rich, personalized insights into your neurological and mental spectrums. This data becomes the linchpin upon which our team constructs bespoke mental health and cognitive enhancement strategies, ensuring interventions that resonate with your unique neural signature, thereby nurturing a harmonious balance of mind and body.</p>
          </article>
          <div className='infoImg-container2About' ref={container2Ref} style={{position:'relative'}}>
            <div className="infoAbout-img2" style={box2Style}>
              <img src={HqIcon} alt="purple-net-icon" />
              <h3>Highest Quality of Care</h3>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default InfoAbout