import React from 'react'
import '../assets/pageCss/contact.css'
import { SEO, Navbar, Footer } from '../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../assets/images/logo192.svg';



const Contact = () => {
  return (
    <>
        <SEO robots = 'noindex, nofollow' url='https://www.oxfordmindtech.com/contact'/>
        <Navbar />
        <section id='leadForm'>
          <div className="wrapper d-flex flex-row align-items-center">
            <div className="leadForm-imgDiv d-flex align-items-center justify-content-center"></div>
            <iframe title='leadForm-contact' className='leadForm-iFrame' src='https://crm.pabau.com/modules/lead-form/form.php?compid=13311'></iframe>
            <h1 className='leadForm-h1'>Contact Us</h1>
          </div>
        </section>
        <Footer/>
        <WhatsAppWidget 
          sendButtonText={`Start Chat`} 
          companyName={`DeepSynaps`}
          message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
          CompanyIcon={CompanyIcon} 
          phoneNumber="447429910079" 
        />        
    </>
  )
}

export default Contact