import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';
import { Link } from 'react-router-dom';


const PsychomotorVigilanceTest = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">

                        <div className={"row w-100"}>
                            <div className={"col-md-8 col-sm-12"}><h1>Psychomotor Vigilance Test</h1></div>
                            <div className={"col-md-4 col-sm-12 text-md-end text-sm-left pt-1"}>
                                <Link target={"_blank"} className={"btn btn-outline-dark"} to={'https://www.cognifit.com/battery-of-tests/rest-sper-test/resolution-test'}>Start test now</Link>
                            </div>
                        </div>

                        <p>Assessing sustained and focused attention over time to gauge mental alertness and vigilance.</p>
                        <p><strong>Relation to QEEG, Neurofeedback, Biofeedback, and Neuromodulation:</strong></p>
                        <ul>
                            <li><strong>Brain Region:</strong> Prefrontal Cortex, Anterior Cingulate Cortex</li>
                            <li><strong>Broadman Area:</strong> BA 9, BA 32</li>
                            <li><strong>EEG Electrode Places:</strong> Fz, FCz</li>
                            <li>
                                <strong>Neurofeedback:</strong>
                                <ul>
                                    <li><strong>Protocol:</strong> Targeting beta wave (15-18Hz) enhancement at frontal sites to foster sustained attention and vigilance.</li>
                                    <li><strong>Objective:</strong> Maintain an alert state and optimal performance across the duration of the PVT.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Biofeedback:</strong>
                                <ul>
                                    <li><strong>Assessment:</strong> HRV (Heart Rate Variability) to understand autonomic balance and stress response during the task.</li>
                                    <li><strong>Protocol:</strong> Employ HRV biofeedback to facilitate an optimal balance of sympathetic and parasympathetic activity, supporting sustained attention and managing physiological stress.</li>
                                    <li><strong>Objective:</strong> Ensuring that physiological arousal is conducive to maintaining focus and vigilance throughout the task.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Neuromodulation:</strong>
                                <ul>
                                    <li>
                                        <strong>tDCS:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Dorsolateral Prefrontal Cortex (DLPFC)</li>
                                            <li><strong>Protocol:</strong> Anodal stimulation to DLPFC to potentially enhance sustained attention and vigilance.</li>
                                            <li><strong>Objective:</strong> Sustain alertness and cognitive performance through the enhancement of neuronal excitability in attention-regulating regions.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>PBM:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Prefrontal Cortex</li>
                                            <li><strong>Protocol:</strong> Utilize photobiomodulation to support metabolic health and connectivity in the prefrontal cortex.</li>
                                            <li><strong>Objective:</strong> Maintain optimal neuronal function to support the cognitive demands of the PVT.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Mental Health Assessments and Their Relation:</strong>
                                <ul>
                                    <li><strong>SISRI-24 (Short Index of Self-Actualization):</strong> Explore the intrinsic motivation and self-actualization that may impact vigilance and attention. A higher sense of purpose and motivation might correlate with better performance on tasks requiring sustained attention.</li>
                                    <li><strong>Sleep Scale:</strong> Investigate the potential impact of sleep quality on sustained attention and vigilance, as poor sleep can detrimentally impact performance on tasks like the PVT.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>PVT emphasizes sustained attention and is especially pertinent in assessing an individual's capacity to maintain performance in tasks that require ongoing vigilance. Through implementing neurofeedback, biofeedback, and neuromodulation, individuals might hone their ability to preserve attention and response capabilities over prolonged periods, which can be evaluated and understood in the context of their broader cognitive and emotional profile.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default PsychomotorVigilanceTest