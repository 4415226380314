import { React, useState } from 'react'
import './cooperateHeader.css'
import {Link} from "react-router-dom";

const CorporateRehabilitationCenterComprehensiveRecoveryHeader = () => {

    return (
      <header id='headerDiagnostic' className='d-flex flex-column'>
        <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
          <h1>Cooperate</h1>
          <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and cognitive abilities, identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
          <a className='navbar-Respbtn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
        </article>
        <div className="breakline-diagnostic"></div>
  
        <div className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
          <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/L38T6fnrVjc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>

        <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                <h1>DeepSynaps Rehabilitation Center Comprehensive Recovery Package:</h1>
                  <ol>
                      <li>
                          <p><b>Comprehensive Physical and Cognitive Assessment:</b></p>
                          <ul>
                              <li>
                                  <p><b>Physical Assessment:</b></p>
                                  <p>Evaluation of patients' physical health and mobility, including EMG, ECG/Pulse, Respiratory, Temperature, and Skin condition assessments.</p>
                              </li>
                              <li>
                                  <p><b>Cognitive Assessment:</b></p>
                                  <p>Comprehensive cognitive assessments to measure memory, attention, problem-solving skills, and emotional well-being.</p>
                              </li>
                          </ul>
                      </li>

                      <li>
                          <p><b>Personalized Recovery Plans:</b></p>
                          <ul>
                              <li>
                                  <p><b>Neurorehabilitation Programs:</b></p>
                                  <p>Customized neurofeedback training to enhance cognitive function, memory, and emotional resilience for patients recovering from brain injuries or surgeries.</p>
                              </li>
                              <li>
                                  <p><b>Neuromodulation (TDCS-PBM):</b></p>
                                  <p>Transcranial Direct Current Stimulation (TDCS) and Photobiomodulation (PBM) sessions to optimize cognitive and physical recovery.</p>
                              </li>
                              <li>
                                  <p><b>Physical Rehabilitation:</b></p>
                                  <p>Tailored physical therapy and exercise plans to improve mobility and strength.</p>
                              </li>
                          </ul>
                      </li>

                      <li>
                          <p><b>Pain Management and Stress Reduction:</b></p>
                          <p>- Customized biofeedback sessions to assist patients in managing pain and stress during their rehabilitation.</p>
                          <p>- Stress reduction techniques to promote emotional well-being and support physical recovery.</p>
                      </li>

                      <li>
                          <p><b>Progress Tracking and Reporting:</b></p>
                          <p>- Detailed reports summarizing patients' physical and cognitive assessment results and progress over time.</p>
                          <p>- Regular monitoring of patient data to track improvements and adapt rehabilitation plans as necessary.</p>
                      </li>

                      <li>
                          <p><b>Equipment and Software:</b></p>
                          <p>DeepSynaps will provide diagnostic equipment, software licenses, and technical support to facilitate effective assessments and rehabilitation.</p>
                      </li>

                      <li>
                          <p><b>Care Team Collaboration:</b></p>
                          <p>Collaboration with rehabilitation center staff, including physical therapists, occupational therapists, and psychologists, to integrate cognitive assessments and enhancement techniques into treatment plans.</p>
                      </li>

                      <li>
                          <p><b>Confidentiality and Data Security:</b></p>
                          <p>Ensuring the highest level of data security and confidentiality for sensitive patient information.</p>
                      </li>
                  </ol>
                  <p>This specialized package is designed to provide comprehensive support for patients in rehabilitation centers, addressing both their physical and cognitive recovery needs. By combining advanced diagnostic assessments with personalized cognitive and physical rehabilitation interventions, it aims to improve patient outcomes and enhance their overall quality of life during the recovery process. DeepSynaps will work closely with rehabilitation centers to customize the package to meet the specific needs and conditions of their patients, ultimately contributing to more successful rehabilitation outcomes.</p>
              </div>
            </div>
        </div>
      </header>
    )
}

export default CorporateRehabilitationCenterComprehensiveRecoveryHeader