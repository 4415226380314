import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';
import { Link } from 'react-router-dom';


const NumberSizeCongruencyTest = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">

                        <div className={"row w-100"}>
                            <div className={"col-md-8 col-sm-12"}><h1>Number Size Congruency Test</h1></div>
                            <div className={"col-md-4 col-sm-12 text-md-end text-sm-left pt-1"}>
                                <Link target={"_blank"} className={"btn btn-outline-dark"} to={'https://www.cognifit.com/battery-of-tests/rest-inh-test/processing-test'}>Start test now</Link>
                            </div>
                        </div>

                        <p>Explore your ability to manage conflicting numerical and physical size information.</p>
                        <p><strong>- Objective:</strong> Explore the ability to manage conflicting numerical and physical size information.</p>
                        <p><strong>Relation to QEEG, Neurofeedback, Biofeedback, and Neuromodulation:</strong></p>
                        <ul>
                            <li><strong>Brain Region:</strong> Parietal Lobes, Prefrontal Cortex</li>
                            <li><strong>Broadman Area:</strong> BA 7, BA 9, BA 46</li>
                            <li><strong>EEG Electrode Places:</strong> Pz, Fp1, Fp2</li>
                            <li>
                                <strong>Neurofeedback:</strong>
                                <ul>
                                    <li><strong>Protocol:</strong> Training might emphasize increasing beta wave activity (13-30 Hz) in the prefrontal and parietal areas, to promote enhanced cognitive control and attention, and managing conflicting information effectively.</li>
                                    <li><strong>Objective:</strong> Enhance cognitive control, attention, and the ability to manage conflicting information efficiently through stable neural activity in relevant regions.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Biofeedback:</strong>
                                <ul>
                                    <li><strong>Assessment:</strong> Employing measures of skin conductance or heart rate to understand stress or anxiety responses to conflicting or challenging stimuli.</li>
                                    <li><strong>Protocol:</strong> Utilizing biofeedback to manage stress responses that may arise during tasks involving conflicting information and promoting relaxation and focused attention.</li>
                                    <li><strong>Objective:</strong> To improve the ability to manage stress responses during cognitive tasks and enhance focused attention and task engagement through adaptive physiological regulation.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Neuromodulation:</strong>
                                <ul>
                                    <li>
                                        <strong>tDCS:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Prefrontal Cortex, Parietal Lobes</li>
                                            <li><strong>Protocol:</strong> Anodal stimulation to promote neural excitability and plasticity in areas contributing to cognitive control and attention.</li>
                                            <li><strong>Objective:</strong> Enhance the ability to manage conflicting information, promote cognitive flexibility, and strengthen attentional networks.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>PBM:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Prefrontal Cortex</li>
                                            <li><strong>Protocol:</strong> Using PBM to promote metabolic activity and health in the prefrontal cortex which is instrumental in cognitive control.</li>
                                            <li><strong>Objective:</strong> To support and enhance the metabolic function of the prefrontal cortex, thereby potentially augmenting cognitive control capacities.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Mental Health Assessments and Their Relation:</strong>
                                <ul>
                                    <li><strong>Personality Scale:</strong> Understanding traits such as neuroticism or conscientiousness could offer insights into the individual's intrinsic ability or style in managing conflicting information.</li>
                                    <li><strong>SISRI-24 (Short Imaginal Processes Inventory):</strong> Insights into an individual’s imaginative capacity and style might indicate their creative problem-solving abilities and strategies used during tasks involving conflict or incongruence.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>Implementing interventions through neurofeedback, biofeedback, and neuromodulation can be invaluable in optimizing cognitive control and attention, particularly when managing conflicting or incongruent information. Tailoring interventions based on individual profiles, cognitive styles, and mental health statuses can further enhance the efficacy and applicability of these strategies.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default NumberSizeCongruencyTest