import React, {useEffect, useState} from 'react'
import { SEO, Navbar, Footer } from '../Components'
import '../assets/pageCss/book-location.css'
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import bookLocationSchema from '../Services/Schemas/BookLocationSchema'
import { getLocation, bookLocation } from "../Services/Apis/Location.service";
import {Link, useParams} from 'react-router-dom';
import { AliY } from '../assets/images/index';

const BookLocation = () => {

    const { register, watch, setValue, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(bookLocationSchema)
    });

    const [selectedLocation, setSelectedLocation] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const { location } = useParams();

    const handleBookLocation = async (data) => {

        if (!isLoading) {

            setIsLoading(true);

            const submitResponse = await bookLocation(location, data);
            setValue('name', '')
            setValue('surname', '')
            setValue('phone_number', '')
            setValue('email', '')
            setValue('service', '')
            setValue('booking_date', '')
            setValue('booking_time', '')
            setValue('subscribe', '')
            setIsLoading(false);
            setShowSuccessMessage(true);

            setTimeout(() => {

                setShowSuccessMessage(false);
            }, 20000);
        }
    }

    useEffect(() => {
        (async () => {
            const response = await getLocation(location);
            setSelectedLocation(response.data);
        })();
    }, [])

  return (
    <>
    <SEO
      title = 'Book Location DeepSynaps - Revolutionizing Brain Health and Cognitive Performance'
      description= 'Learn about DeepSynaps, a leading startup specializing in advanced technologies for optimizing brain health and unlocking cognitive potential. Explore our team, expertise, and commitment to enhancing mental well-being.'
      url = 'https://oxfordmindtech.com/about'
      keywords = 'DeepSynaps, revolutionizing brain health, cognitive performance, advanced technologies, cognitive potential, mental well-being'
    />
    <Navbar />
    <div className={"wrapper bookLocation"}>
      <div className={"container"}>
        <div className={"row"}>
          <div className={"col-sm-12 col-md-7 bookLocationLeft"}>
              <h1>Book now</h1>
              <div className={"locationNameRow"}>You are booking an appointment at <strong>{ selectedLocation?.name }, { selectedLocation?.address }</strong></div>
              <form onSubmit={handleSubmit(handleBookLocation)}>
                <div className="form-floating mb-3 mt-4">
                  <input type="text" className="form-control" id="name" placeholder={"Name"} {...register("name")} />
                  <label htmlFor="name">Name</label>
                  <div className="invalidFeedbackError">{errors?.name?.message}</div>
                </div>
                <div className="form-floating mb-3">
                    <input type="text" className="form-control" id="surname" placeholder={"Surname"} {...register("surname")} />
                    <label htmlFor="surname">Surname</label>
                    <div className="invalidFeedbackError">{errors?.surname?.message}</div>
                </div>
                <div className="form-floating mb-3">
                  <input type="email" className="form-control" id="email" placeholder={"Email"} {...register("email")} />
                    <label htmlFor="email">Email</label>
                  <div className="invalidFeedbackError">{errors?.email?.message}</div>
                </div>
                <div className="form-floating mb-3">
                  <input type="tel" className="form-control" id="phone" placeholder={"Phone Number"} {...register("phone_number")} />
                    <label htmlFor="phone">Phone Number</label>
                  <div className="invalidFeedbackError">{errors?.phone_number?.message}</div>
                </div>
                  <div className="form-floating mb-3">
                      <input type="date" className="form-control" id="booking-date" {...register("booking_date")} />
                      <label htmlFor="booking-date" className="form-label">Boking Date</label>
                      <div className="invalidFeedbackError">{errors?.booking_date?.message}</div>
                  </div>
                  <div className="form-floating mb-3">
                      <select id="booking-time" className={"form-select"} {...register("booking_time")}>
                          <option value={''}>Please select booking time</option>
                          <option value="09:00">09:00 AM</option>
                          <option value="09:15">09:15 AM</option>
                          <option value="09:30">09:30 AM</option>
                          <option value="09:45">09:45 AM</option>
                          <option value="10:00">10:00 AM</option>
                          <option value="10:15">10:15 AM</option>
                          <option value="10:30">10:30 AM</option>
                          <option value="10:45">10:45 AM</option>
                          <option value="11:00">11:00 AM</option>
                          <option value="11:15">11:15 AM</option>
                          <option value="11:30">11:30 AM</option>
                          <option value="11:45">11:45 AM</option>
                          <option value="12:00">12:00 PM</option>
                          <option value="12:15">12:15 PM</option>
                          <option value="12:30">12:30 PM</option>
                          <option value="12:45">12:45 PM</option>
                          <option value="13:00">01:00 PM</option>
                          <option value="13:15">01:15 PM</option>
                          <option value="13:30">01:30 PM</option>
                          <option value="13:45">01:45 PM</option>
                          <option value="14:00">02:00 PM</option>
                          <option value="14:15">02:15 PM</option>
                          <option value="14:30">02:30 PM</option>
                          <option value="14:45">02:45 PM</option>
                          <option value="15:00">03:00 PM</option>
                          <option value="15:15">03:15 PM</option>
                          <option value="15:30">03:30 PM</option>
                          <option value="15:45">03:45 PM</option>
                          <option value="16:00">04:00 PM</option>
                          <option value="16:15">04:15 PM</option>
                          <option value="16:30">04:30 PM</option>
                          <option value="16:45">04:45 PM</option>
                          <option value="17:00">05:00 PM</option>
                          <option value="17:15">05:15 PM</option>
                          <option value="17:30">05:30 PM</option>
                          <option value="17:45">05:45 PM</option>
                          <option value="18:00">06:00 PM</option>
                          <option value="18:15">06:15 PM</option>
                          <option value="18:30">06:30 PM</option>
                          <option value="18:45">06:45 PM</option>
                          <option value="19:00">07:00 PM</option>
                          <option value="19:15">07:15 PM</option>
                          <option value="19:30">07:30 PM</option>
                          <option value="19:45">07:45 PM</option>
                          <option value="20:00">08:00 PM</option>
                          <option value="20:15">08:15 PM</option>
                          <option value="20:30">08:30 PM</option>
                          <option value="20:45">08:45 PM</option>
                          <option value="21:00">09:00 PM</option>
                          <option value="21:15">09:15 PM</option>
                          <option value="21:30">09:30 PM</option>
                          <option value="21:45">09:45 PM</option>
                          <option value="22:00">10:00 PM</option>
                      </select>
                      <label htmlFor="booking-time">Booking Time</label>
                      <div className="invalidFeedbackError">{errors?.booking_time?.message}</div>
                  </div>
                <div className="mb-5">
                    <label className="form-label">Services</label>
                    <div className={"clearfix"}>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="service" id="service1" value={"QEEG scan"} {...register("service")} />
                            <label className="form-check-label" htmlFor="service1">QEEG scan</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="service" id="service2" value={"Body scan"} {...register("service")} />
                            <label className="form-check-label" htmlFor="service2">Body scan</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="service" id="service3" value={"Neurofeedback"} {...register("service")} />
                            <label className="form-check-label" htmlFor="service3">Neurofeedback</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="service" id="service4" value={"Biofeedback"} {...register("service")} />
                            <label className="form-check-label" htmlFor="service4">Biofeedback</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="service" id="service5" value={"Neuro modulation"} {...register("service")} />
                            <label className="form-check-label" htmlFor="service5">Neuro modulation</label>
                        </div>
                    </div>
                    <div className="invalidFeedbackError">{errors?.service?.message}</div>
                </div>
                  <div className="mb-5">
                      <div className="form-check">
                          <input className="form-check-input" type="checkbox" value={"Yes"} id="subscribe" {...register("subscribe")} />
                          <label className="form-check-label" htmlFor="subscribe">
                              I would like DeepSynaps to send me offers and communications.
                          </label>
                      </div>
                      <div className="invalidFeedbackError">{errors?.subscribe?.message}</div>
                  </div>
                  {showSuccessMessage &&
                      <div className="alert alert-success mt-3 mb-3" role="alert" style={{"max-width": "80%"}}>
                          We've received your booking details. We will contact you very soon.
                      </div>
                  }
                <div className="mb-5 d-grid">
                    <button className="btn btn-md btn-dark" type={"submit"}>{ isLoading ? 'Submitting your request...' : 'Book Now' }</button>
                </div>
                <div className="mb-5">
                  <label className="form-check-label">
                      By selecting this you agree to our <Link to={"/privacy-policy"}>Privacy policy</Link>.
                  </label>
                </div>
              </form>
          </div>
          <div className={"col-sm-12 col-md-4 offset-md-1 bookLocationRight"}>
              <h2>What happens next?</h2>
              <ul>
                <li>
                  <span className={"image"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                        <path
                            d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
                      </svg>
                  </span>
                  <span className={"text"}><b>Receive a Callback:</b> Our team will call you to further understand your requirements.</span>
                </li>
                <li>
                  <span className={"image"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calculator" viewBox="0 0 16 16">
                      <path d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z"/>
                      <path d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4z"/>
                    </svg>
                  </span>
                    <span className={"text"}><b>Professional Assessment:</b> A professional advisor will get in touch for an initial assessment to ensure we meet your needs</span>
                </li>

                <li>
                  <span className={"image"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar2-check" viewBox="0 0 16 16">
                        <path d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>
                        <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4z"/>
                      </svg>
                  </span>
                    <span className={"text"}><b>We will Book Your Room:</b> we will book your preferred room and finalise the booking for the date you desire.</span>
                </li>

                <li>
                  <span className={"image"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-fill" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z"/>
                    </svg>
                  </span>
                  <span className={"text"}><b>Confirmation:</b> Receive a confirmation of your booking with all the details.</span>
                </li>

                  <li>
                      <span className={"image"}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-vcard" viewBox="0 0 16 16">
                          <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5ZM9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8Zm1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5Z"/>
                          <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2ZM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96c.026-.163.04-.33.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1.006 1.006 0 0 1 1 12V4Z"/>
                        </svg>
                      </span>
                      <span className={"text"}>Sign up and get started</span>
                  </li>
              </ul>
              <div className={"speakSection"}>
                  <div className={"image"}>
                    <img src={AliY} width={50} />
                  </div>
                  <div className={"text"}>
                      <div className={"title"}>Need to speak to someone?</div>
                      <div className={"phoneNumber"}><a href={'tel: +44 7429 910079'}>+44 7429 910079</a></div>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />

    </>
  )
}

export default BookLocation