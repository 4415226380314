import { React, useState } from 'react'
import '../../CognitiveTests/Header/cognitiveTestHeader.css'

const DepressionAnxietyStressScales = () => {
  
  const [showSection1, setShowSection1] = useState(true)
  const [showSection2, setShowSection2] = useState(false)
  const [showSection3, setShowSection3] = useState(false)
  const [showSection4, setShowSection4] = useState(false)
  const [showSection5, setShowSection5] = useState(false)
  const [showSection6, setShowSection6] = useState(false)

  const handleShowSection1 = () => { // Showing 1st element
    setShowSection1(true)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(false)
  }

  const handleShowSection2 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(true)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(false)
  }

  const handleShowSection3 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(true)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(false)
  }

  const handleShowSection4 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(true)
    setShowSection5(false)
    setShowSection6(false)
  }

  const handleShowSection5 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(true)
    setShowSection6(false)
  }

  const handleShowSection6 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(true)
  }

  const handleShowSection7 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(false)
  }

  return (
    <header id='headerDiagnostic' className='d-flex flex-column'>
      <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
        <h1>Mental Health Assessment</h1>
        <div className='d-flex flex-column position-relative'>
          <p className='headerDiagnosticArticleP'>  Anyone feeling overwhelmed, anxious, or down, or those seeking a general emotional health checkup. To identify and address potential emotional disturbances and promote mental well-being.</p>
        </div>
      </article>
      <div className="breakline-diagnostic"></div>

      <div className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
        <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      </div>

      <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
        <div className="headerDiagnostic-options d-flex flex-column align-items-start">
          <button className={showSection1 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection1}>What is it for?</button>
          <button className={showSection2 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection2}>Who is it for?</button>
          <button className={showSection3 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection3}>Why are we doing it?</button>
          <button className={showSection4 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection4}>Relation to QEEG, Biofeedback, and Neurofeedback</button>
          <button className={showSection5 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection5}>Relation to tDCS and PBM</button>
          <button className={showSection6 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection6}>Relation to Cognitive Tests</button>
        </div>
        {showSection1 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>What is it for?</h1>
                <p>To measure the negative emotional states of depression, anxiety, and stress.</p>
              </div>
            </div>
        }{showSection2 &&
          <div className="headerDiagnostic-content d-flex flex-row align-items-start">
            <div className="diagnosticArticle2 d-flex flex-column align-items-start">
              <h1>Who is it for?</h1>
              <p>Anyone feeling overwhelmed, anxious, or down, or those seeking a general emotional health checkup.</p>
            </div>
          </div>
        }{showSection3 &&
          <div className="headerDiagnostic-content d-flex flex-row align-items-start">
            <div className="diagnosticArticle2 d-flex flex-column align-items-start">
              <h1>Why are we doing it?</h1>
              <p>To identify and address potential emotional disturbances and promote mental well-being.</p>
            </div>
          </div>
        }
        {showSection4 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>Relation to QEEG, Biofeedback, and Neurofeedback</h1>
                <p>Emotional states like depression, anxiety, and stress have been linked to specific neural activity patterns in areas such as the Amygdala, prefrontal cortex, and anterior cingulate cortex. QEEG offers objective measures that can complement the subjective feedback from the DASS test, providing a holistic view of an individual's emotional state. With Neurofeedback, protocols like Alpha/Theta training can be employed for promoting relaxation, while SMR (Sensory Motor Rhythm) training aids in calming both the mind and body. On the Biofeedback front, techniques like Respiratory feedback can help individuals manage anxiety through controlled breathing exercises, and ECG/Pulse feedback can be invaluable in monitoring and modifying heart rate responses linked to stress or anxiety.</p>
              </div>
            </div>
        }
        {showSection5 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>Relation to tDCS and PBM</h1>
                <p>For emotional states like depression and anxiety, tDCS, when applied to the dorsolateral prefrontal cortex (DLPFC), has shown promise in modulating these emotions. Brain PBM therapy, using red/NIR light, stimulates the metabolic capacity of neurons and promotes anti-inflammatory, anti-apoptotic, antioxidant responses, and neurogenesis. This enhancement in neuronal function can aid mood regulation, aligning with insights from the DASS scales.</p>
              </div>
            </div>
        }
        {showSection6 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>Relation to Cognitive Tests</h1>
                <p>Emotional states, such as those quantified by the DASS, inevitably have a profound influence on cognitive performance. For instance, someone experiencing heightened anxiety may find their attention is dispersed, or someone with high depression scores might struggle with memory recall.</p>
                <p><strong>Attention and Concentration:</strong> Anxiety often leads to heightened arousal levels, potentially causing scattered attention or hyper-focus on perceived threats. Stress, too, can hamper the ability to concentrate on tasks at hand. Using cognitive tests that evaluate attention span and focus, one can draw correlations between DASS scores and cognitive performance.</p>
                <p><strong>Memory:</strong> Depressive states often coincide with impaired memory recall. Cognitive tests assessing both short-term and long-term memory can provide deeper insights into how one's emotional state, as gauged by DASS, might be influencing their mnemonic capacities.</p>
                <p><strong>Executive Functioning:</strong> Stress, especially chronic, can impede executive functions such as planning, decision-making, and cognitive flexibility. This implies that someone with high stress scores on the DASS may perform sub-optimally on cognitive tests that gauge these specific faculties.</p>
                <p>By juxtaposing DASS scores with results from assorted cognitive tests, clinicians can offer a broader, more integrative understanding of an individual's mental well-being. This multi-dimensional approach aids in devising comprehensive therapeutic strategies, targeting both emotional and cognitive spheres for holistic betterment.</p>
              </div>
            </div>
        }
      </div>
    </header>
  )
}

export default DepressionAnxietyStressScales