import { React, useState } from 'react'
import '../../CognitiveTests/Header/cognitiveTestHeader.css'

const HolmesandRaheLifeEventScaleHeader = () => {
  
  const [showSection1, setShowSection1] = useState(true)
  const [showSection2, setShowSection2] = useState(false)
  const [showSection3, setShowSection3] = useState(false)
  const [showSection4, setShowSection4] = useState(false)
  const [showSection5, setShowSection5] = useState(false)
  const [showSection6, setShowSection6] = useState(false)

  const handleShowSection1 = () => { // Showing 1st element
    setShowSection1(true)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(false)
  }

  const handleShowSection2 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(true)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(false)
  }

  const handleShowSection3 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(true)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(false)
  }

  const handleShowSection4 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(true)
    setShowSection5(false)
    setShowSection6(false)
  }

  const handleShowSection5 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(true)
    setShowSection6(false)
  }

  const handleShowSection6 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(true)
  }

  const handleShowSection7 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(false)
  }

  return (
    <header id='headerDiagnostic' className='d-flex flex-column'>
      <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
        <h1>Mental Health Assessment</h1>
        <div className='d-flex flex-column position-relative'>
          <p className='headerDiagnosticArticleP'>  Anyone feeling overwhelmed, anxious, or down, or those seeking a general emotional health checkup. To identify and address potential emotional disturbances and promote mental well-being.</p>
        </div>
      </article>
      <div className="breakline-diagnostic"></div>

      <div className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
        <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      </div>

      <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
        <div className="headerDiagnostic-options d-flex flex-column align-items-start">
          <button className={showSection1 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection1}>What is it for?</button>
          <button className={showSection2 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection2}>Who is it for?</button>
          <button className={showSection3 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection3}>Why are we doing it?</button>
          <button className={showSection4 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection4}>Relation to QEEG, Biofeedback, and Neurofeedback</button>
          <button className={showSection5 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection5}>Relation to tDCS and PBM</button>
          <button className={showSection6 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection6}>Relation to Cognitive Tests</button>
        </div>
        {showSection1 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>What is it for?</h1>
                <p>To measure significant life events and their stress impact.</p>
              </div>
            </div>
        }{showSection2 &&
          <div className="headerDiagnostic-content d-flex flex-row align-items-start">
            <div className="diagnosticArticle2 d-flex flex-column align-items-start">
              <h1>Who is it for?</h1>
              <p>Anyone undergoing major life changes or wanting to understand stress levels better.</p>
            </div>
          </div>
        }{showSection3 &&
          <div className="headerDiagnostic-content d-flex flex-row align-items-start">
            <div className="diagnosticArticle2 d-flex flex-column align-items-start">
              <h1>Why are we doing it?</h1>
              <p>To predict the risk of stress-induced health issues and implement coping strategies.</p>
            </div>
          </div>
        }
        {showSection4 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>Relation to QEEG, Biofeedback, and Neurofeedback</h1>
                <p>Life events that induce stress can impact areas of the brain responsible for stress management and emotional regulation, such as the hippocampus and paraventricular nucleus. By using QEEG, these shifts in brain activity can be detected, shedding light on an individual's neural responses to life challenges. Neurofeedback, through protocols like Beta training, can enhance an individual's ability to process stress and improve cognitive clarity. Biofeedback further supports stress management, with ECG/Pulse feedback being instrumental in teaching individuals to control their heart rate and physiological responses to stress, while Skin Conduction feedback provides insights into their emotional arousal levels.</p>
              </div>
            </div>
        }
        {showSection5 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>Relation to tDCS and PBM</h1>
                <p>Stressful life events impact brain physiology. While tDCS targeting the amygdala and DLPFC can potentially aid in stress regulation, brain PBM therapy can accelerate cellular recovery from stress-induced damage through its metabolic stimulation and anti-inflammatory properties.</p>
              </div>
            </div>
        }
        {showSection6 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>Relation to Cognitive Tests</h1>
                <p>Life events and the associated stress responses directly influence cognitive functioning. Using the Holmes and Rahe Life Event Scale alongside cognitive assessments can provide a comprehensive understanding of the interconnectedness of external stressors, internal emotional reactions, and cognitive capacities.</p>
                <p><strong>Attention and Processing Speed:</strong> Stressors can lead to a heightened state of alertness or the "fight or flight" response. Initially, attention might sharpen, but chronic exposure can wear this out, leading to distractibility or slowed processing. Cognitive tests can determine the nature and extent of these alterations in attention span and speed.</p>
                <p><strong>Memory:</strong> Significant life events can overshadow or magnify certain memories, altering the ease with which they're recalled. Concurrently, high stress can cloud memory consolidation. Cognitive tests focused on memory recall, both immediate and delayed, can elucidate the effects of significant life events on memory function.</p>
                <p><strong>Executive Functioning:</strong> The ability to plan, make decisions, and execute actions can be affected by major life changes. Someone undergoing significant stress might display impaired judgment or face difficulties in multitasking. Tests that evaluate these faculties can be correlated with the scores on the Holmes and Rahe scale for a holistic understanding.</p>
                <p>Correlating the findings from the Holmes and Rahe Life Event Scale with cognitive test scores furnishes a more comprehensive view of an individual's mental resilience and adaptability. Recognizing these patterns is quintessential for formulating tailored interventions, ensuring that the individual is not only emotionally, but also cognitively equipped to navigate life's challenges.</p>
              </div>
            </div>
        }
      </div>
    </header>
  )
}

export default HolmesandRaheLifeEventScaleHeader