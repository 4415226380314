import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';
import { Link } from 'react-router-dom';


const InhibitionOfReturnTest = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                        <div className={"row w-100"}>
                            <div className={"col-md-8 col-sm-12"}><h1>Inhibition of Return Test (CAT-IOR)</h1></div>
                            <div className={"col-md-4 col-sm-12 text-md-end text-sm-left pt-1"}>
                                <Link target={"_blank"} className={"btn btn-outline-dark"} to={'https://www.cognifit.com/gb/battery-of-tests/inhibition-of-return-task'}>Start test now</Link>
                            </div>
                        </div>

                        <p>Analyze attentional mechanisms and the behavioral inhibition of returning to previously inspected locations.</p>
                        <p><strong>Relation to QEEG, Neurofeedback, Biofeedback, and Neuromodulation:</strong></p>
                        <ul>
                            <li><strong>Brain Region:</strong> Parietal Lobe, Frontal Eye Fields</li>
                            <li><strong>Broadman Area:</strong> 7 (Parietal Lobe); 8 (Frontal Eye Fields)</li>
                            <li><strong>EEG Electrode Places:</strong> Pz, P3, P4 (Parietal); Fz, F3, F4 (Frontal)</li>
                            <li>
                                <strong>Neurofeedback:</strong>
                                <ul>
                                    <li><strong>Protocol:</strong> Enhancing theta/beta ratio in the parietal region and modulating alpha activity in the frontal eye fields.</li>
                                    <li><strong>Objective:</strong> To support attentional shifting, spatial awareness, and inhibit the propensity to re-attend to previously inspected locations.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Biofeedback:</strong>
                                <ul>
                                    <li><strong>Assessment:</strong> Using GSR and HRV to assess the autonomic response and arousal level during tasks that demand sustained and selective attention.</li>
                                    <li><strong>Protocol:</strong> Training towards a balanced autonomic state to support optimal attention and minimize unnecessary re-engagement with previously attended stimuli.</li>
                                    <li><strong>Objective:</strong> Promoting physiological stability to foster continuous attention and adequate inhibitory control.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Neuromodulation:</strong>
                                <ul>
                                    <li>
                                        <strong>tDCS:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Parietal Lobe, Frontal Eye Fields</li>
                                            <li><strong>Protocol:</strong> Anodal stimulation to enhance neural plasticity and stimulate neuron firing in areas related to attention and visual scanning.</li>
                                            <li><strong>Objective:</strong> Boosting the effectiveness and efficiency of attentional networks and supporting the ability to inhibit return attention to previous locations.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>PBM:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Parietal Cortex</li>
                                            <li><strong>Protocol:</strong> Applying photobiomodulation to facilitate cellular activity and promote neural connectivity within attentional networks.</li>
                                            <li><strong>Objective:</strong> Encouraging neural efficiency and optimizing attentional mechanisms to appropriately shift and hold attention where needed.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Mental Health Assessments and Their Relation:</strong>
                                <ul>
                                    <li><strong>SISRI-24:</strong> Understanding an individual’s intrinsic self-regulatory inhibition capabilities may correlate with performance on attentional tasks, making the SISRI-24 particularly relevant.</li>
                                    <li><strong>Sleep Scale:</strong> Adequate sleep, assessed via the Sleep Scale, underpins optimal cognitive performance, especially in attention-demanding tasks like the Inhibition of Return Test.</li>
                                    <li><strong>Friendship Scale:</strong> Social support and networks (evaluated through the Friendship Scale) can be pivotal in mental health, providing a buffer that may protect cognitive resources, particularly attention, from the detriments of stress and isolation.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>Combining the Inhibition of Return Test with neurophysiological and mental health assessments aids in creating a robust, person-centered cognitive intervention strategy. This holistically integrates psychological, neural, and physiological facets, offering a nuanced approach to cognitive enhancement and mental health support.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default InhibitionOfReturnTest