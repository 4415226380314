import { React, useState } from 'react'
import './cooperateHeader.css'
import {Link} from "react-router-dom";

const BrainScanHeader = () => {

    const [showDiagnostic1, setShowDiagnostic1] = useState(true)
    const [showDiagnostic2, setShowDiagnostic2] = useState(false)
    const [showDiagnostic3, setShowDiagnostic3] = useState(false)
    const [showDiagnostic4, setShowDiagnostic4] = useState(false)
    const [showDiagnostic5, setShowDiagnostic5] = useState(false)
    const [showDiagnostic6, setShowDiagnostic6] = useState(false)
    const [showDiagnostic7, setShowDiagnostic7] = useState(false)
  
    const showWhatItIs = () => { // Showing 1st element
      setShowDiagnostic1(true)
      setShowDiagnostic2(false)
      setShowDiagnostic3(false)
      setShowDiagnostic4(false)
      setShowDiagnostic5(false)
      setShowDiagnostic6(false)
      setShowDiagnostic7(false)
    }
  
    const showHowItWorks = () => { // Showing 2nd element
      setShowDiagnostic1(false)
      setShowDiagnostic2(true)
      setShowDiagnostic3(false)
      setShowDiagnostic4(false)
        setShowDiagnostic5(false)
        setShowDiagnostic6(false)
        setShowDiagnostic7(false)
    }
  
    const showConditions = () => { // Showing 3rd element
      setShowDiagnostic1(false)
      setShowDiagnostic2(false)
      setShowDiagnostic3(true)
      setShowDiagnostic4(false)
        setShowDiagnostic5(false)
        setShowDiagnostic6(false)
        setShowDiagnostic7(false)
    }
  
    const showSection4 = () => { // Showing 4th element
      setShowDiagnostic1(false)
      setShowDiagnostic2(false)
      setShowDiagnostic3(false)
      setShowDiagnostic4(true)
        setShowDiagnostic5(false)
        setShowDiagnostic6(false)
        setShowDiagnostic7(false)
    }

    const showSection5 = () => { // Showing 4th element
        setShowDiagnostic1(false)
        setShowDiagnostic2(false)
        setShowDiagnostic3(false)
        setShowDiagnostic4(false)
        setShowDiagnostic5(true)
        setShowDiagnostic6(false)
        setShowDiagnostic7(false)
    }

    const showSection6 = () => { // Showing 4th element
        setShowDiagnostic1(false)
        setShowDiagnostic2(false)
        setShowDiagnostic3(false)
        setShowDiagnostic4(false)
        setShowDiagnostic5(false)
        setShowDiagnostic6(true)
        setShowDiagnostic7(false)

    }

    const showSection7 = () => { // Showing 4th element
        setShowDiagnostic1(false)
        setShowDiagnostic2(false)
        setShowDiagnostic3(false)
        setShowDiagnostic4(false)
        setShowDiagnostic5(false)
        setShowDiagnostic6(false)
        setShowDiagnostic7(true)

    }
  
    return (
      <header id='headerDiagnostic' className='d-flex flex-column'>
        <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
          <h1>Cooperate</h1>
          <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and cognitive abilities, identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
          <a className='navbar-Respbtn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
        </article>
        <div className="breakline-diagnostic"></div>
  
        <div className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
          <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/L38T6fnrVjc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>

        <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
          <div className="headerDiagnostic-options d-flex flex-column align-items-start">
            <button className={showDiagnostic1 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showWhatItIs}>What it is?</button>
            <button className={showDiagnostic2 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showHowItWorks}>How is it work?</button>
            <button className={showDiagnostic3 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showConditions}>Who can benefit it?</button>
            <button className={showDiagnostic4 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection4}>Scan Service</button>
            <button className={showDiagnostic5 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection5}>Assessment Service</button>
            <button className={showDiagnostic6 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection6}>Intervention</button>
            <button className={showDiagnostic7 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={showSection7}>Cooperate packages</button>
          </div>
          {showDiagnostic1 && 
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle3 d-flex flex-column align-items-start">
                <h1>What it is?</h1>
                <p>The "Cooperate service" offered by DeepSynaps is indeed a comprehensive solution designed for businesses seeking to enhance the brain health and cognitive performance of their employees. This service encompasses a range of services and benefits aimed at supporting the well-being and productivity of the workforce. <br />By utilizing the "Cooperate service," businesses can access various offerings such as qEEG scans, cognitive assessments, personalized brain training programs, and non-invasive neuromodulation therapies. These interventions are designed to enhance cognitive abilities, improve focus and attention, reduce stress, anxiety, and depression, and promote overall mental well-being among employees. <br />Through the "Cooperate service," businesses can invest in the brain health of their employees, recognizing that optimizing cognitive function can have a significant impact on overall productivity and performance. By providing access to advanced brain health assessments and interventions, businesses can empower their employees to reach their full cognitive potential and contribute to the success of the organization. <br />Overall, the "Cooperate service" offered by DeepSynaps aims to support businesses in creating a positive work environment that prioritizes brain health and cognitive optimization, leading to improved employee well-being and enhanced organizational outcomes</p>
              </div>
            </div>
          }{showDiagnostic2 && 
            <div className="headerDiagnostic-content">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>How it works?</h1>
                <ul>
                  <li>The "Cooperate" package offered by DeepSynaps works by utilizing advanced technologies and evidence-based methodologies to optimize brain health and cognitive performance in the workplace. Here's how it works:</li>
                  <br />
                  <li><b>Initial Assessment:</b> The process begins with an initial assessment of the organization's needs, goals, and workforce demographics. This helps in understanding the specific requirements and tailoring the program accordingly.</li>
                  <br />
                  <li><b>Wellness Check:</b> A comprehensive wellness check is conducted to evaluate the cognitive abilities, stress levels, and overall well-being of the employees. This may include assessments such as QEEG scans, cognitive assessments, and HRV analysis.</li>
                  <br />
                  <li><b>Personalized Brain Optimization Program:</b> Based on the assessment results, a personalized brain optimization program is developed for each employee. This program includes a combination of interventions, brain training exercises, and techniques to enhance cognitive abilities, improve focus, reduce stress, and promote mental well-being.</li>
                  <br />
                  <li><b>Implementation and Training:</b> The brain optimization program is implemented in collaboration with the organization. Employees receive training and guidance on how to effectively use the tools and techniques provided to maximize their cognitive potential.</li>
                  <br />
                  <li><b>Ongoing Support and Monitoring:</b> DeepSynaps provides ongoing support and monitoring throughout the program. Regular check-ins, progress tracking, and performance monitoring help in assessing the effectiveness of the interventions and making any necessary adjustments.</li>
                  <br />
                  <li><b>Data Analysis and Reporting:</b> Data collected from the program is analyzed to provide insights into the progress, improvements, and areas of focus. Detailed reports are generated to provide feedback to the organization and employees.</li>
                  <br />
                  <li><b>Continued Collaboration:</b> DeepSynaps maintains a collaborative relationship with the organization, providing support and guidance on an ongoing basis. This includes addressing any concerns, answering questions, and offering additional resources as needed.</li>
                  <br />
                  <li>The "Cooperate" package is designed to create a holistic approach to optimize brain health and cognitive performance in the workplace. By combining assessments, personalized programs, training, and ongoing support, it aims to improve employee well-being, productivity, and job satisfaction.</li>
                  <br />
                  <li>Please note that the specific implementation of the "Cooperate" package may vary depending on the organization's needs and requirements. DeepSynaps works closely with businesses to customize the program and ensure it aligns with their unique goals and objectives</li>
                </ul>
              </div>
            </div>
          }{showDiagnostic3 &&
            <div className="headerDiagnostic-content">
              <div className="diagnosticArticle3 d-flex flex-column align-items-start">
                <h1>Who can benefit it?</h1>
                <p><b>The "Cooperate" package offered by DeepSynaps is beneficial for a wide range of businesses and organizations. Here are some examples of who can benefit from this package:</b></p>
                <ul>
                    <li><b>Companies and Corporations:</b> Businesses of all sizes can benefit from the "Cooperate" package. Whether it's a small startup or a large corporation, optimizing brain health and cognitive performance can have a positive impact on employee productivity, focus, and overall well-being.</li>
                    <li><b>Human Resources Departments:</b> HR departments can leverage the "Cooperate" package to enhance their employee wellness initiatives. By offering brain optimization programs, organizations can demonstrate their commitment to employee well-being and attract and retain top talent.</li>
                    <li><b>Educational Institutions:</b> Schools, universities, and educational institutions can use the "Cooperate" package to support the cognitive development and well-being of their students. This can improve academic performance, attention, and concentration.</li>
                    <li><b>Healthcare Providers:</b> Healthcare providers, including hospitals, clinics, and rehabilitation centers, can incorporate the "Cooperate" package to support patient recovery, cognitive rehabilitation, and overall brain health.</li>
                    <li><b>Research Institutions:</b> Research institutions can collaborate with DeepSynaps to explore the applications of brain optimization and cognitive enhancement in various fields. This can lead to advancements in neuroscience and the development of evidence-based interventions.</li>
                    <li><b>Professional Services Firms:</b> Law firms, consulting firms, and other professional services organizations can benefit from the "Cooperate" package to enhance the cognitive abilities and well-being of their employees. This can improve decision-making, problem-solving, and client interactions.</li>
                </ul>
                <p>It's important to note that the "Cooperate" services can be tailored to suit the specific needs and goals of different industries and organizations. DeepSynaps works closely with clients to understand their requirements and develop customized solutions that address their unique challenges.</p>
                <p><b>Researchers</b></p>
                <iframe className='embed-ytb howItWorks-ytb' src="https://www.youtube-nocookie.com/embed/ITWFBCuHeww" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                <p><b>Healthcare professionals</b></p>
                <iframe className='embed-ytb howItWorks-ytb' src="https://www.youtube-nocookie.com/embed/X6x46HhKDEw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                <p><b>Families</b></p>
                <iframe className='embed-ytb howItWorks-ytb' src="https://www.youtube-nocookie.com/embed/STZqU1JUHYY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                <p><b>Teachers and Schools</b></p>
                <iframe className='embed-ytb howItWorks-ytb' src="https://www.youtube-nocookie.com/embed/VvJWkzNjOGM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </div>
            </div>
          }{showDiagnostic4 &&
            <div className="headerDiagnostic-content">
              <div className="diagnosticArticle4 d-flex flex-column align-items-start">
                <h1>Scan Service</h1>
                <ol className='cooperateOL'>
                    <li><b>Body Scan:</b></li>
                    <p>Delve into the comprehensive suite of brain and body scanning services we offer, designed to empower individuals with insights and control over their physiological functions. Our state-of-the-art, AI-enhanced techniques offer an unparalleled depth of analysis, backed by a team dedicated to your health and well-being.</p>
                    <p><Link to={'/bodyscan'}>Learn more</Link></p>
                    <li><b>Brain Scan:</b></li>
                    <p>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and cognitive abilities, identify potential issues that may be impacting their overall well-being.</p>
                    <p><Link to={'/brainscan'}>Learn more</Link></p>
                </ol>
              </div>
            </div>
          }
            {showDiagnostic5 &&
                <div className="headerDiagnostic-content">
                    <div className="diagnosticArticle4 d-flex flex-column align-items-start">
                        <h1>Assessment Service</h1>
                        <ol className='cooperateOL'>
                            <li><b>Mental health assessment:</b></li>
                            <p>Anyone feeling overwhelmed, anxious, or down, or those seeking a general emotional health checkup. To identify and address potential emotional disturbances and promote mental well-being.</p>
                            <p><Link to={'/mental-health-assessment'}>Learn more</Link></p>
                            <li><b>Cognitive Assessment:</b></li>
                            <p>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being.</p>
                            <p><Link to={'/cognitivetest'}>Learn more</Link></p>
                        </ol>
                    </div>
                </div>
            }
            {showDiagnostic6 &&
                <div className="headerDiagnostic-content">
                    <div className="diagnosticArticle4 d-flex flex-column align-items-start">
                        <h1>Intervention</h1>
                        <ol className='cooperateOL'>
                            <li><b>Biofeedback:</b></li>
                            <p>Biofeedback is a powerful technique that harnesses the power of electronic monitoring to give real-time feedback on various physiological functions. This allows individuals to learn how to modify and control their body's responses, such as brain waves, muscle tone, skin temperature, and more. The goal? To promote relaxation, reduce stress, and alleviate specific medical symptoms, offering a pathway to enhanced well-being.</p>
                            <p><Link to={'/interventions/biofeedback'}>Learn more</Link></p>
                            <li><b>Neurofeedback:</b></li>
                            <p>Neurofeedback, a subset of biofeedback, specifically targets and trains the brain. By monitoring brainwave activity, we can provide real-time feedback to the user. This allows individuals to learn how to self-regulate certain brain functions, aiming to enhance cognitive abilities, emotional regulation, and overall brain performance.</p>
                            <p><Link to={'/interventions/neurofeedback'}>Learn more</Link></p>
                            <li><b>Neuromodulation (TDCS-PBM):</b></li>
                            <p>Neuromodulation is a technology-driven approach that involves directly stimulating the nervous system with electrical or pharmaceutical agents to alter its activity. The aim is to modulate neural function to achieve desired therapeutic effects, often used in addressing a range of neurological and psychological conditions.</p>
                            <p><Link to={'/interventions/neuromodulation-with-advanced-ai-integration'}>Learn more</Link></p>
                            <li><b>Brain stimulation:</b></li>
                            <p>Brain stimulation training, leveraging advanced cognitive science, aims to fine-tune the brain's performance. Through specialized tasks and exercises, individuals can enhance specific cognitive skills, improve neural networks, and maintain optimum brain health.</p>
                            <p><Link to={'/interventions/brain-stimulation-programs'}>Learn more</Link></p>
                        </ol>
                    </div>
                </div>
            }
            {showDiagnostic7 &&
                <div className="headerDiagnostic-content">
                    <div className="diagnosticArticle4 d-flex flex-column align-items-start">
                        <h1>Cooperate packages</h1>
                        <ol className='cooperateOL'>

                            <li><b>Academic and Research Enhancement Package:</b></li>
                            <p>This package is designed to enhance the cognitive capabilities and mental well-being of students and researchers within universities and research institutions. By offering advanced cognitive assessments, training, and support, it aims to improve research productivity, academic performance, and overall well-being. DeepSynaps will work closely with institutions to tailor the package to their specific academic and research goals, contributing to a more innovative and successful academic community.</p>
                            <p><Link to={'/cooperate/academic-and-research'}>Learn more</Link></p>

                            <li><b>Athlete Performance Enhancement Package:</b></li>
                            <p>This comprehensive package is designed to support athletes in achieving peak performance through a combination of physical and cognitive training. It aims to enhance mental resilience, optimize cognitive performance, and improve physical conditioning to help athletes excel in their respective sports. DeepSynaps will work closely with athletes, coaches, and sports organizations to tailor the package to specific sports and individual needs.</p>
                            <p><Link to={'/cooperate/athletes'}>Learn more</Link></p>

                            <li><b>Business and Management Performance Enhancement Package:</b></li>
                            <p>This package is designed to support businesses and management teams in optimizing leadership, decision-making, and employee well-being. By integrating cognitive and mental wellness assessments, training, and coaching, it aims to enhance leadership effectiveness, employee performance, and overall workplace productivity. DeepSynaps will collaborate closely with businesses to tailor the package to meet their specific goals and organizational needs, ultimately contributing to a more resilient and high-performing workforce.</p>
                            <p><Link to={'/cooperate/business-and-management'}>Learn more</Link></p>

                            <li><b>Care Home and Residential Wellness Package:</b></li>
                            <p>This package is designed to enhance the well-being and cognitive function of residents in care homes and residential facilities. By combining advanced diagnostic assessments with personalized cognitive and mental health interventions, it aims to improve the quality of life for residents, especially those with cognitive impairments or dementia. DeepSynaps will collaborate closely with care homes to customize the package to meet the specific needs and conditions of their residents, ultimately contributing to improved resident care and well-being.</p>
                            <p><Link to={'/cooperate/care-homes'}>Learn more</Link></p>

                            <li><b>Child Development Center Cognitive and Emotional Development Package:</b></li>
                            <p>This package is designed to create a supportive and engaging environment within child development centers, focusing on promoting cognitive and emotional development among young children. By offering comprehensive child assessments, personalized development plans, and age-appropriate activities, it aims to help children build a strong foundation for their cognitive and emotional well-being during their formative years. DeepSynaps will collaborate closely with child development centers to tailor the package to meet the specific needs and goals of their young learners, ultimately contributing to more successful and enriching early childhood development experiences.</p>
                            <p><Link to={'/cooperate/child-development-center'}>Learn more</Link></p>

                            <li><b>Clinic Wellness and Assessment Package:</b></li>
                            <p>This comprehensive package is designed to assist clinics in offering advanced diagnostics and treatment options for their patients, addressing both physical and mental health concerns. It provides a holistic approach to healthcare by combining cutting-edge technology with personalized interventions and ongoing support. DeepSynaps will work closely with the clinic to customize the package to their specific needs and patient demographics.</p>
                            <p><Link to={'/cooperate/clinics'}>Learn more</Link></p>

                            <li><b>Correctional Facility Rehabilitation and Mental Health Package:</b></li>
                            <p>This package is specifically designed to support inmate rehabilitation efforts within correctional facilities and prisons, with a focus on addressing behavioral issues, improving emotional well-being, and increasing inmates' chances of successful reintegration into society upon release. DeepSynaps will work closely with correctional facilities to tailor the package to meet the specific needs and requirements of their inmate population, ultimately contributing to safer and more rehabilitative correctional environments.</p>
                            <p><Link to={'/cooperate/correctional-facility-rehabilitation'}>Learn more</Link></p>

                            <li><b>Gym and Sports Center Performance Enhancement Package:</b></li>
                            <p>This tailored package is designed to help gyms and sports centers enhance their members' overall well-being and physical performance. By integrating cognitive and physical training, it aims to create a unique and holistic fitness experience that attracts and retains members seeking not only physical fitness but also mental fitness and stress management. DeepSynaps will collaborate closely with the facility to customize the package to their specific goals and member demographics.</p>
                            <p><Link to={'/cooperate/gyms-and-sport-centres'}>Learn more</Link></p>

                            <li><b>Hospital Wellness and Patient Care Package:</b></li>
                            <p>This package is designed to support hospitals in providing holistic patient care that addresses both physical and mental well-being. By combining advanced diagnostic assessments with personalized cognitive and mental health interventions, it aims to improve patient outcomes, enhance rehabilitation efforts, and reduce the psychological impact of hospitalization. DeepSynaps will collaborate closely with hospitals to customize the package to their specific departments and patient needs, ultimately contributing to improved patient care and recovery.</p>
                            <p><Link to={'/cooperate/hospitals'}>Learn more</Link></p>

                            <li><b>Mental Health Clinic and Counseling Center Wellness Package:</b></li>
                            <p>This package is designed to support hospitals in providing holistic patient care that addresses both physical and mental well-being. By combining advanced diagnostic assessments with personalized cognitive and mental health interventions, it aims to improve patient outcomes, enhance rehabilitation efforts, and reduce the psychological impact of hospitalization. DeepSynaps will collaborate closely with hospitals to customize the package to their specific departments and patient needs, ultimately contributing to improved patient care and recovery.</p>
                            <p><Link to={'/cooperate/mental-health-clinic'}>Learn more</Link></p>

                            <li><b>Military Performance Enhancement Package:</b></li>
                            <p>This package is designed to help military organizations optimize the performance and mental resilience of their soldiers. It combines advanced technology with targeted training and support to prepare military personnel for the physical and cognitive challenges of their roles. DeepSynaps will work closely with military units to customize the package to meet their specific needs and operational requirements while maintaining the highest standards of security and confidentiality.</p>
                            <p><Link to={'/cooperate/military'}>Learn more</Link></p>

                            <li><b>Rehabilitation Center Comprehensive Recovery Package:</b></li>
                            <p>This specialized package is designed to provide comprehensive support for patients in rehabilitation centers, addressing both their physical and cognitive recovery needs. By combining advanced diagnostic assessments with personalized cognitive and physical rehabilitation interventions, it aims to improve patient outcomes and enhance their overall quality of life during the recovery process. DeepSynaps will work closely with rehabilitation centers to customize the package to meet the specific needs and conditions of their patients, ultimately contributing to more successful rehabilitation outcomes.</p>
                            <p><Link to={'/cooperate/rehabilitation-center-comprehensive-recovery'}>Learn more</Link></p>

                            <li><b>Remote Workforce Cognitive and Emotional Well-being Package:</b></li>
                            <p>This specialized package is designed to provide comprehensive support for patients in rehabilitation centers, addressing both their physical and cognitive recovery needs. By combining advanced diagnostic assessments with personalized cognitive and physical rehabilitation interventions, it aims to improve patient outcomes and enhance their overall quality of life during the recovery process. DeepSynaps will work closely with rehabilitation centers to customize the package to meet the specific needs and conditions of their patients, ultimately contributing to more successful rehabilitation outcomes.</p>
                            <p><Link to={'/cooperate/remote-workforce-cognitive-and-emotional-well-being'}>Learn more</Link></p>

                            <li><b>School Wellness and Educational Enhancement Package:</b></li>
                            <p>This specialized package is designed to promote the mental health and cognitive development of students within the school setting. It aims to create a supportive and enriched learning environment where students can thrive academically and emotionally. DeepSynaps will collaborate closely with the school to adapt the package to meet the specific needs of students, teachers, and the educational institution as a whole.</p>
                            <p><Link to={'/cooperate/schools'}>Learn more</Link></p>

                            <li><b>Senior Living Community Cognitive Wellness Package:</b></li>
                            <p>This comprehensive package is designed to create an enriching and supportive environment within senior living communities, focusing on promoting cognitive health, emotional well-being, and overall quality of life among elderly residents. DeepSynaps will collaborate closely with senior living communities to tailor the package to meet the specific needs and preferences of their residents, ultimately contributing to a more vibrant and fulfilling living experience for seniors.</p>
                            <p><Link to={'/cooperate/senior-living-community-cognitive-wellness'}>Learn more</Link></p>

                            <li><b>Sports Performance Enhancement Package:</b></li>
                            <p>This package is designed to help athletes at sports performance centers optimize their cognitive and physical performance. By offering comprehensive assessments, personalized performance enhancement plans, and targeted support, it aims to maximize athletes' competitive advantage, enhance their decision-making abilities, and contribute to peak performance on the field or in competition. DeepSynaps will collaborate closely with sports performance centers to tailor the package to meet the specific needs and goals of their athletes, ultimately leading to improved athletic performance and success.</p>
                            <p><Link to={'/cooperate/sports-performance-enhancement'}>Learn more</Link></p>

                            <li><b>Staff Wellness and Employee Well:</b></li>
                            <p>This package is designed to promote the mental and physical well-being of employees within organizations. By offering comprehensive wellness assessments, mental health support, and stress management techniques, it aims to create a healthier and more productive workforce. DeepSynaps will collaborate closely with organizations to customize the package to meet their specific employee needs and workplace culture, ultimately contributing to a more resilient and engaged workforce.</p>
                            <p><Link to={'/cooperate/staff-wellness'}>Learn more</Link></p>
                        </ol>
                    </div>
                </div>
            }
        </div>
      </header>
    )
}

export default BrainScanHeader