import React from 'react'
import { SEO, Navbar, HeaderTreatments, Footer, CombinationPricing, CombinationSubs } from '../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../assets/images/logo192.svg';
import styles from '../Components/Includes/CombinationPricingPlan/pricingPlan.module.css';

const PrivacyPolicy = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Interventions by DeepSynaps - Unlock Your Cognitive Potential'
        description= 'Discover our range of personalized cognitive interventions at DeepSynaps. From brain training programs to non-invasive neuromodulation therapies, our interventions are designed to enhance cognitive abilities, improve focus, and promote mental well-being.'
        url = 'https://oxfordmindtech.com/interventions'
        keywords = 'Cognitive interventions, DeepSynaps, brain training programs, non-invasive neuromodulation therapies, cognitive abilities, mental well-being'
      />    
      <Navbar />
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div className={`${styles.PricingPlan} wrapper align-items-end clearfix`}>
            <div className={`${styles.pricingP_content_container} d-flex flex-row justify-content-between`}>
                <article className='d-flex flex-column '>
                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <h1>Privacy Policy</h1>
                            <div>&nbsp;</div>
                            <p>Effective Date: 22/12/2023</p>
                            <p className={"mt-3"}>Welcome to DeepSynaps. Our commitment to your privacy is paramount, especially as we use AI to innovate in healthcare, focusing on neurotechnology and cognitive health. This Privacy Policy explains our practices regarding the collection, use, and protection of your information.</p>
                            <ol className={"p-4"} type={"1"}>
                                <li>
                                    <strong>Information We Collect</strong>
                                    <p className={"mt-3"}>a. Personal Information: This includes details like your name, email address, and contact information, provided during registration.</p>
                                    <p>b. Health Data: We collect sensitive health-related data, such as neuroimaging data and cognitive health metrics, essential for our services.</p>
                                    <p>c. Usage Information: Includes data on how you interact with our website, like IP addresses and browser types.</p>
                                </li>
                                <li>
                                    <strong>Use of Your Information</strong>
                                    <p className={"mt-3"}>a. To provide personalized services in neurotech and AI healthcare.</p>
                                    <p>b. For research and development in enhancing our AI tools.</p>
                                    <p>c. To communicate with you about our services and updates.</p>
                                </li>
                                <li>
                                    <strong>Sharing of Information</strong>
                                    <p className={"mt-3"}>We do not sell your data. It may be shared with:</p>
                                    <p>a. Third-party service providers for operational purposes.</p>
                                    <p>b. Research partners, under strict confidentiality agreements.</p>
                                    <p>c. Legal entities, when legally required or for safety.</p>
                                </li>
                                <li>
                                    <strong>Data Security</strong>
                                    <p className={"mt-3"}>Advanced security measures are in place to protect your data from unauthorized access and misuse.</p>
                                </li>
                                <li>
                                    <strong>User Rights</strong>
                                    <p className={"mt-3"}>Under GDPR, you have the right to access, rectify, erase, and restrict processing of your personal data, and the right to data portability.</p>
                                </li>
                                <li>
                                    <strong>International Data Transfers and GDPR Compliance</strong>
                                    <p className={"mt-3"}>We comply with GDPR for users in the European Union. This includes ensuring lawful transfer of data outside the EU through adequacy decisions, standard contractual clauses, or other approved mechanisms.</p>
                                </li>
                                <li>
                                    <strong>HIPAA Compliance</strong>
                                    <p className={"mt-3"}>As a health-related service provider, we adhere to the Health Insurance Portability and Accountability Act (HIPAA) to ensure the confidentiality and security of protected health information.</p>
                                </li>
                                <li>
                                    <strong>Changes to This Privacy Policy</strong>
                                    <p className={"mt-3"}>We may update this policy to reflect changes in our practices. Users will be notified of significant changes via our website or email.</p>
                                </li>
                                <li>
                                    <strong>Contact Us</strong>
                                    <p className={"mt-3"}>For questions, exercising your GDPR or HIPAA rights, or concerns, contact us at <a href={"mailto: info@deepsynaps.com"}>info@deepsynaps.com</a></p>
                                </li>
                            </ol>
                        </div>
                    </div>
                </article>
            </div>
        </div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />
    </>
  )
}

export default PrivacyPolicy