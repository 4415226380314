import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';
import { Link } from 'react-router-dom';


const VisualSearchTest = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                        <div className={"row w-100"}>
                            <div className={"col-md-8 col-sm-12"}><h1>Visual Search Test</h1></div>
                            <div className={"col-md-4 col-sm-12 text-md-end text-sm-left pt-1"}>
                                <Link target={"_blank"} className={"btn btn-outline-dark"} to={'https://www.cognifit.com/battery-of-tests/scavi-rest-test/exploration-test'}>Start test now</Link>
                            </div>
                        </div>

                        <p>Investigate your visual scanning and selective attention by identifying target stimuli amongst distractors.</p>
                        <p><strong>Relation to QEEG, Neurofeedback, Biofeedback, and Neuromodulation:</strong></p>
                        <ul>
                            <li><strong>Brain Region:</strong> Parietal Lobe, Frontal Eye Fields</li>
                            <li><strong>Broadman Area:</strong> BA 7, BA 8</li>
                            <li><strong>EEG Electrode Places:</strong> Pz, Fz</li>
                            <li>
                                <strong>Neurofeedback:</strong>
                                <ul>
                                    <li><strong>Protocol:</strong> Enhance alpha (8-12Hz) and beta (12-30Hz) waves, potentially supporting attention modulation and optimal scanning of visual fields.</li>
                                    <li><strong>Objective:</strong> Improve efficiency and accuracy in identifying and responding to target stimuli amidst distractors.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Biofeedback:</strong>
                                <ul>
                                    <li><strong>Assessment:</strong> GSR (Galvanic Skin Response) to monitor arousal and stress during task performance.</li>
                                    <li><strong>Protocol:</strong> Utilize GSR biofeedback to manage stress response and maintain optimal arousal for vigilant and focused task performance.</li>
                                    <li><strong>Objective:</strong> Avoid the impact of stress or over-arousal on the ability to accurately and efficiently perform the visual search task.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Neuromodulation:</strong>
                                <ul>
                                    <li>
                                        <strong>tDCS:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Parietal Lobe</li>
                                            <li><strong>Protocol:</strong> Anodal stimulation over the parietal cortex to potentially improve spatial attention and visual scanning capabilities.</li>
                                            <li><strong>Objective:</strong> Enhance the efficiency of neural circuits involved in visual attention and discrimination tasks.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>PBM:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Parietal Cortex</li>
                                            <li><strong>Protocol:</strong> Utilize photobiomodulation to enhance metabolic activity and connectivity in the parietal cortex.</li>
                                            <li><strong>Objective:</strong> Support the neural health of regions involved in visual spatial attention and processing.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Mental Health Assessments and Their Relation:</strong>
                                <ul>
                                    <li><strong>DASS 21/42 (Depression, Anxiety, and Stress Scales):</strong> Explore how emotional states such as anxiety might impact attention and visual search capabilities. Anxiety, in particular, might impact selective attention and visual scanning through heightened arousal or distractibility.</li>
                                    <li><strong>Holmes-Rahe Life Stress Inventory:</strong> Investigate the possible impact of life stress on attention and visual processing. Elevated stress might impede efficient visual search through mechanisms like cognitive load or attentional narrowing.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>The Visual Search Test emphasizes selective attention and visual scanning, crucial in everyday tasks like driving or reading. By combining cognitive testing with neurofeedback, biofeedback, and neuromodulation, individuals can potentially improve their performance on tasks requiring heightened visual attention and discrimination, whilst also understanding how their emotional and psychological state may be interacting with their cognitive function. This integration of assessments and interventions provides a comprehensive approach to understanding and enhancing cognitive performance in the context of mental well-being.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default VisualSearchTest