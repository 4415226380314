import { React, useState } from 'react'
import './cooperateHeader.css'
import {Link} from "react-router-dom";

const CorporateRemoteWorkforceCognitiveHeader = () => {

    return (
      <header id='headerDiagnostic' className='d-flex flex-column'>
        <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
          <h1>Cooperate</h1>
          <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and cognitive abilities, identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
          <a className='navbar-Respbtn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
        </article>
        <div className="breakline-diagnostic"></div>
  
        <div className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
          <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/L38T6fnrVjc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>

        <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                <h1>DeepSynaps Remote Workforce Cognitive and Emotional Well-being Package:</h1>
                  <ol>
                      <li>
                          <p><b>Employee Assessments:</b></p>
                          <ul>
                              <li>
                                  <p><b>Cognitive Assessments:</b></p>
                                  <p>Assessments to measure employees' cognitive abilities, memory, attention, and problem-solving skills.</p>
                              </li>
                              <li>
                                  <p><b>Emotional Well-being Assessments:</b></p>
                                  <p>Assessments to evaluate employees' emotional well-being, stress levels, and mental health conditions.</p>
                              </li>
                          </ul>
                      </li>

                      <li>
                          <p><b>Personalized Cognitive and Emotional Wellness Plans:</b></p>
                          <p>Individualized cognitive and emotional wellness plans for each employee based on their assessment results, incorporating the following services.</p>
                      </li>

                      <li>
                          <p><b>Cognitive Enhancement Programs:</b></p>
                          <p>Cognitive enhancement programs and exercises designed to improve cognitive function, focus, and productivity, especially in a remote work setting.</p>
                      </li>

                      <li>
                          <p><b>Stress Management and Resilience Training:</b></p>
                          <p>Stress management workshops and emotional resilience training sessions to help employees effectively manage work-related stress and maintain well-being.</p>
                      </li>

                      <li>
                          <p><b>Remote Work Productivity Enhancement:</b></p>
                          <p>Training sessions and resources to help remote employees optimize their work environment and routines for increased productivity and cognitive performance.</p>
                      </li>

                      <li>
                          <p><b>Progress Tracking and Reporting:</b></p>
                          <p>- Regular cognitive and emotional assessments to monitor and track employees' progress and well-being.</p>
                          <p>- Detailed reports summarizing assessment results and recommendations for adjustments to wellness plans.</p>
                      </li>

                      <li>
                          <p><b>Equipment and Software:</b></p>
                          <p>DeepSynaps will provide diagnostic equipment, software licenses, and technical support for implementing cognitive and emotional wellness programs for remote employees.</p>
                      </li>

                      <li>
                          <p><b>Manager and Leadership Training:</b></p>
                          <p>Training for managers and leaders on how to support the cognitive and emotional well-being of their remote teams, including recognizing signs of stress and providing appropriate support.</p>
                      </li>

                      <li>
                          <p><b>Confidentiality and Data Security:</b></p>
                          <p>Ensuring the highest level of data security and confidentiality for employee information, prioritizing employee privacy.</p>
                      </li>
                  </ol>
                  <p>This package is designed to support the cognitive and emotional well-being of remote employees, especially in environments where remote work is prevalent. By offering comprehensive assessments, personalized wellness plans, and targeted support, it aims to help remote employees thrive in their work, manage stress effectively, and maintain a healthy work-life balance. DeepSynaps will collaborate closely with organizations to tailor the package to meet the specific needs and goals of their remote workforce, ultimately contributing to a more resilient and productive remote work environment.</p>
              </div>
            </div>
        </div>
      </header>
    )
}

export default CorporateRemoteWorkforceCognitiveHeader