import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';
import { Link } from 'react-router-dom';


const DigitSpanTest = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">

                        <div className={"row w-100"}>
                            <div className={"col-md-8 col-sm-12"}><h1>Digit Span Test</h1></div>
                            <div className={"col-md-4 col-sm-12 text-md-end text-sm-left pt-1"}>
                                <Link target={"_blank"} className={"btn btn-outline-dark"} to={'https://www.cognifit.com/battery-of-tests/wom-asm-test/sequential-test'}>Start test now</Link>
                            </div>
                        </div>

                        <p>Measure your working memory capacity and attention through sequence recall.</p>
                        <p><strong>Relation to QEEG, Neurofeedback, Biofeedback, and Neuromodulation:</strong></p>
                        <ul>
                            <li><strong>Brain Region:</strong> Prefrontal Cortex, Parietal Cortex</li>
                            <li><strong>Broadman Area:</strong> BA 46, BA 40, BA 7</li>
                            <li><strong>EEG Electrode Places:</strong> Fp1, Fp2, P3, P4</li>
                            <li>
                                <strong>Neurofeedback:</strong>
                                <ul>
                                    <li><strong>Protocol:</strong> Targeting an increase in beta wave activity (13-30 Hz) and a decrease in theta activity (4-7 Hz) within the prefrontal and parietal areas to support working memory function and attention.</li>
                                    <li><strong>Objective:</strong> Enhance working memory capacity and sustained attention by optimizing neural activity within key cognitive regions.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Biofeedback:</strong>
                                <ul>
                                    <li><strong>Assessment:</strong> Using heart rate variability (HRV) or skin conductance to evaluate stress or focus levels during cognitive tasks requiring working memory.</li>
                                    <li><strong>Protocol:</strong> Training individuals to maintain a calm and focused physiological state during tasks through methods like deep breathing or mindfulness techniques.</li>
                                    <li><strong>Objective:</strong> Improve performance on working memory tasks by managing physiological stress responses and promoting sustained attention through biofeedback-informed techniques.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Neuromodulation:</strong>
                                <ul>
                                    <li>
                                        <strong>tDCS:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Dorsolateral Prefrontal Cortex (DLPFC), Parietal Cortex</li>
                                            <li><strong>Protocol:</strong> Applying anodal stimulation to the DLPFC and/or parietal regions to facilitate neural excitability and optimize working memory performance.</li>
                                            <li><strong>Objective:</strong> To enhance neural efficiency in regions implicated in working memory and attention, potentially improving task performance.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>PBM:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Prefrontal Cortex</li>
                                            <li><strong>Protocol:</strong> Utilizing PBM to promote metabolic activity in prefrontal regions associated with working memory.</li>
                                            <li><strong>Objective:</strong> To foster and maintain cognitive health in regions pertinent to working memory and attention.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Mental Health Assessments and Their Relation:</strong>
                                <ul>
                                    <li><strong>DASS 21/42 (Depression, Anxiety, and Stress Scales):</strong> Elevated scores in depression or anxiety subscales may hint at possible difficulties or variabilities in working memory performance due to emotional or cognitive disturbances.</li>
                                    <li><strong>Holmes Rahe Life Event Scale:</strong> Significant life stressors or changes might negatively impact cognitive functions, like working memory, potentially providing context for any difficulties or variabilities observed during testing.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>Customized interventions utilizing neurofeedback, biofeedback, and neuromodulation can offer targeted support for working memory and attention, particularly within contexts that may be influenced or exacerbated by individual stressors or mental health contexts. Tailoring strategies to individual needs and contexts will further enhance the relevancy and efficacy of these interventions.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default DigitSpanTest