import React from 'react';
import { Helmet } from 'react-helmet-async';

export default function SEO({ 
  title = 'DeepSynaps - Revolutionizing Brain Health and Cognitive Performance',
  description = 'DeepSynaps is a cutting-edge startup dedicated to optimizing brain health and unlocking cognitive potential. Discover our advanced technologies, personalized interventions, and innovative solutions to enhance cognitive abilities, improve mental well-being, and unlock the full potential of the human brain. Explore our QEEG scans, cognitive tests, interventions, partnerships, and cooperate services. Join us on the forefront of brain health and cognitive optimization',
  name = 'deepsynaps',
  type = 'website',
  author = 'deepsynaps',
  url = 'https://oxfordmindtech.com/', 
  image = 'https://oxfordmindtech.com/OxfordMindtech.png', 
  lang = 'en', 
  metaCharset = 'utf-8',
  robots = 'index, follow',
  keywords = 'DeepSynaps, brain health, cognitive performance, AI technology, QEEG scans, cognitive assessments, brain training programs, neuromodulation therapies, mental well-being'
}) {
  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      <meta charset={metaCharset} />
      <meta name="description" content={description} />
      <meta name="author" content={author} />
      <meta name="robots" content={robots} />
      <meta name="keywords" content={keywords} />

      <meta property="og:url" content={url} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  );
}
