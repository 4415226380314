import React, { useState } from 'react'
import './footer.css'
import { footerLogoSvg, linkedinIcon, twitterIcon, youtubeIcon, TiktokLogo, facebookIcon, caretDownSvg, caretUpSvg, logoFooterSvg } from '../../../assets/images'
// import { LogoFooter } from '../../../assets/images'
import { Link, NavLink } from'react-router-dom'
const Footer = () => {

  const [showScanSubMenu, setShowScanSubMenu] = useState(false)
  const [showAssessmentSubMenu, setShowAssessmentSubMenu] = useState(false)

  const handleScanMenu = (e) => {
    setShowScanSubMenu(showScanSubMenu === true ? false : true);
      e.preventDefault();
    e.stopPropagation();
  }

  const handleAssessmentMenu = (e) => {
    setShowAssessmentSubMenu(showAssessmentSubMenu === true ? false : true);
    e.preventDefault();
    e.stopPropagation();
  }

  return (
    <>
      <footer>
        <div className="wrapper d-flex flex-column align-items-center" style={{gap:'64px'}}>
          <div className="footer-head">
            <article>
              <Link className='footer-content-link' to='/contact'><h1>Join Our Family</h1></Link>
              <p>We're doing what we do best</p>
            </article>
          </div>

          <div className="footer-content-container d-flex flex-row justify-content-between align-items-start">

            <div className="footer-content d-flex flex-column align-items-start">
              <p>DeepSynaps</p>
              <NavLink className='footer-content-link' to="/about">About</NavLink>
              <a href={"#"} className='footer-content-link' onClick={(e) => handleScanMenu(e)}>Scan&nbsp;&nbsp;<img width={12} src={showScanSubMenu === true ? caretUpSvg : caretDownSvg} /></a>
              {showScanSubMenu &&
                  <>
                    <NavLink className='footer-content-link' to="/bodyscan">&nbsp;&nbsp;Body Scan</NavLink>
                    <NavLink className='footer-content-link' to="/brainscan">&nbsp;&nbsp;Brain Scan</NavLink>
                  </>
              }
              <a href={"#"} className='footer-content-link' onClick={(e) => handleAssessmentMenu(e)}>Assessments&nbsp;&nbsp;<img width={12} src={showAssessmentSubMenu === true ? caretUpSvg : caretDownSvg} /></a>
              {showAssessmentSubMenu &&
                  <>
                    <NavLink className='footer-content-link' to="/cognitivetest">&nbsp;&nbsp;General Cognitive Assessment</NavLink>
                    <NavLink className='footer-content-link' to="/mental-health-assessment">&nbsp;&nbsp;Mental Health Assessment</NavLink>
                  </>
              }
              <NavLink className='footer-content-link' to="/interventions">Interventions</NavLink>
              <NavLink className='footer-content-link' to="/cooperate">Cooperate</NavLink>
              <NavLink className='footer-content-link' to='/partners'>Partners</NavLink>
              <NavLink className='footer-content-link' to='/pricing'>Prices</NavLink>
              {/*<NavLink className='footer-content-link' to="/contact">Contact Us</NavLink>*/}
            </div>  

            <div className="footer-content footerContactDiv d-flex flex-column align-items-start">
              <p>Contact Information</p>
              <a className='lil-more-left footer-mail' href="mailto:info@deepsynaps.com" target="_blank" rel="noreferrer">info@deepsynaps.com</a>
              <p className='lil-more-left phone-p footer-phone'>01865 338133</p>
              <a className='lil-more-left footer-address' href="https://goo.gl/maps/zhBjcq5Q4eJUdbAt9" target="_blank" rel="noreferrer">DeepSynaps<br />  John Eccles House Robert Robinson Avenue, Oxford Science Park, Oxford OX4 4GP</a>
            </div>

            <div className="Footer-socialMedia d-flex flex-column align-items-start justify-content-center">
              <p>Social Media</p>
              <div className="social-icons" style={{position:'relative', left:'-20px'}}>
                <a href="https://www.linkedin.com/in/deepsynaps" target="_blank" rel="noreferrer"><img src={linkedinIcon} alt="linkedin-logo" /></a>
                <a href="https://twitter.com/deepsynaps" target="_blank" rel="noreferrer"><img src={twitterIcon} alt="twitter-logo" /></a>
                <a href="https://www.youtube.com/@deepsynaps" target="_blank" rel="noreferrer"><img src={youtubeIcon} alt="youtube-logo" /></a>
              </div>
              <div className="social-icons socialIcons-bottom"style={{position:'relative', left:'-20px'}}>
                <a href="https://www.tiktok.com/@deepsynaps" target="_blank" rel="noreferrer"><img className='tiktokLogo-footer' src={TiktokLogo} alt="instagram-logo" /></a>
                <a href="https://www.facebook.com/profile.php?id=100093058925601" target="_blank" rel="noreferrer"><img src={facebookIcon} alt="facebook-logo" /></a>
              </div>
            </div>

          </div>

          <div className="footer-lower-container d-flex flex-row justify-content-between align-items-center">
            <img src={logoFooterSvg} alt="footer-logo" />
            <p>© 2023 DeepSynaps. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer