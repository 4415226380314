import React from 'react'
import './subsComponent.css'
import { ServiceProduct } from '../../../assets/images'


const SubsComponent = () => {
  return (
    <>
        <section id="subProducts" className="d-flex flex-column align-items-center">
          <div className="header-subProducts">
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                  <h1>Subscription</h1>
                  <p>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
              </article>
          </div>
          <div className="wrapper subContent d-flex flex-row justify-content-center align-items-center">
            <div className="subContentImg-container d-flex align-items-center justify-content-center position-relative">
              <img src={ServiceProduct} alt="iSyncWave helmet" />
              <p className='subContent-btn'>Home-Based</p>
            </div>
            <div className="subContent-article">
              <div className="subContent-articleHeader d-flex flex-row justify-content-between">
                <h3>iSyncWave</h3>
                <h3>£400</h3>
              </div>
              <ul className='d-flex flex-column'>
                <li>100% dry EEG measurement</li>
                <li>Fits all ages and genders</li>
                <li>High-quality signal</li>
                <li>Quick measurement and reporting in 10 minutes</li>
                <li>Simultaneous HRV measurement</li>
              </ul>
              <a className="subContent-articleBtn" href='https://connect.pabau.com/index.php?compid=13311' target='_blank' rel="noopener noreferrer">Scan Now</a>
            </div>
          </div>
        </section>
    </>
  )
}

export default SubsComponent