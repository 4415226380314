import { React, useState } from 'react'
import '../../CognitiveTests/Header/cognitiveTestHeader.css'
import {Link} from "react-router-dom";

const ComponentPsychologicalScaleHeader = () => {
  
  const [showSection1, setShowSection1] = useState(true)
  const [showSection2, setShowSection2] = useState(false)
  const [showSection3, setShowSection3] = useState(false)
  const [showSection4, setShowSection4] = useState(false)
  const [showSection5, setShowSection5] = useState(false)
  const [showSection6, setShowSection6] = useState(false)

  const handleShowSection1 = () => { // Showing 1st element
    setShowSection1(true)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(false)
  }

  const handleShowSection2 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(true)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(false)
  }

  const handleShowSection3 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(true)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(false)
  }

  const handleShowSection4 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(true)
    setShowSection5(false)
    setShowSection6(false)
  }

  const handleShowSection5 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(true)
    setShowSection6(false)
  }

  const handleShowSection6 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(true)
  }

  const handleShowSection7 = () => { // Showing 1st element
    setShowSection1(false)
    setShowSection2(false)
    setShowSection3(false)
    setShowSection4(false)
    setShowSection5(false)
    setShowSection6(false)
  }

  return (
    <header id='headerDiagnostic' className='d-flex flex-column'>
      <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
        <h1>Mental Health Assessment</h1>
        <div className='d-flex flex-column position-relative'>
          <p className='headerDiagnosticArticleP'>  Anyone feeling overwhelmed, anxious, or down, or those seeking a general emotional health checkup. To identify and address potential emotional disturbances and promote mental well-being.</p>
        </div>
      </article>
      <div className="breakline-diagnostic"></div>

      <div className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
        <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      </div>

      <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
        <div className="headerDiagnostic-options d-flex flex-column align-items-start">
          <button className={showSection1 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection1}>What is it for?</button>
          <button className={showSection2 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection2}>Who is it for?</button>
          <button className={showSection3 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection3}>Why are we doing it?</button>
          <button className={showSection4 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection4}>Relation to QEEG, Biofeedback, and Neurofeedback</button>
          <button className={showSection5 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection5}>Relation to tDCS and PBM</button>
          <button className={showSection6 ? 'optionsBtn-activeDiagnostic' : 'optionsBtn-passiveDiagnostic'} onClick={handleShowSection6}>Relation to Cognitive Tests</button>
        </div>
        {showSection1 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>What is it for?</h1>
                <p>To understand and measure specific psychological components or attributes.</p>
              </div>
            </div>
        }{showSection2 &&
          <div className="headerDiagnostic-content d-flex flex-row align-items-start">
            <div className="diagnosticArticle2 d-flex flex-column align-items-start">
              <h1>Who is it for?</h1>
              <p>Those wanting insights into specific psychological traits.</p>
            </div>
          </div>
        }{showSection3 &&
          <div className="headerDiagnostic-content d-flex flex-row align-items-start">
            <div className="diagnosticArticle2 d-flex flex-column align-items-start">
              <h1>Why are we doing it?</h1>
              <p>To tailor interventions better.</p>
            </div>
          </div>
        }
        {showSection4 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>Relation to QEEG, Biofeedback, and Neurofeedback</h1>
                <p>Each psychological component or attribute can have associated neural patterns, especially within the cortex regions responsible for higher cognitive functions. QEEG can detect and map these patterns, aligning with the insights provided by the Component Psychological Scale. Neurofeedback, using Delta training, can potentially help in grounding and stabilizing mood. Biofeedback, particularly EMG, offers insights into muscle tension, giving a physiological perspective on emotional and cognitive states.</p>
              </div>
            </div>
        }
        {showSection5 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>Relation to tDCS and PBM</h1>
                <p>Personality insights from MBTI can be complemented by tDCS applied to regions like the frontal lobe to bolster cognitive functions. Brain PBM therapy, on the other hand, promotes cellular health and improved cognition by enhancing the metabolic capacity of neurons, potentially supporting personality-linked skills.</p>
              </div>
            </div>
        }
        {showSection6 &&
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle2 d-flex flex-column align-items-start">
                <h1>Relation to Cognitive Tests</h1>
                <p>The Component Psychological Scale dives deep into specific psychological attributes, providing a more granular perspective than broader personality or mood assessments. This detailed approach invites a closer look at how these specific components interface with cognitive functioning.</p>
                <p><strong>Cognitive Abilities and Traits:</strong> Attributes like resilience, adaptability, or impulsivity measured by the scale can be cross-referenced with cognitive tests that measure executive functions, decision-making, and problem-solving. For instance, someone scoring high in impulsivity might exhibit different patterns in cognitive tests centered on decision-making.</p>
                <p><strong>Memory and Recall:</strong> Psychological components like attention span or distractibility can be linked with cognitive tests that focus on short-term memory, recall speed, and retention. A high score in distractibility might correspond with certain patterns in memory-based cognitive tests.</p>
                <p><strong>Attention and Focus:</strong> Attributes like concentration or susceptibility to distraction can be linked with cognitive assessments gauging attention spans, task-switching, or multitasking capabilities.</p>
                <p><strong>Emotional Intelligence and Perception:</strong> Psychological components revolving around empathy, interpersonal understanding, or emotional stability can be juxtaposed with cognitive tests focused on social perception, facial recognition, and emotional intelligence.</p>
                <p>By aligning findings from the Component Psychological Scale with cognitive assessments, a multidimensional understanding of an individual's psychological and cognitive makeup is achieved. This integrated approach aids in crafting interventions, strategies, or training programs that are both holistic and personalized, addressing the unique needs and strengths of each individual.</p>
              </div>
            </div>
        }
      </div>
    </header>
  )
}

export default ComponentPsychologicalScaleHeader