import React, { useState } from 'react'
import './team.css'
import { AliY, FerhadR, HavvaC, NumanC, RabiaY, SerdarRB, SerkanK, TarlanS, EmiliaSandoiu, HollieHuang, PaulBhangal, SofiaMohamed, ThomasGill } from '../../../assets/images'

const Team = () => {
  const memberBios = [
    // {
    //   id: 2,
    //   name: 'Dr Asli KALIN',
    //   role: 'Academic Clinical Fellow, GP',
    //   image: AsliK,
    //   bio: "Dr. Asli Kalin is an Academic Clinical Fellow at the University of Oxford, specializing in AI and health technologies. With a strong academic background, having graduated from Cambridge University and completed a Master's degree at Harvard University, Dr. Kalin brings a wealth of knowledge and expertise to her role. As a registered GP in the UK, she is dedicated to patient-centered care and is passionate about improving healthcare systems to ensure efficiency and social justice. Dr. Kalin's interests extend to the fields of medicine and public health, where she actively seeks opportunities to collaborate with like-minded individuals who share her passion. Her commitment to advancing healthcare and making a positive impact in people's lives is evident in her work."
    // },
    {
      id: 1,
      name: 'Dr. Ali Yildirim',
      role: 'Founder & CEO',
      image: AliY,
      bio: "<p>Dr. Ali Yildirim, the Founder and CEO of DeepSynaps, brings over 12 years of industry, academia, healthcare, and IT experience to his role. With a background in both healthcare and Artificial Intelligence, Dr. Yildirim's expertise lies in leading teams, implementing innovative solutions, and driving advancements in the field of neurology and mental health. Having studied and conducted research at the University of Oxford and Harvard university, he combines academic excellence with practical knowledge. His strategic management skills and deep understanding of software engineering practices enable him to deliver high-quality and impactful solutions. Through his ongoing research and clinical practice in QEEG, rTMS, tDCS, and AI implementation in non-invasive neuromodulation, Dr. Yildirim remains at the forefront of cutting-edge technologies, ensuring that DeepSynaps continues to revolutionize the field and provide exceptional services to its clients.</p>"
    },
    {
      id: 3,
      name: 'Dr Serkan Kaya',
      role: 'Machine learning Engineer',
      image: SerkanK,
      bio: "<p>Dr. Serkan Kaya is a highly skilled Machine Learning Engineer and Advisor with expertise in physics and machine learning technologies. He holds a Ph.D. degree in Physics from Université de Bourgogne and has over 10 years of experience in research, academia, and industry. With a strong background in data analytics and machine learning models, Dr. Kaya has made significant contributions in solving real-world problems and deriving actionable insights. His experience includes working as a Laboratory Manager at the renowned University of Oxford, where he gained valuable exposure to cutting-edge research and collaboration with top experts in the field. Driven by a passion for innovation and scientific excellence, Dr. Kaya's expertise in machine learning and data science is instrumental in developing advanced solutions and driving positive impact across various domains.</p>",

    },{
      id: 4,
      name: 'Dr Numan Celik',
      role: 'AI-Biomedical Imaging Scientist',
      image: NumanC,
      bio: `<p>Dr. Numan Celik is an accomplished AI-Biomedical Imaging Scientist and Advisor with a strong background in medical image analysis. He has made significant contributions to the field through his work at the University of Oxford, where he served as a Post-Doc Researcher on the project "Computer Aided Cancer Diagnosis using Machine Learning." Dr. Celik's expertise lies in developing advanced deep learning algorithms for computer vision and medical image projects. His areas of interest include leveraging AI in healthcare and applying deep learning techniques to medical image analysis. With a passion for pushing the boundaries of technology in healthcare, Dr. Celik plays a crucial role in driving innovation and advancing the field of biomedical imaging through his expertise in AI and deep learning.</p>`
    },{
      id: 5,
      name: 'Dr Tarlan S.',
      role: 'AI Engineer',
      image: TarlanS,
      bio: "<p>Dr. Tarlan Suleymanov is a highly skilled AI Engineer specializing in Robotics and Computer Vision. With a strong background in Machine Learning and Computer Vision, he is currently focused on developing perception systems for autonomous driving. Dr. Suleymanov completed his DPhil at the Oxford Robotics Institute, University of Oxford, where his research primarily focused on road boundary detection using Deep Learning techniques. Under the guidance of Prof. Paul Newman, he made significant contributions to the field. Dr. Suleymanov is a valued member of Mansfield College and the Oxford Union, reflecting his commitment to academic excellence and intellectual engagement. With his expertise in AI, Robotics, and Computer Vision, he plays a vital role in advancing autonomous driving technology and shaping the future of transportation</p>"
    },
    // {
    //   id: 6,
    //   name: 'Hacer Yildirim',
    //   role: 'Occupational Therapist',
    //   image: HacerY,
    //   bio: "Hacer Yildirim is a dedicated Occupational Therapist specializing in TMS (Transcranial Magnetic Stimulation) and tDCS (Transcranial Direct Current Stimulation) therapies. With a degree from Oxford Brookes University, she brings a wealth of knowledge and experience in the field of Occupational Therapy. Hacer has been instrumental in providing therapeutic interventions and support to patients at the Oxford Health NHS Foundation Trust. Her areas of interest lie in sensory motor function and neuro occupational therapy, where she focuses on helping individuals optimize their cognitive and physical abilities. With her expertise in TMS and tDCS, Hacer plays a crucial role in assisting patients in their journey towards improved well-being and enhanced quality of life."
    // },
    {
      id: 7,
      name: 'Serdar Reshad Bakir',
      role: 'AI ethics and Legal Advisor',
      image: SerdarRB,
      bio: "<p>Serdar is a versatile individual with a background in law and business administration. Upon completing Magister Juris at Oxford University, he founded a tech startup (Xource) within Oxford University Innovation. His expertise spans various fields such as finance, human rights law, legal philosophy, blockchain technology, and artificial intelligence. As part of our advisory board, Serdar brings valuable knowledge and experience to help guide our startup. With a keen understanding of innovation and a well-rounded skill set, Serdar is poised to make a positive impact on our mission to advance the industry.</p>"
    },{
      id: 8,
      name: 'Havva C',
      role: 'ISQTB Certified Agile Tester',
      image: HavvaC,
      bio: "<p>Havva C is a highly skilled professional with a background in mathematics and software testing. Graduating from Oxford University, Havva holds certifications such as ISQTB Certified Agile Tester and AWS CCP. With expertise in project management methodologies and a passion for testing and quality, she excels at driving efficient software development processes. Havva is known for her collaborative approach, creating high-quality acceptance test scenarios and continuously seeking improvement. She welcomes new connections and is always eager to make a positive impact in her field.</p>"
    },{
      id: 9,
      name: 'Farhad R.',
      role: 'Application Security Engineer',
      image: FerhadR,
      bio: "<p>Ferhad R is an experienced Application Security Engineer with a strong background in cybersecurity. He graduated from King's College London, where he gained a solid foundation in the field. With 7+ years of hands-on experience, he specializes in areas such as Application Security, Vulnerability Management, Penetration Testing, and more. Ferhad is committed to ensuring the security and protection of digital assets, and his expertise in various domains, including Cloud Security, Endpoint Security, and Risk Management, makes him a valuable resource in safeguarding organizations from cyber threats.</p>"
    },{
      id: 10,
      name: 'Rabia Yildirim',
      role: 'Cyber security engineer',
      image: RabiaY,
      bio: "<p>Rabia Yildirim is a highly skilled Penetration Tester and Cybersecurity Engineer with a unique background in both healthcare and cybersecurity. With a deep passion for ensuring the security and integrity of healthcare systems, Rabia specializes in conducting penetration tests specifically tailored for healthcare environments. Her expertise lies in identifying vulnerabilities, assessing risks, and implementing robust security measures to protect sensitive patient data and critical healthcare infrastructure. Rabia's dedication to the field of cybersecurity and her focus on healthcare makes her a valuable asset in safeguarding healthcare organizations from cyber threats and ensuring the privacy and safety of patient information.</p>"
    }
  ]
  
  const [selectedMember, setSelectedMember] = useState(memberBios[0]);
  const [showDiv, setShowDiv] = useState(false);
  
  const firstHalf = memberBios.slice(0, 5);
  const secondHalf = memberBios.slice(5, 10);

  const handleCardClick = (member) => {
    setSelectedMember(member);
    setShowDiv(true); // Open the bio-container
    document.getElementById('team').scrollIntoView()
  };

  const handleButtonClick = () => {
    setShowDiv(false);
  };

  return (
    <>
      <div id='team' className="wrapper d-flex flex-column justify-content-center align-items">
        <div className="team-title w-100 text-center">
          <article className={"w-100"}>
            <h1>Our team</h1>
            <p className={"w-100"}>At DeepSynaps, we are committed to providing the highest quality of care and expertise to all our clients. Our team of experienced professionals includes neuroscientists, psychologists, and other brain health experts who are passionate about helping you achieve your best possible brain health.</p>
          </article>
        </div>

        {showDiv && (
          <div id="bio-container" className="member-bios d-flex flex-row align-items-center">
            <div className="bios-image-container d-flex flex-column justify-content-center align-items-center">
              <img src={selectedMember.image} alt="" />
              <br />
              <h6>{selectedMember.name}</h6>
              <p className="memberS-title" href="#">{selectedMember.role}</p>
            </div>
            <article dangerouslySetInnerHTML={{__html: selectedMember.bio}}></article>
            <button onClick={handleButtonClick} className="member-bios-closingBtn d-flex flex-row justify-content-center align-items-center">X</button>
          </div>
        )}
        <div className="member-card-container d-flex flex-row justify-content-center">
          <h2>Technical Team</h2>
          {firstHalf.map(member =>
            <div key={member.id} className="member-card d-flex flex-column justify-content-center align-items-center">
              <div className="member-img-contanier d-flex flex-column align-items-center">
                <img className='member-card-img' src={member.image} alt={`${member.name}'s`} />
                <br />
                <h6>{member.name}</h6>
                <p className='memberS-title' href="#">{member.role}</p>
              </div>
              <button onClick={() => handleCardClick(member)} className='member-card-btn'>Learn More</button>
            </div>
          )}
        </div>
        <div className="member-card-container d-flex flex-row align-items-start">
          {secondHalf.map(member => 
              <div key={member.id} className="member-card d-flex flex-column justify-content-center align-items-center">
                <div className="member-img-contanier d-flex flex-column align-items-center">
                  <img className='member-card-img' src={member.image} alt={`${member.name}'s`}></img>
                  <br />
                  <h6>{member.name}</h6>
                  <p className='memberS-title'>{member.role}</p>
                </div>
                <button onClick={() => handleCardClick(member)} className='member-card-btn'>Learn More</button>
              </div>
          )}
        </div>
      </div>
    </>
  )
}
/**/
 export default Team
