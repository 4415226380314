import { React, useState } from 'react'
import './cooperateHeader.css'
import {Link} from "react-router-dom";

const CorporateMentalHealthClinicHeader = () => {

    return (
      <header id='headerDiagnostic' className='d-flex flex-column'>
        <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
          <h1>Cooperate</h1>
          <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and cognitive abilities, identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
          <a className='navbar-Respbtn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
        </article>
        <div className="breakline-diagnostic"></div>
  
        <div className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
          <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/L38T6fnrVjc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>

        <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                <h1>DeepSynaps Mental Health Clinic and Counseling Center Wellness Package:</h1>
                  <ol>
                      <li>
                          <p><b>Mental Health Assessments:</b></p>
                          <p>Comprehensive mental health assessments, including standardized questionnaires and clinical interviews, to evaluate patients' emotional well-being, stress levels, and mental health conditions.</p>
                      </li>

                      <li>
                          <p><b>Cognitive Assessments:</b></p>
                          <p>Cognitive assessments to measure cognitive abilities, memory, attention, and problem-solving skills, with a focus on identifying any cognitive challenges that may be affecting mental health.</p>
                      </li>
                      <li>
                          <p><b>Psychotherapy and Counseling Services:</b></p>
                          <p>Psychotherapy and counseling sessions provided by licensed mental health professionals to address patients' emotional and psychological needs.</p>
                      </li>
                      <li>
                          <p><b>Neurofeedback Therapy:</b></p>
                          <p>Personalized neurofeedback therapy sessions to enhance emotional regulation, reduce anxiety, and improve focus and cognitive function.</p>
                      </li>
                      <li>
                          <p><b>Neuromodulation (TDCS-PBM):</b></p>
                          <p>Transcranial Direct Current Stimulation (TDCS) and Photobiomodulation (PBM) sessions to support mood stabilization and cognitive enhancement for patients with mood disorders.</p>
                      </li>
                      <li>
                          <p><b>Biofeedback for Stress Management:</b></p>
                          <p>Customized biofeedback sessions to assist patients in managing stress, anxiety, and optimizing their emotional states.</p>
                      </li>
                      <li>
                          <p><b>Progress Tracking and Reporting:</b></p>
                          <p>- Detailed reports summarizing patient assessment results and progress over time.</p>
                          <p>- Regular monitoring of patient data to track improvements and adapt treatment plans as necessary.</p>
                      </li>
                      <li>
                          <p><b>Training and Support for Therapists:</b></p>
                          <p>- Training for therapists and counselors on how to use DeepSynaps technology and techniques effectively in patient care.</p>
                          <p>- Ongoing consultation and support to ensure successful implementation of the package.</p>
                      </li>
                      <li>
                          <p><b>Equipment and Software:</b></p>
                          <p>- DeepSynaps will provide diagnostic equipment, software licenses, and technical support for seamless integration into mental health clinic operations.</p>
                      </li>
                      <li>
                          <p><b>Confidentiality and Data Security:</b></p>
                          <p>- Ensuring the highest level of data security and confidentiality for sensitive patient information.</p>
                      </li>
                  </ol>
                  <p>This package is designed to support mental health clinics and counseling centers in providing holistic care for their patients, addressing both mental health and cognitive well-being. By combining advanced assessments, psychotherapy, and cognitive interventions, it aims to enhance emotional resilience, cognitive function, and overall mental health outcomes for patients. DeepSynaps will collaborate closely with clinics to customize the package to meet the specific needs and treatment approaches of their patients, ultimately contributing to improved mental health and well-being.</p>
              </div>
            </div>
        </div>
      </header>
    )
}

export default CorporateMentalHealthClinicHeader