import { React, useState } from 'react'
import './cooperateHeader.css'
import {Link} from "react-router-dom";

const CoooperateHeaderHospitals = () => {

    return (
      <header id='headerDiagnostic' className='d-flex flex-column'>
        <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
          <h1>Cooperate</h1>
          <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and cognitive abilities, identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
          <a className='navbar-Respbtn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
        </article>
        <div className="breakline-diagnostic"></div>
  
        <div className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
          <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/L38T6fnrVjc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>

        <div className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
            <div className="headerDiagnostic-content d-flex flex-row align-items-start">
              <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                <h1>DeepSynaps Hospital Wellness and Patient Care Package:</h1>
                  <ol>
                      <li>
                          <p><b>Comprehensive Health and Mental Assessment:</b></p>
                          <ul>
                              <li>
                                  <p><b>Body Scan:</b></p>
                                  <p>Including EMG, ECG/Pulse, Respiratory, Temperature, Skin condition, SCP, and EOG scans to assess patients' physical health, stress levels, and physiological responses.</p>
                              </li>
                              <li>
                                  <p><b>Brain Scan (QEEG Brain Mapping):</b></p>
                                  <p>Both Dry and Wet EEG scans to assess patients' brain activity and identify cognitive patterns and potential cognitive challenges.</p>
                              </li>
                          </ul>
                      </li>

                      <li>
                          <p><b>Mental Health and Cognitive Evaluation:</b></p>
                          <p>- In-depth mental health assessments to evaluate patients' emotional well-being, stress levels, and overall mental health.</p>
                          <p>- Cognitive assessments to measure cognitive abilities, memory, attention, and problem-solving skills, especially for patients with neurological conditions.</p>
                      </li>

                      <li>
                          <p><b>Personalized Patient Care:</b></p>
                          <ul>
                              <li>
                                  <p><b>Neurofeedback Sessions:</b></p>
                                  <p>Tailored neurofeedback training for patients with neurological conditions to improve cognitive function, memory, and emotional well-being.</p>
                              </li>
                              <li>
                                  <p><b>Neuromodulation (TDCS-PBM):</b></p>
                                  <p>Sessions for pain management and cognitive enhancement for patients with chronic pain or neurological disorders.</p>
                              </li>
                              <li>
                                  <p><b>Biofeedback:</b></p>
                                  <p>Sessions to assist patients in managing stress and anxiety during their hospital stay.</p>
                              </li>
                          </ul>
                      </li>

                      <li>
                          <p><b>Rehabilitation and Recovery:</b></p>
                          <p>- Integration of neuromodulation (TDCS-PBM) into rehabilitation programs for patients recovering from neurological injuries or surgeries.</p>
                          <p>- Customized neurofeedback training to aid in cognitive rehabilitation and improve motor skills for patients with neurological conditions.</p>
                      </li>
                      <li>
                          <p><b>Reporting and Monitoring:</b></p>
                          <p>- Detailed reports summarizing patient assessment results and progress over time.</p>
                          <p>- Regular monitoring of patient data to track improvements and adapt treatment plans as necessary.</p>
                      </li>
                      <li>
                          <p><b>Equipment and Software:</b></p>
                          <p>DeepSynaps will provide diagnostic equipment, software licenses, and technical support for seamless integration into hospital departments, such as neurology, psychiatry, and rehabilitation.</p>
                      </li>
                      <li>
                          <p><b>Staff Training and Collaboration:</b></p>
                          <p>- Training for hospital staff on how to use DeepSynaps equipment and software effectively in patient care.</p>
                          <p>- Collaboration with hospital departments to integrate cognitive assessment and enhancement techniques into treatment plans.</p>
                      </li>
                      <li>
                          <p><b>Confidentiality and Data Security:</b></p>
                          <p>Ensuring the highest level of data security and confidentiality for sensitive patient information.</p>
                      </li>
                  </ol>
                  <p>This package is designed to support hospitals in providing holistic patient care that addresses both physical and mental well-being. By combining advanced diagnostic assessments with personalized cognitive and mental health interventions, it aims to improve patient outcomes, enhance rehabilitation efforts, and reduce the psychological impact of hospitalization. DeepSynaps will collaborate closely with hospitals to customize the package to their specific departments and patient needs, ultimately contributing to improved patient care and recovery.</p>
              </div>
            </div>
        </div>
      </header>
    )
}

export default CoooperateHeaderHospitals