import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';


const PHQCognitiveAssessment = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">
                        <h1>PHQ9</h1>
                        <p>It is composed of 9 questions aimed at identifying the self-perceived risk of suffering Depression.</p>
                        <p>The PHQ-9 stands out as a prominent tool, equipped with nine critical questions, aimed at delineating symptoms associated with depressive disorders in individuals.</p>
                        <ul>
                            <li>
                                <strong>Emotional Dysregulation and Mood Fluctuations:</strong>
                                <ul>
                                    <li><strong>Brain Regions and QEEG:</strong> The amygdala, hippocampus, and prefrontal cortex, all critical to mood regulation, have been implicated in depression. Altered QEEG patterns, such as decreased alpha and increased theta activity, may be evident in these regions among individuals with high PHQ-9 scores.</li>
                                    <li><strong>Biofeedback:</strong> HRV offers a window into the autonomic nervous system's functioning, with reduced HRV often being correlated with depression, reflecting a potential autonomic imbalance.</li>
                                    <li><strong>Neurofeedback:</strong> Interventions can focus on modulating specific brainwave patterns that are aberrant in depression, like enhancing alpha wave activity and regulating theta waves, to support mood stabilization.</li>
                                    <li><strong>tDCS:</strong> Stimulation targeting the dorsolateral prefrontal cortex, a region often hypoactive in depression, can possibly uplift mood and enhance cognitive functions.</li>
                                    <li><strong>PBM:</strong> Photobiomodulation directed towards the prefrontal cortex and other mood-regulating areas might facilitate cellular energy processes, potentially ameliorating depressive symptoms.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Cognitive Symptoms of Depression:</strong>
                                <ul>
                                    <li><strong>Brain Regions and QEEG:</strong> Depressive disorders often intersect with diminished cognitive functions, involving regions like the anterior cingulate cortex and other frontal areas. QEEG might display irregularities in these regions when cognitively challenged.</li>
                                    <li><strong>Biofeedback:</strong> Parameters such as skin conductance can be monitored to gauge arousal levels, which could be disrupted in depression, leading to cognitive and attentional deficits.</li>
                                    <li><strong>Neurofeedback:</strong> Tailored sessions can be designed to promote brainwave patterns that bolster cognitive clarity and counteract the cognitive fog often accompanying depression.</li>
                                    <li><strong>tDCS:</strong> Directing currents towards cognitive hubs can potentially ameliorate the cognitive symptoms associated with depression.</li>
                                    <li><strong>PBM:</strong> By strategically enhancing metabolic processes in these critical cognitive areas, there's potential to combat the cognitive symptoms of depression.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>Integrating the diagnostic acumen of the PHQ-9 with these sophisticated neuroscientific methodologies can yield a comprehensive picture of an individual's depressive landscape. This intersectional approach is quintessential for diagnostic depth and formulating individualized therapeutic interventions.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default PHQCognitiveAssessment