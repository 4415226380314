import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';
import { Link } from 'react-router-dom';


const EyeHandCoordinationTest = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">

                        <div className={"row w-100"}>
                            <div className={"col-md-8 col-sm-12"}><h1>Eye-Hand Coordination Test</h1></div>
                            <div className={"col-md-4 col-sm-12 text-md-end text-sm-left pt-1"}>
                                <Link target={"_blank"} className={"btn btn-outline-dark"} to={'https://www.cognifit.com/battery-of-tests/upda-shif-test/synchronization-test'}>Start test now</Link>
                            </div>
                        </div>

                        <p>Investigate the synchronization between visual information processing and motor responses.</p>
                        <p><strong>- Objective:</strong> Evaluate the ability to coordinate visual input with hand movements, which is crucial for numerous daily activities and specific occupational tasks.</p>
                        <p><strong>Relation to QEEG, Neurofeedback, Biofeedback, and Neuromodulation:</strong></p>
                        <ul>
                            <li><strong>Brain Region:</strong> Parietal Cortex, Frontal Eye Fields, Motor Cortex, Cerebellum</li>
                            <li><strong>Broadman Area:</strong> BA 7, BA 8, BA 4</li>
                            <li><strong>EEG Electrode Places:</strong> Pz, Fz, Cz</li>
                            <li>
                                <strong>Neurofeedback:</strong>
                                <ul>
                                    <li><strong>Protocol:</strong> Addressing the synchronization and coherence between parietal and motor regions by training beta (13-30 Hz) and/or gamma (30-40 Hz) activities.</li>
                                    <li><strong>Objective:</strong> To enhance the efficiency and speed of information transfer between visual processing and motor execution regions, which can potentially improve eye-hand coordination.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Biofeedback:</strong>
                                <ul>
                                    <li><strong>Assessment:</strong> Investigating any stress or anxiety manifestations (using metrics like GSR and HRV) that might affect motor precision and visual-motor coordination.</li>
                                    <li><strong>Protocol:</strong> Employing stress reduction strategies, which might involve paced breathing and relaxation exercises, to manage stress levels that could adversely impact eye-hand coordination.</li>
                                    <li><strong>Objective:</strong> To maintain a stable and calm physiological state that might facilitate better eye-hand coordination by reducing anxiety or stress-related motor impediments.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Neuromodulation:</strong>
                                <ul>
                                    <li>
                                        <strong>tDCS:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Parietal Cortex, Motor Cortex</li>
                                            <li><strong>Protocol:</strong> Utilizing anodal stimulation to potentially enhance neuronal activity in the parietal and motor cortex areas, which are critical for integrating visual input with motor output.</li>
                                            <li><strong>Objective:</strong> To potentiate neural networks that are instrumental for eye-hand coordination and might thereby facilitate improvement in task performance.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>PBM:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Parietal Cortex, Motor Cortex</li>
                                            <li><strong>Protocol:</strong> Applying PBM to potentially enhance mitochondrial function and cellular health in regions responsible for visual-motor integration and coordination.</li>
                                            <li><strong>Objective:</strong> To sustain and support the neural health and metabolic activity of regions implicated in eye-hand coordination.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Mental Health Assessments and Their Relation:</strong>
                                <ul>
                                    <li><strong>DASS 21/42 (Depression, Anxiety, and Stress Scales):</strong> Emotional states, particularly stress and anxiety, could impact motor coordination and attention, thus affecting eye-hand coordination performance. Understanding emotional wellbeing through DASS can provide insights into any psychological elements that might be influencing task performance</li>
                                    <li><strong>Holmes and Rahe Stress Scale:</strong> Stress can potentially hinder cognitive and motor performance. Identifying life stressors that might be contributing to performance issues could be vital for devising comprehensive intervention strategies.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>A multifaceted approach integrating QEEG, biofeedback, neurofeedback, and neuromodulation, underpinned by insights from psychological assessments, can provide a robust framework for comprehensively addressing and potentially enhancing eye-hand coordination, tailoring interventions to individual cognitive, physiological, and emotional profiles.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default EyeHandCoordinationTest