import React, { useState } from 'react';
import './pricingPlan.css';
import styles from './pricingPlan.module.css';
import { PricingPlanV, PricingPlanX } from '../../../assets/images'
import { useLocation } from 'react-router-dom';

const PricingPlan = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };

  const CombinationPlans = [
    {
      id: `Combination Basic Plan`,
      name: 'Basic',
      description: 'Brain Scan & Cognitive Assessment',
      price: '£1400',
    },
    {
      id: `Combination Standart Plan`,
      name: 'Standard',
      description: 'Brain scan / Cognitive assessment & consultation',
      price: '£3200',
    },
    {
      id: `Combination Premium Plan`,
      name: 'Premium',
      description: 'Brain scan/ cognitive assessment & brain training with neuro modulation',
      price: '£4200',
    },
    {
      id: `Combination All In One Plan`,
      name: 'All in One',
      description: 'Brain scan/ cognitive assessment & brain training with neuro modulation',
      price: '£7000',
    },
  ];

  /* ... */

  const CombinationFeatures = [ 
    {
      id: 0,
      title: 'Initial Assessment (1)',
      availability: [PricingPlanV, PricingPlanV, PricingPlanV, PricingPlanV],
    },
    {
      id: 1,
      title: 'Mental health assessment and Report (5)',
      availability: [PricingPlanV, PricingPlanV, PricingPlanV, PricingPlanV],
    },
    {
      id: 2,
      title: 'Cognitive test and Report (3)',
      availability: [PricingPlanV, PricingPlanV, PricingPlanV, PricingPlanV],
    },
    {
      id: 3,
      title: 'QEEG scan and Report (1)',
      availability: [PricingPlanV, PricingPlanV, PricingPlanV, PricingPlanV],
    },
    {
      id: 4,
      title: 'Biofeedback or Neurofeedback( 20) Session',
      availability: [PricingPlanX, PricingPlanV, PricingPlanX,, PricingPlanX],
    },
    {
      id: 5,
      title: 'Tdcs or PBM headset, (2 months )subscription and personalized protocol',
      availability: [PricingPlanX, PricingPlanX, PricingPlanV, PricingPlanV],
    },
    {
      id: 6,
      title: 'Biofeedback or Neurofeedback (20) Session',
      availability: [PricingPlanX, PricingPlanX, PricingPlanX, PricingPlanV ],
    }
    // ,
    // {
    //   id: 7,
    //   title: 'Artificial intelligent based neuromodulation protocols',
    //   availability: [PricingPlanX, PricingPlanX, PricingPlanV, ],
    // },
    // {
    //   id: 8,
    //   title: 'Non-Invasive neuro modulation devices',
    //   availability: [PricingPlanX, PricingPlanX, PricingPlanV, ],
    // }
  ];

  const plans = CombinationPlans;
  const features = CombinationFeatures;
  const location = useLocation();

  return (
    <>
      {location.pathname == '/pricing' &&
        <>
          <div className={`${styles.PricingPlan} wrapper align-items-end clearfix`}>
            <div className={`${styles.pricingP_content_container} d-flex flex-row justify-content-between`}>
              <div className={`${styles.pricingP_header_content} d-flex flex-column align-items-start`}>
                <article>
                  <h1>Pricing</h1>
                </article>
              </div>
            </div>
            <div className={`${styles.plans_feature_container_new}`}>
                <div className={`${styles.pricingRow}`}>
                  <div className={`row`}>
                    <div className={"col-lg-9"}>Initial Assessment</div>
                    <div className={"col-lg-3 fw-bold text-end"}>&pound;200</div>
                  </div>
                </div>

                <div className={`${styles.pricingRow}`}>
                  <div className={`row`}>
                    <div className={"col-lg-9"}>QEEG Scan, Report &Consultation</div>
                    <div className={"col-lg-3 fw-bold text-end"}>&pound;500</div>
                  </div>
                </div>
              <div className={`${styles.pricingRow}`}>
                <div className={`row`}>
                  <div className={"col-lg-9"}>Cognitive Test, Report & consultation</div>
                  <div className={"col-lg-3 fw-bold text-end"}>&pound;400</div>
                </div>
              </div>

              <div className={`${styles.pricingRow}`}>
                <div className={`row`}>
                  <div className={"col-lg-9"}>Mental Health Test, report, and Consultation</div>
                  <div className={"col-lg-3 fw-bold text-end"}>&pound;400</div>
                </div>
              </div>


              <div className={`${styles.pricingRow}`}>
                <div className={`row`}>
                  <div className={"col-lg-9"}>Neurofeedback 20 session</div>
                  <div className={"col-lg-3 fw-bold text-end"}>&pound;2000</div>
                </div>
              </div>


              <div className={`${styles.pricingRow}`}>
                <div className={`row`}>
                  <div className={"col-lg-9"}>Biofeedback 20 Session</div>
                  <div className={"col-lg-3 fw-bold text-end"}>&pound;2000</div>
                </div>
              </div>


              <div className={`${styles.pricingRow}`}>
                <div className={`row`}>
                  <div className={"col-lg-9"}>Tdcs headset & 2 months subscription with personalized protocol</div>
                  <div className={"col-lg-3 fw-bold text-end"}>&pound;3000</div>
                </div>
              </div>


              <div className={`${styles.pricingRow}`}>
                <div className={`row`}>
                  <div className={"col-lg-9"}>PBM Headset & 2 months subscription with personalized protocol</div>
                  <div className={"col-lg-3 fw-bold text-end"}>&pound;3000</div>
                </div>
              </div>
            </div>
        </div>
      </>
    }

    <div className={`${styles.PricingPlan} wrapper d-flex flex-column align-items-end`}>

      <div className={`${styles.pricingP_content_container} d-flex flex-row justify-content-between`}>
        <div className={`${styles.pricingP_header_content} d-flex flex-column align-items-start`}>
          <article>
            <p>Combination</p>
            <h1>Pricing plans</h1>
          </article>
        </div>
        <form className="d-flex flex-row align-items-start" action="" method="post">
          {plans.map((plan, index) => (
            // <div
            //   key={index}
            //   className={`${index === plans.length -1 ? 'radio-input-active-container-combination' : 'radio-input-container-combination'} d-flex flex-column align-items-center position-relative ${
            //     activeIndex === index ? 'active' : ''
            //   }`}
            //   onClick={() => handleItemClick(index)}
            // >
            <div
              key={index}
              className={`${styles.radio_input_container} d-flex flex-column align-items-center position-relative ${
                activeIndex === index ? styles.active : styles.inactive
              }`}
              onClick={() => handleItemClick(index)}
            >
              <input 
                type="radio" 
                checked={activeIndex === index} 
                readOnly
                className={styles.redioInput}
              />
              <article className={`d-flex flex-column align-items-center justify-content-center`}>
                <div className={styles.package_name_btn}>{plan.name}</div>
                <p className={`${activeIndex === index ? styles.activeText : styles.inactiveText}`}>{plan.description}</p>
                <h2 className={`${activeIndex === index ? styles.activeText : styles.inactiveText}`}>{plan.price}</h2>
              </article>
            </div>
          ))}
        </form>
      </div>
      <div className={`${styles.plans_feature_container} d-flex flex-column`}>
        <ul>
          {features.map((feature, index) => (
            <li className='d-flex flex-row justify-content-between' key={index}>
              <article><p>{feature.title}</p></article>
              <div className={`${styles.pros_cons} d-flex flex-row justify-content-between align-items-center`}>
                  {feature.availability.map((avail, availIndex) => (
                  <div className={`${styles.prosCons_img_container} d-flex justify-content-center align-items-center`} key={availIndex}>
                    <img src={avail} alt="" />
                  </div>
                ))}
              </div>
            </li>
          ))}
        </ul>
      </div>
      <a href="https://connect.pabau.com/packages2.php?compid=13311" className={styles.pricingP_btn}>Book Now</a>
    </div>
    </>
  );
};

export default PricingPlan;