import React from 'react'
import { SEO, Navbar, Footer, CognitivePricing, CognitiveSubs } from '../../Components'
//whatsapp chat widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { ReactComponent as CompanyIcon } from '../../assets/images/logo192.svg';
import { Link } from 'react-router-dom';


const MultiModalLexicalMemoryTest = () => {
  return (
    <>
      <SEO
        title = 'Cognitive Tests by DeepSynaps - Assess and Enhance Your Cognitive Abilities'
        description= 'Take our comprehensive cognitive tests at DeepSynaps to assess your cognitive abilities across various domains. Gain valuable insights into your strengths and weaknesses, and explore personalized recommendations for cognitive enhancement.'
        url = 'https://oxfordmindtech.com/cognitivetest'
        keywords = 'Cognitive tests, DeepSynaps, assess cognitive abilities, personalized recommendations, cognitive enhancement, strengths and weaknesses'
      />    
      {/* <SEO
        title = '' 
        description= ''
        url = ''
        keywords = 'DeepSynaps about brainScan-DeepSynaps brainScan brain cognitive-test brain-test brain-scan services price'
      />     */}
      <Navbar />
        <header id='headerDiagnostic' className='d-flex flex-column'>
            <article className='wrapper d-flex flex-row align-items-center justify-content-between'>
                <h1>Cognitive Test</h1>
                <div className='d-flex flex-column position-relative'>
                    <p className='headerDiagnosticArticleP'>At DeepSynaps, we offer state-of-the-art diagnostic services to help our clients better understand their brain function and identify potential issues that may be impacting their overall well-being. Our diagnostic services include:</p>
                    <br />
                    <br /><br />
                    <a className='navbar-Respbtn cgntveTest-Btnpstn' href='https://connect.pabau.com/bookings.php?compid=13311' target='_blank' rel="noopener noreferrer">Book Now</a>
                </div>
            </article>
            <div className="breakline-diagnostic"></div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-column justify-content-between align-items-start">
                <iframe className='embed-ytb' src="https://www.youtube-nocookie.com/embed/VGu5eAyZMks"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <div
                className="wrapper headerDiagnostic-content-container d-flex flex-row justify-content-between align-items-start">
                <div className="headerDiagnostic-content d-flex flex-row align-items-start">
                    <div className="diagnosticArticle3 d-flex flex-column align-items-start corporatePackages">

                        <div className={"row w-100"}>
                            <div className={"col-md-8 col-sm-12"}><h1>Multi-Modal Lexical Memory Test</h1></div>
                            <div className={"col-md-4 col-sm-12 text-md-end text-sm-left pt-1"}>
                                <Link target={"_blank"} className={"btn btn-outline-dark"} to={'https://www.cognifit.com/battery-of-tests/com-nam-test/identification-test'}>Start test now</Link>
                            </div>
                        </div>

                        <p>The test focuses on exploring memory functions with varied lexical and semantic stimuli, gauging the individual's ability to recall and recognize words across different presentation modalities and contextual cues.</p>
                        <p><strong>Relation to QEEG, Neurofeedback, Biofeedback, and Neuromodulation:</strong></p>
                        <ul>
                            <li><strong>Brain Region:</strong> Temporal Lobes (hippocampus), Prefrontal Cortex</li>
                            <li><strong>Brodmann Area:</strong> BA 21/22 (Temporal Lobes), BA 10 (Prefrontal Cortex)</li>
                            <li><strong>EEG Electrode Places:</strong> Fp1, Fp2, T3, T4</li>
                            <li>
                                <strong>Neurofeedback:</strong>
                                <ul>
                                    <li>
                                        <strong>Protocol:</strong>
                                        <ul>
                                            <li>Targeting theta (4-8 Hz) frequencies at temporal and prefrontal areas for enhancing memory encoding and retrieval.</li>
                                            <li>Beta (13-30 Hz) may also be focused upon for maintaining attentional engagement during memory tasks.</li>
                                        </ul>
                                    </li>
                                    <li><strong>Objective:</strong> To stabilize and optimize memory processes by modulating brainwave patterns associated with memory and attention in relevant regions.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Biofeedback:</strong>
                                <ul>
                                    <li><strong>Assessment:</strong> HRV and GSR can be monitored to gauge emotional and physiological states during memory tasks.</li>
                                    <li><strong>Protocol:</strong> Techniques for maintaining a calm and stable physiological state, like controlled breathing or mindfulness exercises, might be employed.</li>
                                    <li><strong>Objective:</strong> Ensure that physiological stress or distraction does not hinder memory encoding and retrieval processes.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Neuromodulation:</strong>
                                <ul>
                                    <li>
                                        <strong>tDCS:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Temporal Lobes, Prefrontal Cortex</li>
                                            <li><strong>Protocol:</strong> Anodal stimulation may be applied to the temporal lobes or prefrontal areas to potentially enhance memory-related neuronal activity.</li>
                                            <li><strong>Objective:</strong> Facilitate and enhance the neural processes underpinning memory encoding, storage, and retrieval.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>PBM:</strong>
                                        <ul>
                                            <li><strong>Target Area:</strong> Temporal Lobes, Frontal Cortex</li>
                                            <li><strong>Protocol:</strong> Utilize photobiomodulation to stimulate neuronal health and activity in memory-related brain regions.</li>
                                            <li><strong>Objective:</strong> Promote neuronal health and efficiency, aiding in the sustained functionality and potential recovery of memory-related processes.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Mental Health Assessments and Their Relation:</strong>
                                <ul>
                                    <li><strong>DASS 21/42:</strong> Levels of depression, anxiety, and stress can influence cognitive processes, particularly memory, and might be crucial for understanding and contextualizing test performance.</li>
                                    <li><strong>SISRI-24:</strong> As a scale assessing self-efficacy in social interactions, individuals with challenges in this area might exhibit stress or anxiety during testing, potentially impacting memory performance.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>Connecting lexical memory assessments with physiological and neural interventions provides a rounded approach to understanding and enhancing cognitive performance. Interventions, whether they are neurofeedback, biofeedback, or neuromodulation, need to be uniquely tailored, considering an individual’s cognitive, emotional, and physiological profile to ensure they are targeted and effective.</p>
                    </div>
                </div>
            </div>
        </header>
      <br /> <br />
      <CognitivePricing />
      <CognitiveSubs />
      <Footer />
      <WhatsAppWidget 
        sendButtonText={`Start Chat`} 
        companyName={`DeepSynaps`}
        message={`Hey there 👋\n\nI'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`} 
        CompanyIcon={CompanyIcon} 
        phoneNumber="447429910079" 
      />      
    </>
  )
}

export default MultiModalLexicalMemoryTest